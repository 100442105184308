<template>
  <section class="data-sectoin">
    <p class="title">Datenschutz</p>
    <div>
      <p class="subtitle">Verantwortlicher für den Inhalt</p>
      <div class="list">
        <p class="text bolt">Marc Ruffing</p>
        <a href="mailto:info@dfki.de" class="email">Marc.Ruffing@dfki.de</a>
        <a href="tel:490631205751826" class="email phone"
          >+49 0631 20575 1826</a
        >
      </div>
    </div>
    <div>
      <p class="subtitle">Urheberrecht</p>
      <p class="text">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Fremdlinks und Haftungsausschluss Die
        Verweise durch Links auf Inhalte fremder Internetseiten dienen lediglich
        der Information. Die Verantwortlichkeit für diese fremden Inhalte liegt
        alleine bei dem Anbieter, der die Inhalte bereithält; die Inhalte werden
        nicht zu eigen gemacht. Eine Haftung für eventuelle Datenschutz- und
        sonstige Rechtsverletzungen in anderen Angeboten, auf die wir einen Link
        gesetzt haben, übernehmen wir nicht.
      </p>
    </div>
    <div>
      <p class="subtitle">Datenschutzerklärung</p>
      <p class="text">
        Der Schutz Ihrer personenbezogenen Daten ist ein Grundrecht. Das TACNET
        4.0-Konsortium verarbeiten Ihre personenbezogenen Daten nur nach Treu
        und Glauben für festgelegte Zwecke. In dieser Datenschutzerklärung
        informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten
        bei Besuch und Benutzung unserer Website. Der Besuch und die Benutzung
        unserer Website sind grundsätzlich ohne Erhebung personenbezogener Daten
        möglich. Personenbezogene Daten werden auf unserer Webseite nur im
        technisch notwendigen Umfang erhoben. Die erhobenen Daten werden außer
        aufgrund konkreter gesetzlicher Verpflichtung nicht an Dritte
        weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im
        Internet beim Besuch und der Benutzung unserer Website geschützt erfolgt
        und somit von Dritten nicht auf einfache Weise belauscht oder verfälscht
        werden kann.
      </p>
    </div>
    <div>
      <p class="subtitle">Zugriffsdaten</p>
      <p class="text">
        Bei jedem Zugriff auf unsere Website werden Nutzungs-, Verkehrs- und
        Verbindungsdaten erhoben und vorübergehend in einer Protokolldatei
        gespeichert.
      </p>
    </div>
    <div>
      <p class="subtitle subtitle-large">
        Über jeden Zugriff/Abruf werden folgende Daten gespeichert:
      </p>
    </div>
    <div>
      <p class="subtitle">IP-Adresse</p>
      <div class="list">
        <ul class="text">
          <li>
            übermittelte User-Agent Information (insb. Typ/Version von
            Webbrowser, Betriebssystem, usw.)
          </li>
          <li>
            übermittelte Referrer Information (URL der verweisenden Seite)
          </li>
          <li>Datum und Uhrzeit des Zugriffs/Abrufs</li>
          <li>übermittelte Zugriffsmethode/Funktion</li>
          <li>übermittelte Eingabewerte (Suchbegriffe, usw.)</li>
          <li>aufgerufene Seite bzw. abgerufene Datei</li>
          <li>übertragene Datenmenge</li>
          <li>Status der Verarbeitung des Zugriffs/Abrufs</li>
        </ul>
        <p class="text">
          Die gespeicherten Datensätze können statistisch ausgewertet werden, um
          unsere Website an die Bedürfnisse unserer Besucher anpassen und
          optimieren zu können. Techniken, die es ermöglichen, das
          Zugriffsverhalten der Benutzer nachzuvollziehen, werden nicht
          eingesetzt. Die Erstellung von Benutzerprofilen (Tracking) ist
          ausgeschlossen.
        </p>
        <p class="text">
          Die gespeicherten Datensätze sind nicht bestimmten Personen
          zuordenbar. Eine Zusammenführung mit anderen Datenquellen wird
          grundsätzlich nicht vorgenommen. Wir behalten uns jedoch vor, die
          gespeicherten Daten nachträglich zu prüfen, wenn uns konkrete
          Anhaltspunkte für eine rechtswidrige Benutzung bekannt werden.
        </p>
      </div>
    </div>
    <div>
      <p class="subtitle">Cookies</p>
      <p class="text">
        Auf unserer Website verwenden wird sogenannte Cookies. Cookies sind
        kleine Dateien, die von Ihrem Webbrowser gespeichert werden. Die auf
        unserer Website verwendeten Cookies richten auf Ihrem Rechner keinen
        Schaden an und enthalten keine Schadsoftware. Sie ermöglichen eine
        benutzerfreundliche und effektive Nutzung unserer web site. Wir
        übertragen an Ihren Webbrowser so genannte Session Cookies. Sie sind nur
        für die Dauer Ihres Besuches auf unserer Website gültig und sie haben
        außerhalb unserer Website keine Bedeutung. Die Session Cookies werden
        benötigt, um Ihre Sitzung während Ihres Besuches mit einer eindeutigen
        Nummer zu identifizieren und unsere Inhalte in der von Ihnen bevorzugten
        Sprache zu übertragen. Nach Ende Ihres Besuches werden die Session
        Cookies durch Beendigung Ihres Webbrowsers automatisch gelöscht. Wir
        übertragen an Ihren Webbrowser auch permanente Cookies mit einer
        Gültigkeitsdauer von höchstens 365 Tagen. Wir verwenden diese Cookies
        ausschließlich dazu, bei Ihrem nächsten Besuch unserer Website Ihre
        Einstellungen für Art der Darstellung (normal, invertiert) und für die
        Schriftgröße zu beachten. Weiterhin wird vermerkt, ob Sie die
        Information über die Verwendung von Cookies in Ihrem Webbrowser zur
        Kenntnis genommen haben. Sie können Ihren Webbrowser so einstellen, dass
        Sie über das Setzen von Cookies informiert werden und Cookies nur im
        Einzelfall erlauben bzw. die Annahme von Cookies für bestimmte Fälle
        oder generell ausschließen. Sie können auch das automatische Löschen von
        Cookies beim Beenden Ihres Webrowsers einstellen. Bei der Deaktivierung
        von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
        In jedem Fall steht unser Informationsangebot in vollem Umfang zur
        Verfügung.
      </p>
    </div>
    <div>
      <p class="subtitle">Social Media</p>
      <p class="text">
        Wir binden keine Social Media Plugins in unserer Website ein. Wenn Sie
        unsere Website besuchen, werden daher keine Daten an Social Media
        Services übermittelt. Eine Profilbildung durch Dritte ist somit
        ausgeschlossen. Sie haben jedoch die Möglichkeit, direkt zu unseren
        Informationsdiensten auf Facebook, Twitter und YouTube zu wechseln. Für
        die Benutzung dieser Dienste verweisen wir auf die
        Datenschutzbestimmungen der jeweiligen Diensteanbieter.
      </p>
    </div>
    <div>
      <p class="subtitle">Korrespondenz</p>
      <p class="text">
        Sie haben die Möglichkeit, uns per E-Mail zu kontaktieren. Ihre
        E-Mail-Adresse und andere personenbezogene Kontaktdaten verwenden wir
        ausschließlich für die Korrespondenz mit Ihnen. Aufgrund gesetzlicher
        Verpflichtung wird jede E-Mail-Korrespondenz archiviert. Soweit zur
        Wahrung unserer berechtigten Interessen erforderlich, können Ihre
        E-Mail-Adresse und andere personenbezogene Kontaktdaten aus der
        Korrespondenz in unserer Kontaktdatenbank gespeichert werden.
      </p>
    </div>
  </section>
</template>
