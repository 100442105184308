<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Smart Agriculture</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        The OpenLab Smart Agriculture focuses on the broad field of connected
        and intelligent agriculture.
      </p>
      <p class="info-modal-text">
        On an agricultural area outside the city center of Kaiserslautern, the
        OpenLab offers a large experimental area on which a large number of
        possible application scenarios from the field of agriculture can be
        mapped.
      </p>
      <p class="info-modal-text">
        One of the main focuses of the OpenLab is on non-terrestrial networks
        (NTN). For this purpose, the test area is currently covered with a
        mobile 5G cellular network (stand-alone). To map mobile network nodes,
        drones and rovers are equipped with SDR (Software Definded Radio)
        hardware and act as location-independent transmitting stations. Another
        focus is on device-to-device communication (D2D) between the mobile
        robots.
      </p>
    </div>
    <div>
      <img src="@/assets/images/groups/smart/01.png" alt="" width="100%" />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        Drone equipped with SDR hardware
      </p>
    </div>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        Use cases/ Social impact
      </p>
      <p class="info-modal-text">
        The OpenLab Smart Agriculture shows a possible scenario for a
        sustainable and resource-conserving agriculture. In times of a growing
        shortage of skilled workers, farmers' time must be used in the best
        possible way and tasks must be supported or outsourced by new
        technologies where possible. The OpenLab offers a variety of conceivable
        use cases that can be tested on site in a real environment.
      </p>
    </div>
    <div class="smart-images">
      <div>
        <img src="@/assets/images/groups/smart/02.png" alt="" width="100%" />
        <p class="info-modal-text info-modal-text-small info-modal-text-right">
          Robot for weed control
        </p>
      </div>
      <div>
        <img src="@/assets/images/groups/smart/03.png" alt="" width="100%" />
        <p class="info-modal-text info-modal-text-small info-modal-text-right">
          Agricultural test environment outside Kaiserslautern
        </p>
      </div>
    </div>
    <div>
      <p class="info-modal-text info-modal-text-bolt">Technical details</p>
      <ul class="info-modal-text">
        <li>Mobile 5G SA mobile network</li>
        <li>Robot fleet and drones as test vehicles</li>
      </ul>
    </div>
    <div>
      <p class="info-modal-text">
        <span class="info-modal-text-bolt"> Contact person/location</span>
        <br />
        Division of Wireless Communications and Radio Positioning (WICON)
        Paul-Ehrlich-Straße 11, 67663 Kaiserslautern
      </p>
      <ul class="info-modal-text">
        <li>Hans D. Schotten, schotten@eit.uni-kl.de</li>
        <li>Marc Kalter, kalter@eit.uni-kl.de</li>
      </ul>
    </div>

    <div>
      <p class="info-modal-text info-modal-text-small info-modal-text-bolt">
        Publications:
      </p>
      <p class="info-modal-text info-modal-text-small">
        https://arxiv.org/abs/2305.00430
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
.smart-images {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  img {
    object-fit: cover;
    max-height: 278px;
    width: 100%;
  }

  @media (min-width: 786px) {
    grid-template-columns: 1fr 1fr;
    gap: 60px;
  }
}
.smart-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 52px;
}
</style>
