<template>
  <div
    class="worlds-tv-groups-item worlds-group-4 robo-animation-wrap drone-animation-wrap"
  >
    <worlds4-border class="worlds4-border" />
    <worlds4-el1 @click="smallModalOpened = 1" class="worlds4-el1" />
    <worlds4-el2 @click="smallModalOpened = 2" class="worlds4-el2" />
    <worlds4-el3 @click="smallModalOpened = 3" class="worlds4-el3" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content="{ value }">
      <div class="small-modal-content">
        <worlds4-el1 v-if="value === 1" class="small-modal-image" />
        <worlds4-el2 v-else-if="value === 2" class="small-modal-image" />
        <worlds4-el3 v-else-if="value === 3" class="small-modal-image" />
        <p class="small-modal-title">Teleoperation</p>
        <p class="small-modal-text">
          Testbed for wireless robotic manipulation
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <worlds4-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Worlds4Border from "@/components/Worlds/Worlds4/components/Worlds4Border.vue";
import Worlds4El1 from "@/components/Worlds/Worlds4/components/Worlds4El1.vue";
import Worlds4El2 from "@/components/Worlds/Worlds4/components/Worlds4El2.vue";
import Worlds4El3 from "@/components/Worlds/Worlds4/components/Worlds4El3.vue";
import AppModal from "@/components/AppModal.vue";
import Worlds4ModalContent from "@/components/Worlds/Worlds4/components/Worlds4ModalContent.vue";

const smallModalOpened = ref<number | false>(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.worlds-group-4 {
  position: relative;
  display: flex;
  width: 1700px;
  height: 1100px;

  .worlds4-border {
    pointer-events: none;
    position: absolute;
    top: 103px;
    left: 0;
  }
  .worlds4-el1 {
    position: absolute;
    top: 261px;
    left: 359px;
  }
  .worlds4-el2 {
    position: absolute;
    top: 0;
    left: 741px;
  }
  .worlds4-el3 {
    position: absolute;
    top: 458px;
    left: 817px;
  }
}
</style>
