<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Application-Driven 6G</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        In this OpenLab, Wireless control systems and control loops are
        implemented. Our aim is to create an adaptive manufacturing system using
        flexible and scalable network solutions.
      </p>
      <p class="info-modal-text">
        The object of consideration is a machine tool with Computerized
        Numerical Control (CNC). The CNC control system, which is usually part
        of the machine on the shop floor, is migrated wirelessly. This enables
        the central orchestration and management of the machine park using
        high-performance computers.
      </p>
      <p class="info-modal-text">
        Due to the different requirements of machine tools within a
        manufacturing system, we utilize so-called "underlayer networks" to
        implement dedicated networks that meet the requirements of modern
        manufacturing environments. Communication solutions based on the
        TokenRing protocol are used, which enable circular communication between
        the integrated devices. Moreover, the different rings are integrated
        into a mobile communication network. This combines the reliability of
        mobile networks with the advantages of peer-to-peer communication.
      </p>
      <p class="info-modal-text">
        Another advantage is the possibility to manage the radio range. In
        particular, location-independent machines such as automated guided
        vehicle can use this communication concept to navigate through large
        factory halls and continuously establish new peer-to-peer 0
      </p>
    </div>
    <div class="driven-images">
      <div>
        <img
          src="@/assets/images/groups/appDriven/01.png"
          alt=""
          width="100%"
        />
        <p class="info-modal-text info-modal-text-small info-modal-text-right">
          The real CNC machine tool
        </p>
      </div>
      <div>
        <img
          src="@/assets/images/groups/appDriven/02.png"
          alt=""
          width="100%"
        />
        <p class="info-modal-text info-modal-text-small info-modal-text-right">
          The digital twin
        </p>
      </div>
    </div>
    <div>
      <p class="info-modal-text">
        <span class="info-modal-text-bolt">Use cases</span> - Possible
        applications and advantages:
      </p>
      <p class="info-modal-text">
        This technology is primarily used in sectors where highly reliable
        networks are required. In addition to economic factors, security factors
        are relevant:
      </p>
      <p class="info-modal-text">Advantages of this technology:</p>
      <ul class="info-modal-text">
        <li>
          Flexibility, reconfigurability, and scalability of manufacturing
          systems
        </li>
        <li>TeaTea</li>
        <li>Support for different network applications</li>
        <li>Reliable communication</li>
        <li>Efficient utilisation of radio spectrum</li>
      </ul>
    </div>
    <div>
      <p class="info-modal-text">
        <span class="info-modal-text-bolt">Social Impact</span> - Possible
        applications and advantages:
      </p>
      <ul class="info-modal-text">
        <li>
          A flexible manufacturing landscape is the backbone of prosperity in
          Germany, and digitalised production is therefore essential for
          maintaining Germany as a business location.
        </li>
        <li>
          The development of new, wireless automation technologies enables new
          product categories and education opportunities.
        </li>
        <li>
          The technology can create jobs and professional groups that benefit
          from the ongoing digitalization of the industry.
        </li>
      </ul>
    </div>
    <div>
      <p class="info-modal-text info-modal-text-bolt">Technical details</p>
      <p class="info-modal-text">
        Wireless implementation of an open-source machine control system.
      </p>
    </div>
    <div>
      <p class="info-modal-text info-modal-text-small info-modal-text-bolt">
        Publications:
      </p>
      <p class="info-modal-text info-modal-text-small">
        Lindenschmitt, D., Mertes, J., Schellenberger, C., Schmitz, M., Han, B.,
        Aurich, J. C., & Schotten, H. D. (2023). 6G Underlayer Network Concepts
        for Ultra Reliable and Low Latency Communication in Manufacturing
        (Version 1). arXiv. https://doi.org/10.48550/ARXIV.2309.10624
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
.driven-images {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  img {
    object-fit: cover;
    max-height: 278px;
    width: 100%;
  }

  @media (min-width: 786px) {
    grid-template-columns: 1fr 1fr;
    gap: 60px;
  }
}
.driven-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 52px;
}
</style>
