<template>
  <svg
    width="209"
    height="542"
    viewBox="0 0 209 542"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M190.75 316.526L190.631 358.604L104.089 408.932L104.209 366.855L190.75 316.526Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M104.209 366.855L104.089 408.933L17.0503 358.642L17.1696 316.564L104.209 366.855Z"
      fill="#E0E2E2"
      stroke="black"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M24.4277 334.067L24.5121 329.18L96.8312 370.964L96.7468 375.848L24.4277 334.067Z"
      fill="#8491A1"
    />
    <path
      d="M24.4277 344.872L24.5121 339.985L96.8312 381.769L96.7468 386.653L24.4277 344.872Z"
      fill="#8491A1"
    />
    <path
      d="M24.4277 355.674L24.5121 350.79L96.8312 392.571L96.7468 397.458L24.4277 355.674Z"
      fill="#8491A1"
    />
    <path
      d="M190.75 316.526L104.209 366.855L17.1699 316.564L103.711 266.236L190.75 316.526Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M103.787 279.432V282.178L165.635 317.886L167.98 316.512L103.787 279.432Z"
      fill="#D6D7D8"
    />
    <path
      d="M39.9478 316.558L42.337 317.93L103.786 282.178V279.432L39.9478 316.558Z"
      fill="#D6D7D8"
    />
    <path
      d="M42.3374 317.93L44.0195 318.905L104.142 353.682L163.906 318.905L165.635 317.886L103.787 282.178L42.3374 317.93Z"
      fill="#3D566E"
    />
    <path
      d="M133.047 314.176L133.033 319.026C133.021 323.341 130.188 327.649 124.53 330.94C118.118 334.206 111.026 335.909 103.831 335.909C96.6368 335.909 89.5443 334.206 83.1326 330.94C77.3869 327.621 74.5149 323.253 74.5274 318.889L74.5408 314.038C74.5284 318.402 77.4008 322.77 83.1461 326.09C89.5579 329.356 96.6504 331.058 103.845 331.058C111.039 331.058 118.132 329.356 124.544 326.09C130.202 322.799 133.035 318.491 133.047 314.176Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.408 302.093C135.874 308.717 135.934 319.465 124.544 326.09C118.132 329.356 111.039 331.058 103.845 331.058C96.6505 331.058 89.5579 329.356 83.1461 326.09C71.7193 319.488 71.6586 308.74 83.0491 302.115C89.4539 298.851 96.5387 297.148 103.726 297.144C110.914 297.141 118 298.836 124.408 302.093Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M127.58 371.133L127.612 383.091L111.667 392.306L111.632 380.345L127.58 371.133Z"
      fill="#8491A1"
    />
    <path
      d="M173.769 354.85L173.772 356.423L132.138 380.476L132.135 378.906L173.769 354.85Z"
      fill="#8491A1"
    />
    <path
      d="M173.757 351.387L173.763 352.96L132.129 377.013L132.124 375.443L173.757 351.387Z"
      fill="#8491A1"
    />
    <path
      d="M173.748 347.924L173.754 349.497L132.121 373.55L132.115 371.98L173.748 347.924Z"
      fill="#8491A1"
    />
    <path
      d="M173.74 344.461L173.743 346.052L132.109 370.108L132.106 368.518L173.74 344.461Z"
      fill="#8491A1"
    />
    <path
      d="M181.212 341.111C183.615 339.723 185.596 340.815 185.604 343.597C185.52 345.111 185.081 346.585 184.324 347.898C183.566 349.212 182.51 350.329 181.241 351.159C178.822 352.557 176.874 351.408 176.866 348.645C176.942 347.135 177.376 345.664 178.132 344.354C178.888 343.044 179.943 341.933 181.212 341.111Z"
      fill="#8491A1"
    />
    <path
      d="M190.549 268.332L190.43 310.41L103.892 360.738L104.011 318.661L190.549 268.332Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M104.011 318.661L103.891 360.739L16.8496 310.448L16.9689 268.37L104.011 318.661Z"
      fill="#E0E2E2"
      stroke="black"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M24.23 285.873L24.3115 280.986L96.6305 322.77L96.5461 327.654L24.23 285.873Z"
      fill="#8491A1"
    />
    <path
      d="M24.23 296.676L24.3115 291.791L96.6305 333.572L96.5461 338.459L24.23 296.676Z"
      fill="#8491A1"
    />
    <path
      d="M24.23 307.481L24.3115 302.596L96.6305 344.377L96.5461 349.264L24.23 307.481Z"
      fill="#8491A1"
    />
    <path
      d="M190.549 268.332L104.011 318.661L16.9688 268.37L103.51 218.042L190.549 268.332Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M103.586 231.235V233.981L165.434 269.693L167.78 268.318L103.586 231.235Z"
      fill="#D6D7D8"
    />
    <path
      d="M39.7471 268.362L42.1393 269.736L103.586 233.981V231.235L39.7471 268.362Z"
      fill="#D6D7D8"
    />
    <path
      d="M42.1396 269.736L43.8218 270.709L103.941 305.488L163.708 270.709L165.434 269.693L103.586 233.981L42.1396 269.736Z"
      fill="#3D566E"
    />
    <path
      d="M132.847 265.981L132.833 270.831C132.821 275.146 129.988 279.455 124.33 282.746C117.918 286.012 110.825 287.714 103.631 287.714C96.4366 287.714 89.3441 286.012 82.9324 282.746C77.1866 279.427 74.3147 275.059 74.3272 270.694L74.3406 265.844C74.3282 270.208 77.2006 274.576 82.9459 277.895C89.3577 281.161 96.4502 282.864 103.645 282.864C110.839 282.864 117.932 281.161 124.344 277.895C130.002 274.605 132.835 270.296 132.847 265.981Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.208 253.899C135.673 260.523 135.734 271.271 124.344 277.896C117.932 281.161 110.839 282.864 103.645 282.864C96.4503 282.864 89.3578 281.161 82.946 277.896C71.5191 271.293 71.4584 260.545 82.8489 253.921C89.2537 250.657 96.3386 248.954 103.526 248.95C110.713 248.946 117.8 250.642 124.208 253.899Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M127.38 322.936L127.415 334.898L111.467 344.109L111.435 332.151L127.38 322.936Z"
      fill="#8491A1"
    />
    <path
      d="M173.568 306.656L173.574 308.226L131.94 332.282L131.935 330.713L173.568 306.656Z"
      fill="#8491A1"
    />
    <path
      d="M173.559 303.194L173.562 304.763L131.929 328.819L131.926 327.25L173.559 303.194Z"
      fill="#8491A1"
    />
    <path
      d="M173.548 299.731L173.554 301.301L131.92 325.357L131.914 323.787L173.548 299.731Z"
      fill="#8491A1"
    />
    <path
      d="M173.539 296.268L173.545 297.858L131.911 321.911L131.905 320.324L173.539 296.268Z"
      fill="#8491A1"
    />
    <path
      d="M181.013 292.917C183.415 291.528 185.397 292.62 185.404 295.402C185.32 296.917 184.882 298.39 184.124 299.704C183.366 301.018 182.31 302.135 181.042 302.965C178.622 304.362 176.674 303.214 176.666 300.451C176.743 298.94 177.177 297.469 177.932 296.16C178.688 294.85 179.744 293.738 181.013 292.917Z"
      fill="#8491A1"
    />
    <path
      d="M190.663 219.262L190.543 261.34L104.005 311.669L104.121 269.594L190.663 219.262Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M104.121 269.593L104.005 311.668L16.9629 261.381L17.0822 219.303L104.121 269.593Z"
      fill="#E0E2E2"
      stroke="black"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M24.3403 236.804L24.4247 231.92L96.7438 273.7L96.6594 278.587L24.3403 236.804Z"
      fill="#8491A1"
    />
    <path
      d="M24.3403 247.608L24.4247 242.721L96.7438 284.505L96.6594 289.389L24.3403 247.608Z"
      fill="#8491A1"
    />
    <path
      d="M24.3403 258.413L24.4247 253.526L96.7438 295.309L96.6594 300.193L24.3403 258.413Z"
      fill="#8491A1"
    />
    <path
      d="M190.662 219.262L104.121 269.593L17.082 219.303L103.623 168.974L190.662 219.262Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M103.699 182.168V184.914L165.547 220.622L167.893 219.248L103.699 182.168Z"
      fill="#D6D7D8"
    />
    <path
      d="M39.8608 219.294L42.253 220.666L103.699 184.914V182.168L39.8608 219.294Z"
      fill="#D6D7D8"
    />
    <path
      d="M42.2529 220.666L43.935 221.642L104.054 256.421L163.822 221.642L165.547 220.622L103.699 184.914L42.2529 220.666Z"
      fill="#3D566E"
    />
    <path
      d="M132.96 216.912L132.947 221.763C132.934 226.078 130.102 230.386 124.443 233.677C118.031 236.943 110.939 238.645 103.744 238.645C96.5499 238.645 89.4574 236.943 83.0457 233.677C77.2999 230.358 74.428 225.99 74.4405 221.625L74.4539 216.775C74.4415 221.139 77.3139 225.507 83.0592 228.827C89.4709 232.093 96.5635 233.795 103.758 233.795C110.952 233.795 118.045 232.093 124.457 228.827C130.116 225.536 132.948 221.227 132.96 216.912Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.321 204.83C135.787 211.454 135.847 222.202 124.457 228.827C118.045 232.093 110.953 233.795 103.758 233.795C96.5636 233.795 89.471 232.093 83.0592 228.827C71.6324 222.224 71.5717 211.477 82.9622 204.852C89.367 201.588 96.4518 199.885 103.639 199.881C110.827 199.877 117.913 201.573 124.321 204.83Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M127.493 273.869L127.528 285.827L111.58 295.042L111.548 283.08L127.493 273.869Z"
      fill="#8491A1"
    />
    <path
      d="M173.681 257.589L173.687 259.159L132.054 283.215L132.048 281.642L173.681 257.589Z"
      fill="#8491A1"
    />
    <path
      d="M173.673 254.126L173.676 255.696L132.042 279.752L132.039 278.179L173.673 254.126Z"
      fill="#8491A1"
    />
    <path
      d="M173.661 250.664L173.667 252.233L132.033 276.289L132.027 274.717L173.661 250.664Z"
      fill="#8491A1"
    />
    <path
      d="M173.652 247.201L173.658 248.788L132.021 272.844L132.019 271.254L173.652 247.201Z"
      fill="#8491A1"
    />
    <path
      d="M181.126 243.848C183.528 242.46 185.51 243.551 185.517 246.333C185.434 247.848 184.995 249.321 184.237 250.635C183.479 251.949 182.423 253.066 181.155 253.896C178.735 255.294 176.787 254.145 176.779 251.382C176.856 249.871 177.29 248.401 178.046 247.091C178.801 245.781 179.857 244.67 181.126 243.848Z"
      fill="#8491A1"
    />
    <g clip-path="url(#clip0_1849_1517)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.8716 536.42L83.7105 518.027L45.8716 536.42Z"
        fill="#EFEFEF"
      />
      <path
        d="M45.8716 536.42L83.7105 518.027"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.1761 538.229C48.1403 538.555 48.0229 538.867 47.8342 539.139C47.6455 539.41 47.3912 539.633 47.0936 539.786C45.5966 540.645 43.8768 541.065 42.1403 540.997C40.404 541.063 38.6848 540.643 37.1871 539.786C36.8894 539.633 36.6351 539.41 36.4464 539.139C36.2577 538.867 36.1403 538.555 36.1045 538.229C36.1398 537.901 36.2569 537.587 36.4455 537.313C36.6342 537.039 36.8887 536.814 37.1871 536.658C38.6875 535.808 40.4047 535.388 42.1403 535.447C43.876 535.386 45.5939 535.806 47.0936 536.658C47.3919 536.814 47.6464 537.039 47.835 537.313C48.0237 537.587 48.1408 537.901 48.1761 538.229Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.4424 522.197L92.2814 503.804L54.4424 522.197Z"
        fill="#EFEFEF"
      />
      <path
        d="M54.4424 522.197L92.2814 503.804"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.7465 524.007C56.7106 524.332 56.5932 524.645 56.4045 524.916C56.2158 525.187 55.9615 525.41 55.6639 525.564C54.1669 526.422 52.4471 526.842 50.7106 526.775C48.9743 526.84 47.2551 526.42 45.7574 525.564C45.4598 525.41 45.2054 525.188 45.0167 524.916C44.828 524.645 44.7106 524.332 44.6748 524.007C44.7101 523.679 44.8272 523.364 45.0158 523.09C45.2045 522.816 45.459 522.591 45.7574 522.435C47.2578 521.585 48.975 521.165 50.7106 521.224C52.4464 521.163 54.1642 521.583 55.6639 522.435C55.9622 522.592 56.2167 522.817 56.4054 523.09C56.594 523.364 56.7111 523.679 56.7465 524.007Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.7656 526.602L64.6046 508.209L26.7656 526.602Z"
        fill="#EFEFEF"
      />
      <path
        d="M26.7656 526.602L64.6046 508.209"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0702 528.411C29.0344 528.737 28.917 529.049 28.7282 529.321C28.5395 529.592 28.2852 529.815 27.9876 529.968C26.4906 530.827 24.7708 531.247 23.0343 531.179C21.298 531.245 19.5788 530.825 18.0811 529.968C17.7835 529.815 17.5292 529.592 17.3404 529.321C17.1517 529.049 17.0343 528.737 16.9985 528.411C17.0339 528.084 17.151 527.769 17.3397 527.495C17.5283 527.221 17.7829 526.996 18.0812 526.84C19.5817 525.99 21.2989 525.57 23.0344 525.629C24.7702 525.568 26.488 525.988 27.9877 526.84C28.286 526.996 28.5405 527.221 28.7291 527.495C28.9177 527.769 29.0348 528.084 29.0702 528.411Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M132.345 515.864L170.184 534.257"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M178.689 535.093C178.653 535.419 178.536 535.732 178.347 536.003C178.158 536.275 177.904 536.497 177.606 536.651C176.086 537.445 174.387 537.862 172.66 537.862C170.934 537.862 169.235 537.445 167.715 536.651C167.415 536.497 167.159 536.275 166.968 536.004C166.777 535.733 166.656 535.42 166.617 535.093C166.656 534.765 166.776 534.45 166.967 534.176C167.158 533.903 167.415 533.678 167.715 533.522C169.235 532.727 170.934 532.311 172.66 532.311C174.387 532.311 176.086 532.727 177.606 533.522C177.904 533.678 178.159 533.903 178.348 534.177C178.536 534.451 178.653 534.766 178.689 535.093Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M142.766 510.989L166.03 522.297L177.693 516.628"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M186.225 515.79C186.191 516.116 186.074 516.429 185.885 516.701C185.696 516.973 185.441 517.195 185.142 517.347C183.619 518.142 181.918 518.558 180.189 518.558C178.461 518.558 176.759 518.142 175.236 517.347C174.937 517.195 174.682 516.973 174.493 516.701C174.304 516.429 174.187 516.116 174.153 515.79C174.189 515.462 174.306 515.148 174.494 514.874C174.683 514.6 174.938 514.375 175.236 514.219C176.759 513.423 178.461 513.007 180.189 513.007C181.918 513.007 183.619 513.423 185.142 514.219C185.441 514.375 185.695 514.6 185.884 514.874C186.072 515.148 186.19 515.462 186.225 515.79Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M134.089 537.067L146.036 531.26L123.231 520.174"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M138.76 537.986C138.724 538.314 138.607 538.628 138.418 538.902C138.23 539.176 137.975 539.401 137.677 539.557C136.154 540.353 134.452 540.769 132.724 540.769C130.995 540.769 129.293 540.353 127.771 539.557C127.472 539.401 127.218 539.176 127.029 538.902C126.84 538.628 126.723 538.314 126.688 537.986C126.724 537.66 126.841 537.348 127.03 537.077C127.219 536.805 127.473 536.583 127.771 536.429C129.293 535.634 130.995 535.218 132.724 535.218C134.452 535.218 136.154 535.634 137.677 536.429C137.974 536.583 138.229 536.805 138.418 537.077C138.606 537.348 138.724 537.66 138.76 537.986Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M144.774 506.492L104.666 484.363V483.069L145.931 505.845L144.774 506.492Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.295 484.363L64.4462 506.52L63.2637 505.871L104.295 483.07V484.363Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.633 507.178L64.8203 506.728L104.48 484.676L144.402 506.702L143.558 507.178L104.711 528.778L65.633 507.178Z"
        fill="#3D566E"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M123.543 504.39L123.534 507.409C123.378 509.007 122.783 510.535 121.811 511.833C120.832 513.141 119.516 514.173 117.994 514.827C113.807 516.861 109.19 517.92 104.509 517.92C99.8276 517.92 95.2107 516.861 91.0233 514.827C89.4797 514.172 88.1435 513.129 87.1521 511.805C86.1683 510.491 85.5687 508.942 85.417 507.324L85.4256 504.304C85.5775 505.923 86.1772 507.471 87.161 508.785C88.1524 510.109 89.4886 511.152 91.032 511.807C95.2195 513.841 99.8364 514.9 104.518 514.9C109.199 514.9 113.815 513.841 118.003 511.807C119.525 511.154 120.841 510.121 121.82 508.814C122.792 507.515 123.386 505.988 123.543 504.39Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M117.915 496.868C125.385 500.992 125.424 507.683 118.003 511.807C113.815 513.841 109.199 514.9 104.518 514.9C99.8364 514.9 95.2195 513.841 91.032 511.807C83.5874 507.697 83.5478 501.006 90.9689 496.881C95.1519 494.848 99.7638 493.789 104.44 493.787C109.117 493.784 113.73 494.839 117.915 496.868Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M161.212 475.306L161.133 501.502L104.753 532.835L104.828 506.639L161.212 475.306Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.828 506.639L104.753 532.835L48.042 501.526L48.1202 475.331L104.828 506.639Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M161.212 475.306L104.828 506.639L48.1201 475.331L104.504 443.998L161.212 475.306Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.847 475.943L104.738 453.816V452.522L146.002 475.298L144.847 475.943Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.367 453.817L64.5179 475.971L63.3359 475.324L104.367 452.523V453.817Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.7068 476.631L64.8931 476.179L104.554 454.129L144.476 476.153L143.632 476.631L143.63 476.632L104.785 498.231L65.7068 476.631Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M155.093 490.765C155.355 490.582 155.661 490.468 155.982 490.434C156.303 490.399 156.627 490.446 156.924 490.569C157.185 490.742 157.392 490.981 157.525 491.26C157.657 491.539 157.708 491.848 157.673 492.153C157.619 493.067 157.342 493.956 156.865 494.745C156.384 495.54 155.718 496.213 154.921 496.712C154.659 496.896 154.353 497.009 154.033 497.042C153.712 497.075 153.389 497.025 153.094 496.899C152.835 496.722 152.63 496.48 152.5 496.2C152.369 495.92 152.318 495.611 152.351 495.306C152.401 494.394 152.675 493.508 153.151 492.721C153.63 491.929 154.296 491.259 155.093 490.765Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.204 489.783L81.8965 474.765L89.9283 470.385L123.383 479.325L117.858 482.338L111.854 480.684L105.112 484.36L109.218 487.049L104.204 489.783ZM102.026 482.346L107.308 479.466L90.5832 474.843L102.026 482.346Z"
        fill="#EFEFEF"
      />
      <path
        d="M125.914 477.946L98.0342 465.965L103.509 462.979L131.391 474.959L125.914 477.946Z"
        fill="#EFEFEF"
      />
      <mask
        id="mask0_1849_1517"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="109"
        y="502"
        width="21"
        height="20"
      >
        <path
          d="M109.935 521.282L109.913 513.718L129.655 502.638L129.677 510.2L109.935 521.282Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1849_1517)">
        <path
          d="M126.265 504.837L113.294 512.118C112.388 512.691 111.634 513.464 111.095 514.375C110.555 515.285 110.245 516.307 110.189 517.356C110.195 519.281 111.591 520.055 113.314 519.088L126.286 511.807C127.194 511.236 127.949 510.464 128.491 509.555C129.032 508.645 129.344 507.623 129.4 506.573C129.395 504.648 127.998 503.864 126.265 504.837Z"
          fill="#8491A1"
        />
        <path
          d="M113.314 519.088C111.591 520.055 110.195 519.281 110.189 517.356C110.245 516.307 110.555 515.285 111.095 514.375C111.635 513.464 112.388 512.691 113.294 512.118L126.265 504.837C127.998 503.864 129.395 504.648 129.4 506.573C129.344 507.623 129.032 508.645 128.491 509.554C127.949 510.464 127.194 511.236 126.286 511.807L113.314 519.088ZM126.265 504.541L113.293 511.821C112.31 512.443 111.492 513.282 110.907 514.27C110.321 515.258 109.984 516.367 109.924 517.505C109.93 519.589 111.45 520.431 113.315 519.384L126.287 512.103C127.271 511.483 128.09 510.645 128.677 509.658C129.264 508.671 129.603 507.563 129.665 506.425C129.659 504.33 128.139 503.488 126.265 504.541Z"
          fill="#3D464D"
        />
      </g>
      <path
        d="M152.672 471.709L152.616 475.866L104.695 502.365L104.748 498.208L152.672 471.709Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.748 498.207L104.694 502.365L56.0205 475.459L56.076 471.302L104.748 498.207Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.774 473.234L104.666 451.105V449.81L145.931 472.587L144.774 473.234Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.295 451.105L64.4462 473.262L63.2637 472.613L104.295 449.811V451.105Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.633 473.92L64.8203 473.47L104.48 451.418L144.402 473.444L143.558 473.92L104.711 495.52L65.633 473.92Z"
        fill="#3D566E"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M123.543 471.132L123.534 474.151C123.378 475.749 122.783 477.277 121.811 478.575C120.832 479.882 119.516 480.915 117.994 481.569C113.807 483.603 109.19 484.662 104.509 484.662C99.8276 484.662 95.2107 483.603 91.0233 481.569C89.4797 480.914 88.1435 479.87 87.1521 478.547C86.1683 477.233 85.5687 475.684 85.417 474.066L85.4256 471.046C85.5775 472.664 86.1772 474.213 87.161 475.527C88.1524 476.851 89.4886 477.894 91.032 478.549C95.2195 480.583 99.8364 481.642 104.518 481.642C109.199 481.642 113.815 480.583 118.003 478.549C119.525 477.896 120.841 476.863 121.82 475.555C122.792 474.257 123.386 472.73 123.543 471.132Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M117.915 463.61C125.385 467.734 125.424 474.425 118.003 478.549C113.815 480.583 109.199 481.642 104.518 481.642C99.8364 481.642 95.2195 480.583 91.032 478.549C83.5874 474.439 83.5478 467.748 90.9689 463.623C95.1519 461.59 99.7638 460.531 104.44 460.529C109.117 460.526 113.73 461.581 117.915 463.61Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M161.212 442.048L161.133 468.243L104.753 499.577L104.828 473.381L161.212 442.048Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.828 473.381L104.753 499.576L48.042 468.268L48.1202 442.073L104.828 473.381Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M161.212 442.048L104.828 473.381L48.1201 442.073L104.504 410.74L161.212 442.048Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.847 442.685L104.738 420.558V419.264L146.002 442.04L144.847 442.685Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.367 420.558L64.5179 442.713L63.3359 442.066L104.367 419.265V420.558Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.7068 443.373L64.8931 442.921L104.554 420.871L144.476 442.895L143.632 443.373L143.63 443.374L104.785 464.973L65.7068 443.373Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M155.093 457.507C155.355 457.324 155.661 457.21 155.982 457.176C156.303 457.141 156.627 457.188 156.924 457.311C157.185 457.484 157.392 457.723 157.525 458.002C157.657 458.281 157.708 458.59 157.673 458.895C157.619 459.809 157.342 460.698 156.865 461.487C156.384 462.282 155.718 462.955 154.921 463.454C154.659 463.638 154.353 463.751 154.033 463.784C153.712 463.817 153.389 463.767 153.094 463.641C152.835 463.464 152.63 463.222 152.5 462.942C152.369 462.662 152.318 462.353 152.351 462.048C152.401 461.136 152.675 460.25 153.151 459.463C153.63 458.671 154.296 458.001 155.093 457.507Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.204 456.525L81.8965 441.507L89.9283 437.127L123.383 446.067L117.858 449.08L111.854 447.426L105.112 451.102L109.218 453.791L104.204 456.525ZM102.026 449.088L107.308 446.208L90.5832 441.585L102.026 449.088Z"
        fill="#EFEFEF"
      />
      <path
        d="M125.914 444.688L98.0342 432.707L103.509 429.721L131.391 441.701L125.914 444.688Z"
        fill="#EFEFEF"
      />
      <mask
        id="mask1_1849_1517"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="109"
        y="469"
        width="21"
        height="20"
      >
        <path
          d="M109.935 488.024L109.913 480.46L129.655 469.381L129.677 476.942L109.935 488.024Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1849_1517)">
        <path
          d="M126.265 471.579L113.294 478.859C112.388 479.433 111.634 480.206 111.095 481.117C110.555 482.027 110.245 483.049 110.189 484.098C110.195 486.023 111.591 486.797 113.314 485.83L126.286 478.549C127.194 477.978 127.949 477.206 128.491 476.297C129.032 475.387 129.344 474.365 129.4 473.315C129.395 471.39 127.998 470.606 126.265 471.579Z"
          fill="#8491A1"
        />
        <path
          d="M113.314 485.83C111.591 486.797 110.195 486.023 110.189 484.098C110.245 483.049 110.555 482.027 111.095 481.117C111.635 480.206 112.388 479.433 113.294 478.859L126.265 471.579C127.998 470.606 129.395 471.39 129.4 473.315C129.344 474.365 129.032 475.387 128.491 476.296C127.949 477.206 127.194 477.978 126.286 478.549L113.314 485.83ZM126.265 471.282L113.293 478.563C112.31 479.185 111.492 480.024 110.907 481.012C110.321 482 109.984 483.109 109.924 484.247C109.93 486.331 111.45 487.173 113.315 486.126L126.287 478.845C127.271 478.225 128.09 477.387 128.677 476.4C129.264 475.413 129.603 474.305 129.665 473.167C129.659 471.072 128.139 470.23 126.265 471.282Z"
          fill="#3D464D"
        />
      </g>
    </g>
    <mask
      id="mask2_1849_1517"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="50"
      y="48"
      width="110"
      height="168"
    >
      <path
        d="M158.886 152.837L50.9893 215.133L50.6955 111.052L158.592 48.7637L158.886 152.837Z"
        fill="white"
        stroke="white"
        stroke-width="0.25"
      />
    </mask>
    <g class="wifi-animation wifi-3" mask="url(#mask2_1849_1517)">
      <path
        d="M104.644 80.3717L104.499 80.4552C83.0943 93.6526 64.9452 111.513 51.4062 132.704L60.0268 139.996C71.4403 122.223 86.7043 107.244 104.689 96.1675L104.786 96.1118C111.248 91.73 118.57 88.7752 126.264 87.4443C133.957 86.1134 141.846 86.4369 149.405 88.3932L158.005 71.1592C142.916 65.9364 123.986 69.2046 104.644 80.3717Z"
        fill="#00C1BC"
      />
      <path
        d="M104.72 107.639L104.624 107.695C89.2127 117.212 76.1387 130.073 66.3696 145.325L74.9902 152.617C82.6016 140.78 92.7776 130.807 104.765 123.435L104.862 123.38C109.161 120.459 114.034 118.489 119.155 117.6C124.276 116.712 129.527 116.924 134.559 118.225L143.159 100.991C136.645 99.3221 129.853 99.0517 123.227 100.198C116.602 101.343 110.295 103.88 104.72 107.639Z"
        fill="#00C1BC"
      />
      <path
        d="M104.806 134.965L104.709 135.021C95.2967 140.811 87.3101 148.648 81.3423 157.949L89.9626 165.241C93.7709 159.364 98.8401 154.408 104.802 150.734L104.899 150.678C107.049 149.228 109.479 148.246 112.033 147.795C114.587 147.344 117.207 147.434 119.723 148.059L128.323 130.825C124.332 129.823 120.175 129.674 116.123 130.387C112.07 131.101 108.214 132.66 104.806 134.965Z"
        fill="#00C1BC"
      />
      <path
        d="M104.883 163.122C102.305 164.798 100.161 167.06 98.6234 169.723C97.086 172.386 96.1996 175.374 96.0361 178.444C96.052 184.042 100.02 186.359 104.94 183.518C107.505 181.84 109.639 179.58 111.168 176.923C112.696 174.266 113.577 171.285 113.738 168.224C113.722 162.626 109.754 160.309 104.883 163.122Z"
        fill="#00C1BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1849_1517">
        <rect
          width="169.524"
          height="130.553"
          fill="white"
          transform="translate(16.8496 410.595)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
