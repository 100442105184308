<template>
  <div
    @click="updateValue(false)"
    class="modal-wrap"
    :class="{
      'modal-wrap-closed': !props.modelValue,
      'modal-wrap-small': props.small,
    }"
  >
    <div @click.stop v-if="opened" class="modal-content">
      <div class="header">
        <button @click="updateValue(false)" class="close-button">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="#5A5F62"
            />
          </svg>
        </button>
      </div>
      <div class="content"><slot name="content" :value="opened" /></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { Z_INDEX } from "@/constants";

// eslint-disable-next-line no-undef
const props = defineProps<{
  modelValue: boolean | number;
  small?: boolean;
}>();
// eslint-disable-next-line no-undef
const emit = defineEmits<{
  (e: "update:modelValue", data: boolean): void;
}>();

const opened = ref(props.modelValue);

const updateValue = (value: boolean) => {
  emit("update:modelValue", value);
};

watch(
  () => props.modelValue,
  (value) => {
    document.body.style.overflowY = value ? "hidden" : "auto";
    if (value) {
      opened.value = value;
    } else {
      setTimeout(() => {
        opened.value = false;
      }, 150);
    }
  }
);

const zIndex = computed(() => Z_INDEX);
</script>

<style scoped>
.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  pointer-events: auto;
  z-index: v-bind(zIndex[ "modal"]);
  background: rgba(255, 255, 255, 0.62);

  .modal-content {
    display: grid;
    grid-template-rows: auto 1fr;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-sizing: border-box;
    overflow: hidden;
    animation: open 0.15s forwards;

    @media (min-width: 786px) {
      width: 60%;
      animation: open-desktop 0.15s forwards;
      right: -100%;
      top: 0;
    }

    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      width: calc(100% - 76px);
    }

    .header {
      position: relative;
      width: 100%;
      height: 0;
      display: flex;
      justify-content: flex-end;

      @media (min-width: 786px) {
        font-size: 48px;
        justify-content: flex-start;
      }

      .close-button {
        display: flex;
        padding: 16px;
        border: none;
        background: transparent;
        cursor: pointer;
        width: 46px;
        height: 46px;

        @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
          width: 60px;
          height: 60px;
          padding: 12px;
        }

        svg {
          width: 100%;
          height: 100%;
        }

        @media (min-width: 786px) {
          position: absolute;
          top: 30px;
          left: 30px;
        }
      }
    }
    .content {
      font-size: 18px;
      font-weight: 400;
      color: #005478;
      overflow-y: auto;
      padding: 24px 48px 86px 24px;

      @media (min-width: 786px) {
        padding: 64px 40px 86px 108px;
      }

      @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
        padding: 132px;
        margin: 0;
      }
    }
  }
}

.modal-wrap-closed {
  pointer-events: none;

  background: rgba(255, 255, 255, 0);
  .modal-content {
    animation: close 0.15s forwards;
    @media (min-width: 786px) {
      animation: close-desktop 0.15s forwards;
    }
  }
}
.modal-wrap-small {
  right: 100%;
  transition: background-color 0.15s;
  .modal-content {
    position: absolute;
    height: auto;
    width: 996px;
    max-width: 100%;

    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      width: calc(100% - 248px);
    }

    top: 50%;
    transform: translateY(-50%) translateX(0);
    animation: open-small 0.15s forwards;
    border-radius: 16px;
    border: 1px solid #aecdd1;

    box-shadow: 0 24px 38px 3px #00547866, 0 11px 15px -7px #00547866;

    .header {
      padding: 0;
      .close-button {
        display: flex;
        position: absolute;
        top: 32px;
        left: auto;
        right: 32px;
        width: 24px;
        height: 24px;
        box-sizing: content-box;
        svg {
          width: 100%;
        }
      }
    }
    .content {
      padding: 80px;
      margin: 0;
    }
  }
}

.modal-wrap-small.modal-wrap-closed .modal-content {
  animation: close-small 0.15s forwards;
}
@keyframes open {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes open-desktop {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes close {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}
@keyframes close-desktop {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}

@keyframes open-small {
  from {
    right: 100%;
    transform: translateY(-50%) translateX(0);
  }
  to {
    right: 50%;
    transform: translateY(-50%) translateX(50%);
  }
}
@keyframes close-small {
  from {
    right: 50%;
    transform: translateY(-50%) translateX(50%);
  }
  to {
    right: 100%;
    transform: translateY(-50%) translateX(0);
  }
}
</style>
