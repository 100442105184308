<template>
  <svg
    width="366"
    height="291"
    viewBox="0 0 366 291"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1609_14472)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M221.476 226.418L260.176 207.606L221.476 226.418Z"
        fill="#EFEFEF"
      />
      <path
        d="M221.476 226.418L260.176 207.606"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M223.832 228.27C223.795 228.603 223.675 228.923 223.482 229.2C223.289 229.478 223.029 229.705 222.725 229.862C221.194 230.74 219.435 231.17 217.659 231.101C215.883 231.168 214.124 230.738 212.593 229.862C212.288 229.705 212.028 229.478 211.835 229.2C211.642 228.923 211.522 228.603 211.485 228.27C211.521 227.935 211.641 227.613 211.834 227.333C212.027 227.053 212.287 226.823 212.593 226.663C214.127 225.793 215.884 225.364 217.659 225.424C219.434 225.362 221.191 225.791 222.725 226.663C223.03 226.823 223.29 227.053 223.483 227.333C223.676 227.613 223.796 227.935 223.832 228.27Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M230.241 211.873L268.942 193.061L230.241 211.873Z"
        fill="#EFEFEF"
      />
      <path
        d="M230.241 211.873L268.942 193.061"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M232.598 213.723C232.561 214.056 232.441 214.376 232.248 214.653C232.055 214.931 231.795 215.158 231.49 215.316C229.959 216.193 228.2 216.624 226.424 216.554C224.648 216.621 222.89 216.192 221.358 215.316C221.054 215.158 220.794 214.931 220.601 214.653C220.408 214.376 220.288 214.056 220.251 213.723C220.287 213.388 220.407 213.066 220.6 212.786C220.793 212.506 221.053 212.276 221.358 212.116C222.893 211.246 224.649 210.817 226.424 210.877C228.2 210.815 229.956 211.244 231.49 212.116C231.795 212.276 232.056 212.506 232.249 212.786C232.442 213.066 232.561 213.388 232.598 213.723Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M201.935 216.378L240.635 197.565L201.935 216.378Z"
        fill="#EFEFEF"
      />
      <path
        d="M201.935 216.378L240.635 197.565"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M204.291 218.228C204.254 218.561 204.134 218.881 203.941 219.158C203.748 219.436 203.488 219.663 203.184 219.82C201.653 220.698 199.894 221.128 198.118 221.059C196.342 221.126 194.583 220.696 193.052 219.82C192.747 219.663 192.487 219.436 192.294 219.158C192.101 218.881 191.981 218.561 191.944 218.228C191.98 217.893 192.1 217.571 192.293 217.291C192.486 217.011 192.747 216.781 193.052 216.621C194.586 215.751 196.343 215.322 198.118 215.382C199.893 215.32 201.65 215.749 203.184 216.621C203.489 216.781 203.749 217.011 203.942 217.291C204.135 217.571 204.255 217.893 204.291 218.228Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M309.918 205.395L348.619 224.207"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M357.317 225.063C357.281 225.396 357.161 225.715 356.967 225.993C356.774 226.271 356.514 226.498 356.21 226.655C354.655 227.468 352.917 227.894 351.152 227.894C349.386 227.894 347.648 227.468 346.093 226.655C345.787 226.498 345.525 226.271 345.329 225.994C345.134 225.716 345.011 225.397 344.971 225.063C345.01 224.727 345.133 224.405 345.328 224.125C345.524 223.845 345.786 223.615 346.093 223.455C347.648 222.642 349.386 222.217 351.152 222.217C352.917 222.217 354.655 222.642 356.21 223.455C356.515 223.616 356.775 223.846 356.968 224.126C357.161 224.406 357.281 224.727 357.317 225.063Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M320.577 200.408L344.371 211.974L356.299 206.176"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M365.025 205.319C364.991 205.652 364.871 205.972 364.678 206.25C364.485 206.528 364.224 206.755 363.918 206.911C362.36 207.724 360.62 208.15 358.852 208.15C357.084 208.15 355.344 207.724 353.786 206.911C353.48 206.755 353.219 206.528 353.026 206.25C352.833 205.973 352.713 205.652 352.679 205.319C352.715 204.983 352.835 204.662 353.028 204.382C353.221 204.102 353.481 203.872 353.786 203.711C355.344 202.898 357.084 202.473 358.852 202.473C360.62 202.473 362.36 202.898 363.918 203.711C364.223 203.872 364.483 204.102 364.676 204.382C364.869 204.662 364.989 204.983 365.025 205.319Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M311.702 227.08L323.921 221.141L300.597 209.803"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M316.478 228.021C316.442 228.356 316.322 228.678 316.129 228.958C315.936 229.238 315.676 229.468 315.371 229.628C313.813 230.441 312.073 230.867 310.305 230.867C308.537 230.867 306.797 230.441 305.239 229.628C304.934 229.468 304.674 229.238 304.481 228.958C304.288 228.678 304.168 228.356 304.132 228.021C304.168 227.688 304.289 227.368 304.482 227.091C304.675 226.813 304.935 226.585 305.239 226.428C306.797 225.615 308.537 225.189 310.305 225.189C312.073 225.189 313.813 225.615 315.371 226.428C315.675 226.585 315.936 226.813 316.129 227.091C316.322 227.368 316.442 227.688 316.478 228.021Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-miterlimit="10"
      />
      <path
        d="M322.63 195.81L281.608 173.176V171.853L323.813 195.148L322.63 195.81Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.23 173.177L240.473 195.838L239.264 195.174L281.23 171.854V173.177Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.687 196.511L240.855 196.051L281.419 173.496L322.249 196.024L321.387 196.511L281.655 218.603L241.687 196.511Z"
        fill="#3D566E"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M300.916 193.659L300.907 196.747C300.747 198.382 300.139 199.944 299.145 201.272C298.144 202.609 296.797 203.665 295.241 204.334C290.958 206.414 286.236 207.497 281.448 207.497C276.661 207.497 271.939 206.414 267.656 204.334C266.077 203.664 264.71 202.597 263.696 201.243C262.69 199.899 262.077 198.315 261.922 196.66L261.931 193.571C262.086 195.227 262.699 196.81 263.706 198.155C264.72 199.508 266.086 200.575 267.665 201.245C271.948 203.326 276.67 204.409 281.457 204.409C286.245 204.409 290.967 203.326 295.25 201.245C296.806 200.577 298.153 199.521 299.154 198.184C300.148 196.856 300.756 195.293 300.916 193.659Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M295.16 185.966C302.8 190.184 302.84 197.027 295.25 201.245C290.967 203.326 286.245 204.409 281.457 204.409C276.67 204.409 271.947 203.326 267.665 201.245C260.05 197.041 260.01 190.198 267.6 185.979C271.878 183.9 276.595 182.817 281.378 182.814C286.161 182.812 290.879 183.891 295.16 185.966Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M339.442 163.913L339.362 190.705L281.697 222.752L281.774 195.96L339.442 163.913Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.775 195.96L281.698 222.752L223.695 190.731L223.775 163.938L281.775 195.96Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M339.442 163.913L281.774 195.96L223.774 163.939L281.442 131.892L339.442 163.913Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M322.704 164.565L281.683 141.934V140.61L323.887 163.906L322.704 164.565Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.302 141.935L240.546 164.594L239.337 163.932L281.302 140.611V141.935Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.762 165.269L240.93 164.806L281.494 142.254L322.325 164.78L321.462 165.269L321.46 165.27L281.73 187.361L241.762 165.269Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M333.185 179.724C333.453 179.537 333.766 179.421 334.094 179.385C334.422 179.35 334.754 179.398 335.057 179.523C335.324 179.701 335.536 179.945 335.671 180.231C335.806 180.516 335.859 180.832 335.824 181.144C335.768 182.079 335.485 182.988 334.997 183.795C334.505 184.608 333.824 185.297 333.008 185.807C332.741 185.994 332.428 186.111 332.1 186.144C331.772 186.178 331.441 186.127 331.14 185.997C330.875 185.816 330.665 185.57 330.532 185.283C330.398 184.997 330.346 184.681 330.38 184.368C330.431 183.436 330.711 182.529 331.198 181.725C331.688 180.915 332.369 180.229 333.185 179.724Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.136 178.72L258.32 163.359L266.535 158.88L300.752 168.024L295.101 171.105L288.96 169.413L282.064 173.173L286.264 175.924L281.136 178.72ZM278.909 171.113L284.311 168.167L267.205 163.439L278.909 171.113Z"
        fill="#EFEFEF"
      />
      <path
        d="M303.34 166.613L274.825 154.36L280.425 151.306L308.942 163.558L303.34 166.613Z"
        fill="#EFEFEF"
      />
      <mask
        id="mask0_1609_14472"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="286"
        y="191"
        width="22"
        height="20"
      >
        <path
          d="M286.997 210.936L286.975 203.2L307.166 191.868L307.189 199.602L286.997 210.936Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1609_14472)">
        <path
          d="M303.699 194.116L290.432 201.563C289.506 202.149 288.735 202.94 288.183 203.871C287.631 204.803 287.314 205.847 287.257 206.921C287.263 208.89 288.691 209.681 290.453 208.692L303.72 201.245C304.649 200.661 305.422 199.872 305.975 198.941C306.529 198.011 306.848 196.966 306.906 195.892C306.9 193.923 305.472 193.121 303.699 194.116Z"
          fill="#8491A1"
        />
        <path
          d="M290.453 208.692C288.69 209.681 287.262 208.89 287.257 206.921C287.314 205.848 287.631 204.803 288.183 203.871C288.735 202.94 289.506 202.149 290.432 201.563L303.699 194.116C305.472 193.121 306.9 193.923 306.905 195.892C306.848 196.966 306.529 198.011 305.975 198.941C305.421 199.872 304.649 200.661 303.72 201.245L290.453 208.692ZM303.698 193.813L290.431 201.26C289.426 201.896 288.59 202.754 287.991 203.764C287.392 204.775 287.047 205.909 286.985 207.073C286.992 209.204 288.546 210.066 290.454 208.995L303.721 201.548C304.727 200.914 305.565 200.057 306.166 199.047C306.766 198.038 307.113 196.905 307.177 195.74C307.17 193.598 305.616 192.737 303.698 193.813Z"
          fill="#3D464D"
        />
      </g>
      <path
        d="M330.707 160.233L330.65 164.485L281.638 191.588L281.692 187.336L330.707 160.233Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.693 187.336L281.638 191.588L231.855 164.069L231.912 159.817L281.693 187.336Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M322.63 161.794L281.608 139.161V137.837L323.813 161.132L322.63 161.794Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.23 139.161L240.473 161.823L239.264 161.159L281.23 137.838V139.161Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.687 162.495L240.855 162.035L281.419 139.48L322.249 162.009L321.387 162.496L281.655 184.587L241.687 162.495Z"
        fill="#3D566E"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M300.916 159.643L300.907 162.732C300.747 164.366 300.139 165.929 299.145 167.256C298.144 168.594 296.797 169.65 295.241 170.318C290.958 172.398 286.236 173.482 281.448 173.482C276.661 173.482 271.939 172.398 267.656 170.318C266.077 169.648 264.71 168.581 263.696 167.227C262.69 165.883 262.077 164.3 261.922 162.644L261.931 159.556C262.086 161.211 262.699 162.795 263.706 164.139C264.72 165.493 266.086 166.559 267.665 167.23C271.948 169.31 276.67 170.393 281.457 170.393C286.245 170.393 290.967 169.31 295.25 167.23C296.806 166.561 298.153 165.505 299.154 164.168C300.148 162.84 300.756 161.278 300.916 159.643Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M295.16 151.95C302.8 156.168 302.84 163.011 295.25 167.23C290.967 169.31 286.245 170.393 281.457 170.393C276.67 170.393 271.947 169.31 267.665 167.23C260.05 163.026 260.01 156.182 267.6 151.964C271.878 149.885 276.595 148.801 281.378 148.799C286.161 148.796 290.879 149.875 295.16 151.95Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M339.442 129.897L339.362 156.69L281.697 188.737L281.774 161.944L339.442 129.897Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.775 161.944L281.698 188.737L223.695 156.715L223.775 129.923L281.775 161.944Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M339.442 129.897L281.774 161.944L223.774 129.923L281.442 97.876L339.442 129.897Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M322.704 130.549L281.683 107.919V106.595L323.887 129.89L322.704 130.549Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.302 107.919L240.546 130.578L239.337 129.916L281.302 106.596V107.919Z"
        fill="#D6D7D8"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.762 131.253L240.93 130.79L281.494 108.238L322.325 130.764L321.462 131.253L321.46 131.254L281.73 153.345L241.762 131.253Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M333.185 145.709C333.453 145.522 333.766 145.405 334.094 145.37C334.422 145.334 334.754 145.382 335.057 145.508C335.324 145.685 335.536 145.929 335.671 146.215C335.806 146.5 335.859 146.816 335.824 147.129C335.768 148.063 335.485 148.972 334.997 149.779C334.505 150.592 333.824 151.281 333.008 151.791C332.741 151.979 332.428 152.095 332.1 152.129C331.772 152.162 331.441 152.112 331.14 151.982C330.875 151.801 330.665 151.554 330.532 151.268C330.398 150.981 330.346 150.665 330.38 150.353C330.431 149.42 330.711 148.514 331.198 147.709C331.688 146.899 332.369 146.213 333.185 145.709Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M281.136 144.704L258.32 129.344L266.535 124.864L300.752 134.008L295.101 137.089L288.96 135.397L282.064 139.157L286.264 141.908L281.136 144.704ZM278.909 137.097L284.311 134.152L267.205 129.423L278.909 137.097Z"
        fill="#EFEFEF"
      />
      <path
        d="M303.34 132.598L274.825 120.344L280.425 117.29L308.942 129.542L303.34 132.598Z"
        fill="#EFEFEF"
      />
      <mask
        id="mask1_1609_14472"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="286"
        y="157"
        width="22"
        height="20"
      >
        <path
          d="M286.997 176.92L286.975 169.185L307.166 157.853L307.189 165.587L286.997 176.92Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1609_14472)">
        <path
          d="M303.699 160.1L290.432 167.547C289.506 168.133 288.735 168.924 288.183 169.856C287.631 170.787 287.314 171.832 287.257 172.905C287.263 174.874 288.691 175.665 290.453 174.676L303.72 167.229C304.649 166.646 305.422 165.856 305.975 164.926C306.529 163.995 306.848 162.95 306.906 161.877C306.9 159.908 305.472 159.105 303.699 160.1Z"
          fill="#8491A1"
        />
        <path
          d="M290.453 174.676C288.69 175.665 287.262 174.874 287.257 172.905C287.314 171.832 287.631 170.787 288.183 169.856C288.735 168.925 289.506 168.133 290.432 167.547L303.699 160.1C305.472 159.106 306.9 159.908 306.905 161.877C306.848 162.95 306.529 163.995 305.975 164.926C305.421 165.856 304.649 166.646 303.72 167.23L290.453 174.676ZM303.698 159.798L290.431 167.244C289.426 167.88 288.59 168.738 287.991 169.749C287.392 170.759 287.047 171.893 286.985 173.057C286.992 175.189 288.546 176.05 290.454 174.979L303.721 167.532C304.727 166.898 305.565 166.041 306.166 165.032C306.766 164.022 307.113 162.889 307.177 161.724C307.17 159.583 305.616 158.721 303.698 159.798Z"
          fill="#3D464D"
        />
      </g>
    </g>
    <g class="robo-animation">
      <path
        d="M96.8684 261.988L98.3579 268.346C97.0275 272.478 94.6434 276.191 91.4408 279.119C89.7071 280.715 87.8038 282.116 85.765 283.297C83.4856 284.619 81.0958 285.742 78.6223 286.65C72.637 288.771 66.4212 290.174 60.1055 290.828L60.3199 283.432C66.3399 282.778 72.2628 281.421 77.9678 279.389C80.3133 278.528 82.5787 277.463 84.7381 276.205C86.6798 275.086 88.4924 273.757 90.1431 272.241C93.2248 269.456 95.5405 265.926 96.8684 261.988Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M96.8671 261.988C95.5392 265.925 93.2236 269.456 90.1419 272.241C88.4911 273.757 86.6785 275.086 84.7369 276.204C82.5775 277.462 80.3121 278.528 77.9666 279.389C72.2616 281.421 66.3387 282.777 60.3187 283.431C35.4265 275.583 16.0032 264.532 2.04883 250.278C4.75314 246.959 7.86583 243.996 11.3129 241.459C15.1165 238.666 19.1963 236.271 23.4881 234.311C28.3708 232.086 33.4233 230.254 38.5972 228.834C61.9773 237.563 81.4006 248.614 96.8671 261.988Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M60.3183 283.431L60.1039 290.827C59.9459 290.771 59.7993 290.726 59.6413 290.681C34.5347 282.765 14.9271 271.608 0.818537 257.211C0.705698 257.087 0.581588 256.962 0.46875 256.849L2.04849 250.277C16.0066 264.528 35.4299 275.579 60.3183 283.431Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M60.3252 269.91C80.7038 267.425 92.8866 260.277 96.8736 248.466V261.994C92.8904 273.805 80.7076 280.953 60.3252 283.438V269.91Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M60.3245 269.909V283.437C35.4323 275.6 16.009 264.549 2.05469 250.283V236.755C16.0128 251.017 35.4361 262.068 60.3245 269.909Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.05469 236.755C9.85183 227.112 22.0346 219.964 38.6031 215.312C61.9832 224.04 81.4065 235.092 96.8729 248.465C92.8897 260.277 80.7069 267.425 60.3245 269.909C35.4323 262.061 16.009 251.01 2.05469 236.755Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.1383 239.652C67.3375 240.125 67.4741 240.622 67.5445 241.131C67.4737 241.773 67.3063 242.402 67.048 242.994C66.3867 244.365 65.3937 245.549 64.1593 246.438C62.2264 247.874 60.0232 248.903 57.6823 249.464C55.3474 250.081 52.9432 250.396 50.5284 250.402C48.124 250.428 45.7266 250.139 43.397 249.544C41.65 249.1 39.9664 248.436 38.387 247.567C37.0553 246.848 35.8721 245.882 34.9002 244.722C34.1126 243.801 33.5762 242.693 33.3431 241.503C33.3217 241.388 33.3066 241.271 33.2979 241.153C33.4878 239.972 33.9644 238.855 34.6857 237.901C35.8609 236.402 37.3775 235.205 39.109 234.412C41.2475 233.371 43.5295 232.655 45.8793 232.289C48.6918 231.839 51.5557 231.813 54.3761 232.21C57.2128 232.549 59.9588 233.427 62.4666 234.796C63.1334 235.169 63.7709 235.591 64.3736 236.061C65.5925 236.996 66.5456 238.234 67.1383 239.652Z"
        fill="#727B87"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.9539 236.253C66.7318 237.179 67.2636 238.286 67.4998 239.472C67.5939 240.02 67.6091 240.579 67.545 241.132C67.4746 240.623 67.338 240.126 67.1388 239.652C66.5462 238.234 65.5932 236.996 64.3743 236.061C63.7716 235.592 63.1341 235.169 62.4674 234.797C59.9595 233.427 57.2135 232.55 54.3768 232.211C51.5565 231.813 48.6925 231.84 45.88 232.29C43.5302 232.656 41.2482 233.372 39.1097 234.413C37.3782 235.206 35.8616 236.402 34.6865 237.902C33.9651 238.856 33.4885 239.973 33.2986 241.154C33.1854 240.071 33.3565 238.978 33.7951 237.981C34.4606 236.61 35.4574 235.426 36.6951 234.537C38.6251 233.103 40.8242 232.074 43.1608 231.511C45.4958 230.895 47.8999 230.58 50.3147 230.573C52.7188 230.552 55.1157 230.84 57.4461 231.432C59.1994 231.872 60.8876 232.54 62.4674 233.419C63.7961 234.138 64.9786 235.099 65.9539 236.253Z"
        fill="#727B87"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.5449 241.131C67.609 240.578 67.5938 240.019 67.4997 239.471C67.2635 238.285 66.7318 237.178 65.9538 236.252C64.9785 235.098 63.7959 234.137 62.4671 233.418C60.8872 232.539 59.1991 231.871 57.4458 231.431C55.1154 230.839 52.7185 230.551 50.3144 230.572C47.8996 230.579 45.4955 230.894 43.1605 231.51C40.8239 232.073 38.6248 233.102 36.6948 234.536C35.4571 235.425 34.4603 236.609 33.7948 237.98C33.3562 238.976 33.1851 240.07 33.2983 241.153C33.3071 241.27 33.3221 241.387 33.3435 241.503C33.5766 242.692 34.1131 243.801 34.9006 244.721C35.8725 245.882 37.0557 246.848 38.3874 247.567C39.9669 248.436 41.6504 249.1 43.3974 249.543C45.727 250.139 48.1244 250.427 50.5288 250.401C52.9436 250.396 55.3478 250.081 57.6827 249.464C60.0236 248.903 62.2269 247.874 64.1597 246.438C65.3941 245.548 66.3871 244.365 67.0484 242.994C67.3067 242.401 67.4741 241.773 67.5449 241.131ZM76.8203 238.917C76.9106 239.436 76.9559 239.961 76.9557 240.487C76.9425 241.82 76.6624 243.137 76.132 244.36C75.1022 246.48 73.563 248.311 71.6523 249.69C68.6667 251.903 65.2668 253.493 61.6548 254.365C58.0437 255.308 54.3286 255.794 50.5966 255.81C46.8758 255.849 43.1658 255.401 39.561 254.478C36.8592 253.798 34.2572 252.769 31.8203 251.418C29.761 250.313 27.9305 248.826 26.4266 247.036C25.2136 245.613 24.3861 243.903 24.0231 242.068C23.9333 241.565 23.888 241.055 23.8877 240.543V240.487C23.9036 239.157 24.1876 237.843 24.7227 236.625C25.7484 234.503 27.2837 232.668 29.1911 231.284C32.1834 229.075 35.5861 227.485 39.1999 226.609C42.8108 225.665 46.5261 225.179 50.2581 225.163C53.9752 225.125 57.6814 225.573 61.2824 226.496C63.9885 227.174 66.5945 228.203 69.0345 229.556H69.0458C71.0973 230.668 72.9228 232.154 74.4282 233.937C75.6358 235.367 76.4591 237.081 76.8203 238.917Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.5354 255.415C31.3858 254.427 29.4086 253.1 27.6791 251.485C26.2866 250.203 25.1992 248.625 24.497 246.867C24.0986 245.821 23.8922 244.711 23.8877 243.592V240.543C23.888 241.054 23.9333 241.564 24.0231 242.067C24.386 243.902 25.2136 245.613 26.4265 247.036C27.9305 248.826 29.7609 250.312 31.8202 251.417C34.2572 252.769 36.8592 253.797 39.561 254.477C43.1658 255.401 46.8758 255.849 50.5966 255.81C54.3286 255.793 58.0437 255.307 61.6548 254.364C65.2668 253.493 68.6667 251.903 71.6523 249.689C73.563 248.311 75.1022 246.48 76.132 244.36C76.6624 243.137 76.9425 241.82 76.9557 240.486H76.967V243.592C76.7968 246.458 75.5611 249.157 73.5028 251.157C71.5113 253.163 69.1304 254.738 66.5069 255.787C63.9081 256.893 61.1946 257.708 58.4164 258.215C52.8352 259.205 47.1185 259.148 41.5583 258.046C38.7868 257.499 36.0925 256.616 33.5354 255.415Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M102.42 266.237L74.832 282.419V280.375L100.65 265.232L102.42 266.237Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.832 277.677L100.65 262.523V265.233L74.832 280.376V277.677Z"
        fill="#727B87"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M102.422 266.237V261.483L100.651 262.522L74.8331 277.676V282.419L102.422 266.237ZM104.194 258.739V266.915L73.0615 285.174V276.999L104.194 258.739Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.0608 277V285.175L64.8574 280.511V272.336L73.0608 277Z"
        fill="#D1D1D1"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.8574 272.335C67.1706 271.94 69.2355 271.522 71.4359 270.968C73.5339 270.45 75.6019 269.816 77.6307 269.071C79.5525 268.372 81.4259 267.545 83.2388 266.598C84.9196 265.722 86.5238 264.706 88.0344 263.561C89.4183 262.515 90.7026 261.343 91.8709 260.06C92.9371 258.883 93.8858 257.605 94.7032 256.243C95.1207 255.555 95.5043 254.821 95.8429 254.109L95.9557 254.053L104.193 258.739L73.0607 276.998L64.8574 272.335Z"
        fill="#D1D1D1"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M102.421 261.483V266.236L100.65 265.232V262.522L102.421 261.483Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.317 192.473C193.291 192.402 193.273 192.328 193.261 192.253L193.258 192.236L193.258 192.23L193.256 192.218C193.232 192.037 193.246 191.853 193.296 191.677C193.346 191.501 193.431 191.338 193.547 191.196C193.712 190.991 193.917 190.823 194.151 190.702C194.384 190.581 194.64 190.511 194.903 190.495C194.951 190.492 195.001 190.49 195.05 190.491L195.08 190.456L195.065 190.456C195.01 190.454 194.954 190.455 194.899 190.459C194.632 190.475 194.371 190.547 194.134 190.67C193.896 190.794 193.687 190.965 193.52 191.174C193.403 191.318 193.316 191.485 193.264 191.663C193.212 191.842 193.198 192.029 193.22 192.214L193.223 192.231L193.223 192.237L193.225 192.249C193.238 192.336 193.26 192.421 193.291 192.503L193.317 192.473Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.39 190.097L193.206 192.625C192.973 192.424 192.737 192.225 192.498 192.035L192.141 191.756L191.965 191.623L191.809 191.51L193.705 188.76L193.948 188.935L194.165 189.098C194.307 189.207 194.448 189.315 194.584 189.426C194.86 189.645 195.126 189.869 195.39 190.097Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.066 190.473L193.311 192.505C193.279 192.423 193.257 192.338 193.243 192.251L193.241 192.233L193.238 192.215C193.215 192.032 193.229 191.847 193.28 191.67C193.331 191.492 193.417 191.327 193.534 191.185C193.7 190.978 193.907 190.808 194.143 190.686C194.378 190.564 194.637 190.493 194.901 190.476C194.956 190.473 195.011 190.472 195.066 190.473Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.287 190.217L193.532 192.249L193.509 192.249C193.425 192.25 193.341 192.246 193.258 192.236L193.24 192.234L193.222 192.232C192.881 192.188 192.557 192.055 192.284 191.844L192.218 191.793C192.074 191.688 191.964 191.546 191.898 191.381L193.423 189.17C193.538 189.176 193.633 189.175 193.735 189.188L193.755 189.19L193.774 189.192C194.162 189.242 194.529 189.4 194.831 189.649L194.906 189.711C195.072 189.847 195.202 190.02 195.287 190.217Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.301 190.202L195.275 190.232L195.271 190.224C195.188 190.03 195.059 189.859 194.896 189.725L194.821 189.663C194.523 189.418 194.163 189.262 193.781 189.212L193.762 189.209L193.756 189.208L193.741 189.207C193.64 189.194 193.541 189.193 193.429 189.19L193.411 189.189L193.437 189.151C193.546 189.158 193.635 189.158 193.73 189.169L193.751 189.171L193.757 189.172L193.77 189.174C194.164 189.223 194.537 189.383 194.844 189.636L194.918 189.698C195.084 189.833 195.215 190.006 195.301 190.202ZM193.548 192.232L193.518 192.267L193.495 192.267C193.417 192.267 193.339 192.263 193.262 192.254L193.244 192.252L193.238 192.252L193.226 192.25C192.88 192.206 192.551 192.071 192.275 191.859L192.208 191.807C192.065 191.703 191.954 191.561 191.889 191.397L191.911 191.364L191.912 191.37C191.978 191.533 192.088 191.675 192.23 191.778L192.296 191.83C192.565 192.038 192.885 192.17 193.221 192.214L193.239 192.216L193.244 192.217L193.257 192.218C193.346 192.229 193.436 192.234 193.526 192.232H193.533L193.548 192.232Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.541 190.274C193.211 190.685 192.747 190.966 192.231 191.067C192.183 191.075 192.137 191.087 192.091 191.102L193.615 188.891C193.675 188.976 193.721 189.071 193.749 189.171L193.755 189.19L193.76 189.209C193.809 189.391 193.815 189.583 193.777 189.768C193.739 189.953 193.658 190.126 193.541 190.274Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.555 190.286C193.37 190.522 193.136 190.716 192.87 190.855C192.62 190.98 192.354 191.069 192.079 191.12L192.106 191.082C192.371 191.029 192.627 190.94 192.867 190.818C193.123 190.681 193.348 190.492 193.527 190.264C193.643 190.117 193.723 189.945 193.76 189.761C193.797 189.577 193.791 189.388 193.74 189.207L193.735 189.188L193.73 189.17C193.702 189.076 193.66 188.988 193.604 188.908L193.627 188.875C193.691 188.966 193.739 189.067 193.769 189.174L193.775 189.193L193.78 189.212C193.828 189.396 193.833 189.589 193.794 189.775C193.755 189.962 193.674 190.136 193.555 190.286Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.691 193.744C194.671 193.67 194.657 193.595 194.65 193.519L194.649 193.502L194.648 193.496L194.647 193.484C194.635 193.301 194.66 193.118 194.722 192.946C194.784 192.774 194.88 192.617 195.006 192.484C195.185 192.291 195.401 192.137 195.642 192.032C195.883 191.928 196.143 191.874 196.406 191.875C196.454 191.875 196.504 191.877 196.553 191.882L196.585 191.849L196.57 191.847C196.516 191.842 196.46 191.839 196.405 191.839C196.138 191.838 195.873 191.893 195.628 192C195.383 192.106 195.162 192.263 194.981 192.46C194.853 192.595 194.755 192.755 194.691 192.93C194.628 193.105 194.601 193.291 194.612 193.477L194.613 193.495L194.614 193.501L194.615 193.513C194.622 193.601 194.639 193.687 194.663 193.772L194.691 193.744Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.919 191.51L194.572 193.887C194.13 193.451 193.675 193.028 193.208 192.625L195.392 190.097C195.918 190.552 196.428 191.023 196.919 191.51Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.569 191.865L194.683 193.775C194.657 193.691 194.64 193.604 194.632 193.516L194.631 193.498L194.63 193.48C194.618 193.296 194.644 193.111 194.707 192.938C194.769 192.764 194.867 192.605 194.993 192.471C195.174 192.277 195.392 192.122 195.635 192.016C195.878 191.91 196.141 191.856 196.406 191.856C196.461 191.857 196.515 191.86 196.569 191.865Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.809 191.624L194.922 193.535L194.899 193.534C194.816 193.528 194.732 193.517 194.65 193.502L194.632 193.499L194.614 193.495C194.272 193.428 193.954 193.268 193.696 193.034L193.632 192.976C193.489 192.848 193.379 192.687 193.312 192.507L195.067 190.475C195.164 190.476 195.26 190.486 195.355 190.503L195.375 190.507L195.393 190.51C195.77 190.585 196.118 190.765 196.396 191.03L196.466 191.097C196.621 191.242 196.739 191.423 196.809 191.624Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.821 191.61L196.793 191.638L196.79 191.629C196.722 191.431 196.606 191.252 196.452 191.109L196.383 191.042C196.108 190.782 195.766 190.604 195.395 190.529L195.377 190.525L195.371 190.524L195.357 190.522C195.261 190.504 195.164 190.494 195.067 190.492L195.051 190.492L195.081 190.457C195.172 190.459 195.262 190.468 195.351 190.484L195.371 190.488L195.377 190.489L195.389 190.491C195.771 190.566 196.125 190.748 196.407 191.017L196.477 191.083C196.632 191.228 196.75 191.409 196.821 191.61ZM194.937 193.518L194.905 193.551L194.882 193.55C194.805 193.544 194.727 193.534 194.651 193.519L194.632 193.516L194.627 193.515L194.615 193.513C194.267 193.445 193.945 193.284 193.683 193.047L193.619 192.989C193.476 192.861 193.366 192.701 193.298 192.521L193.324 192.491L193.327 192.499C193.393 192.677 193.501 192.836 193.643 192.962L193.707 193.02C193.962 193.251 194.275 193.409 194.612 193.477L194.63 193.48L194.635 193.482L194.647 193.484C194.736 193.5 194.825 193.511 194.915 193.517L194.922 193.517L194.937 193.518Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.068 191.563C194.71 191.954 194.219 192.195 193.691 192.241C193.638 192.246 193.586 192.249 193.533 192.25L195.289 190.218C195.327 190.304 195.354 190.395 195.371 190.488L195.375 190.507L195.378 190.526C195.408 190.711 195.396 190.9 195.342 191.08C195.288 191.26 195.194 191.425 195.068 191.563Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.08 191.575C194.88 191.791 194.639 191.963 194.37 192.082C194.102 192.201 193.811 192.264 193.518 192.266L193.548 192.23C193.832 192.224 194.112 192.161 194.37 192.044C194.629 191.927 194.861 191.759 195.053 191.55C195.179 191.413 195.271 191.249 195.324 191.071C195.376 190.893 195.388 190.705 195.357 190.521L195.354 190.503L195.351 190.484C195.334 190.397 195.309 190.313 195.274 190.232L195.3 190.202C195.341 190.295 195.371 190.391 195.388 190.491L195.392 190.51L195.395 190.528C195.424 190.715 195.411 190.906 195.357 191.087C195.302 191.269 195.207 191.435 195.08 191.575Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.981 195.1C195.966 195.025 195.957 194.95 195.955 194.874L195.954 194.856V194.85L195.954 194.838C195.953 194.655 195.99 194.474 196.063 194.307C196.136 194.139 196.243 193.988 196.376 193.864C196.567 193.683 196.793 193.543 197.041 193.454C197.288 193.365 197.551 193.328 197.814 193.346C197.862 193.35 197.911 193.355 197.96 193.363L197.994 193.332L197.979 193.329C197.925 193.321 197.87 193.314 197.815 193.31C197.548 193.292 197.28 193.33 197.029 193.421C196.777 193.511 196.547 193.653 196.353 193.838C196.217 193.965 196.108 194.118 196.033 194.289C195.959 194.459 195.92 194.643 195.919 194.829L195.919 194.847V194.853L195.919 194.865C195.922 194.953 195.933 195.04 195.951 195.126L195.981 195.1Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198.35 193.019L195.854 195.239C195.44 194.777 195.016 194.323 194.572 193.889L196.92 191.512C197.414 191.996 197.888 192.502 198.35 193.019Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.977 193.349L195.97 195.133C195.951 195.047 195.94 194.959 195.937 194.871L195.937 194.853L195.937 194.835C195.937 194.65 195.975 194.468 196.048 194.299C196.122 194.13 196.23 193.977 196.365 193.852C196.557 193.669 196.785 193.529 197.035 193.439C197.285 193.349 197.55 193.312 197.815 193.329C197.869 193.334 197.923 193.34 197.977 193.349Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198.233 193.123L196.226 194.907L196.203 194.904C196.12 194.893 196.038 194.877 195.956 194.857L195.939 194.853L195.921 194.848C195.584 194.758 195.277 194.579 195.034 194.328L194.974 194.266C194.84 194.128 194.74 193.959 194.684 193.775L196.571 191.864C196.667 191.873 196.762 191.889 196.856 191.912L196.875 191.916L196.893 191.921C197.264 192.019 197.599 192.221 197.859 192.503L197.924 192.574C198.07 192.73 198.176 192.918 198.233 193.123Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198.245 193.11L198.216 193.137L198.213 193.127C198.157 192.925 198.053 192.74 197.909 192.587L197.844 192.516C197.587 192.238 197.258 192.039 196.893 191.94L196.875 191.936L196.87 191.934L196.856 191.931C196.761 191.908 196.665 191.891 196.568 191.883L196.552 191.881L196.585 191.849C196.675 191.857 196.764 191.872 196.852 191.893L196.871 191.898L196.877 191.899L196.889 191.903C197.266 192.001 197.606 192.206 197.87 192.492L197.935 192.563C198.081 192.718 198.187 192.905 198.245 193.11ZM196.241 194.892L196.207 194.923L196.184 194.92C196.107 194.91 196.031 194.893 195.955 194.875L195.937 194.871L195.931 194.87L195.919 194.867C195.577 194.777 195.266 194.595 195.019 194.341L194.959 194.279C194.825 194.141 194.725 193.973 194.668 193.79L194.696 193.761L194.698 193.769C194.754 193.951 194.852 194.117 194.984 194.254L195.045 194.316C195.285 194.563 195.587 194.741 195.919 194.831L195.937 194.835L195.942 194.837L195.954 194.84C196.041 194.861 196.13 194.878 196.219 194.89L196.226 194.891L196.241 194.892Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.498 192.95C196.115 193.316 195.608 193.525 195.079 193.536C195.026 193.538 194.973 193.537 194.921 193.534L196.808 191.623C196.839 191.712 196.86 191.804 196.872 191.898L196.874 191.916L196.876 191.935C196.893 192.122 196.869 192.31 196.803 192.486C196.738 192.662 196.633 192.821 196.498 192.95Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.51 192.964C196.297 193.166 196.045 193.323 195.768 193.424C195.492 193.525 195.199 193.568 194.905 193.55L194.938 193.517C195.222 193.53 195.505 193.485 195.771 193.386C196.037 193.286 196.28 193.134 196.485 192.938C196.619 192.809 196.722 192.652 196.787 192.477C196.851 192.303 196.875 192.116 196.856 191.931L196.854 191.912L196.852 191.893C196.841 191.806 196.821 191.721 196.793 191.638L196.821 191.609C196.855 191.704 196.878 191.803 196.89 191.902L196.892 191.921L196.894 191.94C196.91 192.129 196.885 192.318 196.818 192.496C196.752 192.673 196.646 192.833 196.51 192.964Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.178 196.539C197.167 196.464 197.163 196.388 197.166 196.313L197.166 196.295L197.167 196.289L197.168 196.277C197.18 196.094 197.23 195.916 197.314 195.754C197.398 195.591 197.515 195.447 197.657 195.332C197.859 195.164 198.093 195.04 198.346 194.968C198.599 194.896 198.863 194.877 199.123 194.913C199.172 194.92 199.221 194.928 199.269 194.939L199.306 194.911L199.291 194.907C199.237 194.895 199.182 194.885 199.127 194.878C198.862 194.841 198.593 194.86 198.336 194.933C198.078 195.007 197.84 195.133 197.634 195.305C197.49 195.423 197.372 195.569 197.286 195.734C197.2 195.899 197.148 196.08 197.133 196.266L197.132 196.284L197.132 196.289L197.131 196.302C197.127 196.389 197.132 196.477 197.146 196.563L197.178 196.539Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.681 194.62L197.04 196.667C196.661 196.179 196.266 195.701 195.854 195.238L198.35 193.019C198.811 193.536 199.255 194.07 199.681 194.62Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.288 194.924L197.165 196.569C197.15 196.482 197.145 196.394 197.149 196.307L197.15 196.289L197.151 196.271C197.164 196.086 197.215 195.907 197.3 195.743C197.385 195.579 197.503 195.434 197.646 195.317C197.849 195.148 198.086 195.023 198.341 194.95C198.596 194.877 198.863 194.859 199.126 194.895C199.18 194.902 199.234 194.912 199.288 194.924Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.556 194.717L197.434 196.362L197.411 196.358C197.329 196.342 197.247 196.321 197.167 196.295L197.15 196.289L197.133 196.284C196.803 196.171 196.511 195.972 196.285 195.707L196.23 195.64C196.103 195.495 196.014 195.321 195.972 195.133L197.979 193.349C198.074 193.364 198.168 193.386 198.26 193.415L198.279 193.421L198.296 193.427C198.66 193.55 198.982 193.773 199.224 194.071L199.285 194.147C199.418 194.314 199.511 194.509 199.556 194.717Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.571 194.704L199.539 194.729L199.537 194.719C199.493 194.514 199.401 194.321 199.27 194.157L199.21 194.082C198.971 193.788 198.654 193.568 198.296 193.445L198.278 193.439L198.273 193.437L198.259 193.433C198.166 193.404 198.072 193.381 197.975 193.366L197.96 193.364L197.995 193.333C198.084 193.348 198.172 193.369 198.258 193.395L198.277 193.401L198.283 193.403L198.294 193.407C198.664 193.531 198.991 193.757 199.237 194.06L199.298 194.135C199.431 194.301 199.524 194.496 199.571 194.704ZM197.451 196.347L197.414 196.375L197.392 196.371C197.315 196.356 197.24 196.336 197.165 196.312L197.148 196.307L197.142 196.305L197.131 196.301C196.797 196.188 196.499 195.986 196.271 195.718L196.215 195.651C196.088 195.507 195.999 195.333 195.956 195.146L195.986 195.119L195.987 195.127C196.029 195.313 196.117 195.485 196.242 195.628L196.298 195.695C196.52 195.956 196.809 196.153 197.133 196.265L197.15 196.271L197.155 196.273L197.167 196.277C197.253 196.305 197.34 196.327 197.429 196.343L197.436 196.344L197.451 196.347Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.836 194.427C197.43 194.768 196.911 194.943 196.382 194.92C196.329 194.918 196.277 194.914 196.225 194.908L198.231 193.123C198.257 193.214 198.272 193.307 198.277 193.402L198.278 193.421L198.279 193.44C198.285 193.627 198.248 193.813 198.171 193.984C198.094 194.156 197.98 194.307 197.836 194.427Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.846 194.441C197.621 194.629 197.359 194.769 197.077 194.851C196.795 194.934 196.499 194.958 196.207 194.922L196.242 194.89C196.524 194.922 196.81 194.896 197.082 194.813C197.353 194.731 197.606 194.595 197.824 194.413C197.966 194.294 198.079 194.143 198.154 193.973C198.23 193.804 198.265 193.619 198.259 193.433L198.258 193.414L198.257 193.395C198.252 193.308 198.238 193.221 198.215 193.136L198.245 193.109C198.272 193.206 198.288 193.306 198.294 193.407L198.295 193.426L198.295 193.445C198.3 193.634 198.262 193.822 198.185 193.994C198.107 194.166 197.991 194.319 197.846 194.441Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198.266 198.051C198.262 197.975 198.264 197.9 198.272 197.824L198.274 197.807L198.275 197.801L198.276 197.789C198.301 197.608 198.363 197.435 198.458 197.279C198.553 197.123 198.679 196.989 198.829 196.884C199.042 196.73 199.286 196.623 199.544 196.569C199.801 196.515 200.067 196.515 200.325 196.569C200.372 196.58 200.42 196.592 200.467 196.606L200.505 196.581L200.491 196.576C200.439 196.56 200.385 196.545 200.331 196.533C200.069 196.478 199.798 196.478 199.536 196.534C199.274 196.589 199.026 196.699 198.809 196.855C198.657 196.962 198.528 197.099 198.431 197.257C198.334 197.415 198.27 197.592 198.243 197.776L198.241 197.793L198.24 197.799L198.238 197.811C198.229 197.898 198.227 197.986 198.233 198.073L198.266 198.051Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.901 196.317L198.119 198.167C197.952 197.91 197.769 197.661 197.595 197.407C197.41 197.16 197.231 196.909 197.04 196.666L199.681 194.62C199.895 194.894 200.099 195.177 200.307 195.456C200.505 195.743 200.71 196.024 200.901 196.317Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.487 196.593L198.251 198.08C198.244 197.992 198.246 197.904 198.256 197.817L198.258 197.799L198.26 197.781C198.286 197.599 198.349 197.424 198.445 197.267C198.541 197.11 198.668 196.974 198.819 196.868C199.035 196.714 199.281 196.606 199.54 196.551C199.8 196.496 200.069 196.496 200.329 196.551C200.382 196.562 200.434 196.577 200.487 196.593Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.77 196.406L198.534 197.893L198.512 197.887C198.432 197.865 198.353 197.838 198.275 197.807L198.258 197.8L198.242 197.794C197.921 197.66 197.643 197.442 197.436 197.163L197.385 197.094C197.272 196.939 197.197 196.76 197.165 196.571L199.288 194.926C199.383 194.946 199.475 194.974 199.565 195.011L199.584 195.018L199.601 195.025C199.956 195.174 200.262 195.421 200.481 195.738L200.537 195.817C200.662 195.991 200.742 196.194 200.77 196.406Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.786 196.395L200.753 196.417L200.752 196.408C200.724 196.198 200.645 195.999 200.522 195.827L200.467 195.748C200.25 195.436 199.95 195.192 199.6 195.044L199.583 195.037L199.578 195.035L199.564 195.03C199.474 194.993 199.38 194.964 199.285 194.944L199.269 194.94L199.306 194.912C199.394 194.932 199.481 194.958 199.566 194.991L199.584 194.999L199.59 195.001L199.601 195.006C199.962 195.156 200.273 195.406 200.496 195.727L200.551 195.807C200.676 195.981 200.757 196.183 200.786 196.395ZM198.553 197.881L198.515 197.906L198.493 197.901C198.418 197.88 198.346 197.853 198.273 197.825L198.256 197.818L198.25 197.816L198.239 197.811C197.914 197.677 197.631 197.457 197.422 197.174L197.37 197.105C197.257 196.95 197.182 196.772 197.149 196.583L197.181 196.558L197.182 196.567C197.213 196.754 197.287 196.931 197.399 197.084L197.45 197.153C197.654 197.428 197.928 197.643 198.244 197.776L198.26 197.783L198.265 197.785L198.277 197.79C198.36 197.823 198.445 197.851 198.532 197.875L198.539 197.877L198.553 197.881Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.073 195.993C198.645 196.304 198.116 196.444 197.591 196.384C197.538 196.378 197.485 196.371 197.434 196.362L199.556 194.717C199.577 194.809 199.586 194.903 199.585 194.998L199.584 195.017L199.583 195.036C199.576 195.223 199.526 195.407 199.438 195.573C199.349 195.738 199.225 195.882 199.073 195.993Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.083 196.007C198.845 196.18 198.575 196.301 198.288 196.364C198.001 196.428 197.704 196.432 197.416 196.376L197.453 196.347C197.732 196.397 198.019 196.39 198.295 196.327C198.572 196.264 198.833 196.145 199.062 195.979C199.212 195.869 199.336 195.726 199.423 195.562C199.51 195.397 199.558 195.215 199.565 195.029L199.566 195.01L199.566 194.99C199.567 194.903 199.558 194.815 199.54 194.73L199.572 194.705C199.594 194.803 199.604 194.904 199.602 195.005L199.601 195.024L199.6 195.043C199.592 195.232 199.541 195.417 199.452 195.584C199.362 195.751 199.236 195.896 199.083 196.007Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.232 199.632C199.233 199.557 199.241 199.482 199.257 199.408L199.26 199.39L199.261 199.385L199.264 199.373C199.304 199.195 199.38 199.027 199.487 198.879C199.593 198.731 199.729 198.607 199.886 198.513C200.11 198.375 200.361 198.287 200.622 198.253C200.883 198.219 201.148 198.24 201.4 198.316C201.446 198.33 201.493 198.346 201.54 198.364L201.58 198.342L201.566 198.336C201.515 198.315 201.462 198.297 201.409 198.281C201.152 198.204 200.882 198.182 200.617 198.217C200.351 198.252 200.096 198.343 199.868 198.483C199.709 198.579 199.57 198.705 199.461 198.855C199.352 199.005 199.274 199.176 199.232 199.356L199.228 199.374L199.227 199.38L199.225 199.392C199.207 199.477 199.197 199.564 199.197 199.652L199.232 199.632Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.993 198.11L199.075 199.738C198.782 199.201 198.456 198.681 198.118 198.168L200.9 196.317C201.284 196.901 201.655 197.494 201.993 198.11Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.559 198.354L199.214 199.662C199.213 199.574 199.222 199.487 199.24 199.401L199.244 199.383L199.248 199.366C199.289 199.186 199.365 199.017 199.473 198.868C199.581 198.719 199.718 198.594 199.876 198.499C200.102 198.36 200.355 198.271 200.619 198.237C200.882 198.202 201.149 198.224 201.404 198.3C201.456 198.315 201.508 198.334 201.559 198.354Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.857 198.188L199.511 199.496L199.49 199.489C199.411 199.461 199.335 199.429 199.26 199.391L199.245 199.383L199.229 199.375C198.92 199.22 198.661 198.982 198.479 198.688L198.432 198.616C198.328 198.456 198.266 198.272 198.251 198.081L200.487 196.594C200.579 196.623 200.669 196.658 200.757 196.7L200.774 196.709L200.791 196.717C201.137 196.891 201.425 197.161 201.62 197.496L201.671 197.58C201.782 197.764 201.846 197.973 201.857 198.188Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.874 198.178L201.839 198.197L201.839 198.187C201.829 197.975 201.766 197.769 201.656 197.587L201.606 197.504C201.413 197.174 201.13 196.908 200.79 196.735L200.773 196.727L200.768 196.724L200.755 196.718C200.667 196.675 200.576 196.639 200.483 196.61L200.468 196.606L200.507 196.58C200.593 196.608 200.677 196.641 200.759 196.68L200.777 196.689L200.783 196.691L200.794 196.697C201.145 196.872 201.438 197.146 201.636 197.486L201.686 197.569C201.798 197.754 201.862 197.963 201.874 198.178ZM199.532 199.484L199.492 199.506L199.47 199.499C199.398 199.473 199.327 199.442 199.257 199.407L199.241 199.4L199.236 199.397L199.225 199.391C198.912 199.235 198.649 198.994 198.465 198.696L198.418 198.624C198.313 198.465 198.251 198.281 198.235 198.091L198.269 198.069L198.269 198.077C198.283 198.266 198.344 198.447 198.448 198.605L198.495 198.677C198.674 198.967 198.929 199.202 199.233 199.356L199.249 199.364L199.254 199.367L199.265 199.372C199.345 199.413 199.427 199.448 199.511 199.477L199.518 199.479L199.532 199.484Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.197 197.642C199.746 197.92 199.208 198.022 198.686 197.926C198.635 197.916 198.584 197.905 198.533 197.892L200.769 196.404C200.781 196.498 200.784 196.594 200.776 196.688L200.774 196.707L200.772 196.726C200.752 196.913 200.69 197.092 200.591 197.251C200.491 197.409 200.356 197.543 200.197 197.642Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.207 197.656C199.957 197.81 199.678 197.911 199.387 197.954C199.096 197.996 198.8 197.979 198.516 197.903L198.555 197.877C198.83 197.947 199.117 197.961 199.397 197.917C199.678 197.874 199.947 197.775 200.189 197.625C200.346 197.527 200.479 197.395 200.577 197.237C200.676 197.079 200.736 196.902 200.755 196.717L200.757 196.698L200.759 196.679C200.766 196.591 200.764 196.502 200.753 196.415L200.786 196.393C200.8 196.493 200.802 196.595 200.793 196.696L200.792 196.715L200.79 196.734C200.769 196.922 200.706 197.102 200.605 197.262C200.504 197.422 200.368 197.557 200.207 197.656Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.063 201.277C200.07 201.202 200.084 201.129 200.105 201.057L200.11 201.04L200.112 201.034L200.116 201.023C200.172 200.849 200.262 200.688 200.381 200.55C200.501 200.411 200.646 200.298 200.81 200.217C201.045 200.099 201.302 200.031 201.564 200.019C201.827 200.008 202.089 200.052 202.333 200.149C202.379 200.167 202.425 200.187 202.47 200.209L202.511 200.19L202.498 200.183C202.448 200.158 202.397 200.135 202.345 200.115C202.097 200.016 201.83 199.971 201.562 199.983C201.295 199.996 201.033 200.065 200.794 200.186C200.628 200.269 200.48 200.384 200.358 200.524C200.236 200.664 200.144 200.828 200.085 201.004L200.08 201.021L200.078 201.026L200.075 201.038C200.05 201.121 200.034 201.207 200.026 201.293L200.063 201.277Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.944 199.996L199.899 201.37C199.773 201.094 199.648 200.817 199.505 200.547L199.299 200.139L199.076 199.738L201.994 198.111L202.249 198.572L202.488 199.043C202.652 199.354 202.798 199.675 202.944 199.996Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.491 200.2L200.043 201.305C200.049 201.218 200.065 201.132 200.09 201.048L200.095 201.031L200.101 201.014C200.158 200.839 200.249 200.677 200.37 200.538C200.49 200.398 200.637 200.284 200.802 200.203C201.039 200.083 201.298 200.015 201.563 200.003C201.828 199.991 202.093 200.035 202.339 200.133C202.391 200.153 202.441 200.176 202.491 200.2Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.8 200.061L200.352 201.165L200.332 201.156C200.255 201.122 200.181 201.083 200.11 201.04L200.095 201.031L200.08 201.022C199.788 200.84 199.55 200.585 199.391 200.281L199.352 200.205C199.264 200.037 199.216 199.85 199.214 199.661L201.559 198.353C201.65 198.388 201.738 198.431 201.822 198.48L201.839 198.49L201.855 198.499C202.186 198.703 202.452 198.997 202.622 199.346L202.667 199.434C202.758 199.63 202.803 199.844 202.8 200.061Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.818 200.052L202.782 200.068L202.782 200.058C202.786 199.845 202.741 199.634 202.651 199.441L202.607 199.353C202.439 199.009 202.177 198.72 201.852 198.517L201.836 198.508L201.831 198.504L201.819 198.498C201.734 198.448 201.646 198.405 201.554 198.369L201.54 198.363L201.58 198.341C201.664 198.375 201.746 198.415 201.825 198.46L201.843 198.47L201.848 198.473L201.859 198.48C202.196 198.685 202.466 198.983 202.639 199.338L202.683 199.425C202.774 199.621 202.821 199.835 202.818 200.052ZM200.374 201.155L200.332 201.174L200.311 201.165C200.241 201.133 200.172 201.096 200.106 201.056L200.09 201.047L200.085 201.045L200.075 201.038C199.779 200.855 199.538 200.596 199.375 200.288L199.337 200.212C199.248 200.045 199.2 199.859 199.197 199.669L199.232 199.65L199.232 199.658C199.234 199.846 199.281 200.03 199.368 200.196L199.407 200.272C199.565 200.571 199.799 200.824 200.086 201.004L200.101 201.013L200.105 201.016L200.116 201.023C200.192 201.069 200.271 201.11 200.353 201.146L200.36 201.149L200.374 201.155Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.187 199.378C200.715 199.618 200.172 199.676 199.66 199.541C199.609 199.528 199.559 199.512 199.51 199.495L201.855 198.188C201.861 198.282 201.856 198.377 201.842 198.471L201.839 198.49L201.835 198.509C201.801 198.694 201.725 198.868 201.612 199.019C201.5 199.169 201.354 199.292 201.187 199.378Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.197 199.393C200.935 199.526 200.649 199.604 200.356 199.623C200.063 199.643 199.769 199.603 199.492 199.506L199.533 199.483C199.802 199.573 200.086 199.608 200.369 199.587C200.652 199.567 200.928 199.49 201.18 199.362C201.346 199.277 201.49 199.155 201.601 199.005C201.712 198.855 201.787 198.682 201.82 198.498L201.823 198.48L201.826 198.461C201.839 198.373 201.844 198.285 201.839 198.197L201.874 198.178C201.88 198.279 201.876 198.38 201.86 198.48L201.856 198.499L201.853 198.518C201.817 198.704 201.74 198.88 201.626 199.032C201.512 199.183 201.365 199.307 201.197 199.393Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.743 202.982C200.756 202.909 200.777 202.837 200.805 202.768L200.811 202.751L200.813 202.746L200.818 202.735C200.89 202.567 200.995 202.415 201.127 202.288C201.258 202.161 201.414 202.061 201.585 201.996C201.829 201.899 202.091 201.855 202.353 201.867C202.616 201.88 202.873 201.948 203.107 202.067C203.151 202.089 203.194 202.113 203.237 202.139L203.281 202.124L203.268 202.115C203.22 202.086 203.171 202.058 203.122 202.033C202.883 201.912 202.622 201.843 202.354 201.831C202.087 201.819 201.821 201.864 201.572 201.963C201.399 202.031 201.24 202.132 201.106 202.26C200.972 202.389 200.864 202.543 200.789 202.714L200.783 202.73L200.78 202.735L200.776 202.747C200.744 202.827 200.72 202.91 200.705 202.995L200.743 202.982Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.729 201.967L200.571 203.056C200.374 202.486 200.152 201.922 199.899 201.369L202.944 199.995C203.237 200.637 203.497 201.296 203.729 201.967Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.259 202.129L200.721 203.005C200.735 202.92 200.758 202.836 200.79 202.755L200.797 202.739L200.804 202.722C200.877 202.553 200.983 202.4 201.116 202.272C201.249 202.144 201.406 202.044 201.579 201.977C201.825 201.88 202.089 201.835 202.354 201.848C202.619 201.86 202.878 201.928 203.114 202.048C203.164 202.073 203.212 202.1 203.259 202.129Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.58 202.018L201.042 202.894L201.022 202.883C200.949 202.843 200.879 202.798 200.811 202.749L200.797 202.739L200.783 202.728C200.649 202.626 200.529 202.507 200.426 202.375C200.317 202.242 200.228 202.093 200.164 201.934L200.132 201.856C200.062 201.68 200.032 201.492 200.044 201.304L202.491 200.199C202.579 200.243 202.664 200.293 202.744 200.348L202.761 200.36L202.776 200.371C203.086 200.607 203.326 200.924 203.468 201.287L203.501 201.38C203.575 201.584 203.602 201.802 203.58 202.018Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.6 202.014L203.562 202.027L203.563 202.017C203.585 201.805 203.559 201.59 203.485 201.389L203.452 201.296C203.312 200.938 203.076 200.626 202.772 200.392L202.757 200.381L202.752 200.377L202.74 200.369C202.659 200.312 202.574 200.262 202.485 200.218L202.471 200.211L202.513 200.192C202.595 200.234 202.674 200.28 202.75 200.332L202.766 200.344L202.771 200.347L202.781 200.355C203.097 200.593 203.341 200.915 203.485 201.283L203.519 201.376C203.593 201.58 203.621 201.798 203.6 202.014ZM201.064 202.889L201.021 202.904L201.001 202.893C200.934 202.855 200.868 202.813 200.806 202.768L200.791 202.757L200.786 202.754L200.777 202.747C200.639 202.642 200.515 202.52 200.41 202.383C200.301 202.25 200.213 202.102 200.148 201.943L200.116 201.865C200.046 201.69 200.016 201.502 200.027 201.314L200.063 201.298L200.062 201.306C200.05 201.492 200.08 201.678 200.149 201.851L200.181 201.929C200.314 202.239 200.523 202.509 200.791 202.714L200.805 202.724L200.809 202.728L200.819 202.735C200.891 202.788 200.966 202.836 201.045 202.878L201.051 202.882L201.064 202.889Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.032 201.192C201.542 201.39 200.997 201.401 200.499 201.223C200.449 201.206 200.4 201.187 200.353 201.166L202.8 200.062C202.799 200.156 202.787 200.251 202.765 200.343L202.76 200.362L202.755 200.38C202.705 200.562 202.613 200.729 202.488 200.87C202.362 201.011 202.207 201.121 202.032 201.192Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.039 201.207C201.767 201.316 201.476 201.369 201.183 201.363C200.89 201.357 200.601 201.293 200.333 201.173L200.376 201.154C200.636 201.266 200.916 201.325 201.199 201.329C201.482 201.332 201.763 201.28 202.026 201.174C202.199 201.103 202.353 200.994 202.477 200.854C202.601 200.714 202.69 200.547 202.739 200.367L202.744 200.348L202.749 200.33C202.769 200.244 202.78 200.156 202.783 200.068L202.819 200.052C202.817 200.153 202.805 200.254 202.781 200.352L202.776 200.371L202.771 200.389C202.719 200.572 202.626 200.741 202.5 200.883C202.373 201.024 202.215 201.135 202.039 201.207Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.255 204.732C201.276 204.661 201.304 204.592 201.338 204.525L201.346 204.51L201.348 204.505L201.354 204.494C201.442 204.334 201.561 204.193 201.705 204.08C201.848 203.966 202.013 203.882 202.189 203.833C202.441 203.76 202.706 203.742 202.966 203.779C203.227 203.817 203.476 203.91 203.697 204.051C203.739 204.078 203.78 204.106 203.821 204.136L203.865 204.125L203.853 204.116C203.809 204.082 203.762 204.049 203.715 204.02C203.49 203.876 203.236 203.782 202.971 203.744C202.707 203.706 202.437 203.725 202.18 203.8C201.818 203.901 201.512 204.143 201.328 204.47L201.32 204.486L201.317 204.491L201.311 204.502C201.271 204.578 201.24 204.658 201.217 204.741L201.255 204.732Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.326 204.014L201.077 204.79C200.937 204.206 200.768 203.628 200.571 203.056L203.729 201.967C203.961 202.637 204.16 203.32 204.326 204.014Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.842 204.13L201.23 204.754C201.253 204.67 201.284 204.59 201.323 204.513L201.332 204.497L201.34 204.482C201.43 204.32 201.551 204.179 201.695 204.064C201.84 203.95 202.006 203.865 202.184 203.816C202.438 203.742 202.706 203.723 202.968 203.761C203.231 203.799 203.482 203.892 203.706 204.035C203.752 204.065 203.798 204.096 203.842 204.13Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.173 204.052L201.561 204.676L201.542 204.663C201.473 204.616 201.408 204.565 201.345 204.51L201.332 204.498L201.319 204.486C201.195 204.374 201.088 204.244 201 204.102C200.904 203.96 200.829 203.805 200.779 203.641L200.755 203.561C200.703 203.38 200.692 203.191 200.721 203.006L203.259 202.13C203.343 202.181 203.423 202.239 203.498 202.302L203.514 202.315L203.528 202.327C203.814 202.594 204.022 202.934 204.13 203.31L204.154 203.407C204.209 203.617 204.215 203.838 204.173 204.052Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.192 204.047L204.153 204.057L204.155 204.047C204.197 203.837 204.191 203.619 204.137 203.411L204.113 203.316C204.007 202.945 203.802 202.61 203.521 202.346L203.508 202.334L203.503 202.329L203.492 202.32C203.416 202.256 203.335 202.198 203.251 202.146L203.237 202.138L203.281 202.123C203.359 202.172 203.434 202.226 203.505 202.284L203.52 202.298L203.525 202.301L203.535 202.31C203.826 202.58 204.038 202.924 204.147 203.306L204.171 203.402C204.226 203.613 204.233 203.834 204.192 204.047ZM201.583 204.671L201.539 204.681L201.52 204.669C201.456 204.625 201.396 204.577 201.338 204.526L201.324 204.514L201.32 204.51L201.311 204.502C201.183 204.387 201.072 204.253 200.983 204.107C200.887 203.965 200.813 203.81 200.763 203.647L200.738 203.566C200.686 203.387 200.674 203.198 200.702 203.013L200.74 203L200.738 203.008C200.709 203.191 200.721 203.378 200.772 203.556L200.797 203.637C200.845 203.796 200.917 203.946 201.01 204.084C201.098 204.227 201.205 204.357 201.328 204.471L201.341 204.483L201.345 204.486L201.354 204.494C201.421 204.554 201.491 204.608 201.565 204.658L201.571 204.662L201.583 204.671Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.711 203.077C202.205 203.227 201.662 203.188 201.183 202.965C201.135 202.944 201.088 202.92 201.042 202.895L203.581 202.02C203.571 202.114 203.551 202.207 203.521 202.297L203.515 202.315L203.508 202.333C203.441 202.51 203.334 202.668 203.195 202.797C203.057 202.926 202.891 203.022 202.711 203.077Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.715 203.094C202.434 203.177 202.139 203.202 201.848 203.169C201.558 203.137 201.276 203.046 201.021 202.903L201.065 202.888C201.567 203.16 202.157 203.222 202.704 203.06C202.884 203.005 203.048 202.91 203.184 202.782C203.321 202.654 203.426 202.496 203.492 202.321L203.498 202.303L203.505 202.285C203.532 202.201 203.551 202.114 203.561 202.027L203.599 202.014C203.588 202.115 203.567 202.214 203.535 202.31L203.528 202.328L203.521 202.346C203.452 202.524 203.344 202.684 203.204 202.813C203.064 202.943 202.897 203.039 202.715 203.094Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.587 206.519C201.616 206.451 201.65 206.385 201.691 206.323L201.7 206.308L201.703 206.303L201.71 206.293C201.815 206.143 201.948 206.015 202.102 205.917C202.256 205.819 202.428 205.752 202.608 205.721C202.867 205.674 203.132 205.682 203.387 205.746C203.642 205.81 203.88 205.928 204.086 206.091C204.125 206.122 204.163 206.154 204.2 206.188L204.246 206.182L204.235 206.172C204.194 206.133 204.151 206.096 204.107 206.062C203.898 205.895 203.655 205.775 203.396 205.711C203.136 205.646 202.866 205.638 202.603 205.687C202.233 205.751 201.903 205.96 201.687 206.267L201.676 206.282L201.673 206.286L201.666 206.296C201.619 206.368 201.579 206.445 201.548 206.524L201.587 206.519Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.716 206.119L201.405 206.559C201.326 205.964 201.216 205.374 201.077 204.791L204.326 204.015C204.492 204.709 204.622 205.411 204.716 206.119Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.223 206.185L201.562 206.539C201.592 206.458 201.632 206.381 201.679 206.309L201.689 206.295L201.699 206.28C201.805 206.129 201.939 206 202.095 205.901C202.251 205.802 202.425 205.735 202.606 205.703C202.867 205.656 203.135 205.664 203.392 205.728C203.649 205.793 203.89 205.911 204.097 206.076C204.141 206.111 204.182 206.147 204.223 206.185Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.56 206.14L201.898 206.494L201.881 206.48C201.817 206.426 201.757 206.369 201.701 206.307L201.689 206.295L201.677 206.282C201.566 206.157 201.473 206.018 201.4 205.868C201.318 205.719 201.259 205.557 201.226 205.39L201.21 205.308C201.177 205.124 201.184 204.935 201.231 204.755L203.843 204.131C203.922 204.191 203.996 204.256 204.065 204.326L204.079 204.341L204.091 204.355C204.35 204.65 204.524 205.01 204.593 205.396L204.608 205.495C204.64 205.711 204.624 205.932 204.56 206.14Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.578 206.137L204.538 206.142L204.541 206.133C204.605 205.927 204.621 205.71 204.589 205.497L204.574 205.399C204.505 205.018 204.335 204.663 204.081 204.372L204.069 204.358L204.065 204.353L204.055 204.343C203.986 204.271 203.911 204.205 203.832 204.145L203.819 204.136L203.864 204.125C203.937 204.181 204.007 204.243 204.072 204.308L204.086 204.323L204.091 204.327L204.099 204.337C204.362 204.636 204.538 205.001 204.609 205.393L204.624 205.491C204.657 205.707 204.641 205.928 204.578 206.137ZM201.919 206.49L201.874 206.496L201.856 206.482C201.798 206.432 201.742 206.378 201.69 206.322L201.677 206.309L201.674 206.305L201.666 206.295C201.551 206.168 201.455 206.025 201.38 205.87C201.299 205.721 201.24 205.56 201.207 205.393L201.191 205.311C201.157 205.127 201.164 204.939 201.211 204.759L201.249 204.749L201.247 204.757C201.2 204.936 201.193 205.122 201.225 205.303L201.242 205.386C201.274 205.548 201.331 205.705 201.409 205.851C201.482 206.001 201.575 206.141 201.686 206.266L201.698 206.279L201.701 206.283L201.71 206.292C201.77 206.358 201.834 206.419 201.903 206.476L201.908 206.481L201.919 206.49Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.203 205.019C202.686 205.118 202.15 205.025 201.696 204.758C201.65 204.731 201.605 204.704 201.562 204.675L204.174 204.051C204.155 204.144 204.126 204.235 204.088 204.322L204.079 204.339L204.071 204.357C203.986 204.526 203.864 204.674 203.714 204.788C203.563 204.903 203.389 204.982 203.203 205.019Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.207 205.038C202.919 205.092 202.624 205.088 202.338 205.027C202.051 204.966 201.78 204.848 201.54 204.681L201.586 204.671C202.059 204.99 202.639 205.109 203.2 205.003C203.383 204.966 203.556 204.887 203.705 204.773C203.854 204.659 203.974 204.512 204.057 204.344L204.065 204.326L204.074 204.309C204.109 204.228 204.136 204.144 204.154 204.057L204.193 204.048C204.173 204.148 204.142 204.245 204.101 204.338L204.092 204.355L204.083 204.373C203.997 204.543 203.873 204.691 203.722 204.806C203.57 204.922 203.394 205.001 203.207 205.038Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.731 208.327C201.767 208.262 201.808 208.2 201.855 208.143L201.866 208.129L201.87 208.125L201.878 208.116C201.998 207.978 202.144 207.865 202.308 207.783C202.471 207.701 202.649 207.653 202.832 207.64C203.094 207.62 203.357 207.656 203.604 207.746C203.85 207.836 204.075 207.978 204.261 208.163C204.296 208.197 204.331 208.234 204.364 208.271L204.41 208.27L204.4 208.258C204.364 208.216 204.325 208.174 204.286 208.136C204.095 207.948 203.867 207.803 203.615 207.711C203.364 207.62 203.095 207.584 202.829 207.606C202.454 207.633 202.106 207.806 201.858 208.087L201.846 208.101L201.842 208.105L201.834 208.114C201.78 208.181 201.732 208.252 201.691 208.329L201.731 208.327Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.885 208.257L201.546 208.348C201.532 207.75 201.484 207.153 201.404 206.56L204.716 206.12C204.811 206.829 204.868 207.542 204.885 208.257Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.387 208.271L201.703 208.344C201.743 208.268 201.79 208.195 201.845 208.129L201.857 208.115L201.868 208.102C202.113 207.821 202.459 207.649 202.831 207.623C203.095 207.602 203.361 207.638 203.61 207.729C203.859 207.82 204.085 207.963 204.274 208.149C204.313 208.188 204.351 208.229 204.387 208.271Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.728 208.26L202.043 208.334L202.028 208.318C201.971 208.258 201.917 208.195 201.868 208.128L201.858 208.114L201.847 208.1C201.652 207.824 201.529 207.504 201.491 207.168L201.484 207.085C201.475 206.991 201.479 206.897 201.495 206.804C201.506 206.713 201.528 206.624 201.561 206.538L204.223 206.185C204.296 206.252 204.363 206.325 204.424 206.402L204.437 206.418L204.449 206.433C204.674 206.755 204.81 207.131 204.841 207.523L204.846 207.623C204.854 207.841 204.814 208.059 204.728 208.26Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.747 208.261L204.707 208.262L204.711 208.253C204.796 208.055 204.836 207.84 204.827 207.624L204.823 207.525C204.791 207.139 204.658 206.769 204.438 206.451L204.426 206.436L204.423 206.431L204.414 206.419C204.352 206.341 204.284 206.267 204.211 206.199L204.2 206.189L204.245 206.183C204.312 206.246 204.375 206.314 204.433 206.387L204.446 206.403L204.45 206.407L204.458 206.418C204.688 206.743 204.827 207.125 204.858 207.523L204.863 207.622C204.872 207.841 204.832 208.059 204.747 208.261ZM202.066 208.334L202.02 208.335L202.004 208.319C201.951 208.263 201.902 208.204 201.856 208.143L201.845 208.128L201.842 208.124L201.835 208.114C201.636 207.836 201.511 207.511 201.473 207.171L201.465 207.087C201.452 206.902 201.478 206.716 201.541 206.542L201.581 206.536L201.577 206.544C201.514 206.716 201.488 206.9 201.501 207.084L201.508 207.167C201.546 207.498 201.666 207.815 201.858 208.087L201.868 208.101L201.871 208.106L201.879 208.115C201.931 208.187 201.989 208.255 202.05 208.318L202.055 208.324L202.066 208.334Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.496 207.004C202.971 207.049 202.448 206.901 202.023 206.589C201.98 206.559 201.939 206.527 201.898 206.493L204.561 206.14C204.532 206.231 204.494 206.319 204.447 206.401L204.438 206.418L204.428 206.434C204.327 206.594 204.19 206.729 204.028 206.827C203.867 206.926 203.685 206.986 203.496 207.004Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.496 207.022C203.205 207.046 202.912 207.011 202.633 206.921C202.355 206.831 202.097 206.687 201.875 206.497L201.922 206.491C202.36 206.855 202.925 207.033 203.493 206.987C203.68 206.969 203.86 206.909 204.02 206.81C204.179 206.712 204.314 206.578 204.414 206.419L204.423 206.402L204.433 206.386C204.476 206.309 204.512 206.227 204.539 206.143L204.579 206.138C204.549 206.235 204.508 206.329 204.457 206.417L204.448 206.434L204.438 206.45C204.335 206.611 204.196 206.746 204.033 206.845C203.87 206.944 203.686 207.005 203.496 207.022Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.681 210.114C201.724 210.054 201.772 209.999 201.825 209.948L201.838 209.936L201.842 209.933L201.851 209.924C202.125 209.679 202.485 209.551 202.853 209.568C203.116 209.577 203.373 209.644 203.608 209.763C203.843 209.882 204.049 210.051 204.212 210.258C204.243 210.297 204.273 210.337 204.301 210.379L204.347 210.384L204.339 210.371C204.307 210.323 204.274 210.277 204.239 210.233C204.073 210.023 203.863 209.851 203.624 209.73C203.385 209.609 203.122 209.542 202.854 209.534C202.48 209.517 202.114 209.647 201.833 209.894L201.82 209.906L201.816 209.91L201.807 209.918C201.746 209.976 201.69 210.04 201.642 210.11L201.681 210.114Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.819 210.429L201.493 210.111C201.512 209.819 201.55 209.528 201.547 209.234C201.555 208.942 201.564 208.65 201.547 208.348L204.886 208.257C204.905 208.612 204.896 208.976 204.886 209.34C204.887 209.704 204.844 210.066 204.819 210.429Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.323 210.382L201.649 210.127C201.697 210.057 201.752 209.992 201.814 209.934L201.827 209.922L201.84 209.91C202.118 209.663 202.481 209.534 202.852 209.551C203.117 209.561 203.377 209.628 203.614 209.748C203.851 209.868 204.059 210.038 204.224 210.246C204.259 210.29 204.291 210.335 204.323 210.382Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.662 210.414L201.989 210.159L201.975 210.141C201.925 210.076 201.879 210.007 201.837 209.936L201.829 209.921L201.82 209.906C201.74 209.763 201.679 209.61 201.638 209.45C201.59 209.291 201.568 209.124 201.572 208.957L201.574 208.876C201.578 208.691 201.623 208.51 201.705 208.344L204.389 208.271C204.454 208.345 204.512 208.425 204.565 208.508L204.576 208.525L204.586 208.542C204.783 208.889 204.878 209.285 204.86 209.683L204.852 209.784C204.836 210.006 204.771 210.221 204.662 210.414Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.682 210.414L204.642 210.411L204.647 210.402C204.755 210.212 204.819 210 204.834 209.782L204.842 209.681C204.859 209.288 204.766 208.898 204.574 208.555L204.565 208.539L204.562 208.534L204.554 208.522C204.501 208.438 204.442 208.357 204.377 208.282L204.366 208.27L204.412 208.269C204.472 208.339 204.527 208.413 204.577 208.491L204.588 208.509L204.591 208.514L204.597 208.525C204.799 208.876 204.896 209.278 204.878 209.683L204.87 209.784C204.854 210.005 204.79 210.221 204.682 210.414ZM202.012 210.16L201.966 210.155L201.952 210.138C201.906 210.077 201.864 210.013 201.825 209.947L201.816 209.932L201.813 209.927L201.807 209.917C201.724 209.769 201.661 209.612 201.619 209.448C201.572 209.288 201.55 209.122 201.554 208.956L201.556 208.874C201.56 208.69 201.604 208.509 201.685 208.343L201.725 208.342L201.721 208.349C201.64 208.513 201.596 208.692 201.592 208.874L201.59 208.956C201.586 209.118 201.607 209.28 201.652 209.436C201.693 209.595 201.754 209.749 201.833 209.893L201.842 209.908L201.844 209.913L201.85 209.923C201.895 209.999 201.944 210.073 201.998 210.142L202.003 210.148L202.012 210.16Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.582 209.01C203.055 208.997 202.55 208.795 202.157 208.443C202.118 208.408 202.08 208.372 202.044 208.334L204.728 208.261C204.69 208.348 204.643 208.432 204.587 208.509L204.576 208.525L204.564 208.54C204.449 208.69 204.299 208.81 204.129 208.892C203.958 208.973 203.771 209.014 203.582 209.01Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.58 209.027C202.989 209.01 202.429 208.761 202.021 208.334L202.068 208.332C202.468 208.74 203.01 208.976 203.581 208.991C203.768 208.994 203.954 208.954 204.123 208.872C204.291 208.791 204.438 208.671 204.552 208.522L204.564 208.506L204.575 208.491C204.627 208.418 204.671 208.341 204.707 208.26L204.747 208.259C204.706 208.353 204.656 208.442 204.596 208.524L204.584 208.54L204.573 208.555C204.455 208.705 204.304 208.826 204.132 208.908C203.96 208.99 203.771 209.031 203.58 209.027Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.406 211.875C201.456 211.822 201.511 211.773 201.569 211.729L201.582 211.718L201.587 211.715L201.597 211.708C201.745 211.604 201.913 211.53 202.09 211.491C202.267 211.451 202.45 211.447 202.629 211.479C202.89 211.522 203.138 211.621 203.357 211.769C203.577 211.916 203.761 212.11 203.899 212.335C203.926 212.377 203.95 212.421 203.972 212.466L204.016 212.477L204.01 212.463C203.986 212.411 203.959 212.362 203.929 212.314C203.789 212.085 203.601 211.888 203.377 211.738C203.154 211.588 202.901 211.488 202.636 211.445C202.268 211.381 201.89 211.464 201.583 211.676L201.569 211.686L201.564 211.689L201.554 211.696C201.487 211.747 201.424 211.804 201.367 211.867L201.406 211.875Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.478 212.581L201.219 211.85C201.277 211.563 201.334 211.274 201.384 210.985C201.418 210.694 201.472 210.405 201.493 210.112L204.818 210.43C204.791 210.792 204.728 211.151 204.683 211.511C204.622 211.869 204.552 212.226 204.478 212.581Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.993 212.47L201.373 211.883C201.43 211.821 201.493 211.763 201.56 211.711L201.575 211.701L201.589 211.691C201.892 211.479 202.267 211.397 202.632 211.461C202.895 211.504 203.145 211.604 203.366 211.753C203.587 211.902 203.774 212.096 203.913 212.324C203.943 212.371 203.97 212.42 203.993 212.47Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.324 212.544L201.704 211.957L201.693 211.938C201.654 211.866 201.613 211.792 201.581 211.717L201.574 211.701L201.567 211.685C201.441 211.377 201.396 211.041 201.438 210.71L201.449 210.629C201.478 210.448 201.546 210.276 201.65 210.125L204.323 210.38C204.379 210.462 204.428 210.55 204.468 210.641L204.476 210.659L204.483 210.677C204.635 211.046 204.677 211.451 204.605 211.844L204.586 211.943C204.547 212.161 204.457 212.367 204.324 212.544Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.344 212.548L204.305 212.539L204.312 212.532C204.443 212.357 204.531 212.154 204.57 211.939L204.588 211.84C204.66 211.454 204.619 211.054 204.47 210.69L204.463 210.673L204.461 210.667L204.455 210.654C204.415 210.562 204.367 210.474 204.31 210.391L204.301 210.378L204.347 210.382C204.399 210.46 204.444 210.541 204.483 210.626L204.491 210.645L204.493 210.651L204.498 210.663C204.653 211.037 204.697 211.448 204.623 211.847L204.605 211.946C204.565 212.164 204.476 212.37 204.344 212.548ZM201.727 211.962L201.683 211.951L201.672 211.933C201.636 211.866 201.598 211.796 201.569 211.727L201.561 211.711L201.559 211.706L201.554 211.694C201.425 211.383 201.379 211.043 201.422 210.708L201.433 210.626C201.461 210.446 201.529 210.274 201.632 210.123L201.671 210.127L201.666 210.133C201.564 210.282 201.496 210.452 201.468 210.631L201.457 210.713C201.416 211.039 201.459 211.37 201.583 211.674L201.589 211.69L201.591 211.695L201.596 211.706C201.632 211.787 201.673 211.866 201.717 211.943L201.72 211.949L201.727 211.962Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.426 211.018C202.906 210.939 202.431 210.677 202.088 210.278C202.053 210.239 202.019 210.199 201.987 210.158L204.661 210.413C204.614 210.498 204.556 210.576 204.489 210.646L204.476 210.66L204.462 210.674C204.327 210.808 204.164 210.909 203.984 210.968C203.805 211.028 203.613 211.045 203.426 211.018Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.424 211.036C202.841 210.945 202.318 210.629 201.966 210.156L202.013 210.161C202.359 210.613 202.866 210.913 203.429 211C203.615 211.027 203.804 211.009 203.982 210.949C204.16 210.889 204.322 210.789 204.454 210.655L204.468 210.641L204.482 210.628C204.543 210.562 204.596 210.489 204.641 210.411L204.681 210.415C204.631 210.506 204.569 210.589 204.497 210.664L204.483 210.678L204.469 210.692C204.333 210.826 204.168 210.927 203.987 210.987C203.806 211.046 203.613 211.063 203.424 211.036Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.906 213.591C200.963 213.544 201.025 213.502 201.091 213.466L201.106 213.458L201.111 213.455L201.122 213.45C201.281 213.364 201.456 213.311 201.636 213.293C201.816 213.276 201.998 213.294 202.171 213.348C202.425 213.422 202.659 213.55 202.859 213.723C203.058 213.897 203.217 214.112 203.325 214.352C203.345 214.397 203.364 214.444 203.38 214.491L203.423 214.507L203.419 214.492C203.401 214.439 203.381 214.386 203.358 214.336C203.248 214.091 203.085 213.872 202.882 213.696C202.679 213.52 202.44 213.39 202.181 213.315C201.826 213.206 201.442 213.242 201.113 213.416L201.097 213.424L201.092 213.427L201.081 213.433C201.006 213.474 200.934 213.522 200.868 213.577L200.906 213.591Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.871 214.667L200.725 213.543C200.922 212.989 201.088 212.423 201.219 211.85L204.479 212.58C204.318 213.287 204.114 213.984 203.871 214.667Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.401 214.5L200.872 213.596C200.938 213.54 201.009 213.491 201.085 213.45L201.101 213.442L201.117 213.434C201.278 213.348 201.454 213.294 201.636 213.277C201.818 213.259 202.001 213.278 202.176 213.332C202.431 213.406 202.668 213.536 202.869 213.711C203.07 213.886 203.231 214.102 203.341 214.345C203.363 214.396 203.383 214.447 203.401 214.5Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.721 214.612L201.192 213.709L201.183 213.689C201.152 213.613 201.126 213.536 201.105 213.457L201.101 213.44L201.097 213.424C201.003 213.102 201 212.761 201.089 212.439L201.109 212.359C201.154 212.18 201.245 212.016 201.372 211.883L203.993 212.47C204.034 212.559 204.069 212.651 204.097 212.745L204.103 212.764L204.108 212.783C204.16 212.969 204.186 213.162 204.187 213.355C204.183 213.559 204.149 213.761 204.087 213.954L204.058 214.051C203.996 214.264 203.88 214.458 203.721 214.612Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.739 214.619L203.701 214.606L203.708 214.599C203.866 214.447 203.98 214.256 204.041 214.046L204.07 213.95C204.194 213.576 204.202 213.173 204.093 212.795L204.087 212.777L204.086 212.771L204.082 212.757C204.054 212.662 204.019 212.569 203.976 212.48L203.97 212.466L204.015 212.476C204.053 212.559 204.086 212.645 204.112 212.733L204.118 212.753L204.12 212.759L204.123 212.771C204.177 212.963 204.204 213.162 204.205 213.361C204.199 213.565 204.166 213.766 204.104 213.96L204.074 214.056C204.013 214.27 203.897 214.464 203.739 214.619ZM201.213 213.717L201.17 213.702L201.161 213.681C201.133 213.611 201.109 213.539 201.09 213.466L201.085 213.449L201.083 213.444L201.08 213.432C200.985 213.107 200.982 212.762 201.072 212.434L201.091 212.355C201.136 212.176 201.226 212.012 201.353 211.879L201.392 211.888L201.386 211.893C201.26 212.025 201.17 212.187 201.126 212.364L201.106 212.444C201.019 212.762 201.021 213.098 201.112 213.416L201.116 213.432L201.117 213.438L201.121 213.449C201.143 213.534 201.171 213.616 201.205 213.697L201.208 213.704L201.213 213.717Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.024 212.99C202.516 212.847 202.076 212.527 201.783 212.088C201.755 212.045 201.728 212.001 201.703 211.957L204.324 212.544C204.263 212.621 204.194 212.69 204.118 212.752L204.103 212.764L204.088 212.776C203.939 212.893 203.766 212.973 203.581 213.01C203.396 213.047 203.205 213.04 203.024 212.99Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.019 213.008C202.452 212.847 201.971 212.468 201.682 211.953L201.728 211.963C202.013 212.457 202.479 212.819 203.028 212.973C203.208 213.023 203.398 213.029 203.581 212.992C203.764 212.954 203.936 212.874 204.082 212.758L204.097 212.746L204.113 212.734C204.182 212.676 204.247 212.611 204.304 212.541L204.343 212.55C204.278 212.631 204.204 212.706 204.124 212.771L204.108 212.783L204.093 212.795C203.943 212.912 203.768 212.992 203.581 213.029C203.395 213.066 203.202 213.059 203.019 213.008Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.203 215.239C200.265 215.198 200.331 215.164 200.399 215.136L200.415 215.129L200.421 215.127L200.432 215.123C200.775 214.992 201.156 215.001 201.492 215.149C201.735 215.253 201.952 215.408 202.128 215.603C202.305 215.799 202.436 216.031 202.514 216.283C202.529 216.33 202.542 216.379 202.553 216.428L202.594 216.448L202.591 216.433C202.579 216.378 202.565 216.323 202.548 216.27C202.469 216.014 202.334 215.778 202.154 215.579C201.974 215.38 201.753 215.222 201.506 215.117C201.164 214.968 200.777 214.957 200.427 215.088L200.411 215.095L200.405 215.096L200.394 215.101C200.315 215.133 200.239 215.173 200.168 215.221L200.203 215.239Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M203.019 216.661L200.029 215.17L200.225 214.773C200.287 214.639 200.342 214.501 200.401 214.366C200.523 214.097 200.624 213.82 200.724 213.543L203.87 214.667C203.747 215.008 203.622 215.347 203.473 215.677C203.401 215.843 203.333 216.01 203.257 216.174L203.019 216.661Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.573 216.439L200.17 215.241C200.241 215.192 200.316 215.151 200.396 215.119L200.412 215.113L200.429 215.106C200.775 214.975 201.159 214.985 201.498 215.134C201.743 215.238 201.962 215.395 202.14 215.592C202.319 215.789 202.452 216.023 202.53 216.277C202.547 216.33 202.561 216.384 202.573 216.439Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.877 216.59L200.473 215.392L200.467 215.371C200.445 215.292 200.427 215.211 200.415 215.13L200.412 215.113L200.41 215.095C200.361 214.764 200.401 214.426 200.526 214.116L200.559 214.04C200.623 213.868 200.731 213.715 200.871 213.596L203.4 214.499C203.432 214.593 203.457 214.688 203.474 214.785L203.478 214.805L203.481 214.824C203.542 215.218 203.487 215.621 203.322 215.984L203.282 216.076C203.188 216.276 203.049 216.452 202.877 216.59Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.894 216.6L202.858 216.582L202.866 216.576C203.037 216.44 203.174 216.267 203.266 216.069L203.305 215.977C203.467 215.62 203.522 215.223 203.464 214.835L203.461 214.816L203.46 214.81L203.457 214.796C203.44 214.698 203.415 214.601 203.383 214.507L203.378 214.492L203.422 214.508C203.451 214.595 203.474 214.685 203.49 214.776L203.494 214.796L203.495 214.802L203.497 214.815C203.56 215.214 203.505 215.623 203.338 215.992L203.298 216.084C203.204 216.284 203.066 216.461 202.894 216.6ZM200.494 215.403L200.453 215.382L200.446 215.361C200.426 215.287 200.41 215.213 200.399 215.137L200.396 215.12L200.395 215.114L200.393 215.102C200.343 214.767 200.383 214.425 200.51 214.11L200.542 214.034C200.606 213.862 200.713 213.709 200.853 213.59L200.89 213.603L200.884 213.608C200.745 213.725 200.638 213.877 200.575 214.047L200.542 214.123C200.419 214.43 200.379 214.763 200.426 215.089L200.429 215.107L200.429 215.112L200.431 215.124C200.444 215.211 200.463 215.297 200.488 215.382L200.49 215.389L200.494 215.403Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.379 214.898C201.893 214.692 201.495 214.321 201.257 213.85C201.233 213.804 201.211 213.757 201.191 213.71L203.72 214.613C203.652 214.682 203.576 214.743 203.495 214.795L203.478 214.806L203.462 214.816C203.301 214.914 203.118 214.973 202.93 214.987C202.742 215.001 202.553 214.971 202.379 214.898Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.372 214.914C201.828 214.682 201.397 214.247 201.17 213.702L201.214 213.718C201.44 214.242 201.86 214.659 202.386 214.881C202.558 214.953 202.745 214.982 202.932 214.968C203.118 214.953 203.298 214.894 203.457 214.795L203.474 214.785L203.49 214.775C203.566 214.726 203.636 214.669 203.701 214.606L203.738 214.62C203.665 214.693 203.584 214.758 203.497 214.814L203.48 214.824L203.464 214.834C203.301 214.932 203.117 214.991 202.927 215.005C202.737 215.018 202.547 214.987 202.372 214.914Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.314 216.8C199.379 216.766 199.448 216.739 199.518 216.719L199.535 216.714L199.54 216.712L199.552 216.709C199.728 216.664 199.912 216.654 200.092 216.679C200.273 216.705 200.446 216.766 200.603 216.859C200.831 216.991 201.028 217.171 201.18 217.386C201.332 217.601 201.437 217.846 201.486 218.105C201.496 218.154 201.504 218.204 201.509 218.254L201.548 218.279L201.547 218.264C201.54 218.208 201.532 218.151 201.521 218.096C201.471 217.833 201.365 217.583 201.21 217.365C201.054 217.146 200.854 216.964 200.621 216.83C200.299 216.64 199.914 216.584 199.55 216.674L199.533 216.679L199.528 216.68L199.516 216.683C199.435 216.707 199.355 216.739 199.28 216.778L199.314 216.8Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.945 218.538L199.148 216.711C199.471 216.214 199.765 215.7 200.029 215.17L203.019 216.661C202.697 217.306 202.338 217.933 201.945 218.538Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.528 218.265L199.28 216.796C199.355 216.756 199.434 216.723 199.516 216.7L199.533 216.695L199.55 216.691C199.91 216.599 200.292 216.654 200.612 216.843C200.841 216.977 201.04 217.158 201.194 217.375C201.348 217.591 201.453 217.838 201.503 218.099C201.514 218.154 201.522 218.209 201.528 218.265Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.814 218.452L199.566 216.983L199.562 216.961C199.548 216.879 199.539 216.796 199.535 216.713L199.535 216.696L199.534 216.678C199.529 216.513 199.547 216.347 199.588 216.187C199.623 216.022 199.682 215.863 199.764 215.716L199.804 215.644C199.895 215.484 200.021 215.346 200.171 215.24L202.575 216.438C202.596 216.535 202.61 216.634 202.617 216.733L202.618 216.753L202.619 216.772C202.628 217.169 202.526 217.56 202.323 217.901L202.27 217.987C202.154 218.174 201.998 218.333 201.814 218.452Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.83 218.463L201.796 218.441L201.805 218.436C201.987 218.319 202.141 218.163 202.255 217.978L202.307 217.893C202.506 217.557 202.608 217.172 202.601 216.781L202.6 216.762V216.756L202.599 216.741C202.593 216.641 202.578 216.542 202.557 216.444L202.553 216.429L202.595 216.449C202.614 216.54 202.627 216.632 202.634 216.725L202.635 216.746L202.636 216.752L202.636 216.765C202.647 217.167 202.544 217.565 202.338 217.911L202.285 217.996C202.17 218.184 202.014 218.343 201.83 218.463ZM199.585 216.996L199.546 216.971L199.542 216.949C199.53 216.873 199.522 216.796 199.518 216.719L199.517 216.701L199.517 216.696L199.517 216.684C199.511 216.513 199.53 216.343 199.572 216.177C199.608 216.013 199.667 215.855 199.748 215.709L199.788 215.636C199.879 215.477 200.004 215.339 200.153 215.232L200.189 215.25L200.182 215.255C200.033 215.359 199.909 215.495 199.819 215.653L199.779 215.726C199.7 215.869 199.642 216.023 199.608 216.182C199.566 216.343 199.546 216.509 199.551 216.675L199.551 216.692V216.697L199.552 216.71C199.556 216.798 199.566 216.887 199.581 216.974L199.582 216.982L199.585 216.996Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.51 216.711C201.052 216.451 200.702 216.035 200.523 215.54C200.505 215.491 200.488 215.441 200.474 215.392L202.877 216.59C202.802 216.65 202.72 216.702 202.634 216.745L202.617 216.753L202.6 216.761C202.427 216.838 202.238 216.873 202.049 216.864C201.86 216.856 201.675 216.803 201.51 216.711Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M201.502 216.727C200.99 216.433 200.614 215.95 200.454 215.382L200.496 215.403C200.657 215.95 201.024 216.413 201.519 216.695C201.683 216.786 201.866 216.838 202.053 216.846C202.241 216.854 202.427 216.818 202.598 216.741L202.616 216.733L202.633 216.724C202.713 216.684 202.789 216.636 202.859 216.581L202.895 216.599C202.815 216.663 202.728 216.719 202.636 216.764L202.618 216.772L202.601 216.78C202.426 216.857 202.236 216.892 202.045 216.882C201.854 216.873 201.668 216.82 201.502 216.727Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198.246 218.249C198.317 218.226 198.389 218.208 198.462 218.194L198.479 218.191L198.484 218.19L198.496 218.188C198.676 218.162 198.859 218.172 199.034 218.217C199.21 218.263 199.375 218.343 199.519 218.453C199.731 218.611 199.906 218.813 200.034 219.044C200.162 219.275 200.239 219.531 200.259 219.795C200.262 219.843 200.264 219.893 200.263 219.943L200.298 219.972L200.299 219.957C200.3 219.901 200.299 219.845 200.296 219.79C200.274 219.522 200.196 219.262 200.066 219.026C199.935 218.791 199.756 218.586 199.54 218.426C199.393 218.314 199.225 218.232 199.047 218.186C198.868 218.139 198.682 218.128 198.499 218.153L198.481 218.156L198.476 218.156L198.464 218.158C198.38 218.174 198.297 218.196 198.216 218.224L198.246 218.249Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.663 220.276L198.092 218.143C198.284 217.916 198.458 217.675 198.643 217.443C198.812 217.2 198.986 216.959 199.149 216.712L201.946 218.539C201.747 218.84 201.537 219.133 201.33 219.427C201.108 219.71 200.895 220.001 200.663 220.276Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.279 219.956L198.212 218.242C198.293 218.213 198.376 218.19 198.461 218.175L198.478 218.172L198.495 218.169C198.677 218.143 198.861 218.154 199.039 218.2C199.216 218.246 199.382 218.327 199.528 218.438C199.741 218.597 199.919 218.801 200.048 219.034C200.177 219.267 200.254 219.525 200.276 219.791C200.279 219.846 200.28 219.901 200.279 219.956Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.541 220.174L198.474 218.459L198.473 218.437C198.471 218.355 198.474 218.274 198.478 218.19L198.479 218.173L198.481 218.155C198.508 217.82 198.625 217.498 198.818 217.222L198.865 217.155C198.973 217.004 199.116 216.881 199.281 216.797L201.529 218.266C201.54 218.364 201.542 218.462 201.534 218.561L201.533 218.581L201.531 218.599C201.499 218.995 201.352 219.372 201.108 219.684L201.046 219.764C200.914 219.94 200.741 220.081 200.541 220.174Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.556 220.187L200.525 220.161L200.535 220.157C200.732 220.066 200.903 219.927 201.032 219.753L201.093 219.674C201.334 219.366 201.479 218.995 201.512 218.606L201.514 218.587L201.515 218.581L201.515 218.567C201.524 218.468 201.523 218.369 201.511 218.27L201.51 218.254L201.548 218.279C201.558 218.371 201.56 218.463 201.553 218.555L201.551 218.576L201.551 218.582L201.55 218.594C201.518 218.996 201.369 219.379 201.122 219.696L201.06 219.775C200.929 219.952 200.756 220.093 200.556 220.187ZM198.492 218.475L198.457 218.445L198.455 218.423C198.454 218.347 198.458 218.271 198.461 218.194L198.462 218.176L198.462 218.171L198.463 218.159C198.49 217.819 198.608 217.492 198.804 217.213L198.851 217.145C198.958 216.994 199.1 216.872 199.265 216.787L199.298 216.809L199.291 216.812C199.127 216.895 198.986 217.016 198.88 217.165L198.832 217.234C198.642 217.505 198.527 217.823 198.498 218.153L198.497 218.171L198.496 218.176L198.495 218.188C198.491 218.277 198.488 218.365 198.491 218.453L198.491 218.46L198.492 218.475Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.439 218.412C200.013 218.103 199.713 217.649 199.596 217.136L199.564 216.982L201.812 218.452C201.731 218.504 201.643 218.545 201.55 218.574L201.532 218.58L201.513 218.586C201.333 218.642 201.142 218.656 200.956 218.625C200.769 218.595 200.593 218.522 200.439 218.412Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M200.43 218.427C200.194 218.254 199.995 218.037 199.843 217.787C199.692 217.537 199.591 217.26 199.547 216.971L199.586 216.996C199.679 217.559 199.99 218.062 200.451 218.397C200.603 218.506 200.778 218.578 200.963 218.608C201.148 218.637 201.337 218.623 201.516 218.566L201.534 218.561L201.553 218.555C201.639 218.527 201.72 218.489 201.797 218.441L201.83 218.463C201.743 218.519 201.649 218.563 201.55 218.594L201.531 218.599L201.513 218.605C201.331 218.661 201.138 218.674 200.95 218.643C200.763 218.612 200.584 218.538 200.43 218.427Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.018 219.57C197.09 219.553 197.165 219.543 197.239 219.54L197.257 219.539H197.262L197.275 219.538C197.456 219.533 197.637 219.563 197.806 219.628C197.975 219.693 198.13 219.791 198.262 219.916C198.455 220.096 198.607 220.315 198.708 220.559C198.809 220.803 198.855 221.065 198.845 221.329C198.843 221.378 198.839 221.428 198.833 221.478L198.865 221.511L198.867 221.496C198.874 221.44 198.879 221.384 198.882 221.329C198.892 221.06 198.844 220.793 198.741 220.545C198.638 220.297 198.483 220.074 198.285 219.891C198.152 219.764 197.994 219.664 197.822 219.598C197.65 219.531 197.466 219.5 197.282 219.504L197.264 219.505H197.258L197.246 219.505C197.16 219.509 197.074 219.521 196.99 219.541L197.018 219.57Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.194 221.852L196.877 219.446C197.306 219.034 197.712 218.599 198.092 218.142L200.663 220.274C200.203 220.827 199.713 221.354 199.194 221.852Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M198.849 221.493L196.986 219.559C197.07 219.537 197.156 219.525 197.242 219.521L197.26 219.521L197.278 219.52C197.461 219.515 197.643 219.546 197.814 219.612C197.985 219.677 198.141 219.776 198.274 219.902C198.469 220.083 198.622 220.305 198.724 220.551C198.826 220.797 198.874 221.062 198.864 221.328C198.861 221.383 198.856 221.438 198.849 221.493Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.085 221.739L197.222 219.805L197.223 219.783C197.229 219.701 197.241 219.62 197.257 219.539L197.26 219.522L197.264 219.505C197.327 219.174 197.48 218.867 197.707 218.617L197.761 218.554C197.883 218.413 198.038 218.306 198.213 218.243L200.28 219.958C200.277 220.056 200.268 220.153 200.251 220.25L200.247 220.27L200.244 220.288C200.207 220.477 200.145 220.66 200.059 220.833C199.962 221.009 199.842 221.173 199.703 221.319L199.634 221.392C199.483 221.552 199.294 221.672 199.085 221.739Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.098 221.752L199.071 221.723L199.08 221.72C199.287 221.654 199.474 221.536 199.622 221.378L199.691 221.305C199.825 221.164 199.942 221.005 200.037 220.834C200.124 220.663 200.187 220.48 200.225 220.291L200.228 220.272L200.23 220.266L200.232 220.252C200.249 220.155 200.259 220.056 200.262 219.957L200.262 219.942L200.298 219.971C200.295 220.063 200.285 220.154 200.27 220.245L200.266 220.265L200.265 220.271L200.263 220.283C200.225 220.478 200.161 220.667 200.072 220.844C199.975 221.021 199.855 221.184 199.716 221.33L199.647 221.402C199.497 221.563 199.308 221.684 199.098 221.752ZM197.238 219.82L197.206 219.787L197.207 219.765C197.214 219.689 197.224 219.614 197.239 219.539L197.243 219.521L197.244 219.516L197.246 219.504C197.309 219.169 197.464 218.857 197.694 218.604L197.748 218.541C197.869 218.4 198.024 218.293 198.198 218.229L198.229 218.255L198.221 218.258C198.048 218.32 197.894 218.425 197.775 218.565L197.721 218.628C197.497 218.874 197.345 219.177 197.282 219.504L197.278 219.521L197.277 219.526L197.275 219.538C197.257 219.624 197.245 219.711 197.239 219.799L197.239 219.806L197.238 219.82Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.186 219.982C198.796 219.624 198.548 219.138 198.487 218.613C198.481 218.562 198.477 218.51 198.475 218.459L200.542 220.174C200.453 220.214 200.361 220.244 200.266 220.265L200.248 220.269L200.228 220.273C200.044 220.309 199.854 220.301 199.673 220.251C199.491 220.2 199.325 220.108 199.186 219.982Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.173 219.996C198.958 219.797 198.783 219.558 198.661 219.292C198.538 219.026 198.468 218.738 198.457 218.445L198.493 218.475C198.523 219.046 198.775 219.582 199.197 219.968C199.335 220.093 199.5 220.185 199.68 220.234C199.86 220.283 200.049 220.29 200.231 220.253L200.25 220.249L200.269 220.245C200.357 220.226 200.443 220.197 200.525 220.161L200.556 220.187C200.462 220.23 200.364 220.262 200.262 220.284L200.243 220.288L200.224 220.292C200.038 220.327 199.846 220.319 199.664 220.268C199.481 220.216 199.314 220.123 199.173 219.996Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.563 220.983C195.641 220.956 195.721 220.937 195.803 220.925L195.822 220.922L195.828 220.922L195.841 220.92C196.026 220.897 196.213 220.917 196.389 220.979C196.564 221.041 196.723 221.142 196.852 221.276C197.217 221.654 197.43 222.152 197.452 222.677C197.454 222.723 197.455 222.77 197.453 222.816L197.487 222.848L197.487 222.834C197.489 222.782 197.489 222.729 197.487 222.677C197.464 222.144 197.248 221.637 196.879 221.252C196.747 221.115 196.586 221.01 196.408 220.946C196.229 220.882 196.039 220.86 195.85 220.882L195.831 220.885L195.825 220.885L195.812 220.887C195.717 220.9 195.624 220.923 195.534 220.956L195.563 220.983Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.83 223.176L195.415 220.868C195.665 220.615 195.909 220.355 196.171 220.116L196.877 219.446L199.194 221.852L198.471 222.539C198.247 222.742 198.043 222.963 197.83 223.176Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.469 222.831L195.528 220.976C195.618 220.943 195.712 220.919 195.807 220.905L195.826 220.903L195.845 220.901C196.032 220.878 196.221 220.899 196.398 220.962C196.575 221.025 196.735 221.128 196.865 221.263C197.231 221.645 197.446 222.147 197.469 222.676C197.471 222.728 197.471 222.78 197.469 222.831Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.715 223.067L195.773 221.212L195.776 221.188C195.785 221.099 195.8 221.01 195.821 220.923L195.826 220.904L195.831 220.885C195.929 220.506 196.136 220.164 196.426 219.9L196.498 219.832C196.634 219.7 196.802 219.607 196.985 219.561L198.848 221.495C198.836 221.592 198.816 221.689 198.789 221.784L198.783 221.803L198.778 221.821C198.75 221.912 198.716 222.002 198.677 222.088C198.644 222.168 198.607 222.245 198.565 222.32C198.481 222.469 198.375 222.605 198.25 222.722L198.191 222.782C198.059 222.916 197.895 223.015 197.715 223.067Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.73 223.079L197.701 223.052L197.709 223.049C197.888 222.998 198.05 222.901 198.18 222.768L198.238 222.708C198.359 222.594 198.463 222.462 198.546 222.318C198.588 222.245 198.624 222.169 198.656 222.09C198.697 222.003 198.731 221.913 198.759 221.821L198.765 221.803L198.767 221.798L198.77 221.784C198.798 221.688 198.818 221.591 198.831 221.492L198.833 221.477L198.865 221.51C198.852 221.601 198.834 221.691 198.809 221.78L198.803 221.8L198.802 221.806L198.798 221.818C198.769 221.913 198.734 222.005 198.693 222.095C198.659 222.176 198.621 222.256 198.578 222.332C198.494 222.481 198.388 222.616 198.263 222.733L198.204 222.793C198.073 222.927 197.91 223.026 197.73 223.079ZM195.791 221.226L195.758 221.195L195.76 221.171C195.769 221.088 195.784 221.005 195.803 220.924L195.808 220.905L195.809 220.899L195.812 220.886C195.911 220.501 196.121 220.153 196.415 219.885L196.487 219.817C196.622 219.685 196.79 219.592 196.972 219.545L197 219.574L196.992 219.575C196.811 219.621 196.645 219.713 196.511 219.843L196.439 219.911C196.154 220.172 195.949 220.508 195.851 220.881L195.846 220.9L195.844 220.906L195.841 220.919C195.818 221.012 195.802 221.107 195.793 221.203L195.792 221.211L195.791 221.226Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.77 221.397C197.415 221.002 197.218 220.49 197.218 219.959C197.217 219.907 197.219 219.855 197.222 219.804L199.085 221.738C198.994 221.769 198.9 221.789 198.804 221.8L198.785 221.802L198.765 221.804C198.579 221.818 198.392 221.788 198.22 221.718C198.047 221.647 197.893 221.537 197.77 221.397Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M197.756 221.409C197.561 221.19 197.411 220.933 197.316 220.654C197.222 220.376 197.184 220.081 197.206 219.788L197.239 219.822C197.222 220.105 197.261 220.389 197.354 220.658C197.447 220.926 197.593 221.173 197.782 221.385C197.904 221.524 198.057 221.633 198.228 221.702C198.4 221.772 198.586 221.8 198.77 221.784L198.79 221.782L198.809 221.781C198.898 221.77 198.985 221.751 199.071 221.724L199.098 221.752C199.001 221.785 198.9 221.808 198.798 221.819L198.778 221.82L198.759 221.822C198.572 221.835 198.384 221.804 198.21 221.732C198.036 221.661 197.881 221.55 197.756 221.409Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.174 222.59C194.248 222.553 194.327 222.525 194.408 222.506L194.426 222.501L194.432 222.5L194.445 222.497C194.628 222.455 194.817 222.456 194.999 222.5C195.181 222.544 195.35 222.63 195.493 222.75C195.892 223.087 196.153 223.559 196.227 224.076L196.246 224.216L196.282 224.244L196.281 224.23L196.262 224.074C196.187 223.547 195.922 223.067 195.516 222.722C195.371 222.599 195.199 222.512 195.015 222.466C194.83 222.42 194.637 222.418 194.451 222.459L194.432 222.463L194.426 222.464L194.413 222.467C194.319 222.489 194.228 222.522 194.143 222.566L194.174 222.59Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.656 224.535L194.014 222.491C194.235 222.209 194.465 221.935 194.693 221.658C194.933 221.395 195.166 221.124 195.414 220.868L197.829 223.176C197.619 223.392 197.425 223.621 197.222 223.841C197.033 224.073 196.84 224.301 196.656 224.535Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.263 224.231L194.139 222.589C194.224 222.543 194.315 222.509 194.409 222.487L194.428 222.483L194.447 222.479C194.631 222.438 194.822 222.439 195.006 222.484C195.189 222.529 195.359 222.616 195.504 222.737C195.905 223.079 196.169 223.555 196.244 224.076L196.263 224.231Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.532 224.439L194.408 222.796L194.407 222.772C194.406 222.681 194.412 222.591 194.426 222.502L194.429 222.483L194.432 222.464C194.495 222.075 194.669 221.712 194.933 221.419L194.999 221.346C195.143 221.181 195.325 221.055 195.529 220.978L197.47 222.833C197.468 222.923 197.458 223.012 197.446 223.102L197.444 223.12L197.441 223.137C197.387 223.472 197.245 223.786 197.03 224.048L196.977 224.113C196.857 224.256 196.705 224.367 196.532 224.439Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.547 224.45L196.515 224.426L196.523 224.423C196.693 224.353 196.844 224.242 196.962 224.101L197.015 224.037C197.227 223.778 197.368 223.468 197.422 223.138L197.425 223.121L197.426 223.116L197.427 223.103C197.439 223.013 197.449 222.922 197.452 222.831L197.452 222.817L197.486 222.849C197.483 222.933 197.473 223.015 197.463 223.1L197.46 223.118L197.459 223.124L197.457 223.135C197.404 223.475 197.26 223.794 197.042 224.06L196.99 224.124C196.87 224.266 196.718 224.378 196.547 224.45ZM194.425 222.81L194.389 222.782L194.388 222.757C194.387 222.673 194.393 222.59 194.406 222.507L194.409 222.487L194.41 222.481L194.412 222.468C194.474 222.073 194.65 221.704 194.918 221.408L194.985 221.333C195.128 221.169 195.31 221.042 195.514 220.964L195.542 220.991L195.534 220.994C195.332 221.07 195.153 221.195 195.012 221.357L194.945 221.431C194.685 221.719 194.513 222.076 194.45 222.459L194.447 222.478L194.446 222.484L194.444 222.498C194.429 222.593 194.422 222.689 194.424 222.786L194.425 222.794L194.425 222.81Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.414 222.778C196.213 222.603 196.051 222.388 195.94 222.146C195.828 221.904 195.769 221.642 195.766 221.375C195.766 221.321 195.768 221.266 195.773 221.211L197.715 223.067C197.632 223.091 197.546 223.108 197.46 223.117L197.443 223.119L197.425 223.121C197.242 223.134 197.059 223.11 196.885 223.052C196.711 222.993 196.551 222.9 196.414 222.778Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M196.402 222.791C196.176 222.595 196 222.348 195.888 222.07C195.776 221.793 195.731 221.493 195.757 221.195L195.791 221.228C195.771 221.516 195.817 221.804 195.927 222.071C196.037 222.338 196.208 222.575 196.425 222.765C196.561 222.885 196.72 222.978 196.892 223.035C197.064 223.093 197.247 223.116 197.428 223.102L197.445 223.1L197.463 223.099C197.543 223.089 197.622 223.074 197.7 223.052L197.729 223.08C197.64 223.105 197.55 223.124 197.458 223.134L197.44 223.135L197.423 223.137C197.238 223.15 197.053 223.126 196.877 223.066C196.702 223.007 196.541 222.913 196.402 222.791Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.962 224.361C193.03 224.314 193.104 224.275 193.181 224.245L193.2 224.238L193.205 224.237L193.218 224.232C193.395 224.169 193.584 224.148 193.771 224.171C193.957 224.194 194.136 224.26 194.293 224.363C194.726 224.654 195.039 225.091 195.175 225.594C195.188 225.639 195.201 225.685 195.211 225.731L195.251 225.754L195.248 225.74C195.236 225.69 195.223 225.638 195.208 225.587C195.071 225.075 194.753 224.629 194.312 224.333C194.154 224.227 193.972 224.159 193.783 224.135C193.593 224.111 193.401 224.131 193.22 224.193L193.202 224.2L193.196 224.201L193.183 224.206C193.092 224.239 193.007 224.284 192.928 224.34L192.962 224.361Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.658 225.998L192.792 224.281C192.972 223.965 193.183 223.672 193.378 223.366C193.58 223.066 193.802 222.784 194.014 222.492L196.656 224.536C196.484 224.776 196.297 225.01 196.134 225.255C195.977 225.504 195.801 225.743 195.658 225.998Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.231 225.742L192.928 224.361C193.006 224.305 193.092 224.259 193.182 224.225L193.201 224.218L193.22 224.212C193.398 224.149 193.589 224.129 193.777 224.152C193.965 224.176 194.145 224.242 194.303 224.347C194.739 224.641 195.054 225.082 195.192 225.59C195.207 225.641 195.22 225.691 195.231 225.742Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.523 225.915L193.22 224.535L193.216 224.511C193.203 224.42 193.198 224.329 193.201 224.237L193.202 224.218L193.203 224.198C193.226 223.802 193.36 223.419 193.59 223.095L193.649 223.015C193.779 222.837 193.946 222.691 194.14 222.587L196.264 224.23C196.273 224.32 196.276 224.41 196.273 224.5L196.273 224.519L196.272 224.535C196.252 224.87 196.147 225.194 195.968 225.477L195.922 225.546C195.818 225.697 195.682 225.823 195.523 225.915Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.539 225.926L195.504 225.905L195.512 225.901C195.669 225.81 195.803 225.686 195.906 225.536L195.951 225.467C196.128 225.188 196.231 224.869 196.253 224.539L196.253 224.522L196.254 224.517L196.254 224.504C196.258 224.413 196.255 224.321 196.246 224.23L196.244 224.216L196.281 224.244C196.289 224.328 196.291 224.413 196.289 224.497L196.288 224.516V224.521L196.287 224.533C196.268 224.872 196.163 225.2 195.981 225.487L195.936 225.555C195.832 225.706 195.697 225.833 195.539 225.926ZM193.238 224.547L193.199 224.524L193.195 224.5C193.184 224.415 193.179 224.329 193.181 224.244L193.182 224.224V224.218L193.183 224.205C193.205 223.802 193.341 223.414 193.574 223.085L193.633 223.004C193.763 222.827 193.93 222.68 194.123 222.575L194.154 222.6L194.146 222.604C193.955 222.706 193.79 222.85 193.662 223.025L193.603 223.106C193.376 223.425 193.244 223.801 193.22 224.192L193.219 224.211L193.218 224.217L193.218 224.231C193.214 224.329 193.22 224.427 193.234 224.523L193.235 224.532L193.238 224.547Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.209 224.286C194.991 224.135 194.807 223.938 194.671 223.71C194.535 223.482 194.449 223.227 194.419 222.963C194.413 222.907 194.409 222.851 194.407 222.795L196.532 224.438C196.454 224.472 196.372 224.498 196.289 224.515L196.271 224.519L196.254 224.522C195.888 224.585 195.513 224.5 195.209 224.286Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.199 224.301C194.953 224.13 194.751 223.904 194.611 223.639C194.47 223.375 194.394 223.081 194.39 222.782L194.427 222.811C194.436 223.1 194.512 223.382 194.65 223.636C194.788 223.89 194.983 224.108 195.22 224.273C195.368 224.378 195.536 224.453 195.714 224.493C195.892 224.532 196.075 224.536 196.255 224.505L196.272 224.501L196.289 224.498C196.367 224.482 196.443 224.458 196.516 224.426L196.547 224.451C196.464 224.488 196.377 224.515 196.288 224.533L196.271 224.537L196.253 224.54C195.884 224.602 195.505 224.516 195.199 224.301Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.972 226.286C192.034 226.229 192.102 226.18 192.175 226.14L192.192 226.131L192.198 226.128L192.21 226.122C192.377 226.035 192.562 225.989 192.751 225.987C192.939 225.985 193.126 226.028 193.295 226.111C193.76 226.344 194.127 226.735 194.329 227.214C194.349 227.257 194.367 227.3 194.384 227.344L194.426 227.362L194.422 227.349C194.403 227.3 194.383 227.251 194.361 227.203C194.156 226.715 193.783 226.316 193.31 226.078C193.139 225.993 192.95 225.949 192.758 225.95C192.567 225.951 192.378 225.996 192.207 226.083L192.189 226.092L192.184 226.095L192.171 226.101C192.085 226.146 192.006 226.203 191.936 226.27L191.972 226.286Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.86 227.55L191.791 226.23C191.928 225.888 192.104 225.567 192.26 225.236C192.419 224.905 192.614 224.599 192.791 224.28L195.657 225.997C195.517 226.254 195.355 226.5 195.231 226.763C195.11 227.026 194.965 227.281 194.86 227.55Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.404 227.354L191.938 226.293C192.007 226.226 192.086 226.168 192.172 226.122L192.19 226.113L192.208 226.104C192.377 226.017 192.564 225.971 192.754 225.97C192.944 225.968 193.132 226.011 193.302 226.095C193.771 226.331 194.14 226.726 194.344 227.21C194.366 227.258 194.386 227.306 194.404 227.354Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.717 227.488L192.25 226.428L192.243 226.404C192.218 226.315 192.201 226.224 192.192 226.132L192.19 226.113L192.189 226.093C192.164 225.693 192.25 225.293 192.437 224.939L192.487 224.851C192.597 224.657 192.747 224.49 192.929 224.362L195.232 225.743C195.252 225.83 195.266 225.919 195.274 226.008L195.275 226.026L195.277 226.043C195.289 226.206 195.278 226.371 195.245 226.532C195.218 226.695 195.168 226.854 195.097 227.003L195.062 227.076C194.978 227.237 194.86 227.378 194.717 227.488Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.733 227.496L194.697 227.481L194.703 227.476C194.845 227.366 194.962 227.228 195.044 227.069L195.08 226.996C195.212 226.698 195.273 226.374 195.258 226.048L195.257 226.031L195.257 226.026L195.255 226.013C195.248 225.923 195.234 225.833 195.214 225.745L195.211 225.731L195.25 225.754C195.268 225.836 195.281 225.919 195.289 226.003L195.291 226.021L195.291 226.027L195.292 226.038C195.305 226.206 195.294 226.376 195.259 226.541C195.232 226.704 195.182 226.862 195.112 227.011L195.076 227.084C194.993 227.244 194.876 227.385 194.733 227.496ZM192.27 226.437L192.227 226.419L192.22 226.395C192.198 226.312 192.182 226.227 192.173 226.142L192.171 226.122L192.171 226.116L192.17 226.102C192.143 225.696 192.23 225.291 192.42 224.931L192.471 224.842C192.579 224.649 192.729 224.481 192.91 224.353L192.944 224.373L192.936 224.378C192.757 224.504 192.609 224.668 192.502 224.859L192.451 224.948C192.267 225.297 192.181 225.69 192.205 226.084L192.207 226.104L192.207 226.11L192.208 226.124C192.217 226.222 192.235 226.319 192.263 226.414L192.265 226.422L192.27 226.437Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.192 225.919C193.957 225.796 193.75 225.624 193.588 225.413C193.425 225.203 193.31 224.96 193.251 224.701C193.238 224.647 193.228 224.591 193.22 224.535L195.523 225.915C195.45 225.958 195.373 225.993 195.292 226.019L195.276 226.024L195.259 226.03C194.902 226.133 194.52 226.093 194.192 225.919Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.183 225.935C193.918 225.797 193.69 225.596 193.518 225.351C193.347 225.105 193.238 224.822 193.2 224.525L193.241 224.55C193.282 224.835 193.391 225.107 193.557 225.343C193.724 225.578 193.945 225.771 194.2 225.905C194.525 226.079 194.904 226.117 195.257 226.013L195.273 226.008L195.29 226.003C195.365 225.978 195.437 225.946 195.505 225.907L195.54 225.927C195.462 225.973 195.379 226.01 195.293 226.038L195.276 226.043L195.259 226.048C194.899 226.151 194.514 226.111 194.183 225.935Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.249 228.352C191.303 228.286 191.365 228.228 191.434 228.178L191.45 228.166L191.455 228.163L191.466 228.155C191.619 228.044 191.795 227.97 191.981 227.941C192.167 227.911 192.358 227.926 192.537 227.984C193.03 228.147 193.449 228.481 193.718 228.925L193.791 229.044L193.835 229.056L193.828 229.043L193.749 228.91C193.475 228.458 193.049 228.117 192.548 227.95C192.366 227.89 192.172 227.874 191.983 227.903C191.793 227.932 191.613 228.005 191.458 228.116L191.441 228.128L191.436 228.131L191.425 228.139C191.345 228.197 191.273 228.265 191.211 228.341L191.249 228.352Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.295 229.177L191.065 228.322C191.161 227.964 191.282 227.614 191.391 227.261C191.507 226.909 191.658 226.574 191.792 226.23L194.861 227.55C194.759 227.819 194.636 228.082 194.548 228.355C194.466 228.63 194.369 228.901 194.295 229.177Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.815 229.049L191.219 228.362C191.279 228.284 191.351 228.215 191.431 228.158L191.447 228.146L191.464 228.135C191.618 228.023 191.796 227.95 191.984 227.921C192.172 227.891 192.364 227.907 192.545 227.966C193.041 228.132 193.463 228.469 193.735 228.916L193.815 229.049Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.142 229.136L191.546 228.45L191.536 228.427C191.499 228.343 191.47 228.256 191.451 228.166L191.446 228.147L191.442 228.127C191.357 227.731 191.386 227.319 191.526 226.939L191.564 226.844C191.646 226.635 191.774 226.447 191.937 226.293L194.404 227.354C194.436 227.437 194.462 227.523 194.482 227.61L194.486 227.627L194.49 227.643C194.525 227.802 194.538 227.965 194.527 228.127C194.521 228.29 194.495 228.452 194.45 228.609L194.426 228.685C194.368 228.856 194.271 229.01 194.142 229.136Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.161 229.141L194.123 229.131L194.128 229.125C194.256 229.001 194.352 228.848 194.409 228.679L194.433 228.603C194.478 228.451 194.503 228.293 194.509 228.135C194.521 227.972 194.509 227.809 194.474 227.65L194.471 227.634L194.47 227.629L194.466 227.616C194.446 227.529 194.42 227.442 194.388 227.358L194.383 227.344L194.425 227.363C194.454 227.441 194.479 227.521 194.498 227.602L194.502 227.62L194.504 227.625L194.506 227.636C194.543 227.799 194.556 227.967 194.545 228.133C194.538 228.296 194.512 228.458 194.467 228.614L194.443 228.69C194.385 228.86 194.289 229.015 194.161 229.141ZM191.568 228.455L191.524 228.444L191.514 228.421C191.48 228.343 191.453 228.261 191.435 228.178L191.43 228.158L191.429 228.152L191.426 228.139C191.339 227.737 191.368 227.319 191.51 226.933L191.548 226.837C191.63 226.628 191.756 226.44 191.919 226.285L191.956 226.301L191.948 226.307C191.787 226.458 191.662 226.644 191.581 226.85L191.543 226.945C191.405 227.32 191.376 227.725 191.459 228.116L191.463 228.135L191.464 228.141L191.467 228.154C191.488 228.25 191.518 228.344 191.558 228.433L191.562 228.441L191.568 228.455Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.401 227.668C193.15 227.579 192.921 227.436 192.732 227.25C192.542 227.063 192.395 226.837 192.302 226.588C192.282 226.535 192.265 226.482 192.249 226.427L194.716 227.487C194.65 227.539 194.578 227.583 194.503 227.619L194.487 227.627L194.471 227.634C194.132 227.783 193.748 227.795 193.401 227.668Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.395 227.684C193.112 227.584 192.858 227.417 192.655 227.196C192.452 226.976 192.306 226.709 192.229 226.419L192.273 226.437C192.351 226.716 192.495 226.971 192.693 227.183C192.89 227.394 193.135 227.555 193.408 227.652C193.752 227.779 194.132 227.766 194.468 227.617L194.484 227.61L194.499 227.603C194.57 227.569 194.636 227.528 194.698 227.48L194.735 227.496C194.664 227.551 194.588 227.598 194.507 227.637L194.491 227.644L194.475 227.651C194.133 227.8 193.746 227.812 193.395 227.684Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.854 230.514C190.901 230.442 190.954 230.375 191.013 230.313L191.027 230.299L191.031 230.295L191.041 230.285C191.172 230.151 191.334 230.051 191.513 229.992C191.692 229.934 191.882 229.92 192.067 229.95C192.581 230.037 193.047 230.302 193.386 230.698C193.415 230.732 193.444 230.768 193.471 230.804L193.516 230.809L193.509 230.797C193.479 230.757 193.447 230.717 193.414 230.678C193.069 230.275 192.595 230.005 192.072 229.914C191.884 229.882 191.691 229.896 191.509 229.954C191.327 230.012 191.161 230.113 191.026 230.248L191.012 230.262L191.007 230.267L190.998 230.276C190.929 230.348 190.867 230.426 190.814 230.51L190.854 230.514Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.988 230.858L190.664 230.515L190.818 229.409L191.065 228.322L194.295 229.177L194.104 230.011L193.988 230.858Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.493 230.808L190.822 230.531C190.875 230.447 190.936 230.368 191.005 230.296L191.019 230.282L191.033 230.268C191.166 230.133 191.33 230.033 191.511 229.974C191.691 229.916 191.883 229.902 192.07 229.933C192.587 230.023 193.058 230.29 193.4 230.689C193.433 230.728 193.464 230.767 193.493 230.808Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.831 230.842L191.16 230.566L191.148 230.545C191.102 230.466 191.061 230.384 191.027 230.3L191.019 230.282L191.012 230.263C190.941 230.079 190.895 229.887 190.874 229.691C190.862 229.588 190.859 229.485 190.863 229.382C190.873 229.277 190.89 229.173 190.913 229.07L190.936 228.969C190.984 228.749 191.081 228.542 191.218 228.363L193.815 229.05C193.859 229.128 193.898 229.208 193.932 229.291L193.939 229.307L193.945 229.323C194.06 229.627 194.096 229.955 194.052 230.276L194.041 230.355C194.016 230.533 193.943 230.701 193.831 230.842Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.851 230.843L193.811 230.839L193.816 230.832C193.927 230.693 193.999 230.527 194.024 230.351L194.034 230.273C194.078 229.955 194.042 229.632 193.931 229.331L193.925 229.316L193.923 229.311L193.918 229.299C193.884 229.216 193.844 229.134 193.799 229.056L193.792 229.043L193.836 229.055C193.878 229.128 193.915 229.203 193.947 229.28L193.954 229.297L193.956 229.302L193.96 229.312C194.077 229.62 194.114 229.952 194.069 230.278L194.059 230.357C194.034 230.534 193.962 230.702 193.851 230.843ZM191.183 230.567L191.137 230.563L191.125 230.542C191.083 230.468 191.045 230.392 191.013 230.313L191.005 230.295L191.003 230.289L190.998 230.276C190.924 230.087 190.877 229.888 190.856 229.686C190.844 229.583 190.841 229.479 190.846 229.375C190.856 229.271 190.873 229.168 190.896 229.066L190.919 228.964C190.967 228.744 191.063 228.537 191.199 228.357L191.238 228.368L191.232 228.375C191.096 228.551 191.001 228.755 190.953 228.972L190.931 229.073C190.843 229.465 190.876 229.875 191.026 230.248L191.033 230.267L191.035 230.272L191.041 230.285C191.077 230.376 191.121 230.463 191.171 230.547L191.175 230.554L191.183 230.567Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.875 229.508C192.613 229.458 192.365 229.351 192.148 229.194C191.932 229.038 191.753 228.835 191.623 228.602C191.595 228.553 191.57 228.502 191.546 228.449L194.142 229.136C194.085 229.196 194.022 229.25 193.953 229.297L193.939 229.306L193.924 229.316C193.612 229.512 193.236 229.581 192.875 229.508Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.871 229.524C192.575 229.468 192.298 229.34 192.063 229.151C191.829 228.963 191.644 228.72 191.524 228.443L191.57 228.455C191.689 228.72 191.871 228.952 192.098 229.132C192.326 229.313 192.594 229.435 192.879 229.491C193.237 229.564 193.609 229.495 193.917 229.299L193.932 229.289L193.946 229.28C194.01 229.236 194.069 229.186 194.122 229.131L194.161 229.141C194.1 229.205 194.033 229.263 193.959 229.312L193.945 229.322L193.93 229.331C193.615 229.528 193.236 229.597 192.871 229.524Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.803 232.71C190.837 232.632 190.877 232.558 190.925 232.488L190.936 232.472L190.939 232.467L190.947 232.456C191.054 232.303 191.197 232.179 191.362 232.094C191.528 232.009 191.712 231.965 191.899 231.967C192.424 231.975 192.93 232.164 193.331 232.502C193.366 232.532 193.401 232.562 193.434 232.594L193.48 232.592L193.47 232.582C193.434 232.546 193.395 232.512 193.355 232.478C192.947 232.134 192.432 231.94 191.897 231.931C191.708 231.928 191.521 231.971 191.351 232.057C191.182 232.142 191.036 232.268 190.926 232.422L190.915 232.438L190.911 232.443L190.903 232.454C190.848 232.535 190.801 232.621 190.764 232.712L190.803 232.71Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.954 232.567L190.618 232.738C190.578 231.996 190.594 231.253 190.666 230.514L193.989 230.857C193.935 231.426 193.923 231.997 193.954 232.567Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.457 232.593L190.775 232.73C190.812 232.639 190.859 232.552 190.914 232.47L190.925 232.454L190.937 232.439C191.045 232.285 191.19 232.16 191.357 232.075C191.524 231.99 191.71 231.946 191.898 231.948C192.428 231.958 192.938 232.149 193.343 232.49C193.383 232.523 193.421 232.557 193.457 232.593Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.796 232.575L191.114 232.712L191.098 232.694C191.039 232.623 190.985 232.549 190.936 232.471L190.925 232.454L190.914 232.437C190.812 232.268 190.733 232.085 190.681 231.895C190.638 231.691 190.622 231.483 190.633 231.276L190.638 231.172C190.644 230.946 190.707 230.724 190.822 230.529L193.493 230.806C193.543 230.876 193.592 230.949 193.642 231.024L193.651 231.039L193.66 231.054C193.833 231.331 193.92 231.653 193.91 231.979L193.914 232.059C193.921 232.238 193.881 232.416 193.796 232.575Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.816 232.574L193.776 232.576L193.78 232.568C193.864 232.412 193.904 232.236 193.896 232.059L193.892 231.979C193.902 231.657 193.817 231.339 193.647 231.065L193.639 231.051L193.636 231.046L193.629 231.035C193.581 230.96 193.529 230.885 193.479 230.814L193.47 230.803L193.516 230.808C193.563 230.874 193.607 230.942 193.654 231.012L193.664 231.028L193.667 231.032L193.672 231.042C193.849 231.322 193.938 231.648 193.928 231.979L193.932 232.059C193.94 232.238 193.9 232.415 193.816 232.574ZM191.137 232.711L191.091 232.713L191.075 232.695C191.021 232.629 190.971 232.559 190.925 232.487L190.914 232.47L190.91 232.465L190.903 232.453C190.797 232.279 190.716 232.091 190.662 231.894C190.621 231.69 190.605 231.482 190.615 231.275L190.621 231.171C190.626 230.944 190.689 230.723 190.803 230.527L190.842 230.531L190.837 230.539C190.724 230.732 190.662 230.95 190.656 231.173L190.651 231.276C190.641 231.478 190.656 231.679 190.696 231.877C190.746 232.069 190.823 232.252 190.926 232.421L190.936 232.438L190.939 232.443L190.947 232.455C190.999 232.538 191.058 232.618 191.122 232.693L191.127 232.699L191.137 232.711Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.642 231.404C192.373 231.395 192.11 231.327 191.87 231.206C191.631 231.085 191.42 230.913 191.253 230.703C191.22 230.658 191.189 230.612 191.161 230.564L193.832 230.841C193.78 230.906 193.724 230.968 193.665 231.028L193.653 231.04L193.64 231.051C193.366 231.294 193.008 231.421 192.642 231.404Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.639 231.421C192.338 231.412 192.043 231.329 191.781 231.179C191.519 231.03 191.298 230.818 191.138 230.562L191.184 230.567C191.344 230.811 191.559 231.013 191.813 231.155C192.066 231.298 192.35 231.377 192.641 231.387C193.004 231.404 193.359 231.278 193.629 231.036L193.642 231.024L193.654 231.013C193.707 230.958 193.76 230.9 193.811 230.839L193.851 230.843C193.795 230.913 193.736 230.979 193.672 231.043L193.66 231.054L193.647 231.066C193.37 231.31 193.009 231.437 192.639 231.421Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.069 234.874C191.089 234.794 191.116 234.716 191.151 234.641L191.159 234.624L191.162 234.619L191.168 234.607C191.257 234.44 191.384 234.297 191.539 234.188C191.694 234.079 191.873 234.009 192.06 233.981C192.575 233.912 193.098 234.026 193.538 234.305C193.578 234.329 193.618 234.354 193.657 234.381L193.702 234.372L193.69 234.363C193.647 234.334 193.603 234.304 193.557 234.277C193.111 233.993 192.579 233.876 192.055 233.945C191.864 233.973 191.682 234.044 191.524 234.154C191.365 234.263 191.234 234.408 191.141 234.577L191.132 234.594L191.129 234.599L191.124 234.611C191.083 234.698 191.052 234.789 191.03 234.882L191.069 234.874Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.167 234.28L190.89 234.93L190.831 234.662C190.814 234.572 190.804 234.48 190.791 234.389L190.717 233.841L190.646 233.293C190.632 233.109 190.627 232.923 190.618 232.739L193.954 232.567C193.961 232.71 193.963 232.853 193.973 232.996L194.032 233.425L194.089 233.853C194.099 233.925 194.105 233.996 194.118 234.067L194.167 234.28Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.678 234.378L191.044 234.9C191.064 234.806 191.095 234.715 191.136 234.628L191.144 234.611L191.153 234.593C191.244 234.426 191.374 234.281 191.53 234.172C191.687 234.063 191.867 233.992 192.056 233.965C192.576 233.896 193.103 234.012 193.546 234.292C193.592 234.32 193.635 234.348 193.678 234.378Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.011 234.311L191.377 234.833L191.358 234.818C191.287 234.758 191.22 234.693 191.158 234.625L191.144 234.61L191.131 234.595C190.889 234.273 190.736 233.893 190.688 233.494L190.675 233.392C190.652 233.167 190.686 232.939 190.775 232.73L193.457 232.593C193.519 232.654 193.578 232.718 193.632 232.787L193.643 232.801L193.653 232.814C193.749 232.945 193.835 233.083 193.909 233.228C193.98 233.376 194.027 233.535 194.049 233.697L194.059 233.777C194.071 233.868 194.071 233.959 194.058 234.049C194.053 234.138 194.037 234.226 194.011 234.311Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.031 234.308L193.992 234.316L193.995 234.308C194.021 234.223 194.037 234.135 194.042 234.046C194.054 233.958 194.055 233.869 194.043 233.781L194.033 233.701C194.012 233.543 193.966 233.389 193.898 233.245C193.825 233.099 193.739 232.96 193.643 232.828L193.633 232.815L193.63 232.811L193.622 232.801C193.568 232.732 193.508 232.666 193.445 232.605L193.435 232.595L193.481 232.593C193.539 232.65 193.594 232.711 193.645 232.774L193.656 232.788L193.659 232.792L193.666 232.801C193.716 232.868 193.762 232.937 193.804 233.009C193.851 233.079 193.892 233.152 193.929 233.228C193.999 233.376 194.046 233.534 194.068 233.696L194.078 233.775C194.09 233.864 194.09 233.954 194.078 234.043C194.073 234.133 194.057 234.222 194.031 234.308ZM191.401 234.83L191.356 234.839L191.337 234.824C191.271 234.767 191.209 234.707 191.151 234.643L191.137 234.628L191.133 234.623L191.124 234.613C190.877 234.288 190.721 233.902 190.672 233.497L190.659 233.395C190.635 233.169 190.669 232.942 190.756 232.733L190.796 232.731L190.792 232.739C190.704 232.945 190.671 233.169 190.694 233.391L190.707 233.493C190.756 233.887 190.905 234.26 191.141 234.579L191.154 234.594L191.158 234.598L191.168 234.609C191.234 234.682 191.306 234.751 191.382 234.815L191.388 234.82L191.401 234.83Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.693 233.316C192.429 233.35 192.16 233.325 191.907 233.242C191.653 233.158 191.422 233.019 191.229 232.835C191.189 232.796 191.151 232.755 191.114 232.713L193.796 232.575C193.757 232.65 193.709 232.721 193.655 232.787L193.644 232.801L193.632 232.814C193.394 233.094 193.059 233.274 192.693 233.316Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.696 233.334C192.394 233.368 192.089 233.331 191.804 233.225C191.527 233.119 191.282 232.943 191.093 232.715L191.14 232.713C191.326 232.929 191.563 233.095 191.829 233.197C192.105 233.297 192.4 233.332 192.692 233.3C193.054 233.258 193.387 233.08 193.622 232.801L193.634 232.788L193.645 232.774C193.695 232.713 193.739 232.647 193.777 232.577L193.817 232.575C193.774 232.656 193.724 232.731 193.666 232.801L193.654 232.814L193.643 232.828C193.402 233.11 193.064 233.291 192.696 233.334Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.496 236.773C191.52 236.707 191.551 236.644 191.587 236.585L191.596 236.57L191.599 236.566L191.605 236.556C191.804 236.252 192.11 236.034 192.463 235.945C192.719 235.876 192.988 235.866 193.249 235.916C193.51 235.965 193.756 236.073 193.97 236.231C194.011 236.261 194.052 236.293 194.092 236.327L194.138 236.319L194.126 236.308C194.081 236.27 194.035 236.233 193.989 236.199C193.771 236.038 193.521 235.929 193.255 235.879C192.989 235.829 192.716 235.84 192.455 235.911C192.097 236.004 191.785 236.225 191.579 236.533L191.57 236.548L191.567 236.552L191.561 236.562C191.519 236.631 191.484 236.704 191.457 236.78L191.496 236.773Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.605 236.236L191.315 236.819L190.891 234.93L194.167 234.28L194.605 236.236Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.114 236.322L191.471 236.792C191.497 236.715 191.531 236.642 191.574 236.573L191.583 236.558L191.592 236.544C191.794 236.238 192.103 236.018 192.459 235.927C192.717 235.858 192.988 235.847 193.252 235.897C193.515 235.947 193.763 236.055 193.979 236.214C194.026 236.248 194.07 236.284 194.114 236.322Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.45 236.263L191.806 236.732L191.788 236.72C191.721 236.674 191.657 236.623 191.596 236.57L191.583 236.559L191.571 236.547C191.309 236.316 191.134 236.003 191.073 235.659L191.05 235.559C191 235.342 190.998 235.117 191.045 234.899L193.679 234.377C193.753 234.428 193.823 234.484 193.889 234.545L193.903 234.557L193.915 234.568C194.035 234.68 194.138 234.809 194.222 234.949C194.263 235.019 194.299 235.093 194.329 235.168C194.365 235.268 194.395 235.37 194.418 235.474L194.442 235.577C194.494 235.803 194.497 236.036 194.45 236.263Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.468 236.26L194.428 236.267L194.431 236.257C194.477 236.034 194.475 235.804 194.423 235.582L194.4 235.478C194.377 235.378 194.348 235.279 194.313 235.182C194.284 235.108 194.25 235.036 194.21 234.967C194.127 234.826 194.024 234.697 193.904 234.584L193.892 234.573L193.889 234.569L193.879 234.56C193.812 234.499 193.741 234.442 193.667 234.39L193.655 234.382L193.7 234.373C193.769 234.421 193.834 234.474 193.897 234.53L193.91 234.542L193.914 234.546L193.922 234.554C194.046 234.669 194.153 234.801 194.239 234.946C194.28 235.017 194.316 235.09 194.345 235.166C194.381 235.265 194.411 235.367 194.434 235.47L194.458 235.573C194.51 235.799 194.514 236.033 194.468 236.26ZM191.827 236.729L191.782 236.737L191.763 236.725C191.702 236.681 191.643 236.634 191.586 236.585L191.573 236.573L191.569 236.57L191.56 236.562C191.294 236.329 191.116 236.012 191.054 235.663L191.031 235.564C190.98 235.347 190.978 235.122 191.024 234.904L191.063 234.896L191.061 234.905C191.014 235.12 191.016 235.342 191.066 235.555L191.089 235.655C191.149 235.994 191.322 236.303 191.579 236.533L191.591 236.545L191.595 236.548L191.604 236.556C191.669 236.614 191.737 236.667 191.809 236.717L191.815 236.721L191.827 236.729Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.031 235.206C192.773 235.273 192.504 235.284 192.242 235.238C191.98 235.191 191.731 235.088 191.512 234.936C191.466 234.904 191.421 234.87 191.377 234.834L194.011 234.312C193.986 234.392 193.952 234.469 193.911 234.542L193.902 234.558L193.893 234.572C193.699 234.888 193.39 235.115 193.031 235.206Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.035 235.222C192.745 235.298 192.44 235.303 192.148 235.236C191.856 235.17 191.584 235.033 191.355 234.838L191.402 234.829C191.625 235.013 191.889 235.142 192.171 235.204C192.454 235.267 192.747 235.261 193.027 235.189C193.383 235.099 193.689 234.874 193.88 234.56L193.889 234.545L193.898 234.53C193.936 234.462 193.967 234.39 193.991 234.315L194.031 234.308C194.004 234.393 193.968 234.476 193.923 234.554L193.914 234.569L193.905 234.584C193.709 234.901 193.397 235.13 193.035 235.222Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.592 238.359C191.636 238.31 191.688 238.262 191.734 238.215L191.745 238.204L191.749 238.2L191.757 238.193C192.024 237.955 192.367 237.82 192.725 237.811C192.993 237.799 193.26 237.851 193.504 237.962C193.748 238.073 193.963 238.24 194.13 238.449C194.159 238.49 194.188 238.534 194.215 238.579L194.261 238.583L194.253 238.569C194.223 238.518 194.192 238.468 194.158 238.422C193.987 238.209 193.768 238.04 193.519 237.928C193.27 237.816 192.998 237.764 192.725 237.777C192.36 237.789 192.011 237.926 191.737 238.166L191.725 238.177L191.721 238.181L191.713 238.188C191.659 238.244 191.604 238.3 191.552 238.356L191.592 238.359Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.734 238.621L191.404 238.359C191.418 238.235 191.421 238.11 191.414 237.985L191.415 237.607C191.378 237.345 191.357 237.085 191.316 236.82L194.606 236.236C194.668 236.619 194.705 237.015 194.749 237.404L194.754 238.011C194.761 238.214 194.755 238.418 194.734 238.621Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.237 238.582L191.56 238.371C191.61 238.313 191.669 238.257 191.722 238.202L191.734 238.191L191.745 238.18C192.016 237.941 192.362 237.805 192.723 237.795C192.994 237.783 193.263 237.834 193.51 237.946C193.756 238.057 193.973 238.225 194.143 238.436C194.176 238.483 194.207 238.531 194.237 238.582Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.576 238.608L191.899 238.398L191.886 238.382L191.745 238.204L191.735 238.191L191.725 238.178C191.634 238.059 191.561 237.927 191.507 237.787C191.484 237.719 191.464 237.65 191.449 237.58C191.427 237.508 191.411 237.433 191.401 237.358L191.393 237.285C191.378 237.117 191.405 236.948 191.472 236.793L194.116 236.323C194.193 236.39 194.261 236.467 194.32 236.551L194.332 236.568L194.343 236.584C194.461 236.753 194.555 236.936 194.625 237.13C194.663 237.232 194.693 237.337 194.712 237.444C194.727 237.558 194.735 237.672 194.735 237.787L194.736 237.9C194.746 238.146 194.691 238.39 194.576 238.608Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.596 238.61L194.557 238.607L194.562 238.597C194.591 238.544 194.617 238.489 194.64 238.433C194.658 238.377 194.673 238.32 194.684 238.262C194.708 238.143 194.719 238.021 194.719 237.899L194.718 237.787C194.717 237.677 194.71 237.567 194.696 237.458C194.679 237.353 194.651 237.249 194.613 237.15C194.544 236.956 194.451 236.772 194.334 236.602L194.322 236.586L194.319 236.581L194.31 236.569C194.252 236.484 194.183 236.406 194.106 236.338L194.093 236.327L194.139 236.319C194.211 236.383 194.275 236.454 194.33 236.533L194.342 236.55L194.346 236.555L194.354 236.566C194.475 236.74 194.573 236.929 194.643 237.129C194.682 237.232 194.712 237.338 194.731 237.446C194.745 237.559 194.753 237.673 194.754 237.787L194.754 237.899C194.755 238.023 194.743 238.146 194.72 238.268C194.708 238.327 194.693 238.385 194.675 238.442C194.652 238.499 194.626 238.555 194.596 238.61ZM191.923 238.4L191.877 238.396L191.863 238.381L191.734 238.215L191.724 238.202L191.72 238.198L191.713 238.189C191.619 238.067 191.544 237.931 191.489 237.787C191.466 237.719 191.446 237.649 191.431 237.579C191.409 237.507 191.393 237.434 191.384 237.36L191.375 237.286C191.36 237.119 191.387 236.951 191.453 236.796L191.492 236.789L191.489 236.796C191.422 236.949 191.395 237.117 191.411 237.283L191.419 237.356C191.428 237.429 191.444 237.501 191.465 237.571C191.485 237.641 191.496 237.707 191.522 237.774C191.574 237.915 191.647 238.047 191.737 238.167L191.747 238.18L191.75 238.184L191.757 238.193L191.91 238.384L191.914 238.389L191.923 238.4Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.405 237.192C192.886 237.231 192.368 237.1 191.931 236.817L191.805 236.733L194.449 236.264C194.426 236.364 194.39 236.46 194.341 236.55L194.331 236.568L194.321 236.586C194.231 236.755 194.1 236.899 193.94 237.005C193.78 237.111 193.596 237.175 193.405 237.192Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.408 237.21C192.827 237.25 192.251 237.082 191.783 236.737L191.829 236.728C192.285 237.058 192.843 237.215 193.403 237.173C193.593 237.156 193.775 237.091 193.933 236.986C194.091 236.88 194.22 236.736 194.309 236.568L194.319 236.55L194.329 236.532C194.374 236.448 194.408 236.359 194.429 236.267L194.468 236.26C194.444 236.367 194.405 236.47 194.352 236.565L194.343 236.583L194.333 236.602C194.241 236.772 194.108 236.916 193.947 237.022C193.785 237.129 193.6 237.193 193.408 237.21Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.245 239.792C191.301 239.758 191.359 239.729 191.419 239.705L191.434 239.7L191.439 239.698L191.449 239.694C191.613 239.635 191.784 239.601 191.958 239.593C192.139 239.58 192.32 239.599 192.494 239.649C192.748 239.721 192.983 239.848 193.182 240.021C193.382 240.205 193.53 240.438 193.612 240.697C193.629 240.747 193.644 240.799 193.657 240.853L193.699 240.871L193.695 240.854C193.681 240.795 193.665 240.736 193.646 240.679C193.563 240.416 193.411 240.179 193.207 239.993C193.002 239.818 192.761 239.689 192.502 239.617C192.151 239.525 191.781 239.542 191.439 239.664L191.424 239.67L191.419 239.672L191.409 239.676C191.339 239.703 191.272 239.736 191.209 239.776L191.245 239.792Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.136 241.058L191.071 239.728C191.112 239.619 191.155 239.512 191.199 239.405C191.241 239.297 191.256 239.178 191.288 239.065C191.347 238.833 191.386 238.596 191.404 238.357L194.734 238.62C194.702 239.035 194.636 239.448 194.535 239.853C194.48 240.057 194.44 240.264 194.368 240.463L194.136 241.058Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.678 240.861L191.215 239.792C191.278 239.751 191.344 239.717 191.414 239.69L191.429 239.684L191.445 239.679C191.607 239.621 191.776 239.587 191.948 239.577C192.133 239.562 192.32 239.581 192.498 239.633C192.76 239.706 193.002 239.837 193.206 240.016C193.402 240.2 193.548 240.431 193.63 240.688C193.648 240.744 193.664 240.802 193.678 240.861Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.99 240.996L191.526 239.927L191.518 239.908C191.486 239.84 191.459 239.77 191.435 239.699L191.43 239.684L191.425 239.669C191.331 239.409 191.309 239.127 191.362 238.855C191.367 238.833 191.37 238.811 191.371 238.789C191.389 238.633 191.455 238.487 191.56 238.371L194.237 238.582C194.29 238.673 194.336 238.769 194.373 238.867L194.381 238.888L194.389 238.907C194.466 239.109 194.509 239.323 194.514 239.539C194.51 239.774 194.478 240.008 194.418 240.236L194.389 240.349C194.303 240.591 194.167 240.811 193.99 240.996Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.008 241.003L193.971 240.988L193.979 240.98C194.154 240.798 194.288 240.581 194.372 240.343L194.401 240.23C194.459 240.01 194.491 239.783 194.495 239.555C194.493 239.339 194.452 239.124 194.374 238.922L194.367 238.903L194.365 238.897L194.359 238.882C194.322 238.782 194.276 238.686 194.222 238.594L194.214 238.58L194.26 238.583C194.309 238.669 194.351 238.759 194.387 238.851L194.395 238.872L194.398 238.878L194.402 238.891C194.483 239.099 194.527 239.32 194.532 239.543C194.527 239.778 194.495 240.013 194.435 240.241L194.405 240.354C194.32 240.596 194.184 240.817 194.008 241.003ZM191.547 239.936L191.505 239.917L191.496 239.898C191.467 239.833 191.441 239.767 191.42 239.704L191.414 239.689L191.413 239.685L191.41 239.674C191.314 239.411 191.292 239.127 191.344 238.852C191.349 238.83 191.352 238.807 191.353 238.785C191.371 238.63 191.436 238.485 191.54 238.369L191.58 238.372L191.575 238.378C191.471 238.493 191.406 238.637 191.388 238.791C191.387 238.814 191.384 238.836 191.379 238.858C191.327 239.127 191.348 239.405 191.44 239.663L191.445 239.678L191.446 239.682L191.45 239.693C191.475 239.769 191.505 239.844 191.539 239.917L191.542 239.923L191.547 239.936Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.331 239.254C192.822 239.138 192.358 238.877 191.996 238.503C191.961 238.468 191.929 238.432 191.898 238.396L194.576 238.607C194.525 238.701 194.464 238.79 194.395 238.871L194.382 238.887L194.368 238.902C194.242 239.048 194.079 239.157 193.897 239.219C193.715 239.281 193.519 239.293 193.331 239.254Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.327 239.274C192.763 239.142 192.255 238.835 191.877 238.396L191.924 238.4C192.294 238.821 192.789 239.115 193.336 239.237C193.523 239.275 193.716 239.262 193.896 239.2C194.077 239.138 194.237 239.028 194.36 238.883L194.374 238.867L194.388 238.852C194.452 238.776 194.508 238.694 194.556 238.607L194.596 238.61C194.542 238.711 194.477 238.805 194.403 238.892L194.389 238.908L194.375 238.923C194.247 239.07 194.083 239.179 193.899 239.241C193.714 239.302 193.517 239.314 193.327 239.274Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.441 241.027C190.507 241.012 190.574 241.003 190.642 240.999L190.658 240.999H190.664L190.675 240.998C190.847 240.993 191.018 241.014 191.183 241.059C191.359 241.107 191.525 241.186 191.673 241.293C191.888 241.445 192.066 241.643 192.196 241.872C192.33 242.109 192.397 242.378 192.389 242.65C192.389 242.702 192.386 242.755 192.381 242.808L192.415 242.838L192.417 242.822C192.423 242.763 192.427 242.703 192.428 242.645C192.437 242.367 192.367 242.093 192.228 241.852C192.095 241.62 191.912 241.42 191.693 241.266C191.396 241.059 191.039 240.954 190.677 240.967L190.66 240.968H190.655L190.643 240.968C190.565 240.971 190.487 240.982 190.411 241L190.441 241.027Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.766 243.155L190.293 240.909C190.378 240.822 190.458 240.73 190.532 240.632L190.74 240.342C190.805 240.245 190.864 240.144 190.915 240.039C190.977 239.941 191.029 239.836 191.07 239.728L194.134 241.058C194.055 241.255 193.961 241.445 193.854 241.628C193.757 241.816 193.65 241.999 193.533 242.175L193.168 242.685C193.042 242.849 192.908 243.006 192.766 243.155Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.397 242.822L190.409 241.017C190.485 240.998 190.563 240.986 190.642 240.983L190.658 240.982L190.675 240.982C191.034 240.968 191.388 241.072 191.682 241.278C191.903 241.434 192.086 241.638 192.218 241.874C192.283 241.992 192.332 242.118 192.365 242.248C192.395 242.379 192.409 242.513 192.407 242.647C192.407 242.705 192.403 242.763 192.397 242.822Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.649 243.051L190.662 241.245L190.66 241.224C190.655 241.149 190.655 241.073 190.659 240.998L190.66 240.982L190.661 240.967C190.668 240.823 190.687 240.679 190.716 240.538C190.756 240.408 190.808 240.283 190.87 240.163L190.9 240.105C190.981 239.979 191.088 239.872 191.215 239.792L193.679 240.861C193.706 240.967 193.72 241.075 193.721 241.184V241.206L193.72 241.227C193.713 241.445 193.678 241.661 193.614 241.869C193.598 241.924 193.58 241.979 193.559 242.034C193.535 242.085 193.509 242.136 193.48 242.188C193.423 242.292 193.36 242.392 193.29 242.489L193.222 242.583C193.076 242.787 192.878 242.949 192.649 243.051Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.664 243.064L192.634 243.037L192.644 243.033C192.87 242.932 193.064 242.773 193.207 242.572L193.275 242.478C193.413 242.297 193.52 242.095 193.592 241.879C193.656 241.671 193.693 241.455 193.701 241.237L193.701 241.216L193.701 241.209L193.701 241.194C193.702 241.084 193.688 240.975 193.661 240.869L193.657 240.852L193.699 240.87C193.724 240.97 193.737 241.071 193.739 241.173L193.739 241.196L193.739 241.202L193.739 241.216C193.732 241.441 193.695 241.664 193.629 241.879C193.599 241.992 193.553 242.1 193.493 242.2C193.436 242.304 193.373 242.403 193.304 242.499L193.236 242.593C193.09 242.798 192.893 242.96 192.664 243.064ZM190.678 241.261L190.644 241.23L190.643 241.209C190.639 241.139 190.639 241.069 190.642 240.999L190.643 240.983L190.643 240.978L190.644 240.967C190.651 240.819 190.671 240.672 190.701 240.526C190.741 240.398 190.792 240.273 190.854 240.154L190.885 240.097C190.964 239.97 191.071 239.864 191.197 239.784L191.234 239.8L191.228 239.804C191.101 239.882 190.995 239.988 190.916 240.114L190.885 240.172C190.825 240.289 190.774 240.411 190.734 240.536C190.703 240.678 190.684 240.822 190.677 240.966L190.676 240.982L190.676 240.986L190.675 240.997C190.671 241.078 190.672 241.159 190.677 241.24L190.678 241.247L190.678 241.261Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.592 241.201C192.156 240.924 191.809 240.528 191.593 240.059C191.569 240.014 191.547 239.97 191.526 239.926L193.99 240.995C193.917 241.074 193.833 241.142 193.739 241.194L193.721 241.205L193.701 241.215C193.532 241.31 193.34 241.359 193.145 241.357C192.95 241.354 192.76 241.301 192.592 241.201Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.582 241.218C192.326 241.061 192.101 240.857 191.919 240.618C191.833 240.508 191.755 240.392 191.686 240.271C191.653 240.213 191.624 240.156 191.595 240.097C191.562 240.037 191.532 239.977 191.505 239.918L191.548 239.937C191.658 240.176 191.795 240.402 191.955 240.611C192.133 240.841 192.351 241.035 192.6 241.185C192.766 241.283 192.956 241.336 193.149 241.337C193.342 241.339 193.532 241.289 193.7 241.193L193.719 241.183L193.738 241.173C193.824 241.123 193.902 241.061 193.97 240.988L194.007 241.004C193.929 241.089 193.838 241.161 193.738 241.216L193.719 241.226L193.7 241.236C193.528 241.332 193.335 241.38 193.138 241.377C192.942 241.374 192.75 241.319 192.582 241.218Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M189.227 242.054C189.299 242.054 189.37 242.06 189.441 242.073L189.457 242.075L189.462 242.076L189.473 242.078C189.828 242.143 190.146 242.335 190.368 242.618C190.536 242.825 190.654 243.067 190.713 243.327C190.772 243.587 190.77 243.857 190.707 244.115C190.694 244.163 190.68 244.212 190.663 244.261L190.688 244.299L190.694 244.284C190.713 244.231 190.73 244.176 190.744 244.121C190.808 243.858 190.809 243.583 190.749 243.319C190.688 243.054 190.567 242.808 190.395 242.598C190.167 242.31 189.844 242.114 189.483 242.045L189.467 242.043L189.462 242.042L189.451 242.04C189.369 242.027 189.287 242.02 189.205 242.021L189.227 242.054Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.949 244.696L189.111 241.906C189.226 241.837 189.335 241.76 189.439 241.676C189.543 241.595 189.655 241.52 189.753 241.437C189.945 241.274 190.126 241.098 190.294 240.911L192.767 243.157C192.495 243.458 192.204 243.739 191.894 244C191.742 244.129 191.583 244.241 191.427 244.361C191.274 244.481 191.114 244.593 190.949 244.696Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.674 244.279L189.197 242.036C189.28 242.035 189.363 242.041 189.444 242.055L189.461 242.058L189.477 242.061C189.834 242.127 190.156 242.321 190.381 242.607C190.55 242.815 190.67 243.06 190.729 243.322C190.789 243.584 190.787 243.856 190.724 244.117C190.71 244.172 190.693 244.225 190.674 244.279Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.861 244.562L189.384 242.32L189.389 242.299C189.407 242.221 189.431 242.148 189.456 242.074L189.46 242.058L189.465 242.042C189.558 241.754 189.721 241.493 189.941 241.285L189.994 241.238C190.055 241.185 190.122 241.14 190.193 241.103C190.261 241.064 190.334 241.034 190.41 241.016L192.398 242.821C192.386 242.927 192.367 243.032 192.339 243.136L192.334 243.157L192.328 243.177C192.264 243.376 192.169 243.564 192.047 243.734C191.984 243.825 191.915 243.912 191.841 243.995C191.763 244.074 191.678 244.148 191.589 244.214L191.501 244.28C191.316 244.429 191.095 244.526 190.861 244.562Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.872 244.578L190.851 244.545L190.861 244.544C191.092 244.508 191.309 244.412 191.492 244.265L191.579 244.2C191.664 244.136 191.746 244.065 191.822 243.99C191.895 243.91 191.962 243.825 192.024 243.736C192.146 243.566 192.242 243.379 192.308 243.181L192.314 243.161L192.316 243.154L192.32 243.139C192.348 243.035 192.368 242.929 192.38 242.822L192.381 242.805L192.415 242.836C192.404 242.936 192.386 243.034 192.36 243.131L192.354 243.152L192.353 243.159L192.348 243.172C192.284 243.379 192.186 243.573 192.059 243.748C191.995 243.84 191.926 243.927 191.851 244.011C191.773 244.089 191.689 244.162 191.6 244.228L191.513 244.294C191.328 244.442 191.107 244.54 190.872 244.578ZM189.397 242.338L189.372 242.3L189.377 242.279C189.394 242.208 189.417 242.138 189.44 242.071L189.445 242.055L189.446 242.05L189.45 242.039C189.542 241.746 189.708 241.482 189.93 241.272L189.983 241.224C190.043 241.173 190.108 241.128 190.178 241.092C190.246 241.051 190.32 241.021 190.396 241.002L190.426 241.029L190.419 241.03C190.342 241.049 190.269 241.079 190.201 241.119C190.132 241.155 190.067 241.199 190.007 241.25L189.954 241.298C189.737 241.504 189.575 241.76 189.483 242.044L189.478 242.06L189.476 242.065L189.473 242.076C189.448 242.156 189.421 242.234 189.402 242.318L189.4 242.325L189.397 242.338Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.272 242.795C191.094 242.594 190.954 242.364 190.858 242.114C190.771 241.881 190.71 241.638 190.677 241.392C190.67 241.342 190.665 241.293 190.662 241.244L192.65 243.05C192.555 243.095 192.456 243.129 192.354 243.153L192.334 243.157L192.314 243.162C192.124 243.194 191.928 243.177 191.746 243.113C191.563 243.049 191.4 242.939 191.272 242.795Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M191.258 242.808C191.06 242.583 190.909 242.321 190.812 242.038C190.766 241.906 190.73 241.771 190.704 241.634C190.675 241.501 190.655 241.366 190.646 241.23L190.68 241.262C190.705 241.528 190.761 241.79 190.849 242.043C190.945 242.316 191.093 242.567 191.286 242.783C191.413 242.926 191.575 243.034 191.756 243.097C191.937 243.16 192.131 243.175 192.32 243.141L192.34 243.137L192.36 243.133C192.455 243.11 192.547 243.078 192.635 243.037L192.664 243.064C192.563 243.113 192.458 243.15 192.349 243.174L192.329 243.179L192.308 243.183C192.116 243.214 191.919 243.196 191.735 243.13C191.551 243.065 191.387 242.954 191.258 242.808Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M187.718 242.861C187.79 242.869 187.86 242.885 187.929 242.907L187.945 242.912L187.95 242.914L187.962 242.918C188.307 243.041 188.592 243.292 188.757 243.62C188.878 243.854 188.947 244.113 188.957 244.376C188.967 244.64 188.918 244.902 188.815 245.145C188.796 245.191 188.774 245.238 188.751 245.283L188.769 245.326L188.777 245.312C188.803 245.261 188.828 245.209 188.85 245.157C188.955 244.91 189.004 244.642 188.993 244.373C188.982 244.105 188.912 243.842 188.787 243.604C188.618 243.272 188.329 243.016 187.979 242.888L187.962 242.882L187.957 242.881L187.946 242.877C187.867 242.851 187.785 242.833 187.702 242.824L187.718 242.861Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M188.958 245.761L187.63 242.696C187.886 242.572 188.158 242.465 188.397 242.324C188.519 242.257 188.646 242.194 188.765 242.124L189.11 241.905L190.947 244.695L190.46 245.003C190.295 245.099 190.129 245.185 189.963 245.275C189.633 245.464 189.294 245.603 188.958 245.761Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M188.76 245.306L187.692 242.842C187.776 242.85 187.858 242.867 187.937 242.893L187.954 242.898L187.97 242.904C188.318 243.03 188.605 243.283 188.772 243.613C188.895 243.85 188.964 244.11 188.974 244.376C188.985 244.642 188.936 244.907 188.832 245.152C188.81 245.204 188.786 245.255 188.76 245.306Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M188.897 245.618L187.829 243.153L187.838 243.133C187.87 243.058 187.906 242.984 187.947 242.914L187.955 242.899L187.964 242.884C188.048 242.746 188.148 242.619 188.263 242.506C188.372 242.389 188.5 242.291 188.641 242.216L188.71 242.179C188.857 242.09 189.026 242.041 189.198 242.038L190.675 244.281C190.641 244.377 190.599 244.47 190.55 244.559L190.54 244.577L190.53 244.595C190.322 244.953 190.013 245.243 189.642 245.427L189.549 245.471C189.345 245.567 189.122 245.617 188.897 245.618Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M188.904 245.634L188.888 245.598L188.898 245.598C189.12 245.598 189.34 245.549 189.541 245.454L189.634 245.41C189.999 245.228 190.303 244.944 190.51 244.592L190.519 244.574L190.523 244.569L190.53 244.556C190.579 244.466 190.622 244.372 190.656 244.275L190.662 244.26L190.687 244.299C190.654 244.388 190.615 244.475 190.569 244.559L190.559 244.578L190.556 244.583L190.55 244.595C190.339 244.96 190.026 245.254 189.649 245.442L189.556 245.486C189.352 245.582 189.129 245.633 188.904 245.634ZM187.838 243.173L187.819 243.131L187.828 243.11C187.858 243.041 187.892 242.973 187.93 242.907L187.938 242.892L187.941 242.887L187.947 242.877C188.033 242.735 188.136 242.604 188.255 242.488C188.364 242.372 188.491 242.274 188.631 242.199L188.7 242.162C188.847 242.073 189.015 242.024 189.187 242.021L189.209 242.054L189.201 242.054C189.03 242.056 188.864 242.105 188.718 242.193L188.649 242.23C188.512 242.303 188.388 242.398 188.281 242.511C188.166 242.624 188.065 242.751 187.98 242.888L187.971 242.903L187.968 242.907L187.962 242.918C187.919 242.994 187.88 243.072 187.846 243.153L187.843 243.16L187.838 243.173Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M189.604 243.979C189.474 243.745 189.389 243.49 189.353 243.225C189.315 242.974 189.316 242.719 189.354 242.468C189.362 242.418 189.372 242.369 189.383 242.32L190.86 244.563C190.761 244.579 190.659 244.584 190.559 244.578L190.539 244.576L190.519 244.575C190.329 244.559 190.146 244.497 189.986 244.392C189.826 244.288 189.695 244.146 189.604 243.979Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M189.588 243.988C189.443 243.726 189.354 243.438 189.326 243.14C189.294 242.861 189.309 242.577 189.371 242.303L189.397 242.342C189.34 242.608 189.328 242.882 189.361 243.152C189.392 243.439 189.48 243.718 189.62 243.971C189.711 244.137 189.841 244.277 190 244.38C190.159 244.482 190.341 244.543 190.529 244.557L190.549 244.558L190.569 244.56C190.663 244.565 190.757 244.561 190.85 244.547L190.871 244.581C190.765 244.597 190.657 244.603 190.549 244.596L190.529 244.594L190.509 244.593C190.318 244.576 190.133 244.512 189.973 244.406C189.812 244.301 189.68 244.157 189.588 243.988Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.036 243.466C186.108 243.484 186.179 243.509 186.247 243.54L186.263 243.547L186.268 243.55L186.279 243.555C186.442 243.634 186.589 243.744 186.709 243.88C186.83 244.016 186.922 244.175 186.98 244.347C187.069 244.595 187.102 244.86 187.079 245.123C187.056 245.385 186.976 245.64 186.846 245.869C186.822 245.912 186.795 245.954 186.768 245.995L186.781 246.039L186.79 246.027C186.821 245.981 186.851 245.934 186.879 245.886C187.011 245.652 187.092 245.393 187.115 245.125C187.138 244.858 187.103 244.588 187.013 244.335C186.892 243.983 186.636 243.692 186.301 243.528L186.285 243.52L186.28 243.517L186.269 243.512C186.191 243.476 186.109 243.448 186.024 243.428L186.036 243.466Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.915 246.493L185.967 243.289C186.533 243.124 187.088 242.926 187.631 242.695L188.958 245.761C188.292 246.045 187.61 246.289 186.915 246.493Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.773 246.016L186.011 243.441C186.095 243.46 186.178 243.488 186.256 243.525L186.273 243.532L186.289 243.54C186.453 243.62 186.601 243.732 186.722 243.869C186.843 244.006 186.936 244.166 186.996 244.34C187.085 244.59 187.119 244.857 187.095 245.123C187.072 245.388 186.992 245.645 186.861 245.876C186.834 245.924 186.804 245.97 186.773 246.016Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.87 246.342L186.108 243.767L186.12 243.748C186.163 243.678 186.211 243.61 186.263 243.546L186.274 243.532L186.285 243.519C186.494 243.257 186.77 243.055 187.083 242.936L187.159 242.906C187.329 242.84 187.513 242.818 187.694 242.841L188.761 245.305C188.715 245.393 188.661 245.476 188.6 245.554L188.587 245.57L188.575 245.585C188.326 245.897 187.992 246.13 187.612 246.255L187.517 246.287C187.309 246.359 187.087 246.378 186.87 246.342Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.875 246.361L186.863 246.322L186.873 246.324C187.087 246.36 187.306 246.341 187.51 246.27L187.605 246.238C187.792 246.175 187.969 246.088 188.131 245.977C188.29 245.864 188.432 245.73 188.555 245.579L188.567 245.564L188.571 245.56L188.579 245.548C188.642 245.47 188.697 245.386 188.743 245.298L188.751 245.284L188.769 245.326C188.725 245.408 188.675 245.486 188.617 245.559L188.605 245.575L188.601 245.58L188.593 245.59C188.341 245.908 188.002 246.145 187.616 246.271L187.521 246.304C187.314 246.376 187.092 246.396 186.875 246.361ZM186.114 243.789L186.101 243.745L186.112 243.726C186.153 243.661 186.197 243.598 186.245 243.539L186.256 243.525L186.26 243.52L186.268 243.511C186.479 243.245 186.758 243.041 187.075 242.919L187.151 242.889C187.321 242.823 187.504 242.8 187.685 242.823L187.701 242.859L187.693 242.858C187.514 242.835 187.332 242.857 187.164 242.922L187.088 242.952C186.779 243.071 186.507 243.269 186.3 243.527L186.289 243.54L186.285 243.544L186.278 243.554C186.222 243.622 186.171 243.695 186.125 243.77L186.121 243.776L186.114 243.789Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M187.769 244.828C187.588 244.333 187.589 243.791 187.772 243.297L187.829 243.153L188.896 245.618C188.798 245.617 188.7 245.603 188.606 245.576L188.587 245.571L188.568 245.566C188.386 245.514 188.219 245.42 188.08 245.292C187.941 245.164 187.835 245.005 187.769 244.828Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M187.751 244.833C187.551 244.279 187.576 243.668 187.819 243.132L187.838 243.175C187.609 243.696 187.59 244.285 187.785 244.82C187.851 244.995 187.957 245.153 188.095 245.279C188.233 245.406 188.399 245.498 188.58 245.548L188.599 245.554L188.618 245.559C188.706 245.583 188.796 245.596 188.887 245.598L188.903 245.634C188.798 245.634 188.694 245.619 188.593 245.59L188.574 245.585L188.556 245.579C188.373 245.526 188.204 245.431 188.065 245.302C187.925 245.172 187.818 245.012 187.751 244.833Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184.254 243.904C184.325 243.929 184.394 243.959 184.46 243.995L184.475 244.003L184.48 244.006L184.491 244.012C184.648 244.104 184.785 244.228 184.893 244.375C185.001 244.523 185.077 244.69 185.118 244.868C185.18 245.124 185.187 245.39 185.14 245.649C185.092 245.908 184.991 246.154 184.842 246.371C184.814 246.413 184.785 246.451 184.753 246.49L184.762 246.535L184.772 246.523C184.808 246.481 184.841 246.436 184.872 246.39C185.024 246.169 185.127 245.919 185.175 245.655C185.223 245.392 185.216 245.121 185.152 244.861C185.11 244.68 185.032 244.509 184.923 244.359C184.813 244.209 184.675 244.082 184.516 243.986L184.5 243.977L184.495 243.974L184.485 243.968C184.408 243.927 184.329 243.892 184.246 243.865L184.254 243.904Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184.855 247L184.201 243.724C184.799 243.599 185.395 243.464 185.967 243.289L186.914 246.492C186.23 246.701 185.542 246.857 184.855 247Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184.758 246.512L184.232 243.879C184.315 243.905 184.396 243.94 184.472 243.981L184.488 243.99L184.503 243.999C184.662 244.093 184.8 244.218 184.908 244.367C185.017 244.516 185.094 244.685 185.136 244.864C185.198 245.123 185.205 245.391 185.157 245.652C185.109 245.913 185.007 246.162 184.857 246.381C184.827 246.426 184.793 246.47 184.758 246.512Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184.825 246.846L184.299 244.212L184.312 244.194C184.364 244.129 184.418 244.063 184.476 244.004L184.488 243.991L184.5 243.978C184.743 243.735 185.045 243.561 185.376 243.472L185.458 243.452C185.639 243.405 185.829 243.402 186.012 243.441L186.774 246.016C186.718 246.097 186.656 246.174 186.589 246.246L186.575 246.261L186.562 246.274C186.287 246.551 185.938 246.745 185.557 246.831L185.462 246.852C185.252 246.9 185.034 246.898 184.825 246.846Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184.829 246.865L184.821 246.826L184.831 246.829C185.036 246.88 185.252 246.883 185.459 246.835L185.554 246.814C185.929 246.728 186.273 246.539 186.545 246.267L186.558 246.253L186.562 246.249L186.572 246.238C186.64 246.166 186.702 246.089 186.759 246.007L186.768 245.994L186.781 246.038C186.728 246.114 186.67 246.186 186.607 246.253L186.593 246.268L186.589 246.273L186.58 246.282C186.302 246.564 185.949 246.761 185.562 246.849L185.466 246.87C185.256 246.918 185.038 246.916 184.829 246.865ZM184.304 244.235L184.295 244.19L184.308 244.172C184.357 244.112 184.407 244.051 184.461 243.995L184.473 243.982L184.477 243.978L184.485 243.969C184.73 243.723 185.036 243.545 185.372 243.456L185.454 243.435C185.635 243.388 185.824 243.384 186.007 243.423L186.018 243.461L186.01 243.459C185.829 243.42 185.642 243.423 185.463 243.469L185.381 243.49C185.054 243.577 184.756 243.749 184.516 243.987L184.504 244L184.5 244.004L184.492 244.013C184.43 244.078 184.372 244.146 184.317 244.218L184.313 244.224L184.304 244.235Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M185.853 245.425C185.726 244.913 185.79 244.371 186.032 243.902C186.056 243.856 186.081 243.81 186.108 243.766L186.87 246.341C186.775 246.326 186.683 246.301 186.593 246.267L186.575 246.26L186.558 246.252C186.384 246.178 186.23 246.065 186.108 245.921C185.986 245.778 185.898 245.608 185.853 245.425Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M185.834 245.429C185.765 245.145 185.753 244.849 185.799 244.56C185.844 244.271 185.947 243.993 186.101 243.744L186.114 243.789C185.821 244.28 185.733 244.866 185.869 245.421C185.915 245.602 186.002 245.77 186.123 245.912C186.245 246.053 186.398 246.165 186.571 246.237L186.588 246.244L186.606 246.252C186.689 246.283 186.776 246.306 186.863 246.321L186.875 246.359C186.774 246.344 186.674 246.317 186.579 246.28L186.561 246.273L186.543 246.265C186.369 246.19 186.215 246.075 186.092 245.93C185.969 245.785 185.881 245.614 185.834 245.429Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.415 244.203C182.485 244.231 182.553 244.266 182.616 244.307L182.631 244.317L182.636 244.32L182.646 244.327C182.797 244.43 182.925 244.564 183.022 244.719C183.119 244.874 183.183 245.047 183.211 245.228C183.252 245.488 183.239 245.753 183.173 246.007C183.106 246.261 182.987 246.499 182.824 246.705C182.794 246.743 182.762 246.781 182.728 246.817L182.734 246.863L182.744 246.852C182.782 246.812 182.819 246.769 182.853 246.726C183.019 246.517 183.14 246.275 183.207 246.016C183.275 245.757 183.288 245.487 183.245 245.223C183.217 245.039 183.151 244.863 183.053 244.704C182.955 244.546 182.826 244.41 182.673 244.303L182.658 244.293L182.653 244.29L182.643 244.283C182.57 244.235 182.492 244.195 182.41 244.163L182.415 244.203Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.794 247.335L182.376 244.02C182.991 243.942 183.6 243.844 184.202 243.726L184.856 247.001C184.169 247.136 183.481 247.247 182.794 247.335Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.73 246.841L182.395 244.177C182.477 244.208 182.555 244.248 182.629 244.296L182.644 244.306L182.659 244.316C182.811 244.421 182.939 244.556 183.037 244.712C183.135 244.869 183.2 245.044 183.227 245.226C183.269 245.488 183.256 245.756 183.189 246.012C183.122 246.269 183.002 246.508 182.838 246.716C182.803 246.759 182.768 246.801 182.73 246.841Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.773 247.177L182.438 244.512L182.452 244.495C182.508 244.432 182.568 244.372 182.631 244.316L182.644 244.304L182.658 244.293C182.922 244.066 183.242 243.916 183.585 243.857L183.669 243.842C183.857 243.807 184.051 243.819 184.233 243.878L184.759 246.512C184.697 246.586 184.629 246.656 184.557 246.721L184.543 246.734L184.529 246.746C184.236 246.998 183.878 247.162 183.497 247.218L183.401 247.233C183.19 247.262 182.975 247.243 182.773 247.177Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.775 247.196L182.77 247.157L182.779 247.16C182.978 247.226 183.19 247.245 183.397 247.216L183.493 247.201C183.869 247.145 184.222 246.985 184.511 246.737L184.525 246.725L184.529 246.722L184.539 246.712C184.613 246.647 184.681 246.577 184.743 246.502L184.753 246.49L184.762 246.535C184.703 246.604 184.64 246.669 184.573 246.73L184.558 246.743L184.554 246.747L184.544 246.755C184.249 247.012 183.886 247.179 183.498 247.236L183.403 247.251C183.192 247.28 182.977 247.262 182.775 247.196ZM182.439 244.535L182.434 244.49L182.448 244.472C182.501 244.414 182.556 244.359 182.615 244.307L182.628 244.294L182.633 244.291L182.642 244.283C182.909 244.053 183.233 243.899 183.581 243.84L183.665 243.825C183.853 243.789 184.046 243.801 184.228 243.86L184.235 243.899L184.228 243.896C184.048 243.837 183.857 243.825 183.671 243.86L183.587 243.875C183.249 243.933 182.933 244.081 182.672 244.303L182.658 244.315L182.654 244.318L182.645 244.326C182.577 244.386 182.513 244.45 182.454 244.518L182.449 244.524L182.439 244.535Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M183.897 245.844C183.81 245.322 183.922 244.786 184.208 244.34C184.237 244.296 184.267 244.253 184.298 244.212L184.824 246.845C184.732 246.821 184.643 246.787 184.559 246.743L184.542 246.734L184.525 246.725C184.36 246.636 184.217 246.51 184.109 246.358C184 246.205 183.927 246.029 183.897 245.844Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M183.879 245.847C183.831 245.557 183.844 245.261 183.915 244.976C183.986 244.691 184.115 244.424 184.293 244.19L184.302 244.236C184.133 244.464 184.012 244.724 183.945 244.999C183.878 245.275 183.868 245.562 183.914 245.841C183.945 246.025 184.017 246.199 184.125 246.35C184.234 246.501 184.375 246.625 184.539 246.712L184.556 246.721L184.573 246.73C184.652 246.77 184.734 246.802 184.82 246.826L184.827 246.865C184.729 246.839 184.634 246.802 184.544 246.756L184.527 246.747L184.51 246.738C184.344 246.647 184.201 246.519 184.092 246.365C183.982 246.211 183.91 246.034 183.879 245.847Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M180.546 244.379C180.615 244.413 180.68 244.452 180.741 244.497L180.755 244.508L180.76 244.511L180.77 244.519C180.914 244.631 181.034 244.772 181.121 244.933C181.208 245.094 181.261 245.271 181.277 245.454C181.301 245.715 181.271 245.979 181.188 246.229C181.106 246.478 180.973 246.708 180.797 246.903C180.765 246.94 180.73 246.975 180.694 247.008L180.697 247.054L180.708 247.044C180.749 247.007 180.788 246.968 180.824 246.926C181.003 246.727 181.139 246.493 181.222 246.239C181.306 245.985 181.337 245.717 181.311 245.451C181.295 245.265 181.241 245.085 181.153 244.921C181.065 244.757 180.944 244.612 180.798 244.497L180.784 244.486L180.78 244.482L180.77 244.475C180.699 244.423 180.623 244.378 180.544 244.34L180.546 244.379Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M180.727 247.527L180.519 244.193C181.14 244.154 181.76 244.094 182.375 244.019L182.793 247.333C182.105 247.418 181.416 247.482 180.727 247.527Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M180.696 247.032L180.528 244.352C180.608 244.389 180.684 244.434 180.756 244.486L180.77 244.497L180.784 244.508C180.93 244.622 181.05 244.765 181.137 244.927C181.225 245.089 181.279 245.268 181.294 245.452C181.319 245.716 181.289 245.982 181.205 246.234C181.122 246.486 180.988 246.717 180.811 246.915C180.774 246.956 180.736 246.994 180.696 247.032Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M180.718 247.37L180.55 244.69L180.566 244.674C180.626 244.615 180.69 244.559 180.756 244.507L180.77 244.496L180.785 244.485C181.064 244.275 181.396 244.145 181.744 244.11L181.83 244.1C182.022 244.079 182.216 244.105 182.395 244.176L182.731 246.84C182.665 246.911 182.594 246.976 182.517 247.035L182.502 247.047L182.487 247.058C182.181 247.288 181.816 247.427 181.434 247.458L181.338 247.466C181.126 247.483 180.914 247.45 180.718 247.37Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M180.718 247.39L180.716 247.35L180.725 247.354C180.918 247.433 181.128 247.466 181.336 247.448L181.432 247.441C181.808 247.41 182.168 247.273 182.47 247.048L182.485 247.037L182.49 247.033L182.501 247.025C182.578 246.965 182.651 246.9 182.717 246.829L182.727 246.817L182.733 246.863C182.671 246.929 182.604 246.99 182.533 247.045L182.517 247.057L182.513 247.061L182.503 247.068C182.193 247.303 181.822 247.444 181.435 247.476L181.339 247.484C181.128 247.501 180.915 247.469 180.718 247.39ZM180.551 244.713L180.548 244.667L180.564 244.651C180.62 244.597 180.68 244.545 180.741 244.497L180.755 244.485L180.76 244.482L180.77 244.475C181.053 244.261 181.389 244.129 181.741 244.092L181.828 244.082C182.019 244.061 182.213 244.087 182.392 244.156L182.397 244.196L182.39 244.193C182.212 244.123 182.021 244.097 181.832 244.118L181.745 244.128C181.402 244.163 181.075 244.291 180.798 244.497L180.784 244.508L180.78 244.511L180.77 244.518C180.698 244.574 180.631 244.634 180.567 244.697L180.561 244.703L180.551 244.713Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M181.92 246.115C181.868 245.587 182.017 245.059 182.337 244.637C182.369 244.595 182.402 244.554 182.436 244.514L182.772 247.178C182.682 247.148 182.597 247.108 182.516 247.058L182.5 247.048L182.484 247.038C182.327 246.936 182.194 246.801 182.097 246.641C181.999 246.481 181.939 246.301 181.92 246.115Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M181.902 246.115C181.874 245.822 181.907 245.527 181.998 245.247C182.089 244.968 182.238 244.71 182.433 244.49L182.439 244.537C182.253 244.752 182.113 245.002 182.027 245.272C181.94 245.543 181.91 245.828 181.938 246.111C181.956 246.296 182.017 246.474 182.114 246.632C182.211 246.79 182.343 246.924 182.5 247.024L182.516 247.034L182.532 247.045C182.607 247.09 182.686 247.128 182.769 247.157L182.774 247.196C182.678 247.164 182.587 247.121 182.501 247.068L182.485 247.058L182.469 247.047C182.311 246.944 182.178 246.807 182.08 246.646C181.982 246.484 181.921 246.303 181.902 246.115Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.702 244.434C178.751 244.473 178.816 244.517 178.869 244.566L178.882 244.578L178.886 244.582L178.896 244.59C179.03 244.713 179.137 244.862 179.213 245.028C179.288 245.194 179.329 245.374 179.333 245.556C179.342 245.82 179.294 246.082 179.194 246.326C179.093 246.57 178.941 246.789 178.749 246.969C178.711 247.006 178.67 247.038 178.626 247.067L178.625 247.112L178.639 247.103C178.688 247.071 178.734 247.035 178.776 246.995C178.971 246.811 179.125 246.587 179.227 246.339C179.329 246.091 179.377 245.824 179.368 245.556C179.363 245.37 179.322 245.187 179.246 245.018C179.17 244.849 179.061 244.696 178.925 244.57L178.912 244.558L178.908 244.554L178.899 244.546C178.836 244.492 178.771 244.442 178.703 244.395L178.702 244.434Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.613 247.588L178.694 244.248L179.127 244.248L179.588 244.238C179.897 244.229 180.208 244.213 180.518 244.194L180.727 247.528C180.382 247.55 180.037 247.567 179.689 247.577L179.165 247.588C178.985 247.591 178.822 247.591 178.613 247.588Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.625 247.091L178.69 244.406C178.743 244.449 178.822 244.5 178.883 244.557L178.897 244.569L178.91 244.581C179.045 244.706 179.153 244.857 179.229 245.024C179.304 245.192 179.346 245.373 179.35 245.557C179.359 245.823 179.311 246.087 179.209 246.333C179.108 246.579 178.956 246.801 178.762 246.983C178.72 247.023 178.674 247.059 178.625 247.091Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.617 247.429L178.682 244.744L178.696 244.73C178.753 244.673 178.816 244.622 178.883 244.578L178.897 244.568L178.912 244.558C179.2 244.367 179.536 244.258 179.882 244.244L179.968 244.24C180.161 244.232 180.353 244.27 180.528 244.351L180.696 247.031C180.626 247.097 180.55 247.157 180.471 247.212L180.455 247.224L180.439 247.234C180.118 247.445 179.744 247.561 179.359 247.567L179.262 247.568C179.039 247.57 178.819 247.522 178.617 247.429Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.615 247.449L178.616 247.409L178.627 247.413C178.825 247.506 179.041 247.553 179.26 247.551L179.358 247.549C179.736 247.543 180.105 247.43 180.422 247.223L180.438 247.213L180.443 247.209L180.454 247.201C180.535 247.146 180.611 247.085 180.682 247.019L180.693 247.008L180.696 247.054C180.629 247.115 180.559 247.172 180.485 247.223L180.469 247.235L180.464 247.238L180.453 247.245C180.128 247.461 179.748 247.579 179.358 247.585L179.261 247.586C179.038 247.588 178.818 247.541 178.615 247.449ZM178.68 244.768L178.681 244.722L178.695 244.707C178.748 244.655 178.806 244.608 178.868 244.566L178.882 244.556L178.887 244.553L178.897 244.546C179.189 244.352 179.529 244.241 179.879 244.227L179.966 244.223C180.159 244.214 180.35 244.252 180.525 244.332L180.528 244.372L180.52 244.368C180.348 244.287 180.158 244.25 179.968 244.258L179.881 244.262C179.54 244.277 179.209 244.383 178.923 244.57L178.909 244.58L178.904 244.583L178.894 244.59C178.822 244.638 178.755 244.692 178.694 244.753L178.689 244.758L178.68 244.768Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M179.934 246.255C179.915 245.726 180.097 245.209 180.442 244.806C180.476 244.766 180.512 244.728 180.549 244.69L180.717 247.371C180.63 247.334 180.547 247.289 180.47 247.235L180.454 247.224L180.439 247.213C180.288 247.102 180.164 246.958 180.077 246.792C179.989 246.626 179.941 246.443 179.934 246.255Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M179.916 246.255C179.907 245.961 179.958 245.668 180.066 245.395C180.175 245.122 180.339 244.874 180.548 244.667L180.551 244.714C180.352 244.917 180.196 245.157 180.093 245.422C179.99 245.687 179.942 245.969 179.952 246.253C179.959 246.439 180.007 246.621 180.095 246.785C180.182 246.949 180.305 247.091 180.455 247.2L180.471 247.212L180.486 247.223C180.558 247.272 180.635 247.315 180.716 247.349L180.718 247.389C180.625 247.35 180.537 247.302 180.454 247.244L180.439 247.233L180.424 247.222C180.272 247.109 180.148 246.963 180.06 246.796C179.972 246.629 179.923 246.444 179.916 246.255Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.9571 232.868L69.9382 239.551C69.9297 242.577 67.9408 245.599 63.9638 247.91C55.9663 252.558 42.9552 252.552 34.9054 247.904C30.852 245.564 28.8263 242.491 28.835 239.424L28.8539 232.741C28.8452 235.809 30.871 238.881 34.9243 241.222C42.9742 245.869 55.9853 245.875 63.9828 241.228C67.9597 238.916 69.9486 235.894 69.9571 232.868Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M63.8887 224.39C71.9385 229.038 71.9913 236.574 63.9834 241.228C55.9859 245.876 42.9748 245.87 34.925 241.222C26.8751 236.575 26.8223 229.038 34.8198 224.39C42.8277 219.737 55.8389 219.743 63.8887 224.39Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.3883 226.68L66.3726 232.203C66.3655 234.703 64.7219 237.201 61.4353 239.111C57.7157 241.004 53.6011 241.99 49.4276 241.989C45.254 241.988 41.1399 241 37.421 239.106C34.0713 237.172 32.3971 234.633 32.4043 232.098L32.42 226.575C32.4128 229.11 34.0869 231.65 37.4367 233.584C41.1555 235.478 45.2697 236.466 49.4432 236.467C53.6168 236.468 57.7313 235.481 61.451 233.588C64.7376 231.679 66.3812 229.181 66.3883 226.68Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.3726 219.674C68.0251 223.515 68.0688 229.744 61.4509 233.589C57.7313 235.482 53.6167 236.468 49.4432 236.468C45.2697 236.467 41.1555 235.479 37.4366 233.584C30.7842 229.744 30.7405 223.515 37.3497 219.675C41.0701 217.78 45.186 216.792 49.3612 216.792C53.5363 216.792 57.6522 217.78 61.3726 219.674Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M63.5165 190.333L63.4554 225.58C63.4496 227.644 62.0938 229.705 59.3833 231.28C56.3102 232.844 52.9106 233.66 49.4622 233.66C46.0138 233.66 42.6142 232.844 39.5411 231.28C36.7775 229.684 35.3984 227.591 35.4043 225.5L35.4654 190.253C35.4595 192.344 36.8386 194.437 39.6022 196.032C42.6754 197.597 46.0749 198.412 49.5234 198.412C52.9718 198.412 56.3713 197.597 59.4445 196.032C62.1549 194.457 63.5107 192.397 63.5165 190.333Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M59.3708 185.902C64.8667 189.075 64.9041 194.218 59.4438 197.391C56.3707 198.955 52.9711 199.771 49.5227 199.771C46.0743 199.771 42.6748 198.955 39.6016 197.391C34.1057 194.218 34.0851 189.075 39.5453 185.902C42.6155 184.338 46.0123 183.522 49.458 183.522C52.9038 183.522 56.3006 184.338 59.3708 185.902Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.3616 198.694L55.6671 199.153C52.127 200.075 48.4082 200.065 44.8733 199.123L44.7041 199.076L44.6195 199.052L44.4503 199.005L44.3657 198.982L44.1965 198.935L44.1119 198.912L43.9428 198.865L43.7736 198.818L43.689 198.795L43.5199 198.748L43.3508 198.701L43.2662 198.678L43.1816 198.654C47.8254 199.893 52.7109 199.906 57.3616 198.694Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.75 169.78L57.442 170.249C59.0374 170.678 60.5722 171.307 62.0101 172.12L59.2215 171.203C58.1289 170.584 56.9625 170.106 55.75 169.78Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.9449 176.402C62.9563 172.368 57.3197 169.081 50.3551 169.062C43.3905 169.042 37.7353 172.296 37.7238 176.33C37.7124 180.364 43.3491 183.651 50.3137 183.67C57.2783 183.69 62.9335 180.436 62.9449 176.402Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.9027 178.956L66.8712 190.04C66.8644 192.482 65.2603 194.92 62.0562 196.782C58.4251 198.63 54.4085 199.593 50.3342 199.594C46.26 199.594 42.2434 198.63 38.6123 196.782C35.3453 194.896 33.7137 192.419 33.7207 189.945L33.7522 178.861C33.7451 181.335 35.3768 183.812 38.6437 185.698C42.2748 187.546 46.2914 188.51 50.3657 188.51C54.4399 188.51 58.4565 187.546 62.0876 185.698C65.2918 183.836 66.8957 181.398 66.9027 178.956Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.2598 170.208L43.4292 170.162L43.5139 170.139L43.6834 170.093L43.7681 170.07L43.9376 170.024L44.0223 170.001L44.1918 169.956L44.3614 169.91L44.4461 169.887L44.6156 169.841L44.7851 169.795L44.8699 169.772L44.9547 169.749C43.7077 170.076 42.5089 170.565 41.3885 171.202C36.4838 174.05 36.5099 178.685 41.4466 181.532C44.209 182.937 47.2643 183.67 50.3636 183.67C53.4628 183.67 56.5182 182.937 59.2805 181.532C64.1852 178.684 64.1591 174.05 59.2224 171.202L62.011 172.12C68.5032 175.868 68.5375 181.951 62.0874 185.699C58.4563 187.547 54.4398 188.51 50.3655 188.51C46.2912 188.51 42.2747 187.547 38.6436 185.699C32.1513 181.951 32.1172 175.868 38.5671 172.12C40.0415 171.281 41.6191 170.638 43.2598 170.208Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.408 130.535L64.2787 175.952C64.2728 178.016 62.9171 180.076 60.2066 181.651C57.1334 183.216 53.7339 184.031 50.2854 184.031C46.837 184.031 43.4375 183.216 40.3643 181.652C37.6008 180.056 36.2216 177.963 36.2276 175.872L36.3568 130.455C36.3509 132.546 37.73 134.639 40.4936 136.235C43.5667 137.799 46.9663 138.614 50.4147 138.614C53.8631 138.614 57.2627 137.799 60.3358 136.235C63.0463 134.659 64.4021 132.599 64.408 130.535Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M60.2614 124.746C65.7574 127.919 65.7947 133.061 60.3344 136.235C57.2613 137.799 53.8618 138.614 50.4133 138.614C46.9649 138.614 43.5654 137.799 40.4922 136.235C34.9963 133.062 34.9757 127.919 40.4359 124.746C43.5061 123.182 46.9029 122.366 50.3487 122.366C53.7944 122.366 57.1912 123.182 60.2614 124.746Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.3586 114.58C56.1261 112.452 54.6631 110.467 52.996 108.659C51.5059 107.04 49.7907 105.644 47.9032 104.513C48.1486 104.637 48.4238 104.691 48.6979 104.668C48.972 104.644 49.2343 104.545 49.4553 104.382C53.3129 102.264 53.0444 96.753 56.3151 94.9343C57.6776 94.1763 58.7406 93.7991 59.5762 94.2817C60.5547 94.8443 61.4936 95.4731 62.3862 96.1638C63.2864 96.8539 64.1469 97.5941 64.9639 98.3809C67.0202 100.38 68.8367 102.611 70.3768 105.03C73.1954 109.361 75.0404 114.252 75.7846 119.365C76.5071 123.474 75.8445 127.706 73.9009 131.398C72.9635 133.005 71.7054 134.402 70.205 135.502C68.7082 136.701 66.9396 137.513 65.0549 137.867C63.0952 138.202 61.0862 138.103 59.1688 137.578C56.8937 136.953 54.7384 135.954 52.7911 134.622C50.8473 133.312 49.0572 131.788 47.4545 130.078C45.4661 127.964 43.7317 125.625 42.2868 123.109C39.659 118.641 38.0532 113.646 37.5849 108.484C37.413 106.283 37.5709 104.069 38.0534 101.915C38.4806 100.032 39.2891 98.2581 40.4291 96.7009C42.6272 94.0223 45.7617 92.2798 49.197 91.8269C52.833 91.4748 56.4831 92.3382 59.5762 94.2817C58.7406 93.7991 57.6776 94.1763 56.3151 94.9343C53.0444 96.753 53.3129 102.264 49.4553 104.382C49.2343 104.545 48.972 104.644 48.6979 104.668C48.4238 104.691 48.1486 104.637 47.9032 104.513C46.9895 103.977 46.0162 103.549 45.0031 103.238C44.2786 103.022 43.5283 102.905 42.7724 102.889C42.1907 102.851 41.6079 102.946 41.0677 103.165C40.5276 103.385 40.0442 103.724 39.6538 104.157C38.6452 105.707 38.1349 107.529 38.1913 109.378C38.1841 111.617 38.5254 113.843 39.2029 115.976C41.0888 122.242 44.7787 127.813 49.812 131.994C51.6414 133.624 53.9178 134.667 56.3467 134.99C57.2016 135.112 58.0723 134.932 58.8093 134.482C59.5463 134.032 60.1037 133.34 60.3857 132.523C61.4005 129.814 61.6056 126.869 60.976 124.045C60.2941 120.714 59.0721 117.517 57.3586 114.58Z"
        fill="#8491A1"
      />
      <path
        d="M47.9032 104.513C49.7907 105.644 51.5059 107.04 52.996 108.659C54.6631 110.467 56.1261 112.452 57.3586 114.58C59.0721 117.517 60.2941 120.714 60.976 124.045C61.6056 126.869 61.4005 129.814 60.3857 132.523C60.1037 133.34 59.5463 134.032 58.8093 134.482C58.0723 134.932 57.2016 135.112 56.3467 134.99C53.9178 134.667 51.6414 133.624 49.812 131.994C44.7787 127.813 41.0888 122.242 39.2029 115.976C38.5254 113.843 38.1841 111.617 38.1913 109.378C38.1349 107.529 38.6452 105.707 39.6538 104.157C40.0442 103.724 40.5276 103.385 41.0677 103.165C41.6079 102.946 42.1907 102.851 42.7724 102.889C43.5283 102.905 44.2786 103.022 45.0031 103.238C46.0162 103.549 46.9895 103.977 47.9032 104.513ZM47.9032 104.513C48.1486 104.637 48.4238 104.691 48.6979 104.668C48.972 104.644 49.2343 104.545 49.4553 104.382C53.3129 102.264 53.0444 96.753 56.3151 94.9343C57.6776 94.1763 58.7406 93.7991 59.5762 94.2817M59.5762 94.2817C60.5547 94.8443 61.4936 95.4731 62.3862 96.1638C63.2864 96.8539 64.1469 97.5941 64.9639 98.3809C67.0202 100.38 68.8367 102.611 70.3768 105.03C73.1954 109.361 75.0404 114.252 75.7846 119.365C76.5071 123.474 75.8445 127.706 73.9009 131.398C72.9635 133.005 71.7054 134.402 70.205 135.502C68.7082 136.701 66.9396 137.513 65.0549 137.867C63.0952 138.202 61.0862 138.103 59.1688 137.578C56.8937 136.953 54.7384 135.954 52.7911 134.622C50.8473 133.312 49.0572 131.788 47.4545 130.078C45.4661 127.964 43.7317 125.625 42.2868 123.109C39.659 118.641 38.0532 113.646 37.5849 108.484C37.413 106.283 37.5709 104.069 38.0534 101.915C38.4806 100.032 39.2891 98.2581 40.4291 96.7009C42.6272 94.0223 45.7617 92.2798 49.197 91.8269C52.833 91.4748 56.4831 92.3382 59.5762 94.2817Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M57.7255 142.345C56.366 143.649 54.6948 144.584 52.8714 145.059C51.0728 145.529 49.1961 145.62 47.3603 145.326C45.5472 145.045 43.7875 144.49 42.1419 143.678C40.1092 142.681 38.2133 141.426 36.5009 139.945C34.786 138.463 33.2253 136.811 31.8428 135.015C30.118 132.788 28.6503 130.373 27.4677 127.816C25.5184 123.743 24.467 119.298 24.3849 114.783C24.3801 113.23 24.5501 111.682 24.8918 110.167C25.199 108.803 25.6845 107.487 26.3357 106.25C27.4026 104.204 29.0223 102.499 31.0106 101.328C33.0678 100.213 35.396 99.6949 37.7321 99.8335C40.6103 100.032 43.3987 100.919 45.8634 102.418C45.551 102.255 45.2055 102.166 44.8532 102.156C44.5009 102.147 44.1512 102.218 43.8306 102.365C42.4319 102.857 39.5332 105.591 38.834 111.447C32.195 107.614 26.8032 110.687 26.7816 118.315C26.9994 122.472 28.1979 126.518 30.279 130.123C32.3602 133.728 35.2649 136.789 38.7557 139.056C45.3947 142.889 50.7967 139.822 50.8183 132.193C50.5986 128.035 49.3983 123.988 47.3155 120.382C45.2326 116.777 42.3263 113.715 38.834 111.447C39.5332 105.591 42.4319 102.857 43.8306 102.365C44.1512 102.218 44.5009 102.147 44.8532 102.156C45.2055 102.166 45.551 102.255 45.8634 102.418C46.6677 102.917 47.7223 103.613 48.4932 104.162C48.9945 104.525 49.4772 104.913 49.9396 105.324C50.8125 106.063 51.6403 106.854 52.4186 107.692C53.673 109.018 54.6295 110.28 54.639 110.292C56.0207 112.086 57.2425 113.997 58.2905 116.004C59.3303 117.994 60.1831 120.076 60.8378 122.224C62.0491 125.994 62.4082 129.985 61.8894 133.911C61.484 137.109 60.0179 140.078 57.7255 142.345Z"
        fill="#8491A1"
      />
      <path
        d="M45.8634 102.418C43.3987 100.919 40.6103 100.032 37.7321 99.8335C35.396 99.6949 33.0678 100.213 31.0106 101.328C29.0223 102.499 27.4026 104.204 26.3357 106.25C25.6845 107.487 25.199 108.803 24.8918 110.167C24.5501 111.682 24.3801 113.23 24.3849 114.783C24.467 119.298 25.5184 123.743 27.4677 127.816C28.6503 130.373 30.118 132.788 31.8428 135.015C33.2253 136.811 34.786 138.463 36.5009 139.945C38.2133 141.426 40.1092 142.681 42.1419 143.678C43.7875 144.49 45.5472 145.045 47.3603 145.326C49.1961 145.62 51.0728 145.529 52.8714 145.059C54.6948 144.584 56.366 143.649 57.7255 142.345C60.0179 140.078 61.484 137.109 61.8894 133.911C62.4082 129.985 62.0491 125.994 60.8378 122.224C60.1831 120.076 59.3303 117.994 58.2905 116.004C57.2425 113.997 56.0207 112.086 54.639 110.292C54.6295 110.28 53.673 109.018 52.4186 107.692C51.6403 106.854 50.8125 106.063 49.9396 105.324C49.4772 104.913 48.9945 104.525 48.4932 104.162C47.7223 103.613 46.6677 102.917 45.8634 102.418ZM45.8634 102.418C45.551 102.255 45.2055 102.166 44.8532 102.156C44.5009 102.147 44.1512 102.218 43.8306 102.365C42.4319 102.857 39.5332 105.591 38.834 111.447M38.834 111.447C32.195 107.614 26.8032 110.687 26.7816 118.315C26.9994 122.472 28.1979 126.518 30.279 130.123C32.3602 133.728 35.2649 136.789 38.7557 139.056C45.3947 142.889 50.7967 139.822 50.8183 132.193C50.5986 128.035 49.3983 123.988 47.3155 120.382C45.2326 116.777 42.3263 113.715 38.834 111.447Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M38.8346 111.448C42.3269 113.715 45.2332 116.777 47.3161 120.383C49.3989 123.988 50.5992 128.035 50.819 132.194C50.7973 139.822 45.3953 142.889 38.7564 139.056C35.2655 136.789 32.3608 133.728 30.2796 130.123C28.1985 126.519 27 122.473 26.7822 118.316C26.8039 110.687 32.1957 107.615 38.8346 111.448Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M38.8302 113.204C41.8778 115.183 44.4141 117.855 46.2317 121.002C48.0493 124.148 49.0968 127.68 49.2885 131.309C49.2697 137.966 44.5555 140.642 38.7619 137.297C35.7156 135.319 33.1807 132.648 31.3646 129.502C29.5484 126.356 28.5026 122.826 28.3125 119.198C28.3314 112.541 33.0366 109.859 38.8302 113.204Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M38.8131 119.628C40.2357 120.552 41.4197 121.799 42.2682 123.268C43.1167 124.737 43.6056 126.385 43.6951 128.079C43.6863 131.187 41.4857 132.436 38.7812 130.875C37.3591 129.951 36.1758 128.705 35.3281 127.236C34.4803 125.768 33.992 124.119 33.9033 122.426C33.9121 119.318 36.1086 118.067 38.8131 119.628Z"
        fill="#6B7884"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M74.7323 119.592L74.8037 119.759L74.8394 119.842L74.9108 120.01L74.9465 120.093L75.0178 120.26L75.0535 120.344L75.1249 120.511L75.1963 120.678L75.232 120.762L75.3033 120.929L75.3747 121.096L75.4104 121.179L75.4461 121.263C74.2592 118.484 71.3222 117.726 67.8962 119.704L66.3994 117.872C70.1815 115.688 73.4223 116.525 74.7323 119.592Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.9746 118.624L72.8167 118.433C71.6126 118.588 70.4545 118.994 69.4172 119.625L67.8955 119.704C68.835 119.133 69.8839 118.765 70.9746 118.624Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.7536 121.35L78.4661 123.022C77.2802 120.24 74.3421 119.48 70.9145 121.459L69.418 119.626C73.2022 117.441 76.4447 118.28 77.7536 121.35Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.9315 139.354L63.1263 139.562C59.8432 139.938 57.5171 137.585 57.5049 133.276C57.6677 130.189 58.5583 127.184 60.1039 124.507C61.6496 121.83 63.8064 119.557 66.3984 117.872L67.8952 119.704C65.547 121.23 63.593 123.29 62.1927 125.716C60.7924 128.141 59.9855 130.863 59.8378 133.66C59.8484 137.564 61.9569 139.696 64.9315 139.354Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.0028 120.377L75.8529 120.188C74.6448 120.341 73.4828 120.748 72.4423 121.381L70.9141 121.458C71.8562 120.884 72.9087 120.516 74.0028 120.377Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.7618 123.066L80.8345 123.233L80.8708 123.316L80.9434 123.484L80.9798 123.567L81.0524 123.734L81.0887 123.818L81.1613 123.985L81.2339 124.152L81.2703 124.236L81.3429 124.403L81.4155 124.571L81.4519 124.654L81.4882 124.738C80.2911 121.983 77.3607 121.237 73.9446 123.21L72.4424 121.381C76.2106 119.205 79.4417 120.027 80.7618 123.066Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.17 138.472L60.4242 136.777C60.0173 135.789 59.8179 134.728 59.8383 133.66C59.986 130.863 60.7929 128.141 62.1932 125.716C63.5935 123.29 65.5475 121.23 67.8958 119.704L69.4174 119.625C66.8252 121.31 64.6681 123.584 63.1223 126.261C61.5765 128.938 60.6858 131.944 60.5229 135.031C60.5005 136.21 60.7207 137.382 61.17 138.472Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.953 141.108L66.1482 141.316C62.8622 141.696 60.5337 139.343 60.5215 135.031C60.6843 131.944 61.575 128.938 63.1208 126.261C64.6666 123.584 66.8237 121.31 69.416 119.625L70.9125 121.458C68.5643 122.984 66.6102 125.044 65.2099 127.47C63.8096 129.895 63.0027 132.617 62.8551 135.414C62.8657 139.319 64.9761 141.452 67.953 141.108Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.0466 122.126L78.8865 121.941C77.6742 122.094 76.5079 122.501 75.4642 123.136L73.9443 123.209C74.8904 122.633 75.9476 122.264 77.0466 122.126Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M83.8033 124.864L83.8746 125.032L83.9102 125.115L83.9814 125.283L84.0171 125.367L84.0883 125.534L84.124 125.618L84.1952 125.785L84.2664 125.952L84.3021 126.036L84.3733 126.203L84.4446 126.371L84.4802 126.454L84.5159 126.538C83.3307 123.754 80.3918 122.993 76.9632 124.973L75.4648 123.137C79.251 120.951 82.4948 121.791 83.8033 124.864Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.1985 140.24L63.4467 138.539C63.037 137.549 62.8362 136.485 62.8569 135.413C63.0045 132.617 63.8114 129.894 65.2118 127.469C66.6121 125.044 68.5661 122.984 70.9144 121.457L72.4426 121.381C69.85 123.066 67.6927 125.34 66.1467 128.018C64.6007 130.695 63.7099 133.701 63.5471 136.789C63.5243 137.972 63.7461 139.147 64.1985 140.24Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.9421 142.883L69.13 143.08C65.8665 143.434 63.5581 141.082 63.5459 136.789C63.7087 133.701 64.5995 130.695 66.1455 128.018C67.6915 125.34 69.8489 123.066 72.4415 121.381L73.9437 123.209C71.5931 124.737 69.6371 126.799 68.2354 129.227C66.8336 131.655 66.0259 134.38 65.8782 137.179C65.8888 141.071 67.9829 143.204 70.9421 142.883Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.1458 123.882L82.0181 123.724C80.7742 123.863 79.576 124.273 78.5078 124.925L76.9609 124.972C77.93 124.38 79.0172 124.008 80.1458 123.882Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M86.8764 126.758L87.2327 127.64L87.2651 127.72L87.3299 127.88L87.3623 127.96L87.4272 128.121L87.492 128.281L87.5244 128.361C86.3672 125.497 83.3997 124.694 79.9308 126.697L78.5088 124.925C82.3318 122.717 85.6011 123.601 86.8764 126.758Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.2248 142.007L66.474 140.317C66.0609 139.323 65.8584 138.255 65.8794 137.179C66.0272 134.379 66.8349 131.654 68.2366 129.226C69.6383 126.798 71.5943 124.736 73.9449 123.209L75.4648 123.136C72.872 124.821 70.7145 127.095 69.1684 129.773C67.6222 132.451 66.7314 135.457 66.5685 138.545C66.5455 139.732 66.769 140.911 67.2248 142.007Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.0061 144.621L72.1991 144.831C68.911 145.212 66.5806 142.859 66.5684 138.545C66.7312 135.457 67.6221 132.451 69.1682 129.773C70.7143 127.095 72.8718 124.821 75.4646 123.136L76.9629 124.972C74.6147 126.498 72.6607 128.558 71.2603 130.984C69.86 133.409 69.0531 136.131 68.9055 138.928C68.916 142.834 71.0277 144.967 74.0061 144.621Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M83.0039 125.618L84.84 125.425C83.6387 125.581 82.4834 125.987 81.4483 126.616L79.9316 126.697C80.8692 126.127 81.9157 125.759 83.0039 125.618Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.3249 143.847L69.5356 142.141C69.0976 141.128 68.8828 140.032 68.906 138.928C69.0536 136.131 69.8605 133.409 71.2608 130.984C72.6611 128.558 74.6152 126.498 76.9634 124.972L78.5103 124.925C75.9223 126.607 73.7689 128.877 72.2257 131.55C70.6825 134.223 69.7934 137.223 69.6309 140.305C69.6054 141.522 69.8422 142.73 70.3249 143.847Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.0808 146.334L75.3671 146.564C72.0235 147.015 69.6422 144.661 69.6299 140.305C69.7924 137.223 70.6816 134.223 72.2248 131.55C73.7679 128.877 75.9214 126.607 78.5093 124.925L79.9313 126.697C77.5831 128.224 75.629 130.283 74.2287 132.709C72.8284 135.134 72.0215 137.857 71.8739 140.653C71.8847 144.606 74.0466 146.742 77.0808 146.334Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.4482 126.618C86.3763 123.773 90.3854 126.055 90.4015 131.718C90.2384 134.805 89.3475 137.81 87.8016 140.488C86.2557 143.165 84.0986 145.439 81.5063 147.123C76.5799 149.968 72.5707 147.683 72.5547 142.022C72.7175 138.935 73.6081 135.93 75.1537 133.253C76.6993 130.576 78.8562 128.302 81.4482 126.618Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.198 145.457L72.4566 143.766C72.0516 142.78 71.8532 141.721 71.8735 140.655C72.0211 137.858 72.828 135.136 74.2283 132.711C75.6286 130.285 77.5827 128.225 79.9309 126.699L81.4475 126.618C78.8556 128.303 76.6987 130.576 75.1531 133.253C73.6074 135.931 72.7168 138.935 72.554 142.022C72.5317 143.199 72.7509 144.368 73.198 145.457Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.0026 123.189L121.995 141.781C119.976 140.608 117.183 140.772 114.103 142.55L82.1113 123.959C85.1912 122.181 87.9844 122.016 90.0026 123.189Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M106.269 168.839L74.2765 150.247C72.2855 149.09 71.0509 146.63 71.041 143.132C71.2439 139.29 72.3524 135.55 74.2763 132.218C76.2001 128.886 78.8846 126.056 82.1107 123.959L114.103 142.551C110.877 144.648 108.192 147.478 106.268 150.81C104.345 154.142 103.236 157.882 103.033 161.724C103.043 165.222 104.278 167.682 106.269 168.839Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M114.103 142.551C120.226 139.016 125.215 141.86 125.235 148.906C125.033 152.745 123.925 156.482 122.003 159.812C120.081 163.141 117.399 165.969 114.175 168.063C108.043 171.604 103.053 168.771 103.033 161.725C103.236 157.883 104.345 154.143 106.268 150.811C108.192 147.478 110.877 144.648 114.103 142.551Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M114.926 135.328C116.772 135.323 118.617 135.318 120.463 135.312C117.986 135.408 115.577 136.147 113.473 137.457L108.873 137.185C110.695 136.051 112.781 135.411 114.926 135.328Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M99.4843 171.423L97.9841 168.773C97.5749 168.05 97.1657 167.327 96.7565 166.605C95.788 164.747 95.3118 162.671 95.3735 160.577C95.3479 151.982 101.394 141.501 108.873 137.185L113.473 137.456C104.835 142.443 97.859 154.537 97.8871 164.463C97.8161 166.881 98.366 169.278 99.4843 171.423Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M124.603 136.369L144.69 148.042C141.845 146.389 137.905 146.621 133.558 149.13L113.472 137.457C117.818 134.948 121.758 134.716 124.603 136.369Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M147.658 151.117C148.567 152.723 149.477 154.33 150.386 155.936C148.17 152.022 143.526 151.121 138.172 154.214L133.559 149.131C139.741 145.562 145.1 146.599 147.658 151.117Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M122.534 186.167L102.447 174.494C99.6408 172.863 97.9008 169.394 97.8868 164.463C97.8587 154.538 104.835 142.444 113.472 137.457L133.559 149.13C124.921 154.117 117.945 166.211 117.973 176.136C117.987 181.067 119.727 184.536 122.534 186.167Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M132.207 187.207C130.362 187.213 128.516 187.218 126.67 187.223C121.532 187.237 117.994 183.177 117.974 176.137C117.946 166.212 124.922 154.118 133.559 149.131L138.173 154.214C130.695 158.529 124.648 169.011 124.674 177.606C124.691 183.703 127.757 187.22 132.207 187.207Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M138.174 154.213C145.653 149.893 151.748 153.366 151.771 161.961C151.797 170.554 145.743 181.031 138.262 185.348C130.783 189.668 124.698 186.201 124.675 177.605C124.649 169.011 130.696 158.529 138.174 154.213Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M146.3 156.685L176.455 174.209C174.438 173.037 171.646 173.202 168.562 174.982L138.407 157.458C141.491 155.678 144.283 155.513 146.3 156.685Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M160.727 201.27L130.572 183.746C128.581 182.589 127.347 180.129 127.337 176.631C127.54 172.789 128.648 169.049 130.572 165.717C132.496 162.385 135.18 159.555 138.406 157.458L168.562 174.982C165.335 177.079 162.651 179.909 160.727 183.241C158.803 186.573 157.695 190.313 157.492 194.155C157.502 197.653 158.736 200.113 160.727 201.27Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M168.562 174.982C174.694 171.441 179.674 174.29 179.694 181.337C179.493 185.176 178.386 188.913 176.464 192.243C174.542 195.572 171.858 198.4 168.634 200.494C162.502 204.034 157.512 201.201 157.492 194.155C157.695 190.313 158.804 186.573 160.727 183.241C162.651 179.908 165.336 177.078 168.562 174.982Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.835 252.865L148.741 250.596L150.8 263.456L146.894 265.725L144.835 252.865Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.724 245.303L150.629 243.032L148.741 250.594L144.835 252.864L146.724 245.303Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M150.55 237.89L154.455 235.621L157.197 240.445L153.292 242.714L150.55 237.89Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.724 245.304L144.835 252.865L146.894 265.725L143.357 252.211L143.31 252.066L143.339 251.822L144.214 242.743L144.253 242.387L144.603 242.132L150.549 237.891L153.291 242.714L146.724 245.304Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M175.885 276.465L179.791 274.194L172.766 279.269L168.86 281.538L175.885 276.465Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M175.96 276.41L179.865 274.141L179.79 274.194L175.885 276.465L175.96 276.41Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M179.866 274.141L179.922 273.924L176.016 276.193L175.96 276.41L179.866 274.141Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.238 265.992L182.333 265.678L178.428 267.947L178.332 268.261L176.017 276.193L179.922 273.924L182.238 265.992Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M178.25 267.27L178.427 267.947L178.332 268.261L176.016 276.194L175.96 276.41L175.885 276.465L168.86 281.538L174.573 274.888L175.644 266.669L171.631 255.644L175.226 255.876L178.25 267.27Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M171.632 255.643L175.537 253.374L179.133 253.606L175.227 255.875L171.632 255.643Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.156 265L179.132 253.605L175.227 255.875L178.25 267.269L178.428 267.947L182.333 265.677L182.156 265Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M174.852 246.09C174.369 245.255 173.795 244.476 173.141 243.767C172.556 243.132 171.883 242.584 171.143 242.141C171.239 242.189 171.347 242.21 171.455 242.201C171.562 242.192 171.665 242.153 171.752 242.089C173.265 241.258 173.16 239.096 174.443 238.383C174.977 238.086 175.394 237.938 175.722 238.127C176.106 238.348 176.474 238.594 176.824 238.865C177.178 239.136 177.515 239.426 177.836 239.735C178.642 240.519 179.355 241.395 179.959 242.344C181.065 244.043 181.789 245.961 182.081 247.967C182.364 249.579 182.104 251.239 181.342 252.688C180.974 253.318 180.48 253.866 179.892 254.298C179.304 254.768 178.611 255.087 177.871 255.226C177.103 255.357 176.314 255.318 175.562 255.112C174.67 254.867 173.824 254.475 173.06 253.952C172.298 253.439 171.595 252.841 170.967 252.17C170.187 251.341 169.506 250.423 168.939 249.436C167.908 247.683 167.279 245.724 167.095 243.698C167.027 242.835 167.089 241.966 167.279 241.121C167.446 240.383 167.763 239.687 168.211 239.076C169.073 238.025 170.303 237.342 171.65 237.164C173.077 237.026 174.509 237.365 175.722 238.127C175.394 237.938 174.977 238.086 174.443 238.383C173.16 239.096 173.265 241.258 171.752 242.089C171.665 242.153 171.562 242.192 171.455 242.201C171.347 242.21 171.239 242.189 171.143 242.141C170.784 241.93 170.402 241.762 170.005 241.641C169.721 241.556 169.426 241.51 169.13 241.504C168.902 241.489 168.673 241.526 168.461 241.612C168.249 241.698 168.06 241.831 167.906 242.001C167.511 242.609 167.311 243.324 167.333 244.049C167.33 244.928 167.464 245.801 167.73 246.638C168.469 249.096 169.917 251.281 171.891 252.922C172.609 253.561 173.502 253.97 174.455 254.097C174.79 254.145 175.132 254.074 175.421 253.898C175.71 253.721 175.929 253.449 176.039 253.129C176.438 252.066 176.518 250.911 176.271 249.803C176.004 248.496 175.524 247.242 174.852 246.09Z"
        fill="#EFEFEF"
      />
      <path
        d="M171.143 242.141C171.883 242.584 172.556 243.132 173.141 243.767C173.795 244.476 174.369 245.255 174.852 246.09C175.524 247.242 176.004 248.496 176.271 249.803C176.518 250.911 176.438 252.066 176.039 253.129C175.929 253.449 175.71 253.721 175.421 253.898C175.132 254.074 174.79 254.145 174.455 254.097C173.502 253.97 172.609 253.561 171.891 252.922C169.917 251.281 168.469 249.096 167.73 246.638C167.464 245.801 167.33 244.928 167.333 244.049C167.311 243.324 167.511 242.609 167.906 242.001C168.06 241.831 168.249 241.698 168.461 241.612C168.673 241.526 168.902 241.489 169.13 241.504C169.426 241.51 169.721 241.556 170.005 241.641C170.402 241.762 170.784 241.93 171.143 242.141ZM171.143 242.141C171.239 242.189 171.347 242.21 171.455 242.201C171.562 242.192 171.665 242.153 171.752 242.089C173.265 241.258 173.16 239.096 174.443 238.383C174.977 238.086 175.394 237.938 175.722 238.127M175.722 238.127C176.106 238.348 176.474 238.594 176.824 238.865C177.178 239.136 177.515 239.426 177.836 239.735C178.642 240.519 179.355 241.395 179.959 242.344C181.065 244.043 181.789 245.961 182.081 247.967C182.364 249.579 182.104 251.239 181.342 252.688C180.974 253.318 180.48 253.866 179.892 254.298C179.304 254.768 178.611 255.087 177.871 255.226C177.103 255.357 176.314 255.318 175.562 255.112C174.67 254.867 173.824 254.475 173.06 253.952C172.298 253.439 171.595 252.841 170.967 252.17C170.187 251.341 169.506 250.423 168.939 249.436C167.908 247.683 167.279 245.724 167.095 243.698C167.027 242.835 167.089 241.966 167.279 241.121C167.446 240.383 167.763 239.687 168.211 239.076C169.073 238.025 170.303 237.342 171.65 237.164C173.077 237.026 174.509 237.365 175.722 238.127Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M174.997 256.983C174.463 257.494 173.808 257.861 173.092 258.047C172.387 258.232 171.651 258.268 170.93 258.152C170.219 258.042 169.529 257.824 168.883 257.506C168.086 257.115 167.342 256.622 166.67 256.041C165.997 255.46 165.385 254.812 164.843 254.107C164.166 253.233 163.59 252.286 163.126 251.283C162.362 249.685 161.949 247.941 161.917 246.17C161.915 245.561 161.982 244.953 162.116 244.359C162.236 243.824 162.427 243.308 162.682 242.823C163.101 242.02 163.736 241.351 164.516 240.892C165.323 240.454 166.237 240.251 167.153 240.306C168.282 240.383 169.376 240.731 170.343 241.32C170.22 241.256 170.085 241.22 169.947 241.217C169.809 241.213 169.671 241.241 169.546 241.298C168.946 241.681 168.453 242.207 168.11 242.83C167.768 243.452 167.587 244.151 167.585 244.861C164.981 243.358 162.866 244.563 162.857 247.556C162.943 249.187 163.413 250.774 164.229 252.188C165.046 253.602 166.185 254.803 167.555 255.692C170.159 257.196 172.278 255.993 172.287 253C172.201 251.369 171.73 249.781 170.913 248.367C170.096 246.952 168.955 245.751 167.585 244.861C167.587 244.151 167.768 243.452 168.11 242.83C168.453 242.207 168.946 241.681 169.546 241.298C169.671 241.241 169.809 241.213 169.947 241.217C170.085 241.22 170.22 241.256 170.343 241.32C170.659 241.515 171.072 241.788 171.375 242.004C171.571 242.146 171.761 242.298 171.942 242.459C172.285 242.749 172.609 243.06 172.915 243.388C173.407 243.909 173.782 244.403 173.786 244.408C174.328 245.112 174.807 245.862 175.218 246.649C175.626 247.43 175.961 248.247 176.218 249.089C176.693 250.568 176.834 252.134 176.63 253.674C176.471 254.929 175.896 256.094 174.997 256.983Z"
        fill="#EFEFEF"
      />
      <path
        d="M170.343 241.32C169.376 240.731 168.282 240.383 167.153 240.306C166.237 240.251 165.323 240.454 164.516 240.892C163.736 241.351 163.101 242.02 162.682 242.823C162.427 243.308 162.236 243.824 162.116 244.359C161.982 244.953 161.915 245.561 161.917 246.17C161.949 247.941 162.362 249.685 163.126 251.283C163.59 252.286 164.166 253.233 164.843 254.107C165.385 254.812 165.997 255.46 166.67 256.041C167.342 256.622 168.086 257.115 168.883 257.506C169.529 257.824 170.219 258.042 170.93 258.152C171.651 258.268 172.387 258.232 173.092 258.047C173.808 257.861 174.463 257.494 174.997 256.983C175.896 256.094 176.471 254.929 176.63 253.674C176.834 252.134 176.693 250.568 176.218 249.089C175.961 248.247 175.626 247.43 175.218 246.649C174.807 245.862 174.328 245.112 173.786 244.408C173.782 244.403 173.407 243.909 172.915 243.388C172.609 243.06 172.285 242.749 171.942 242.459C171.761 242.298 171.571 242.146 171.375 242.004C171.072 241.788 170.659 241.515 170.343 241.32ZM170.343 241.32C170.22 241.256 170.085 241.22 169.947 241.217C169.809 241.213 169.671 241.241 169.546 241.298C168.946 241.681 168.453 242.207 168.11 242.83C167.768 243.452 167.587 244.151 167.585 244.861M167.585 244.861C164.981 243.358 162.866 244.563 162.857 247.556C162.943 249.187 163.413 250.774 164.229 252.188C165.046 253.602 166.185 254.803 167.555 255.692C170.159 257.196 172.278 255.993 172.287 253C172.201 251.369 171.73 249.781 170.913 248.367C170.096 246.952 168.955 245.751 167.585 244.861Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M167.586 244.861C168.956 245.751 170.096 246.952 170.913 248.366C171.73 249.781 172.201 251.368 172.287 253C172.278 255.992 170.159 257.196 167.555 255.692C166.185 254.802 165.046 253.602 164.229 252.188C163.413 250.773 162.943 249.186 162.857 247.556C162.866 244.563 164.981 243.357 167.586 244.861Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M167.584 245.55C168.78 246.326 169.775 247.375 170.488 248.609C171.201 249.843 171.612 251.229 171.687 252.652C171.679 255.264 169.83 256.314 167.557 255.002C166.362 254.226 165.368 253.178 164.655 251.944C163.943 250.71 163.533 249.325 163.458 247.902C163.465 245.29 165.311 244.238 167.584 245.55Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M162.715 234.215C162.232 233.38 161.658 232.601 161.004 231.892C160.419 231.257 159.746 230.709 159.006 230.266C159.102 230.314 159.21 230.335 159.318 230.326C159.425 230.317 159.528 230.278 159.615 230.214C161.128 229.383 161.023 227.221 162.306 226.508C162.84 226.211 163.257 226.063 163.585 226.252C163.969 226.473 164.337 226.719 164.688 226.99C165.041 227.261 165.378 227.551 165.699 227.86C166.506 228.644 167.218 229.52 167.822 230.469C168.928 232.168 169.652 234.086 169.944 236.092C170.227 237.704 169.967 239.364 169.205 240.813C168.837 241.443 168.343 241.991 167.755 242.423C167.168 242.893 166.474 243.212 165.734 243.351C164.966 243.482 164.178 243.443 163.425 243.237C162.533 242.992 161.687 242.6 160.923 242.077C160.161 241.564 159.459 240.966 158.83 240.295C158.05 239.465 157.369 238.548 156.803 237.561C155.772 235.808 155.142 233.849 154.958 231.823C154.891 230.96 154.953 230.091 155.142 229.246C155.309 228.508 155.627 227.812 156.074 227.201C156.936 226.15 158.166 225.467 159.513 225.289C160.94 225.151 162.372 225.49 163.585 226.252C163.257 226.063 162.84 226.211 162.306 226.508C161.023 227.221 161.128 229.383 159.615 230.214C159.528 230.278 159.425 230.317 159.318 230.326C159.21 230.335 159.102 230.314 159.006 230.266C158.648 230.055 158.266 229.887 157.868 229.766C157.584 229.681 157.29 229.635 156.993 229.629C156.765 229.614 156.536 229.651 156.324 229.737C156.113 229.823 155.923 229.956 155.77 230.126C155.374 230.734 155.174 231.449 155.196 232.174C155.193 233.052 155.327 233.926 155.593 234.763C156.333 237.221 157.78 239.406 159.755 241.046C160.472 241.686 161.365 242.095 162.318 242.222C162.654 242.269 162.995 242.199 163.284 242.023C163.574 241.846 163.792 241.574 163.903 241.254C164.301 240.191 164.381 239.036 164.134 237.928C163.867 236.621 163.387 235.367 162.715 234.215Z"
        fill="#EFEFEF"
      />
      <path
        d="M159.006 230.266C159.746 230.709 160.419 231.257 161.004 231.892C161.658 232.601 162.232 233.38 162.715 234.215C163.387 235.367 163.867 236.621 164.134 237.928C164.381 239.036 164.301 240.191 163.903 241.254C163.792 241.574 163.574 241.846 163.284 242.023C162.995 242.199 162.654 242.269 162.318 242.222C161.365 242.095 160.472 241.686 159.755 241.046C157.78 239.406 156.333 237.221 155.593 234.763C155.327 233.926 155.193 233.052 155.196 232.174C155.174 231.449 155.374 230.734 155.77 230.126C155.923 229.956 156.113 229.823 156.324 229.737C156.536 229.651 156.765 229.614 156.993 229.629C157.29 229.635 157.584 229.681 157.868 229.766C158.266 229.887 158.648 230.055 159.006 230.266ZM159.006 230.266C159.102 230.314 159.21 230.335 159.318 230.326C159.425 230.317 159.528 230.278 159.615 230.214C161.128 229.383 161.023 227.221 162.306 226.508C162.84 226.211 163.257 226.063 163.585 226.252M163.585 226.252C163.969 226.473 164.337 226.719 164.688 226.99C165.041 227.261 165.378 227.551 165.699 227.86C166.506 228.644 167.218 229.52 167.822 230.469C168.928 232.168 169.652 234.086 169.944 236.092C170.227 237.704 169.967 239.364 169.205 240.813C168.837 241.443 168.343 241.991 167.755 242.423C167.168 242.893 166.474 243.212 165.734 243.351C164.966 243.482 164.178 243.443 163.425 243.237C162.533 242.992 161.687 242.6 160.923 242.077C160.161 241.564 159.459 240.966 158.83 240.295C158.05 239.465 157.369 238.548 156.803 237.561C155.772 235.808 155.142 233.849 154.958 231.823C154.891 230.96 154.953 230.091 155.142 229.246C155.309 228.508 155.627 227.812 156.074 227.201C156.936 226.15 158.166 225.467 159.513 225.289C160.94 225.151 162.372 225.49 163.585 226.252Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M162.86 245.108C162.327 245.619 161.671 245.986 160.956 246.172C160.25 246.357 159.514 246.393 158.794 246.277C158.082 246.167 157.392 245.949 156.746 245.631C155.949 245.24 155.205 244.747 154.533 244.166C153.861 243.585 153.248 242.937 152.706 242.232C152.029 241.358 151.454 240.411 150.99 239.408C150.225 237.81 149.813 236.066 149.78 234.295C149.778 233.686 149.845 233.078 149.979 232.484C150.1 231.949 150.29 231.433 150.546 230.948C150.964 230.145 151.6 229.476 152.38 229.017C153.187 228.579 154.1 228.376 155.016 228.431C156.146 228.508 157.239 228.856 158.206 229.445C158.084 229.381 157.948 229.345 157.81 229.342C157.672 229.338 157.535 229.366 157.409 229.423C156.81 229.806 156.316 230.332 155.974 230.955C155.631 231.577 155.451 232.276 155.449 232.986C152.844 231.483 150.729 232.688 150.721 235.681C150.806 237.312 151.276 238.899 152.093 240.313C152.909 241.727 154.049 242.928 155.418 243.817C158.023 245.321 160.142 244.118 160.15 241.125C160.064 239.494 159.593 237.906 158.776 236.492C157.959 235.077 156.819 233.876 155.449 232.986C155.451 232.276 155.631 231.577 155.974 230.955C156.316 230.332 156.81 229.806 157.409 229.423C157.535 229.366 157.672 229.338 157.81 229.342C157.948 229.345 158.084 229.381 158.206 229.445C158.522 229.64 158.936 229.913 159.238 230.129C159.435 230.271 159.624 230.423 159.805 230.584C160.148 230.874 160.473 231.185 160.778 231.513C161.27 232.034 161.645 232.528 161.649 232.533C162.191 233.237 162.67 233.987 163.082 234.774C163.489 235.555 163.824 236.372 164.081 237.214C164.556 238.693 164.697 240.259 164.493 241.799C164.334 243.053 163.759 244.218 162.86 245.108Z"
        fill="#EFEFEF"
      />
      <path
        d="M158.206 229.445C157.239 228.856 156.146 228.508 155.016 228.431C154.1 228.376 153.187 228.579 152.38 229.017C151.6 229.476 150.964 230.145 150.546 230.948C150.29 231.433 150.1 231.949 149.979 232.484C149.845 233.078 149.778 233.686 149.78 234.295C149.813 236.066 150.225 237.81 150.99 239.408C151.454 240.411 152.029 241.358 152.706 242.232C153.248 242.937 153.861 243.585 154.533 244.166C155.205 244.747 155.949 245.24 156.746 245.631C157.392 245.949 158.082 246.167 158.794 246.277C159.514 246.393 160.25 246.357 160.956 246.172C161.671 245.986 162.327 245.619 162.86 245.108C163.759 244.218 164.334 243.053 164.493 241.799C164.697 240.259 164.556 238.693 164.081 237.214C163.824 236.372 163.489 235.555 163.082 234.774C162.67 233.987 162.191 233.237 161.649 232.533C161.645 232.528 161.27 232.034 160.778 231.513C160.473 231.185 160.148 230.874 159.805 230.584C159.624 230.423 159.435 230.271 159.238 230.129C158.936 229.913 158.522 229.64 158.206 229.445ZM158.206 229.445C158.084 229.381 157.948 229.345 157.81 229.342C157.672 229.338 157.535 229.366 157.409 229.423C156.81 229.806 156.316 230.332 155.974 230.955C155.631 231.577 155.451 232.276 155.449 232.986M155.449 232.986C152.844 231.483 150.729 232.688 150.721 235.681C150.806 237.312 151.276 238.899 152.093 240.313C152.909 241.727 154.049 242.928 155.418 243.817C158.023 245.321 160.142 244.118 160.15 241.125C160.064 239.494 159.593 237.906 158.776 236.492C157.959 235.077 156.819 233.876 155.449 232.986Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M155.449 232.986C156.819 233.876 157.959 235.077 158.776 236.491C159.593 237.906 160.064 239.493 160.15 241.125C160.142 244.117 158.023 245.32 155.418 243.817C154.049 242.927 152.909 241.727 152.093 240.313C151.276 238.898 150.806 237.311 150.721 235.681C150.729 232.688 152.844 231.482 155.449 232.986Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M155.447 233.677C156.643 234.453 157.638 235.502 158.351 236.736C159.064 237.97 159.475 239.356 159.55 240.779C159.543 243.391 157.693 244.441 155.421 243.129C154.226 242.353 153.231 241.305 152.519 240.071C151.806 238.837 151.396 237.451 151.321 236.028C151.329 233.417 153.175 232.365 155.447 233.677Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M183.5 224.139L177.028 241.582C176.525 242.881 175.591 243.968 174.382 244.659C170.466 247.016 164.241 245.965 160.453 242.307C159.332 241.39 158.505 240.164 158.076 238.781C157.646 237.397 157.634 235.918 158.039 234.528L164.511 217.085C164.106 218.475 164.119 219.954 164.548 221.338C164.977 222.721 165.804 223.947 166.925 224.864C170.713 228.522 176.938 229.573 180.854 227.216C182.063 226.525 182.998 225.438 183.5 224.139Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M181.077 216.348C184.865 220.006 184.761 224.866 180.854 227.217C176.938 229.574 170.713 228.522 166.926 224.865C163.147 221.216 163.251 216.356 167.167 213.999C171.074 211.648 177.299 212.699 181.077 216.348Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M187.317 228.955C185.088 231.027 185.088 231.027 185.069 231.039C184.579 231.491 184.044 231.89 183.473 232.232C178.003 235.524 169.278 234.044 163.997 228.942C161.933 227.081 160.581 224.56 160.172 221.811C160.058 220.796 159.937 219.782 159.828 218.767C160.279 221.801 161.772 224.585 164.05 226.64C169.884 232.274 179.514 233.907 185.555 230.272C186.186 229.894 186.777 229.453 187.317 228.955Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.373 212.769L189.644 225.514C188.867 227.523 187.423 229.202 185.554 230.271C179.513 233.907 169.884 232.273 164.049 226.639C160.168 222.891 158.931 218.314 160.31 214.597L165.04 201.852C163.661 205.568 164.897 210.145 168.779 213.894C174.613 219.528 184.242 221.161 190.284 217.526C192.153 216.457 193.597 214.777 194.373 212.769Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.512 205.554L194.702 207.229C194.753 207.685 194.805 208.142 194.857 208.599C195.069 210.373 194.748 212.17 193.933 213.76C193.119 215.351 191.848 216.662 190.284 217.525C184.243 221.161 174.613 219.528 168.779 213.894C163.572 208.865 163.125 202.345 167.366 198.413L167.591 198.204L167.703 198.1L167.928 197.892L168.041 197.787L168.603 197.266L168.828 197.058L168.94 196.954L169.165 196.745L169.39 196.537L169.502 196.432L169.614 196.328C165.774 199.889 166.178 205.796 170.895 210.35C176.177 215.452 184.902 216.932 190.371 213.639C191.788 212.857 192.939 211.669 193.677 210.229C194.414 208.789 194.705 207.161 194.512 205.554Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M190.697 198.433C195.98 203.534 195.843 210.348 190.372 213.639C184.903 216.931 176.178 215.451 170.896 210.349C165.603 205.24 165.741 198.427 171.211 195.135C176.681 191.843 185.405 193.323 190.697 198.433Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M186.107 188.535C184.968 186.569 183.617 184.735 182.077 183.065C180.701 181.57 179.117 180.28 177.373 179.236C177.6 179.351 177.854 179.4 178.107 179.379C178.36 179.357 178.603 179.266 178.807 179.115C182.37 177.158 182.122 172.068 185.143 170.388C186.401 169.688 187.383 169.34 188.155 169.786C189.059 170.305 189.926 170.886 190.751 171.524C191.582 172.161 192.377 172.845 193.132 173.572C195.031 175.418 196.709 177.479 198.131 179.714C200.735 183.714 202.439 188.231 203.126 192.954C203.794 196.75 203.182 200.659 201.386 204.069C200.521 205.553 199.358 206.843 197.973 207.859C196.59 208.967 194.956 209.717 193.216 210.045C191.405 210.353 189.55 210.262 187.779 209.778C185.677 209.2 183.687 208.277 181.888 207.047C180.092 205.837 178.439 204.429 176.959 202.849C175.122 200.897 173.52 198.737 172.186 196.412C169.758 192.285 168.275 187.672 167.842 182.904C167.684 180.871 167.83 178.826 168.275 176.836C168.67 175.097 169.417 173.459 170.47 172.02C172.5 169.546 175.395 167.937 178.568 167.518C181.927 167.193 185.298 167.99 188.155 169.786C187.383 169.34 186.401 169.688 185.143 170.388C182.122 172.068 182.37 177.158 178.807 179.115C178.603 179.266 178.36 179.357 178.107 179.379C177.854 179.4 177.6 179.351 177.373 179.236C176.529 178.741 175.63 178.346 174.694 178.059C174.025 177.859 173.332 177.751 172.634 177.736C172.097 177.701 171.558 177.789 171.059 177.991C170.56 178.194 170.114 178.507 169.753 178.907C168.822 180.339 168.35 182.022 168.402 183.73C168.396 185.798 168.711 187.854 169.337 189.824C171.079 195.612 174.487 200.758 179.136 204.62C180.826 206.125 182.929 207.089 185.172 207.386C185.962 207.499 186.766 207.334 187.447 206.918C188.127 206.502 188.642 205.863 188.903 205.109C189.84 202.606 190.03 199.886 189.448 197.278C188.818 194.201 187.69 191.248 186.107 188.535Z"
        fill="#8491A1"
      />
      <path
        d="M177.373 179.236C179.117 180.28 180.701 181.57 182.077 183.065C183.617 184.735 184.968 186.569 186.107 188.535C187.69 191.248 188.818 194.201 189.448 197.278C190.03 199.886 189.84 202.606 188.903 205.109C188.642 205.863 188.127 206.502 187.447 206.918C186.766 207.334 185.962 207.499 185.172 207.386C182.929 207.089 180.826 206.125 179.136 204.62C174.487 200.758 171.079 195.612 169.337 189.824C168.711 187.854 168.396 185.798 168.402 183.73C168.35 182.022 168.822 180.339 169.753 178.907C170.114 178.507 170.56 178.194 171.059 177.991C171.558 177.789 172.097 177.701 172.634 177.736C173.332 177.751 174.025 177.859 174.694 178.059C175.63 178.346 176.529 178.741 177.373 179.236ZM177.373 179.236C177.6 179.351 177.854 179.4 178.107 179.379C178.36 179.357 178.603 179.266 178.807 179.115C182.37 177.158 182.122 172.068 185.143 170.388C186.401 169.688 187.383 169.34 188.155 169.786M188.155 169.786C189.059 170.305 189.926 170.886 190.751 171.524C191.582 172.161 192.377 172.845 193.132 173.572C195.031 175.418 196.709 177.479 198.131 179.714C200.735 183.714 202.439 188.231 203.126 192.954C203.794 196.75 203.182 200.659 201.386 204.069C200.521 205.553 199.358 206.843 197.973 207.859C196.59 208.967 194.956 209.717 193.216 210.045C191.405 210.353 189.55 210.262 187.779 209.778C185.677 209.2 183.687 208.277 181.888 207.047C180.092 205.837 178.439 204.429 176.959 202.849C175.122 200.897 173.52 198.737 172.186 196.412C169.758 192.285 168.275 187.672 167.842 182.904C167.684 180.871 167.83 178.826 168.275 176.836C168.67 175.097 169.417 173.459 170.47 172.02C172.5 169.546 175.395 167.937 178.568 167.518C181.927 167.193 185.298 167.99 188.155 169.786Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M186.445 214.18C185.19 215.385 183.646 216.248 181.962 216.687C180.301 217.122 178.567 217.206 176.871 216.934C175.197 216.675 173.571 216.161 172.051 215.412C170.174 214.491 168.422 213.332 166.841 211.964C165.257 210.595 163.815 209.069 162.538 207.41C160.945 205.353 159.589 203.122 158.497 200.761C156.696 196.998 155.725 192.893 155.65 188.723C155.645 187.288 155.802 185.858 156.118 184.458C156.401 183.199 156.85 181.983 157.451 180.841C158.437 178.951 159.933 177.376 161.77 176.294C163.67 175.264 165.82 174.786 167.978 174.914C170.637 175.097 173.212 175.916 175.489 177.301C175.2 177.151 174.881 177.068 174.556 177.059C174.23 177.051 173.907 177.117 173.611 177.252C172.319 177.706 169.642 180.232 168.996 185.641C162.864 182.1 157.883 184.939 157.863 191.985C158.065 195.824 159.172 199.562 161.094 202.891C163.016 206.221 165.699 209.048 168.924 211.142C175.056 214.683 180.046 211.85 180.065 204.803C179.863 200.963 178.754 197.224 176.83 193.894C174.906 190.564 172.222 187.736 168.996 185.641C169.642 180.232 172.319 177.706 173.611 177.252C173.907 177.117 174.23 177.051 174.556 177.059C174.881 177.068 175.2 177.151 175.489 177.301C176.232 177.762 177.206 178.405 177.918 178.912C178.381 179.247 178.827 179.605 179.254 179.985C180.06 180.668 180.825 181.398 181.544 182.173C182.702 183.398 183.586 184.562 183.595 184.574C184.871 186.231 185.999 187.996 186.967 189.85C187.928 191.688 188.715 193.611 189.32 195.595C190.439 199.077 190.771 202.764 190.291 206.39C189.917 209.344 188.563 212.087 186.445 214.18Z"
        fill="#8491A1"
      />
      <path
        d="M175.489 177.301C173.212 175.916 170.637 175.097 167.978 174.914C165.82 174.786 163.67 175.264 161.77 176.294C159.933 177.376 158.437 178.951 157.451 180.841C156.85 181.983 156.401 183.199 156.118 184.458C155.802 185.858 155.645 187.288 155.65 188.723C155.725 192.893 156.696 196.998 158.497 200.761C159.589 203.122 160.945 205.353 162.538 207.41C163.815 209.069 165.257 210.595 166.841 211.964C168.422 213.332 170.174 214.491 172.051 215.412C173.571 216.161 175.197 216.675 176.871 216.934C178.567 217.206 180.301 217.122 181.962 216.687C183.646 216.248 185.19 215.385 186.445 214.18C188.563 212.087 189.917 209.344 190.291 206.39C190.771 202.764 190.439 199.077 189.32 195.595C188.715 193.611 187.928 191.688 186.967 189.85C185.999 187.996 184.871 186.231 183.595 184.574C183.586 184.562 182.702 183.398 181.544 182.173C180.825 181.398 180.06 180.668 179.254 179.985C178.827 179.605 178.381 179.247 177.918 178.912C177.206 178.405 176.232 177.762 175.489 177.301ZM175.489 177.301C175.2 177.151 174.881 177.068 174.556 177.059C174.23 177.051 173.907 177.117 173.611 177.252C172.319 177.706 169.642 180.232 168.996 185.641M168.996 185.641C162.864 182.1 157.883 184.939 157.863 191.985C158.065 195.824 159.172 199.562 161.094 202.891C163.016 206.221 165.699 209.048 168.924 211.142C175.056 214.683 180.046 211.85 180.065 204.803C179.863 200.963 178.754 197.224 176.83 193.894C174.906 190.564 172.222 187.736 168.996 185.641Z"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M168.997 185.641C172.222 187.736 174.907 190.564 176.831 193.894C178.755 197.224 179.863 200.963 180.066 204.804C180.046 211.85 175.057 214.683 168.924 211.142C165.7 209.048 163.017 206.221 161.095 202.891C159.172 199.562 158.065 195.825 157.864 191.985C157.884 184.939 162.864 182.101 168.997 185.641Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M168.992 187.264C171.807 189.092 174.15 191.56 175.829 194.467C177.508 197.373 178.475 200.635 178.652 203.987C178.635 210.136 174.281 212.608 168.929 209.518C166.115 207.691 163.774 205.224 162.096 202.318C160.419 199.413 159.453 196.151 159.277 192.801C159.295 186.652 163.641 184.175 168.992 187.264Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M168.979 194.054C170.402 194.978 171.586 196.225 172.434 197.694C173.283 199.163 173.772 200.811 173.861 202.505C173.852 205.613 171.652 206.862 168.947 205.301C167.525 204.377 166.342 203.13 165.494 201.662C164.646 200.193 164.158 198.545 164.069 196.852C164.078 193.744 166.275 192.492 168.979 194.054Z"
        fill="#6B7884"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M167.552 248.447C168.068 248.782 168.497 249.234 168.804 249.766C169.112 250.298 169.289 250.896 169.322 251.51C169.318 252.636 168.521 253.089 167.541 252.523C167.025 252.189 166.596 251.737 166.289 251.204C165.982 250.672 165.805 250.075 165.772 249.461C165.776 248.334 166.572 247.881 167.552 248.447Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M155.441 236.719C155.956 237.054 156.386 237.506 156.693 238.039C157.001 238.571 157.178 239.169 157.21 239.782C157.207 240.909 156.41 241.362 155.429 240.796C154.914 240.461 154.485 240.009 154.178 239.477C153.87 238.945 153.693 238.347 153.661 237.733C153.664 236.607 154.46 236.153 155.441 236.719Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <mask
        id="mask2_1609_14472"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="114"
        y="23"
        width="54"
        height="82"
      >
        <path
          d="M167.598 74.1688L115.09 104.311L114.947 53.9763L167.455 23.8376L167.598 74.1688Z"
          fill="white"
          stroke="white"
          stroke-width="0.25"
        />
      </mask>
      <g class="wifi-animation wifi-3" mask="url(#mask2_1609_14472)">
        <path
          d="M141.201 39.0576L141.13 39.0982C130.688 45.4994 121.834 54.1623 115.229 64.4406L119.435 67.9776C125.003 59.357 132.449 52.0917 141.223 46.7192L141.27 46.6922C144.422 44.5669 147.994 43.1337 151.747 42.4881C155.501 41.8426 159.349 41.9995 163.037 42.9484L167.232 34.5893C159.871 32.056 150.636 33.6412 141.201 39.0576Z"
          fill="#00C1BC"
        />
        <path
          d="M141.238 52.2842L141.191 52.3112C133.673 56.9273 127.295 63.1653 122.529 70.5634L126.735 74.1002C130.448 68.3589 135.412 63.5214 141.26 59.9459L141.307 59.9189C143.404 58.5025 145.782 57.5469 148.28 57.1158C150.778 56.6846 153.34 56.7879 155.795 57.4186L159.989 49.0596C156.812 48.2501 153.498 48.1189 150.266 48.6747C147.034 49.2305 143.957 50.4606 141.238 52.2842Z"
          fill="#00C1BC"
        />
        <path
          d="M141.28 65.5378L141.233 65.5648C136.641 68.3736 132.745 72.1747 129.834 76.6861L134.039 80.223C135.897 77.3722 138.37 74.9684 141.278 73.1862L141.325 73.1592C142.374 72.456 143.56 71.9795 144.806 71.7607C146.052 71.5419 147.33 71.5856 148.557 71.8889L152.753 63.5299C150.806 63.0438 148.778 62.9715 146.801 63.3175C144.824 63.6635 142.943 64.42 141.28 65.5378Z"
          fill="#00C1BC"
        />
        <path
          d="M141.318 79.1953C140.06 80.0085 139.014 81.1056 138.264 82.3971C137.514 83.6886 137.082 85.138 137.002 86.6272C137.01 89.3425 138.946 90.4661 141.346 89.0884C142.597 88.2743 143.638 87.1783 144.384 85.8894C145.129 84.6004 145.559 83.1549 145.638 81.67C145.63 78.9547 143.694 77.8311 141.318 79.1953Z"
          fill="#00C1BC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1609_14472">
          <rect
            width="173.386"
            height="133.527"
            fill="white"
            transform="translate(191.792 97.7285)"
          />
        </clipPath>
      </defs>
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
