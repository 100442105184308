<template>
  <svg
    width="286"
    height="430"
    viewBox="0 0 286 430"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.2619 299.363H33.262L33.2618 299.358C33.2383 298.829 33.084 298.314 32.8127 297.859C32.5431 297.387 32.1606 296.99 31.6993 296.7L31.6994 296.7L31.6951 296.698L22.7285 291.553C22.5614 291.433 22.3651 291.359 22.1597 291.339C21.9526 291.318 21.7437 291.354 21.555 291.441C21.5176 291.457 21.4823 291.478 21.4499 291.502L18.605 293.128C18.5488 293.161 18.5264 293.23 18.5534 293.289C18.5804 293.348 18.6478 293.376 18.7088 293.355C18.8559 293.302 19.0136 293.286 19.1684 293.307C19.3231 293.329 19.4704 293.387 19.5977 293.476L19.5974 293.477L19.6072 293.482L21.094 294.341C21.1077 294.348 21.1226 294.354 21.1381 294.356L28.578 298.63C29.002 298.893 29.3496 299.261 29.5868 299.697L29.5866 299.697L29.591 299.704C29.8526 300.119 30.0008 300.593 30.0212 301.082V307.961H30.0208L30.0215 307.97C30.0327 308.12 30.0095 308.27 29.9536 308.41C29.8978 308.55 29.8107 308.676 29.6989 308.778C29.6518 308.82 29.6445 308.892 29.682 308.944C29.7195 308.995 29.7898 309.01 29.8451 308.979L32.6945 307.35L32.7603 307.318C32.7668 307.314 32.7731 307.31 32.779 307.306C32.9451 307.183 33.0767 307.02 33.1612 306.832C33.2449 306.646 33.2796 306.442 33.2619 306.239V299.363Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.271 301.079H30.2711L30.2709 301.074C30.2493 300.542 30.0885 300.025 29.8045 299.574C29.5461 299.1 29.1682 298.701 28.7079 298.416L28.7079 298.415L28.7043 298.413L21.236 294.123C21.2225 294.116 21.2077 294.11 21.1923 294.108L19.7369 293.268C19.578 293.157 19.3946 293.086 19.2022 293.059C19.0078 293.033 18.8099 293.053 18.6251 293.119C18.6204 293.12 18.6157 293.122 18.6112 293.124C18.6038 293.128 18.5999 293.13 18.5928 293.132C18.5894 293.134 18.5854 293.135 18.5798 293.137C18.5644 293.143 18.5414 293.154 18.518 293.174C18.3545 293.296 18.2248 293.458 18.1413 293.644C18.0576 293.83 18.023 294.034 18.0406 294.237V301.096H18.0404L18.0408 301.103C18.0722 301.639 18.2298 302.16 18.5009 302.624C18.7721 303.088 19.1491 303.482 19.6017 303.775L19.6017 303.775L19.6074 303.778L28.574 308.923C28.7412 309.043 28.9375 309.117 29.1429 309.137C29.35 309.158 29.5588 309.122 29.7475 309.035C29.7881 309.018 29.8262 308.995 29.8608 308.967L29.861 308.967L29.8671 308.962C30.0068 308.835 30.1158 308.678 30.1857 308.502C30.255 308.329 30.2842 308.142 30.271 307.955V301.079Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M134.006 400.132C134.044 400.154 134.092 400.154 134.131 400.132C134.169 400.11 134.193 400.068 134.193 400.024V379.432C134.193 379.387 134.169 379.346 134.131 379.323C134.092 379.301 134.044 379.301 134.006 379.323L116.039 389.619C116.001 389.642 115.977 389.683 115.977 389.728C115.977 389.773 116.001 389.814 116.039 389.836L134.006 400.132Z"
      fill="#CCCCCC"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M126.75 392.006L126.75 392.006C125.608 391.352 123.067 389.896 120.563 388.461C118.191 387.102 115.853 385.762 114.771 385.142C111.796 383.437 107.913 383.98 104.061 386.187C100.207 388.396 96.3594 392.284 93.4287 397.322C90.498 402.36 89.0293 407.612 89.0293 412.031C89.0293 416.446 90.4978 420.056 93.4746 421.762C94.981 422.626 98.9301 424.889 102.009 426.653C103.471 427.491 104.736 428.216 105.451 428.626C108.428 430.332 112.311 429.789 116.163 427.582C120.018 425.373 123.865 421.484 126.796 416.446L126.688 416.383L126.796 416.446C129.726 411.408 131.194 406.156 131.194 401.738C131.194 397.323 129.726 393.713 126.75 392.006Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M116.039 393.049L116.039 393.049L116.033 393.052C111.659 395.884 108.02 399.7 105.409 404.189C102.797 408.678 101.289 413.715 101.005 418.891L101.005 418.891V418.898C101.005 423.66 102.692 427.04 105.454 428.623C108.216 430.206 112.009 429.967 116.163 427.587L116.163 427.587L116.169 427.583C120.543 424.751 124.182 420.936 126.793 416.446C129.404 411.957 130.913 406.92 131.197 401.744L131.197 401.744V401.738C131.197 396.975 129.51 393.595 126.748 392.011C123.986 390.428 120.193 390.668 116.039 393.049Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M116.02 396.478L116.02 396.478L116.015 396.482C112.51 398.751 109.594 401.807 107.502 405.404C105.41 409 104.201 413.036 103.974 417.183L103.974 417.183V417.189C103.974 421.007 105.327 423.723 107.548 424.996C109.769 426.269 112.815 426.074 116.145 424.166L116.145 424.166L116.15 424.162C119.654 421.893 122.571 418.836 124.663 415.24C126.755 411.643 127.963 407.608 128.191 403.461L128.191 403.461V403.454C128.191 399.637 126.838 396.921 124.617 395.648C122.396 394.375 119.35 394.57 116.02 396.478Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M121.146 394.934C121.123 394.888 121.075 394.86 121.023 394.864C119.249 394.997 117.532 395.551 116.018 396.479L116.018 396.479L116.015 396.481C112.511 398.75 109.595 401.806 107.503 405.403C105.411 408.999 104.202 413.035 103.975 417.182L103.975 417.182L103.974 417.185C103.923 418.939 104.292 420.681 105.052 422.265C105.074 422.312 105.122 422.34 105.174 422.336C106.948 422.202 108.665 421.648 110.179 420.72L110.179 420.72L110.182 420.719C113.686 418.45 116.602 415.393 118.694 411.797C120.786 408.2 121.995 404.165 122.222 400.018L122.223 400.018L122.223 400.015C122.274 398.26 121.905 396.519 121.146 394.934Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M171.62 258.451C171.62 258.406 171.596 258.365 171.557 258.342L75.7389 203.428C75.7002 203.406 75.6527 203.406 75.6141 203.428C75.5755 203.45 75.5518 203.492 75.5518 203.536V333.956C75.5518 334 75.5757 334.042 75.6146 334.064L171.433 388.977C171.472 388.999 171.519 388.999 171.558 388.976C171.596 388.954 171.62 388.913 171.62 388.868V258.451Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M165.634 261.882C165.634 261.837 165.61 261.796 165.571 261.774L159.583 258.342C159.544 258.32 159.496 258.32 159.458 258.342C159.419 258.364 159.396 258.406 159.396 258.45V265.314C159.396 265.359 159.419 265.4 159.458 265.423L165.447 268.855C165.486 268.877 165.533 268.877 165.572 268.854C165.611 268.832 165.634 268.791 165.634 268.746V261.882Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M153.657 255.02C153.657 254.976 153.633 254.934 153.594 254.912L141.614 248.048C141.575 248.026 141.528 248.026 141.489 248.048C141.451 248.07 141.427 248.112 141.427 248.156V255.02C141.427 255.065 141.451 255.107 141.49 255.129L153.469 261.993C153.508 262.015 153.556 262.015 153.594 261.993C153.633 261.97 153.657 261.929 153.657 261.884V255.02Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M105.746 227.563C105.746 227.518 105.722 227.476 105.683 227.454L93.7057 220.588C93.667 220.566 93.6195 220.566 93.5809 220.588C93.5423 220.61 93.5186 220.652 93.5186 220.696V227.563C93.5186 227.607 93.5425 227.649 93.5814 227.671L105.559 234.535C105.598 234.557 105.645 234.557 105.684 234.535C105.722 234.512 105.746 234.471 105.746 234.427V227.563Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M87.7778 217.265C87.7778 217.22 87.7539 217.179 87.715 217.157L81.7262 213.725C81.6875 213.702 81.64 213.702 81.6014 213.725C81.5628 213.747 81.5391 213.788 81.5391 213.833V220.697C81.5391 220.742 81.563 220.783 81.6019 220.806L87.5907 224.238C87.6294 224.26 87.6769 224.26 87.7155 224.237C87.7541 224.215 87.7778 224.174 87.7778 224.129V217.265Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M171.621 344.252C171.621 344.207 171.597 344.166 171.558 344.143L159.583 337.279C159.544 337.257 159.496 337.257 159.458 337.28C159.419 337.302 159.396 337.343 159.396 337.388V351.116C159.396 351.161 159.419 351.202 159.458 351.224L171.434 358.088C171.472 358.111 171.52 358.11 171.559 358.088C171.597 358.066 171.621 358.024 171.621 357.98V344.252Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M264.258 335.782C264.296 335.804 264.344 335.804 264.383 335.781C264.421 335.759 264.445 335.718 264.445 335.673V315.081C264.445 315.036 264.421 314.995 264.383 314.973C264.344 314.951 264.296 314.95 264.258 314.973L246.291 325.269C246.252 325.291 246.229 325.332 246.229 325.377C246.229 325.422 246.252 325.463 246.291 325.486L264.258 335.782Z"
      fill="#CCCCCC"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M248.02 322.507L248.02 322.507C246.871 321.849 244.304 320.378 241.785 318.934C239.429 317.584 237.116 316.259 236.042 315.643C233.066 313.938 229.183 314.481 225.332 316.688C221.477 318.897 217.63 322.785 214.699 327.823C211.769 332.861 210.3 338.113 210.3 342.532C210.3 346.947 211.768 350.557 214.745 352.263C216.251 353.127 220.196 355.387 223.274 357.151C224.738 357.99 226.006 358.716 226.722 359.127C229.699 360.833 233.582 360.29 237.433 358.083C241.288 355.874 245.136 351.985 248.066 346.947L247.958 346.884L248.066 346.947C250.996 341.908 252.464 336.657 252.464 332.239C252.464 327.824 250.996 324.214 248.02 322.507Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M237.309 323.55L237.309 323.55L237.304 323.553C232.93 326.385 229.29 330.201 226.679 334.69C224.068 339.179 222.559 344.216 222.276 349.392L222.275 349.392V349.399C222.275 354.161 223.963 357.541 226.725 359.124C229.487 360.707 233.279 360.468 237.434 358.088L237.434 358.088L237.44 358.084C241.813 355.252 245.453 351.437 248.064 346.947C250.675 342.458 252.184 337.421 252.468 332.245L252.468 332.245V332.239C252.468 327.476 250.78 324.096 248.018 322.512C245.256 320.929 241.464 321.169 237.309 323.55Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M237.289 326.977L237.289 326.977L237.283 326.981C233.78 329.25 230.864 332.307 228.772 335.903C226.681 339.5 225.472 343.535 225.244 347.682L225.244 347.682V347.688C225.244 351.506 226.597 354.222 228.818 355.495C231.039 356.768 234.084 356.573 237.413 354.665L237.414 354.665L237.419 354.661C240.923 352.392 243.839 349.335 245.931 345.739C248.023 342.142 249.232 338.107 249.46 333.96L249.46 333.96V333.953C249.46 330.136 248.107 327.42 245.886 326.147C243.665 324.874 240.619 325.069 237.289 326.977Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M242.414 325.434C242.392 325.388 242.344 325.36 242.292 325.364C240.518 325.497 238.801 326.051 237.287 326.979L237.287 326.979L237.284 326.981C233.781 329.25 230.865 332.307 228.773 335.903C226.681 339.5 225.473 343.535 225.245 347.682L225.245 347.682L225.245 347.685C225.193 349.439 225.562 351.18 226.32 352.765C226.343 352.812 226.391 352.84 226.443 352.836C228.217 352.703 229.934 352.149 231.448 351.221L231.448 351.221L231.451 351.219C234.955 348.95 237.871 345.893 239.963 342.297C242.055 338.7 243.264 334.665 243.491 330.518L243.491 330.518L243.492 330.515C243.543 328.761 243.174 327.019 242.414 325.434Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M228.451 356.373C228.489 356.35 228.513 356.309 228.513 356.264V346.002L237.464 330.615L255.337 320.371L264.196 325.448V335.672C264.196 335.716 264.22 335.758 264.258 335.78C264.297 335.802 264.345 335.802 264.383 335.78L285.343 323.767C285.382 323.745 285.406 323.703 285.406 323.659V193.239C285.406 193.195 285.382 193.153 285.343 193.131C285.305 193.109 285.257 193.109 285.219 193.131L171.434 258.342C171.395 258.364 171.371 258.405 171.371 258.45V321.629V388.867C171.371 388.912 171.395 388.953 171.433 388.975C171.472 388.998 171.52 388.998 171.558 388.976L228.451 356.373Z"
      fill="#D6D7D8"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M279.417 203.536C279.417 203.492 279.393 203.45 279.355 203.428C279.316 203.406 279.269 203.406 279.23 203.428L267.25 210.292C267.211 210.314 267.188 210.355 267.188 210.4V224.128C267.188 224.173 267.211 224.214 267.25 224.236C267.288 224.259 267.336 224.259 267.375 224.237L279.354 217.373C279.393 217.35 279.417 217.309 279.417 217.264V203.536Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M177.422 261.775C177.383 261.797 177.359 261.839 177.359 261.884V275.612C177.359 275.656 177.383 275.698 177.422 275.72C177.46 275.742 177.508 275.742 177.547 275.72L189.524 268.856C189.563 268.834 189.587 268.792 189.587 268.748V255.02C189.587 254.975 189.563 254.934 189.525 254.911C189.486 254.889 189.438 254.889 189.4 254.911L177.422 261.775Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M261.45 213.833C261.45 213.788 261.426 213.747 261.388 213.725C261.349 213.702 261.302 213.702 261.263 213.725L249.286 220.589C249.247 220.611 249.223 220.652 249.223 220.697V234.427C249.223 234.472 249.246 234.513 249.285 234.536C249.324 234.558 249.371 234.558 249.41 234.536L261.387 227.672C261.426 227.649 261.45 227.608 261.45 227.563V213.833Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M195.389 251.478C195.35 251.501 195.326 251.542 195.326 251.587V265.315C195.326 265.359 195.35 265.401 195.388 265.423C195.427 265.445 195.475 265.445 195.513 265.423L207.491 258.559C207.53 258.537 207.554 258.496 207.554 258.451V244.723C207.554 244.678 207.53 244.637 207.491 244.615C207.453 244.592 207.405 244.592 207.367 244.614L195.389 251.478Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M271.543 310.796L271.543 310.795L271.533 310.802C271.122 311.094 270.786 311.478 270.552 311.922C270.318 312.367 270.191 312.86 270.184 313.361L270.184 313.361V313.363V316.795C270.184 317.29 270.335 317.682 270.613 317.891C270.899 318.105 271.28 318.099 271.667 317.877L278.057 314.215L278.057 314.215L278.067 314.208C278.478 313.916 278.814 313.532 279.048 313.088C279.282 312.644 279.408 312.151 279.416 311.649L279.416 311.649V311.647V308.215C279.416 307.721 279.265 307.329 278.986 307.12C278.701 306.905 278.32 306.912 277.933 307.134L277.995 307.242L277.933 307.134L271.543 310.796Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M285.406 279.044C285.406 278.999 285.382 278.958 285.343 278.936C285.305 278.913 285.257 278.913 285.219 278.935L171.434 344.144C171.395 344.166 171.371 344.208 171.371 344.253V357.981C171.371 358.025 171.395 358.066 171.433 358.089C171.472 358.111 171.52 358.111 171.558 358.089L285.343 292.881C285.382 292.858 285.406 292.817 285.406 292.772V279.044Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M98.199 420.725C98.2378 420.703 98.2618 420.662 98.2618 420.617C98.2618 414.962 100.265 408.681 103.509 403.104C106.753 397.527 111.23 392.664 116.164 389.837C121.1 387.009 125.542 386.758 128.741 388.592C131.941 390.426 133.943 394.369 133.943 400.024C133.943 400.069 133.966 400.11 134.005 400.133C134.044 400.155 134.091 400.155 134.13 400.133L159.583 385.546C159.622 385.524 159.646 385.483 159.646 385.438V327.093C159.646 327.048 159.622 327.007 159.583 326.985C159.545 326.962 159.497 326.962 159.458 326.984L87.5931 368.169C87.5542 368.192 87.5303 368.233 87.5303 368.278V426.623C87.5303 426.668 87.554 426.709 87.5926 426.731C87.6312 426.753 87.6787 426.754 87.7174 426.731L98.199 420.725Z"
      fill="#D6D7D8"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M159.646 337.388C159.646 337.343 159.622 337.302 159.583 337.28C159.545 337.257 159.497 337.257 159.459 337.279L87.5931 378.464C87.5543 378.486 87.5303 378.527 87.5303 378.572V392.3C87.5303 392.345 87.554 392.386 87.5926 392.408C87.6312 392.431 87.6787 392.431 87.7174 392.409L159.583 351.224C159.622 351.202 159.646 351.161 159.646 351.116V337.388Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M189.524 138.219C189.485 138.197 189.438 138.197 189.399 138.219L75.6146 203.427C75.5757 203.45 75.5518 203.491 75.5518 203.536C75.5518 203.581 75.5757 203.622 75.6146 203.644L171.433 258.559C171.471 258.581 171.519 258.581 171.557 258.559L285.342 193.348C285.381 193.326 285.405 193.284 285.405 193.24C285.405 193.195 285.381 193.153 285.342 193.131L189.524 138.219Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <mask
      id="path-29-outside-1_1609_14463"
      maskUnits="userSpaceOnUse"
      x="107.615"
      y="281.475"
      width="53"
      height="79"
      fill="black"
    >
      <rect fill="white" x="107.615" y="281.475" width="53" height="79" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M108.615 359.696L120.591 304.782L159.519 282.475V330.523"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M108.615 359.696L120.591 304.782L159.519 282.475V330.523"
      fill="#D6D7D8"
    />
    <path
      d="M108.371 359.643C108.342 359.778 108.427 359.911 108.562 359.941C108.697 359.97 108.83 359.885 108.859 359.75L108.371 359.643ZM120.591 304.782L120.466 304.565C120.405 304.6 120.361 304.659 120.346 304.728L120.591 304.782ZM159.519 282.475H159.769C159.769 282.385 159.721 282.303 159.644 282.258C159.567 282.214 159.472 282.213 159.395 282.258L159.519 282.475ZM159.269 330.523C159.269 330.661 159.381 330.773 159.519 330.773C159.657 330.773 159.769 330.661 159.769 330.523H159.269ZM108.859 359.75L120.835 304.835L120.346 304.728L108.371 359.643L108.859 359.75ZM120.715 304.999L159.643 282.692L159.395 282.258L120.466 304.565L120.715 304.999ZM159.269 282.475V330.523H159.769V282.475H159.269Z"
      fill="#3D464D"
      mask="url(#path-29-outside-1_1609_14463)"
    />
    <path
      d="M87.7792 378.572C87.7792 378.527 87.7553 378.486 87.7164 378.463L3.87563 330.415C3.83695 330.393 3.78938 330.393 3.7508 330.415C3.71223 330.438 3.68848 330.479 3.68848 330.523V368.276C3.68848 368.321 3.71245 368.362 3.75132 368.384L87.5921 416.435C87.6308 416.457 87.6783 416.457 87.7169 416.435C87.7555 416.412 87.7792 416.371 87.7792 416.326V378.572Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M87.7792 378.572C87.7792 378.527 87.7553 378.486 87.7164 378.463L3.87563 330.415C3.83695 330.393 3.78938 330.393 3.7508 330.415C3.71223 330.438 3.68848 330.479 3.68848 330.523V340.82C3.68848 340.864 3.71245 340.906 3.75132 340.928L87.5921 388.976C87.6308 388.999 87.6783 388.999 87.7169 388.976C87.7555 388.954 87.7792 388.913 87.7792 388.868V378.572Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M87.7792 382.005C87.7792 381.96 87.7553 381.918 87.7164 381.896L3.87563 333.848C3.83695 333.825 3.78938 333.826 3.7508 333.848C3.71223 333.87 3.68848 333.911 3.68848 333.956V361.412C3.68848 361.457 3.71245 361.498 3.75132 361.521L87.5921 409.571C87.6308 409.594 87.6783 409.593 87.7169 409.571C87.7555 409.549 87.7792 409.508 87.7792 409.463V382.005Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M69.8133 375.14C69.8133 375.095 69.7894 375.054 69.7505 375.031L21.8424 347.575C21.8037 347.553 21.7562 347.553 21.7176 347.575C21.679 347.598 21.6553 347.639 21.6553 347.684V351.116C21.6553 351.16 21.6792 351.202 21.7181 351.224L69.6262 378.68C69.6649 378.703 69.7124 378.702 69.751 378.68C69.7896 378.658 69.8133 378.616 69.8133 378.572V375.14Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M69.8133 380.289C69.8133 380.244 69.7894 380.203 69.7505 380.181L21.8424 352.725C21.8037 352.702 21.7562 352.702 21.7176 352.725C21.679 352.747 21.6553 352.788 21.6553 352.833V356.265C21.6553 356.31 21.6792 356.351 21.7181 356.373L69.6262 383.83C69.6649 383.852 69.7124 383.852 69.751 383.829C69.7896 383.807 69.8133 383.766 69.8133 383.721V380.289Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M69.8133 385.437C69.8133 385.392 69.7894 385.351 69.7505 385.328L21.8424 357.872C21.8037 357.85 21.7562 357.85 21.7176 357.872C21.679 357.895 21.6553 357.936 21.6553 357.98V361.412C21.6553 361.457 21.6792 361.499 21.7181 361.521L69.6262 388.977C69.6649 388.999 69.7124 388.999 69.751 388.977C69.7896 388.955 69.8133 388.913 69.8133 388.869V385.437Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M69.8133 392.301C69.8133 392.256 69.7894 392.215 69.7505 392.193L21.8424 364.736C21.8037 364.714 21.7562 364.714 21.7176 364.737C21.679 364.759 21.6553 364.8 21.6553 364.845V368.277C21.6553 368.322 21.6792 368.363 21.7181 368.385L69.6262 395.841C69.6649 395.864 69.7124 395.864 69.751 395.841C69.7896 395.819 69.8133 395.778 69.8133 395.733V392.301Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M84.7845 401.872H84.7846L84.7845 401.87C84.7798 401.572 84.7048 401.279 84.5657 401.016C84.4265 400.752 84.2271 400.524 83.9835 400.351L83.9838 400.35L83.9732 400.344L73.4933 394.338L73.4933 394.338C73.257 394.202 73.0135 394.194 72.8269 394.334C72.6478 394.468 72.5576 394.714 72.5576 395.008V396.459H72.5576L72.5576 396.461C72.5624 396.759 72.6374 397.052 72.7765 397.316C72.9156 397.579 73.1151 397.807 73.3587 397.98L73.3583 397.981L73.369 397.987L83.8488 403.993C83.8489 403.993 83.8489 403.993 83.8489 403.993C84.0852 404.129 84.3287 404.138 84.5153 403.998C84.6944 403.863 84.7845 403.617 84.7845 403.324V401.872Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.9105 364.118H18.9105L18.9105 364.116C18.9057 363.818 18.8307 363.525 18.6916 363.262C18.5525 362.998 18.3531 362.77 18.1094 362.597L18.1098 362.596L18.0991 362.59L7.61932 356.584C7.61931 356.584 7.6193 356.584 7.61929 356.584C7.38296 356.449 7.13949 356.44 6.9529 356.58C6.77376 356.714 6.68359 356.96 6.68359 357.254V358.705H6.68358L6.68361 358.707C6.68839 359.005 6.76337 359.298 6.90251 359.562C7.04165 359.825 7.24104 360.053 7.48465 360.226L7.4843 360.227L7.49498 360.233L17.9748 366.239C18.2111 366.375 18.4546 366.384 18.6412 366.244C18.8203 366.109 18.9105 365.864 18.9105 365.57V364.118Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M84.7846 385.703L84.7846 385.702C84.7758 385.125 84.631 384.559 84.3618 384.048C84.0926 383.538 83.7067 383.097 83.2349 382.762L83.2353 382.761L83.2246 382.755L74.2419 377.607L74.2419 377.607C73.7991 377.353 73.3685 377.347 73.0463 377.589C72.7316 377.825 72.5576 378.271 72.5576 378.839L72.5576 388.604L72.5576 388.605C72.5664 389.182 72.7113 389.748 72.9804 390.259C73.2496 390.769 73.6356 391.21 74.1073 391.546L74.107 391.546L74.1176 391.552L83.1003 396.7C83.5431 396.954 83.9738 396.96 84.2959 396.718C84.6106 396.482 84.7846 396.036 84.7846 395.468L84.7846 385.703Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.9106 347.95L18.9105 347.948C18.9017 347.371 18.7569 346.805 18.4878 346.295C18.2186 345.784 17.8326 345.343 17.3609 345.008L17.3612 345.007L17.3506 345.001L8.36788 339.853C8.36787 339.853 8.36787 339.853 8.36787 339.853C7.92506 339.599 7.49442 339.594 7.17224 339.835C6.85754 340.071 6.68359 340.517 6.68359 341.085L6.68358 350.85L6.68361 350.852C6.69241 351.428 6.83721 351.994 7.10638 352.505C7.37554 353.015 7.76152 353.456 8.23326 353.792L8.23291 353.792L8.24356 353.798L17.2263 358.946C17.6691 359.2 18.0997 359.206 18.4219 358.964C18.7366 358.728 18.9105 358.282 18.9105 357.714L18.9106 347.95Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M75.7406 234.317C75.7021 234.295 75.6548 234.295 75.6163 234.317L42.6791 253.192C42.6402 253.215 42.6162 253.256 42.6162 253.301C42.6162 253.346 42.6402 253.387 42.6791 253.409L126.52 301.458C126.558 301.48 126.606 301.48 126.644 301.458L159.584 282.583C159.622 282.56 159.646 282.519 159.646 282.474C159.646 282.429 159.622 282.388 159.584 282.366L75.7406 234.317Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M153.655 268.747C153.655 268.702 153.631 268.661 153.593 268.639C153.554 268.617 153.507 268.616 153.468 268.639L132.506 280.65C132.467 280.672 132.443 280.713 132.443 280.758V294.445C132.443 294.49 132.467 294.531 132.506 294.553C132.544 294.575 132.592 294.576 132.631 294.553L153.592 282.542C153.631 282.52 153.655 282.479 153.655 282.434V268.747Z"
      fill="#BC4646"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M132.695 280.758C132.695 280.714 132.671 280.672 132.632 280.65L48.7916 232.602C48.753 232.579 48.7054 232.579 48.6668 232.602C48.6282 232.624 48.6045 232.665 48.6045 232.71V246.438C48.6045 246.483 48.6285 246.524 48.6673 246.547L132.508 294.595C132.547 294.617 132.594 294.617 132.633 294.595C132.672 294.572 132.695 294.531 132.695 294.487V280.758Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M102.752 263.598C102.752 263.553 102.728 263.512 102.689 263.49L78.734 249.762C78.6953 249.739 78.6478 249.74 78.6092 249.762C78.5706 249.784 78.5469 249.826 78.5469 249.87V263.598C78.5469 263.643 78.5709 263.684 78.6097 263.707L102.565 277.435C102.604 277.457 102.651 277.457 102.69 277.435C102.728 277.412 102.752 277.371 102.752 277.326V263.598Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M69.7163 220.61C69.6778 220.588 69.6304 220.588 69.5919 220.61L48.6673 232.602C48.6285 232.624 48.6045 232.665 48.6045 232.71C48.6045 232.755 48.6285 232.796 48.6673 232.819L132.508 280.867C132.547 280.889 132.594 280.889 132.632 280.867L153.557 268.876C153.596 268.853 153.62 268.812 153.62 268.767C153.62 268.722 153.596 268.681 153.557 268.659L69.7163 220.61Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M99.6586 237.77C99.6201 237.748 99.5728 237.748 99.5343 237.77L78.6097 249.762C78.5709 249.784 78.5469 249.826 78.5469 249.87C78.5469 249.915 78.5709 249.957 78.6097 249.979L102.565 263.707C102.603 263.729 102.651 263.729 102.689 263.707L123.612 251.715C123.65 251.693 123.674 251.652 123.674 251.607C123.674 251.562 123.65 251.521 123.612 251.499L99.6586 237.77Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.8353 311.54C24.7884 311.513 24.7296 311.519 24.6895 311.556L3.72983 330.431C3.70004 330.457 3.68493 330.497 3.68918 330.537C3.69344 330.577 3.71655 330.612 3.75132 330.632L87.5921 378.68C87.6389 378.707 87.6977 378.701 87.7379 378.665L108.7 359.79C108.729 359.763 108.745 359.723 108.74 359.684C108.736 359.644 108.713 359.608 108.678 359.588L24.8353 311.54Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.8045 297.751C32.7693 297.694 32.6944 297.674 32.6356 297.708L28.5834 300.03C28.5445 300.052 28.5205 300.093 28.5205 300.138V305.286C28.5205 305.331 28.5445 305.372 28.5834 305.395L31.5776 307.111C31.5776 307.111 31.5776 307.111 31.5776 307.111C32.0135 307.361 32.4374 307.4 32.7603 307.215C33.0836 307.029 33.2618 306.645 33.2618 306.144V299.27H33.2619L33.2617 299.265C33.2406 298.73 33.0833 298.209 32.8045 297.751Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.5973 308.885C29.6319 308.944 29.7079 308.964 29.7675 308.93L32.7608 307.214C32.7896 307.198 32.8107 307.171 32.8193 307.138C32.828 307.106 32.8234 307.072 32.8067 307.043L31.7491 305.223C31.7145 305.164 31.6385 305.144 31.5789 305.178L28.5834 306.893C28.5545 306.91 28.5334 306.937 28.5248 306.969C28.5161 307.002 28.5207 307.036 28.5375 307.065L29.5973 308.885Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M126.699 301.386C126.716 301.33 126.692 301.27 126.641 301.241L42.8027 253.192C42.7697 253.173 42.73 253.171 42.6946 253.185C42.6593 253.198 42.6322 253.228 42.6211 253.264L24.6569 311.611C24.6397 311.667 24.6635 311.727 24.7142 311.756L108.555 359.804C108.588 359.823 108.628 359.826 108.663 359.812C108.698 359.798 108.725 359.769 108.737 359.733L126.699 301.386Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.0383 264.173C43.0054 264.154 42.9656 264.151 42.9303 264.165C42.895 264.179 42.8679 264.208 42.8567 264.244L27.7282 313.373C27.711 313.429 27.7348 313.489 27.7855 313.518L105.48 358.046C105.513 358.065 105.553 358.067 105.588 358.054C105.623 358.04 105.65 358.01 105.661 357.974L120.788 308.843C120.805 308.787 120.781 308.727 120.73 308.698L43.0383 264.173Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M150.66 342.536C150.66 342.492 150.636 342.45 150.598 342.428C150.559 342.406 150.512 342.406 150.473 342.428L138.495 349.292C138.457 349.314 138.433 349.355 138.433 349.4V352.832C138.433 352.877 138.456 352.918 138.495 352.94C138.534 352.963 138.581 352.963 138.62 352.941L150.597 346.077C150.636 346.054 150.66 346.013 150.66 345.968V342.536Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M150.66 295.211C150.66 295.166 150.636 295.125 150.597 295.103C150.559 295.08 150.511 295.08 150.472 295.102L126.745 308.7C126.717 308.716 126.696 308.743 126.687 308.774L112.526 357.348C112.512 357.397 112.529 357.45 112.569 357.481C112.609 357.513 112.664 357.517 112.709 357.492L150.597 335.78C150.636 335.758 150.66 335.716 150.66 335.672V295.211Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M116.161 348.65C116.2 348.628 116.224 348.586 116.224 348.542C116.224 348.497 116.2 348.456 116.161 348.433L114.679 347.578C114.52 347.467 114.337 347.395 114.144 347.369C113.95 347.342 113.752 347.363 113.567 347.428C113.563 347.43 113.558 347.432 113.553 347.434C113.546 347.438 113.542 347.439 113.535 347.442C113.532 347.443 113.528 347.445 113.522 347.447C113.505 347.454 113.478 347.466 113.452 347.491C113.411 347.532 113.403 347.594 113.432 347.644L114.504 349.463C114.539 349.523 114.615 349.543 114.674 349.508L116.161 348.65Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M125.214 355.388H125.215L125.214 355.38C125.179 354.848 125.019 354.331 124.748 353.87C124.478 353.41 124.103 353.018 123.653 352.726L123.653 352.726L123.648 352.723L116.179 348.433C116.16 348.422 116.139 348.416 116.117 348.416H116.1C116.031 348.416 115.975 348.472 115.975 348.541V353.689H115.974L115.975 353.696C116.006 354.232 116.164 354.753 116.435 355.217C116.706 355.681 117.083 356.075 117.536 356.368L117.536 356.368L117.541 356.372L125.027 360.662C125.066 360.684 125.113 360.684 125.152 360.661C125.191 360.639 125.214 360.598 125.214 360.553V355.388Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M125.213 355.389H125.213L125.213 355.384C125.191 354.851 125.03 354.332 124.744 353.881C124.709 353.824 124.635 353.806 124.577 353.839L123.522 354.439C123.483 354.462 123.459 354.503 123.459 354.548V359.696C123.459 359.725 123.469 359.753 123.487 359.775L123.972 360.365L123.472 361.356C123.453 361.394 123.455 361.438 123.476 361.475L124.53 363.294C124.562 363.348 124.629 363.371 124.688 363.346C124.729 363.328 124.768 363.305 124.803 363.277L124.803 363.278L124.809 363.272C124.949 363.145 125.058 362.988 125.128 362.813C125.197 362.639 125.226 362.452 125.213 362.266V355.389Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M128.078 353.673V360.554C128.095 360.737 128.064 360.922 127.989 361.09C127.913 361.259 127.795 361.405 127.646 361.515L127.577 361.549L124.724 363.179C124.85 363.065 124.948 362.923 125.011 362.766C125.074 362.608 125.1 362.439 125.088 362.27V355.389C125.067 354.878 124.912 354.381 124.638 353.947C124.39 353.491 124.027 353.106 123.584 352.832L116.115 348.542H116.098L114.611 347.684C114.467 347.582 114.301 347.517 114.127 347.493C113.952 347.469 113.774 347.487 113.608 347.546L116.461 345.916C116.487 345.895 116.516 345.878 116.547 345.865C116.716 345.786 116.903 345.754 117.089 345.773C117.274 345.791 117.452 345.858 117.602 345.968L126.574 351.116C127.019 351.394 127.387 351.778 127.646 352.231C127.907 352.669 128.056 353.164 128.078 353.673Z"
      fill="#8491A1"
    />
    <path
      d="M128.203 353.673H128.203L128.203 353.667C128.18 353.138 128.025 352.624 127.754 352.168C127.484 351.697 127.102 351.299 126.641 351.01L126.641 351.01L126.636 351.007L117.67 345.863C117.503 345.742 117.306 345.668 117.101 345.648C116.894 345.628 116.685 345.663 116.496 345.751C116.459 345.767 116.424 345.787 116.391 345.812L113.546 347.438C113.49 347.47 113.468 347.54 113.495 347.598C113.522 347.657 113.589 347.686 113.65 347.664C113.797 347.612 113.955 347.596 114.11 347.617C114.265 347.638 114.412 347.696 114.539 347.786L114.539 347.786L114.549 347.792L116.035 348.65C116.049 348.658 116.064 348.663 116.08 348.665L123.519 352.939C123.943 353.202 124.291 353.57 124.528 354.007L124.528 354.007L124.532 354.014C124.794 354.428 124.942 354.903 124.963 355.391V362.27H124.962L124.963 362.279C124.974 362.429 124.951 362.58 124.895 362.72C124.839 362.86 124.752 362.985 124.64 363.087C124.593 363.13 124.586 363.201 124.623 363.253C124.661 363.304 124.731 363.319 124.786 363.288L127.636 361.66L127.702 361.627C127.708 361.624 127.715 361.62 127.72 361.615C127.887 361.493 128.018 361.33 128.103 361.142C128.186 360.956 128.221 360.751 128.203 360.548V353.673Z"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M125.213 355.39H125.214L125.213 355.385C125.192 354.853 125.031 354.337 124.747 353.885C124.489 353.411 124.111 353.012 123.65 352.727L123.65 352.727L123.647 352.725L116.178 348.435C116.165 348.427 116.15 348.422 116.135 348.42L114.679 347.58C114.52 347.469 114.337 347.397 114.145 347.371C113.95 347.344 113.752 347.364 113.567 347.43C113.563 347.432 113.558 347.434 113.554 347.436C113.546 347.44 113.542 347.441 113.535 347.444C113.532 347.445 113.528 347.447 113.522 347.449C113.507 347.455 113.484 347.465 113.46 347.486C113.297 347.608 113.167 347.77 113.084 347.955C113 348.142 112.965 348.346 112.983 348.549V355.407H112.983L112.983 355.415C113.015 355.95 113.172 356.471 113.443 356.935C113.714 357.399 114.092 357.793 114.544 358.086L114.544 358.086L114.55 358.09L123.516 363.235C123.684 363.355 123.88 363.429 124.085 363.449C124.292 363.469 124.501 363.434 124.69 363.347C124.731 363.329 124.769 363.306 124.803 363.279L124.803 363.279L124.809 363.273C124.949 363.146 125.058 362.989 125.128 362.814C125.197 362.64 125.227 362.453 125.213 362.267V355.39Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M89.2717 428.992C89.777 428.887 90.2623 428.703 90.709 428.446L99.6984 423.298L99.6987 423.298L99.7086 423.291C100.179 422.957 100.564 422.517 100.831 422.008C101.099 421.499 101.242 420.934 101.248 420.359V420.358V414.034C101.28 413.786 101.244 413.534 101.145 413.305C101.045 413.075 100.886 412.875 100.683 412.727C100.663 412.71 100.644 412.7 100.635 412.696L100.634 412.695C100.613 412.677 100.586 412.667 100.558 412.665C100.399 412.607 100.228 412.587 100.059 412.606C99.8834 412.626 99.7157 412.688 99.57 412.787L98.0703 413.643L98.0701 413.643L90.5877 417.931C90.5872 417.931 90.5866 417.932 90.5861 417.932C90.1542 418.162 89.6944 418.337 89.2179 418.451C88.2178 418.676 87.1804 418.682 86.1779 418.468C85.6707 418.358 85.1818 418.178 84.7256 417.932C84.7252 417.932 84.7248 417.931 84.7243 417.931L83.2231 417.075L83.2229 417.075L0.881491 369.884C0.842807 369.862 0.795242 369.862 0.756664 369.884C0.718086 369.906 0.694336 369.948 0.694336 369.992V380.288C0.694336 380.333 0.718312 380.375 0.757181 380.397L83.0988 427.588L83.099 427.588L84.6031 428.446L84.603 428.446L84.6074 428.448C85.5475 428.937 86.5962 429.184 87.6573 429.166L89.2717 428.992ZM89.2717 428.992L89.2463 428.869L89.272 428.992C89.2719 428.992 89.2718 428.992 89.2717 428.992ZM71.0555 413.824V416.967L17.4196 386.222V383.078L71.0555 413.824Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M100.535 412.791C100.388 412.734 100.23 412.714 100.073 412.732C99.9161 412.749 99.7662 412.805 99.6363 412.894L98.1322 413.752L90.6466 418.042C90.2046 418.278 89.734 418.457 89.2463 418.574C88.2283 418.803 87.1723 418.809 86.1518 418.592C85.6326 418.479 85.132 418.294 84.665 418.042L83.161 417.184L0.819336 369.993L3.8102 368.277L87.6559 416.326L96.6455 411.178C96.7855 411.081 96.9495 411.023 97.12 411.011C97.2905 410.999 97.4611 411.033 97.6136 411.11L97.7519 411.195L100.535 412.791Z"
      fill="#8491A1"
    />
    <path
      d="M100.49 412.908C100.551 412.932 100.62 412.904 100.648 412.845C100.676 412.786 100.654 412.715 100.597 412.683L97.8178 411.089L97.814 411.087L97.6796 411.003L97.6799 411.003L97.6697 410.998C97.4971 410.911 97.3041 410.873 97.1112 410.886C96.9202 410.9 96.7363 410.964 96.5788 411.072L87.6559 416.182L3.87235 368.169C3.83384 368.147 3.7865 368.147 3.748 368.169L0.757128 369.885C0.718279 369.907 0.694325 369.949 0.694336 369.993C0.694347 370.038 0.718321 370.08 0.757181 370.102L83.0988 417.293L83.0991 417.293L84.6031 418.151L84.6057 418.152C85.0831 418.41 85.5947 418.599 86.1253 418.714L86.1258 418.714C87.1639 418.935 88.2381 418.929 89.2737 418.696L89.2755 418.696C89.7735 418.576 90.2541 418.394 90.7055 418.153L90.7087 418.151L98.1942 413.861L98.1944 413.861L99.6982 413.003L99.6984 413.003L99.7069 412.997C99.8199 412.92 99.9505 412.871 100.087 412.856C100.224 412.84 100.362 412.858 100.49 412.908Z"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M71.3078 413.751C71.3078 413.706 71.2838 413.664 71.2449 413.642L17.359 382.754C17.3204 382.732 17.2728 382.732 17.2342 382.754C17.1956 382.777 17.1719 382.818 17.1719 382.862V386.294C17.1719 386.339 17.1958 386.381 17.2347 386.403L71.1206 417.291C71.1593 417.313 71.2069 417.313 71.2455 417.291C71.284 417.268 71.3078 417.227 71.3078 417.183V413.751Z"
      fill="#94A3B2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M71.1828 413.751V417.183L17.2969 386.294V382.862L71.1828 413.751Z"
      fill="#8491A1"
    />
    <path
      d="M71.3078 413.751C71.3078 413.706 71.2838 413.664 71.2449 413.642L17.359 382.754C17.3204 382.732 17.2728 382.732 17.2342 382.754C17.1956 382.777 17.1719 382.818 17.1719 382.862V386.294C17.1719 386.339 17.1958 386.381 17.2347 386.403L71.1206 417.291C71.1593 417.313 71.2069 417.313 71.2455 417.291C71.284 417.268 71.3078 417.227 71.3078 417.183V413.751Z"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M248.091 256.78V270.888L237.532 276.928V289.013L225.208 296.066V283.981L218.874 287.633L214.674 290.024V275.918L225.208 269.875V257.792L237.532 250.739V255.547V262.822L248.091 256.78Z"
      fill="#D1514E"
    />
    <mask
      id="mask0_1609_14463"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="128"
      y="49"
      width="109"
      height="168"
    >
      <path
        d="M236.547 153.611L128.662 215.9L128.368 111.831L236.253 49.5492L236.547 153.611Z"
        fill="white"
        stroke="white"
        stroke-width="0.25"
      />
    </mask>
    <g class="wifi-animation wifi-3" mask="url(#mask0_1609_14463)">
      <path
        d="M182.311 81.1538L182.167 81.2373C160.764 94.4334 142.617 112.292 129.079 133.48L137.699 140.772C149.111 123.001 164.373 108.023 182.356 96.948L182.453 96.8923C188.915 92.5109 196.236 89.5565 203.929 88.2257C211.621 86.8949 219.509 87.2184 227.068 89.1745L235.666 71.9424C220.579 66.7201 201.651 69.9879 182.311 81.1538Z"
        fill="#00C1BC"
      />
      <path
        d="M182.388 108.418L182.291 108.474C166.882 117.99 153.809 130.85 144.041 146.101L152.661 153.392C160.271 141.556 170.446 131.584 182.432 124.213L182.529 124.157C186.828 121.237 191.7 119.267 196.821 118.379C201.941 117.49 207.192 117.703 212.224 119.003L220.822 101.771C214.309 100.102 207.517 99.8319 200.893 100.978C194.268 102.123 187.961 104.659 182.388 108.418Z"
        fill="#00C1BC"
      />
      <path
        d="M182.473 135.741L182.376 135.797C172.965 141.587 164.979 149.423 159.012 158.723L167.631 166.014C171.439 160.137 176.508 155.182 182.469 151.508L182.565 151.452C184.715 150.003 187.146 149.02 189.699 148.569C192.253 148.118 194.872 148.208 197.389 148.834L205.987 131.602C201.997 130.6 197.84 130.451 193.788 131.164C189.736 131.877 185.881 133.437 182.473 135.741Z"
        fill="#00C1BC"
      />
      <path
        d="M182.55 163.895C179.973 165.572 177.828 167.833 176.291 170.496C174.754 173.158 173.868 176.146 173.704 179.216C173.72 184.813 177.688 187.13 182.607 184.289C185.172 182.611 187.306 180.352 188.834 177.695C190.363 175.038 191.243 172.058 191.404 168.997C191.388 163.399 187.421 161.083 182.55 163.895Z"
        fill="#00C1BC"
      />
    </g>
  </svg>
</template>
