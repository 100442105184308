<template>
  <div class="field-wrap">
    <div class="desktop-content activator">
      <img src="@/assets/images/field.svg" alt="" />
    </div>
    <div @click="emit('click')" class="card">
      <!--      <img src="@/assets/images/field.svg" alt="" width="100%" />-->
      <slot />
      <span class="title">{{ props.title }}</span>
      <span class="description">{{ props.description }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Z_INDEX } from "@/constants";

// eslint-disable-next-line no-undef
const props = defineProps<{
  title: string;
  description: string;
}>();

// eslint-disable-next-line no-undef
const emit = defineEmits<{
  (e: "click"): void;
}>();

const zIndex = computed(() => Z_INDEX);
</script>

<style>
.field-wrap {
  position: relative;
  display: flex;
  width: 100%;
  opacity: 1;
  transition: opacity 0.15s;

  .activator {
    img {
      width: 224px;
    }
  }

  &:hover {
    .card {
      display: grid;
      pointer-events: auto;
      opacity: 1;
    }
  }

  .card svg {
    width: 100%;
    max-height: 60vw;
  }
  .card {
    display: grid;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    padding: 24px;
    box-sizing: border-box;
    cursor: pointer;

    @media (min-width: 786px) {
      pointer-events: none;
      position: absolute;
      opacity: 0;
      top: -24px;
      left: -24px;
      width: 272px;
      z-index: v-bind(zIndex[ "tooltip"]);
      transition: opacity 0.15s;
      box-shadow: 0 24px 38px 3px #00547866;
    }

    &:hover,
    &:focus {
      outline: 2px #0076b0 solid;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      color: #005478;
      margin-top: 30px;
      margin-bottom: 16px;
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      color: #005478;
    }
  }
}
</style>
