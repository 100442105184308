<template>
  <svg
    width="568"
    height="588"
    viewBox="0 0 568 588"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M484.366 258.437C484.366 258.437 484.368 363.102 484.368 364.175C484.309 364.814 484.081 365.425 483.707 365.946C483.333 366.467 482.827 366.879 482.241 367.14C480.649 367.947 478.89 368.368 477.105 368.368C475.32 368.368 473.561 367.947 471.969 367.14C471.383 366.879 470.877 366.467 470.503 365.946C470.129 365.425 469.901 364.814 469.842 364.175V258.437H484.366Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M415.425 274.979C414.128 261.953 407.912 249.913 398.043 241.313C380.119 225.485 384.417 195.509 412.524 174.798C450.768 146.619 510.348 149.839 545.775 191.304C586.719 239.227 562.827 298.273 519.555 299.466C509.991 299.791 500.793 303.217 493.346 309.227C464.528 332.105 419.492 319.778 415.425 274.979Z"
      fill="#86A085"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M502.247 272.575C496.946 272.489 491.727 271.25 486.953 268.942C486.542 268.752 486.222 268.405 486.066 267.979C485.909 267.553 485.929 267.083 486.119 266.671C486.31 266.259 486.656 265.94 487.082 265.783C487.508 265.627 487.979 265.646 488.39 265.837C497.143 269.885 505.563 270.225 512.74 266.824C520.068 263.35 525.587 256.26 527.882 247.37C527.938 247.152 528.036 246.947 528.171 246.767C528.306 246.587 528.475 246.436 528.669 246.322C528.863 246.207 529.077 246.132 529.3 246.101C529.522 246.069 529.749 246.082 529.967 246.138C530.184 246.195 530.389 246.293 530.569 246.428C530.748 246.564 530.899 246.733 531.013 246.927C531.127 247.121 531.202 247.335 531.233 247.558C531.264 247.781 531.251 248.007 531.195 248.225C528.646 258.101 522.454 266.007 514.205 269.916C510.467 271.684 506.381 272.593 502.247 272.575Z"
      fill="#6A826A"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M289.086 480.132L185.42 420.369L185.496 351.973L289.086 411.78V480.132Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M170.697 428.869L185.419 420.369L185.496 351.973L170.697 343.43V428.869Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M303.886 505.764L451.871 420.324V283.621L303.886 369.061V505.764Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M303.885 505.764L289.086 497.22V411.78L170.698 343.429V428.868L155.899 420.324V283.621L229.892 240.901L303.885 369.061V505.764Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M451.871 283.621L377.879 155.462L229.774 240.969L303.886 369.061L451.871 283.621Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M377.878 223.814L348.281 206.727L318.607 223.857L348.281 240.902L377.878 223.814Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M363.305 223.813L348.262 215.127L333.18 223.834L348.262 232.497L363.305 223.813Z"
      fill="#6F6F70"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M348.281 240.902L348.243 292.187L318.607 240.946V223.857L348.281 240.902Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M348.243 292.187L377.879 275.077V223.813L348.281 240.901L348.243 292.187Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M407.475 326.34V360.516L348.28 394.692V360.516L407.475 326.34Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M377.877 343.43V377.605"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M289.085 394.692L289.081 360.52L229.892 257.988L170.697 292.165V326.341L289.085 394.692Z"
      fill="#EAEFFF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M259.446 377.605L259.469 309.253L200.275 275.078V343.492L259.446 377.605Z"
      fill="#EAEFFF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M229.872 360.528L229.891 292.201"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M274.264 471.588L274.287 403.237L215.094 369.062V437.476L274.264 471.588Z"
      fill="#EAEFFF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M244.691 386.283V454.499"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path d="M148.745 585.515V551.339" stroke="#3D464D" />
    <path
      d="M148.744 587.225C148.29 587.225 147.855 587.045 147.534 586.724C147.214 586.403 147.033 585.968 147.033 585.514V551.338C147.033 550.884 147.213 550.449 147.534 550.128C147.855 549.807 148.29 549.627 148.744 549.627C149.198 549.627 149.633 549.807 149.954 550.128C150.275 550.449 150.455 550.884 150.455 551.338V585.514C150.455 585.968 150.275 586.403 149.954 586.724C149.633 587.045 149.198 587.225 148.744 587.225Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M178.307 551.359L148.744 466.283L119.182 551.359C119.182 560.785 132.417 568.426 148.744 568.426C165.071 568.426 178.307 560.785 178.307 551.359Z"
      fill="#86A085"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M37.9248 369.433V340.51"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M37.9248 371.145C37.471 371.145 37.0359 370.965 36.715 370.644C36.3942 370.323 36.2139 369.888 36.2139 369.434V340.511C36.2139 340.057 36.3941 339.622 36.715 339.301C37.0358 338.98 37.471 338.8 37.9248 338.8C38.3786 338.8 38.8137 338.98 39.1346 339.301C39.4555 339.622 39.6357 340.057 39.6357 340.511V369.434C39.6357 369.888 39.4554 370.323 39.1346 370.644C38.8137 370.965 38.3785 371.145 37.9248 371.145Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M7.69271 305.398C-5.3182 315.631 1.02888 333.829 15.5633 336.894C21.2288 338.247 26.3279 341.341 30.1438 345.742C48.5306 365.813 84.7671 341.2 72.8661 312.709C60.6883 283.557 26.5229 283.833 14.7116 298.346C12.6398 300.949 10.2858 303.314 7.69271 305.398Z"
      fill="#86A085"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <mask
      id="mask0_1609_14468"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="200"
      y="48"
      width="108"
      height="165"
    >
      <path
        d="M307.094 151.217L200.795 212.59L200.505 110.051L306.804 48.6849L307.094 151.217Z"
        fill="white"
        stroke="white"
        stroke-width="0.25"
      />
    </mask>
    <g class="wifi-animation wifi-2" mask="url(#mask0_1609_14468)">
      <path
        d="M253.655 79.823L253.512 79.9053C232.423 92.9078 214.542 110.504 201.203 131.382L209.696 138.567C220.941 121.056 235.98 106.298 253.699 95.3855L253.794 95.3307C260.161 91.0136 267.375 88.1024 274.955 86.7912C282.535 85.4799 290.307 85.7986 297.755 87.7261L306.227 70.7466C291.361 65.6009 272.711 68.8209 253.655 79.823Z"
        fill="#00C1BC"
      />
      <path
        d="M253.73 106.688L253.635 106.743C238.451 116.119 225.57 128.79 215.945 143.818L224.439 151.002C231.938 139.34 241.963 129.513 253.774 122.251L253.869 122.196C258.105 119.319 262.906 117.378 267.951 116.502C272.996 115.626 278.17 115.836 283.128 117.117L291.6 100.138C285.183 98.4936 278.491 98.2272 271.963 99.3561C265.436 100.485 259.222 102.984 253.73 106.688Z"
        fill="#00C1BC"
      />
      <path
        d="M253.814 133.61L253.719 133.665C244.446 139.37 236.577 147.091 230.697 156.255L239.19 163.439C242.942 157.648 247.937 152.766 253.811 149.145L253.906 149.091C256.024 147.662 258.419 146.694 260.935 146.25C263.451 145.806 266.032 145.894 268.511 146.51L276.984 129.531C273.052 128.544 268.956 128.397 264.964 129.1C260.971 129.803 257.172 131.339 253.814 133.61Z"
        fill="#00C1BC"
      />
      <path
        d="M253.89 161.35C251.351 163.002 249.238 165.23 247.723 167.854C246.208 170.477 245.335 173.421 245.174 176.446C245.189 181.962 249.099 184.244 253.946 181.445C256.474 179.792 258.576 177.566 260.082 174.947C261.588 172.329 262.456 169.393 262.614 166.377C262.599 160.862 258.689 158.579 253.89 161.35Z"
        fill="#00C1BC"
      />
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
