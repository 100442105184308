<template>
  <svg
    width="279"
    height="279"
    viewBox="0 0 279 279"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M213.902 222.52L216.204 232.345C214.148 238.73 210.464 244.468 205.514 248.993C202.835 251.459 199.894 253.625 196.743 255.45C193.221 257.494 189.527 259.228 185.705 260.632C176.455 263.91 166.849 266.078 157.089 267.089L157.42 255.659C166.724 254.648 175.877 252.551 184.693 249.411C188.318 248.08 191.819 246.434 195.156 244.49C198.157 242.762 200.958 240.708 203.509 238.365C208.272 234.061 211.85 228.605 213.902 222.52Z"
      fill="#456E82"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M213.902 222.52C211.85 228.605 208.272 234.061 203.509 238.365C200.958 240.708 198.157 242.762 195.156 244.49C191.819 246.434 188.318 248.08 184.693 249.412C175.877 252.551 166.724 254.648 157.42 255.659C118.952 243.531 88.9352 226.452 67.3701 204.423C71.5494 199.295 76.3597 194.715 81.6868 190.794C87.5649 186.478 93.8698 182.777 100.502 179.748C108.048 176.309 115.856 173.479 123.852 171.284C159.984 184.774 190 201.852 213.902 222.52Z"
      fill="#E2E2E2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M157.42 255.659L157.089 267.089C156.845 267.002 156.618 266.932 156.374 266.863C117.574 254.629 87.2726 237.388 65.4693 215.138C65.2949 214.946 65.1031 214.754 64.9287 214.58L67.37 204.423C88.9409 226.446 118.958 243.525 157.42 255.659Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M157.431 234.762C188.924 230.922 207.751 219.876 213.913 201.622V222.528C207.757 240.782 188.93 251.828 157.431 255.668V234.762Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M157.431 234.762V255.668C118.963 243.557 88.9459 226.478 67.3809 204.432V183.526C88.9517 205.566 118.968 222.645 157.431 234.762Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.3809 183.526C80 168.5 98.2579 157.576 123.863 150.387C159.994 163.876 190.011 180.955 213.913 201.622C207.757 219.876 188.93 230.922 157.431 234.762C118.963 222.633 88.9459 205.555 67.3809 183.526Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M167.959 188.001C168.267 188.732 168.478 189.501 168.587 190.287C168.477 191.28 168.219 192.251 167.82 193.166C166.798 195.285 165.263 197.114 163.355 198.489C160.368 200.708 156.963 202.298 153.346 203.166C149.737 204.119 146.022 204.605 142.29 204.614C138.574 204.654 134.87 204.208 131.269 203.288C128.569 202.603 125.968 201.576 123.527 200.234C121.469 199.122 119.64 197.63 118.138 195.836C116.921 194.414 116.092 192.7 115.732 190.863C115.699 190.684 115.676 190.503 115.662 190.322C115.956 188.496 116.692 186.77 117.807 185.296C119.623 182.978 121.967 181.13 124.643 179.904C127.948 178.295 131.474 177.189 135.106 176.623C139.452 175.928 143.878 175.887 148.236 176.501C152.62 177.025 156.864 178.381 160.74 180.497C161.77 181.073 162.755 181.726 163.687 182.451C165.57 183.896 167.043 185.81 167.959 188.001Z"
      fill="#456E82"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M166.128 182.748C167.33 184.178 168.152 185.889 168.517 187.722C168.663 188.569 168.686 189.433 168.587 190.287C168.478 189.501 168.267 188.732 167.959 188.001C167.044 185.81 165.571 183.896 163.687 182.451C162.756 181.726 161.771 181.073 160.74 180.497C156.865 178.381 152.621 177.025 148.237 176.501C143.878 175.887 139.453 175.928 135.106 176.623C131.475 177.189 127.948 178.295 124.643 179.904C121.968 181.13 119.624 182.978 117.808 185.296C116.693 186.771 115.956 188.496 115.663 190.322C115.488 188.648 115.752 186.958 116.43 185.418C117.459 183.299 118.999 181.469 120.912 180.096C123.894 177.88 127.293 176.29 130.904 175.419C134.512 174.467 138.228 173.98 141.96 173.97C145.675 173.937 149.379 174.383 152.98 175.297C155.69 175.977 158.299 177.01 160.74 178.368C162.794 179.479 164.621 180.965 166.128 182.748Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M168.588 190.287C168.687 189.433 168.664 188.569 168.518 187.722C168.153 185.889 167.331 184.178 166.129 182.748C164.622 180.964 162.794 179.479 160.741 178.368C158.299 177.009 155.69 175.977 152.981 175.297C149.379 174.383 145.675 173.937 141.96 173.97C138.228 173.98 134.513 174.467 130.904 175.419C127.293 176.29 123.895 177.88 120.912 180.096C118.999 181.469 117.459 183.299 116.43 185.418C115.753 186.958 115.488 188.648 115.663 190.322C115.677 190.503 115.7 190.684 115.733 190.863C116.093 192.7 116.922 194.414 118.14 195.836C119.641 197.63 121.47 199.122 123.528 200.234C125.969 201.576 128.571 202.603 131.27 203.288C134.871 204.208 138.576 204.654 142.291 204.614C146.023 204.605 149.739 204.119 153.347 203.166C156.965 202.298 160.369 200.708 163.356 198.489C165.264 197.114 166.799 195.285 167.821 193.166C168.22 192.251 168.479 191.28 168.588 190.287ZM182.922 186.867C183.062 187.667 183.132 188.479 183.131 189.292C183.111 191.352 182.678 193.388 181.858 195.278C180.267 198.554 177.888 201.384 174.935 203.515C170.322 206.935 165.067 209.392 159.485 210.739C153.905 212.197 148.163 212.947 142.396 212.973C136.646 213.033 130.913 212.341 125.342 210.914C121.166 209.863 117.145 208.273 113.379 206.185C110.197 204.477 107.368 202.18 105.044 199.414C103.169 197.215 101.89 194.571 101.329 191.736C101.191 190.958 101.121 190.17 101.12 189.38V189.292C101.145 187.236 101.584 185.206 102.411 183.324C103.996 180.044 106.368 177.208 109.316 175.07C113.94 171.656 119.199 169.2 124.784 167.845C130.364 166.387 136.105 165.636 141.873 165.611C147.617 165.551 153.345 166.244 158.91 167.67C163.092 168.719 167.119 170.308 170.89 172.4H170.907C174.078 174.118 176.899 176.414 179.225 179.171C181.092 181.38 182.364 184.028 182.922 186.867Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M116.03 212.362C112.708 210.835 109.652 208.785 106.979 206.289C104.827 204.308 103.147 201.869 102.062 199.152C101.446 197.536 101.127 195.821 101.12 194.091V189.38C101.121 190.169 101.191 190.958 101.329 191.735C101.89 194.571 103.169 197.215 105.044 199.414C107.368 202.179 110.197 204.477 113.379 206.185C117.145 208.273 121.166 209.863 125.342 210.914C130.912 212.341 136.646 213.033 142.396 212.973C148.163 212.947 153.905 212.197 159.485 210.739C165.067 209.392 170.322 206.935 174.935 203.515C177.888 201.384 180.267 198.554 181.858 195.278C182.678 193.388 183.111 191.352 183.131 189.292H183.149V194.091C182.886 198.521 180.976 202.692 177.795 205.783C174.718 208.882 171.038 211.317 166.984 212.938C162.968 214.648 158.774 215.906 154.481 216.69C145.856 218.221 137.021 218.132 128.428 216.428C124.145 215.584 119.981 214.219 116.03 212.362Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M222.484 229.087L179.85 254.095V250.935L219.749 227.534L222.484 229.087Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M179.85 246.765L219.749 223.347V227.535L179.85 250.935V246.765Z"
      fill="#896D02"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M222.485 229.087V221.741L219.747 223.346L179.849 246.765V254.094L222.485 229.087ZM225.223 217.5V230.134L177.111 258.352V245.718L225.223 217.5Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M177.112 245.718V258.352L164.435 251.145V238.511L177.112 245.718Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M164.435 238.511C168.009 237.9 171.201 237.254 174.601 236.399C177.843 235.597 181.039 234.619 184.174 233.467C187.144 232.386 190.039 231.109 192.841 229.646C195.439 228.292 197.918 226.721 200.252 224.951C202.391 223.335 204.376 221.524 206.181 219.542C207.829 217.723 209.295 215.747 210.558 213.643C211.203 212.579 211.796 211.444 212.319 210.345L212.494 210.258L225.224 217.5L177.112 245.718L164.435 238.511Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M222.485 221.742V229.086L219.749 227.534V223.346L222.485 221.742Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g class="tag-animation">
      <path
        d="M167.471 94.6884L157.446 89.4514L156.822 89.1272L156.673 89.0525C153.891 87.7402 150.806 87.2059 147.745 87.5063C135.999 88.5787 125.65 97.8553 118.917 106.833C111.168 116.929 106.516 129.057 105.526 141.745C104.866 147.976 106.343 154.245 109.715 159.526C112.234 163.017 134.129 187.032 134.129 187.032L143.156 191.72L138.418 179.376C147.196 163.316 166.448 126.209 166.448 109.052C166.459 107.197 166.309 105.345 165.999 103.516C168.119 104.364 169.64 104.813 169.491 104.264C169.066 102.569 167.471 94.6884 167.471 94.6884ZM153.032 123.89C149.79 136.683 139.69 147.78 130.463 148.728C128.418 148.973 126.349 148.534 124.578 147.481C124.346 146.848 124.162 146.197 124.029 145.536C123.62 141.279 123.661 136.991 124.154 132.743C124.404 130.573 124.653 129.825 125.301 126.508C125.949 123.192 126.697 118.08 126.697 118.08L131.56 111.197L137.296 105.96L136.124 104.239C138.027 103.305 140.077 102.706 142.184 102.469C151.411 101.521 156.274 111.122 153.032 123.89Z"
        fill="#A44240"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M118.743 164.216C121.266 167.713 143.159 191.712 143.159 191.712C143.159 191.712 175.482 135.964 175.482 113.739C175.808 107.679 173.882 101.712 170.075 96.9853C168.378 95.2114 166.292 93.8556 163.981 93.0246C161.671 92.1936 159.199 91.9101 156.761 92.1964C145.037 93.2595 134.687 102.558 127.955 111.526C120.193 121.612 115.533 133.739 114.544 146.427C113.889 152.661 115.37 158.933 118.743 164.216ZM151.223 107.163C160.443 106.217 165.291 115.813 162.053 128.59C158.81 141.368 148.714 152.477 139.495 153.424C132.102 154.178 127.955 147.393 127.688 140.671C127.127 128.189 137.059 108.622 151.223 107.163Z"
        fill="#D1514E"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
