<template>
  <div class="logic-tv-item logic-group-8 car-pulse-animation-wrap">
    <logic8-border class="logic8-border" />
    <logic8-el1 @click="smallModalOpened = true" class="logic8-el1" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content>
      <div class="small-modal-content">
        <logic8-el1 class="small-modal-image" />
        <p class="small-modal-title">Digital Twin</p>
        <p class="small-modal-text">
          This technology enables companies to enhance efficiency, optimize
          operations, and...
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <logic8-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logic8Border from "@/components/Logic/Logic8/components/Logic8Border.vue";
import Logic8El1 from "@/components/Logic/Logic8/components/Logic8El1.vue";
import AppModal from "@/components/AppModal.vue";
import Logic8ModalContent from "@/components/Logic/Logic8/components/Logic8ModalContent.vue";

const smallModalOpened = ref(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.logic-group-8 {
  position: relative;
  display: flex;
  width: 559px;
  height: 318px;
  pointer-events: none;

  svg {
    pointer-events: auto;
  }

  .logic8-border {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
  }
  .logic8-el1 {
    position: absolute;
    top: 58px;
    left: 147px;
  }
}
</style>
