<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Collaborative Robots</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        Collaborative Robots (Cobots) are designed to work alongside humans in
        various tasks, enhancing productivity and safety in industries such as
        manufacturing, healthcare, and logistics. Unlike traditional industrial
        robots, cobots are equipped with sensors and AI to interact safely and
        efficiently with human workers.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group6/01.png"
        alt=""
        width="100%"
      />
    </div>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        How 6G Enables Collaborative Robots:
      </p>
      <ul class="info-modal-text margin-list">
        <li>
          <span class="info-modal-text-bolt">Ultra-Low Latency</span>: 6G's
          potential latency under 1 millisecond ensures real-time
          responsiveness, crucial for the precise and safe interaction between
          cobots and humans.
        </li>
        <li>
          <span class="info-modal-text-bolt">High Data Rates</span>: With data
          rates up to 1 Tbps, 6G can support the transmission of vast amounts of
          data from cobots’ sensors and cameras, allowing for detailed
          monitoring and control.
        </li>
        <li>
          <span class="info-modal-text-bolt">Massive Connectivity</span>: 6G can
          connect numerous devices simultaneously, facilitating large-scale
          deployment of cobots across different environments without performance
          degradation.
        </li>
        <li>
          <span class="info-modal-text-bolt"
            >Advanced AI and Edge Computing</span
          >: Integrated AI and edge computing capabilities in 6G enable
          real-time data processing and decision-making at the network edge,
          enhancing the autonomy and intelligence of cobots.
        </li>
        <li>
          <span class="info-modal-text-bolt">Enhanced Reliability</span>: 6G
          provides highly reliable connections, ensuring consistent and safe
          operation of cobots in mission-critical applications.
        </li>
      </ul>

      <p class="info-modal-text">
        In summary, 6G technology will significantly enhance the functionality
        and deployment of collaborative robots by providing ultra-low latency,
        high data rates, massive connectivity, advanced AI capabilities, and
        enhanced reliability, leading to safer and more efficient human-robot
        collaboration.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
