<template>
  <div class="worlds-tv-groups-item worlds-group-2 ambulance-animation-wrap">
    <worlds2-border class="worlds2-border" />
    <worlds2-el1
      @click="smallModalOpened = 1"
      class="worlds2-el1 ambulance-animation"
    />
    <worlds2-el2 @click="smallModalOpened = 2" class="worlds2-el2" />
    <worlds2-el3 @click="smallModalOpened = 3" class="worlds2-el3" />
    <worlds2-el4 @click="smallModalOpened = 4" class="worlds2-el4" />
    <worlds2-el5 @click="smallModalOpened = 5" class="worlds2-el5" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content="{ value }">
      <div class="small-modal-content">
        <worlds2-el1 v-if="value === 1" class="small-modal-image" />
        <worlds2-el2 v-else-if="value === 2" class="small-modal-image" />
        <worlds2-el3 v-else-if="value === 3" class="small-modal-image" />
        <worlds2-el4 v-else-if="value === 4" class="small-modal-image" />
        <worlds2-el5 v-else-if="value === 5" class="small-modal-image" />
        <p class="small-modal-title">E-Health Applications</p>
        <p class="small-modal-text">
          Brain-Computer-Interface, emergency detection and teleconsulting
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <worlds2-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Worlds2Border from "@/components/Worlds/Worlds2/components/Worlds2Border.vue";
import Worlds2El1 from "@/components/Worlds/Worlds2/components/Worlds2El1.vue";
import Worlds2El2 from "@/components/Worlds/Worlds2/components/Worlds2El2.vue";
import Worlds2El3 from "@/components/Worlds/Worlds2/components/Worlds2El3.vue";
import AppModal from "@/components/AppModal.vue";
import Worlds2ModalContent from "@/components/Worlds/Worlds2/components/Worlds2ModalContent.vue";
import Worlds2El4 from "@/components/Worlds/Worlds2/components/Worlds2El4.vue";
import Worlds2El5 from "@/components/Worlds/Worlds2/components/Worlds2El5.vue";

const smallModalOpened = ref<number | false>(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.worlds-group-2 {
  position: relative;
  display: flex;
  width: 1432px;
  height: 910px;

  .worlds2-border {
    pointer-events: none;
    position: absolute;
    top: 46px;
    left: 0;
  }
  .worlds2-el1 {
    position: absolute;
    top: 34px;
    left: 341px;
  }
  .worlds2-el2 {
    position: absolute;
    top: 0;
    left: 776px;
  }
  .worlds2-el3 {
    position: absolute;
    top: 423px;
    left: 483px;
  }
  .worlds2-el4 {
    position: absolute;
    top: 316px;
    left: 663px;
  }
  .worlds2-el5 {
    position: absolute;
    top: 206px;
    left: 993px;
  }
}
</style>
