<template>
  <div class="info-modal-content">
    <p class="info-modal-title">AR/VR</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        Augmented Reality (AR) and Virtual Reality (VR) are increasingly being
        integrated into various industries to enhance productivity, improve
        training, and streamline operations.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group3/01.png"
        alt=""
        width="100%"
      />
    </div>
    <div>
      <p class="info-modal-text">
        The emergence of 6G technology is expected to significantly amplify the
        impact of AR and VR by providing the necessary infrastructure for more
        advanced and seamless applications. AR and VR are transforming various
        industries by providing immersive, interactive, and efficient solutions.
      </p>
      <p class="info-modal-text">
        The advent of 6G technology will significantly enhance these
        applications by offering ultra-low latency, high data rates, massive
        connectivity, and advanced AI capabilities, driving further innovation
        and adoption of AR and VR across multiple sectors.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
