<template>
  <svg
    width="209"
    height="386"
    viewBox="0 0 209 386"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2256 340.732L41.9238 350.557C43.9798 356.942 47.6642 362.681 52.6135 367.205C55.2927 369.672 58.2341 371.837 61.3849 373.662C64.9073 375.706 68.6007 377.441 72.4232 378.845C81.6728 382.123 91.2787 384.29 101.039 385.302L100.708 373.871C91.4044 372.86 82.2511 370.764 73.4346 367.624C69.8099 366.293 66.3089 364.646 62.9717 362.703C59.9711 360.974 57.17 358.92 54.6189 356.578C49.8564 352.273 46.2777 346.817 44.2256 340.732Z"
      fill="#456E82"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.2258 340.732C46.2778 346.817 49.8564 352.273 54.6189 356.578C57.1699 358.92 59.9711 360.974 62.9717 362.703C66.3088 364.646 69.8098 366.293 73.4345 367.624C82.251 370.764 91.4043 372.861 100.708 373.872C139.176 361.743 169.193 344.665 190.758 322.636C186.579 317.507 181.768 312.928 176.441 309.007C170.563 304.691 164.258 300.989 157.626 297.96C150.08 294.522 142.272 291.692 134.276 289.497C98.1443 302.986 68.1276 320.065 44.2258 340.732Z"
      fill="#E2E2E2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.708 373.872L101.039 385.302C101.283 385.215 101.51 385.145 101.754 385.075C140.554 372.842 170.855 355.601 192.659 333.351C192.833 333.159 193.025 332.967 193.199 332.792L190.758 322.636C169.187 344.659 139.17 361.737 100.708 373.872Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.697 352.974C69.2041 349.135 50.3768 338.088 44.2154 319.835V340.741C50.371 358.994 69.1983 370.041 100.697 373.88V352.974Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.697 352.974V373.88C139.165 361.769 169.182 344.691 190.747 322.645V301.738C169.176 323.779 139.159 340.857 100.697 352.974Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M190.747 301.738C178.697 286.835 159.87 275.789 134.265 268.599C98.1335 282.089 68.1168 299.167 44.215 319.835C50.3707 338.088 69.198 349.135 100.697 352.974C139.165 340.846 169.182 323.767 190.747 301.738Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M90.1688 306.213C89.8609 306.945 89.6499 307.713 89.5411 308.499C89.6505 309.492 89.9091 310.463 90.3083 311.379C91.3303 313.497 92.8649 315.327 94.7726 316.701C97.7596 318.92 101.164 320.511 104.782 321.378C108.39 322.331 112.106 322.818 115.838 322.826C119.553 322.867 123.258 322.421 126.859 321.5C129.558 320.815 132.16 319.789 134.601 318.446C136.659 317.335 138.488 315.843 139.989 314.049C141.207 312.626 142.036 310.913 142.396 309.075C142.429 308.896 142.452 308.716 142.466 308.534C142.172 306.709 141.436 304.983 140.321 303.508C138.505 301.191 136.161 299.342 133.485 298.116C130.18 296.507 126.654 295.401 123.022 294.835C118.676 294.14 114.25 294.099 109.891 294.713C105.508 295.237 101.264 296.593 97.3884 298.709C96.358 299.285 95.3729 299.939 94.4414 300.664C92.5577 302.109 91.0847 304.022 90.1688 306.213Z"
      fill="#456E82"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.9996 300.961C90.7974 302.391 89.9757 304.102 89.6106 305.934C89.4652 306.782 89.4417 307.646 89.5407 308.5C89.6496 307.713 89.8606 306.945 90.1685 306.213C91.0843 304.022 92.5571 302.109 94.4407 300.664C95.3722 299.939 96.3574 299.285 97.3877 298.709C101.263 296.593 105.507 295.237 109.891 294.713C114.249 294.099 118.675 294.14 123.022 294.835C126.653 295.401 130.18 296.507 133.485 298.116C136.16 299.342 138.504 301.191 140.32 303.508C141.435 304.983 142.172 306.709 142.465 308.534C142.64 306.861 142.376 305.171 141.698 303.631C140.669 301.511 139.129 299.682 137.216 298.308C134.234 296.093 130.835 294.502 127.224 293.631C123.615 292.679 119.9 292.193 116.168 292.183C112.453 292.149 108.749 292.595 105.148 293.509C102.438 294.189 99.8292 295.222 97.3876 296.58C95.3343 297.691 93.5068 299.177 91.9996 300.961Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M89.5399 308.499C89.4409 307.645 89.4644 306.781 89.6098 305.934C89.9749 304.101 90.7966 302.391 91.9988 300.961C93.5061 299.177 95.3337 297.691 97.3872 296.58C99.8288 295.222 102.438 294.189 105.147 293.509C108.749 292.595 112.453 292.149 116.168 292.183C119.9 292.192 123.615 292.679 127.224 293.631C130.835 294.502 134.233 296.093 137.216 298.308C139.129 299.682 140.669 301.511 141.697 303.631C142.375 305.17 142.64 306.861 142.465 308.534C142.451 308.716 142.428 308.896 142.395 309.075C142.035 310.913 141.206 312.626 139.988 314.049C138.486 315.842 136.658 317.335 134.6 318.446C132.159 319.789 129.557 320.815 126.858 321.5C123.257 322.421 119.552 322.867 115.837 322.826C112.105 322.818 108.389 322.331 104.781 321.378C101.163 320.511 97.7585 318.92 94.7715 316.701C92.8638 315.327 91.3293 313.497 90.3072 311.379C89.908 310.463 89.6494 309.492 89.5399 308.499ZM75.2058 305.079C75.0662 305.88 74.9962 306.692 74.9966 307.505C75.017 309.565 75.4498 311.6 76.2696 313.49C77.8609 316.767 80.2396 319.597 83.1925 321.727C87.8063 325.148 93.0606 327.605 98.6426 328.952C104.223 330.409 109.965 331.16 115.732 331.186C121.482 331.246 127.215 330.553 132.786 329.127C136.962 328.075 140.983 326.486 144.749 324.397C147.931 322.69 150.76 320.392 153.084 317.626C154.959 315.427 156.238 312.783 156.799 309.948C156.937 309.17 157.007 308.382 157.008 307.592V307.505C156.983 305.449 156.544 303.419 155.717 301.537C154.132 298.257 151.76 295.421 148.812 293.282C144.188 289.868 138.929 287.412 133.344 286.058C127.764 284.599 122.023 283.849 116.255 283.824C110.511 283.764 104.783 284.456 99.2181 285.883C95.0361 286.931 91.0087 288.521 87.238 290.612H87.2205C84.0502 292.33 81.229 294.627 78.9025 297.383C77.0363 299.592 75.764 302.241 75.2058 305.079Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M142.098 330.575C145.42 329.048 148.476 326.998 151.149 324.502C153.301 322.521 154.981 320.082 156.066 317.365C156.682 315.748 157.001 314.034 157.008 312.304V307.592C157.007 308.382 156.937 309.17 156.799 309.948C156.238 312.783 154.959 315.427 153.084 317.626C150.76 320.392 147.931 322.69 144.749 324.397C140.983 326.485 136.962 328.075 132.786 329.126C127.215 330.553 121.482 331.246 115.732 331.185C109.965 331.16 104.223 330.409 98.6426 328.952C93.0606 327.605 87.8063 325.148 83.1925 321.727C80.2396 319.597 77.8609 316.767 76.2696 313.49C75.4499 311.6 75.017 309.565 74.9966 307.505H74.9791V312.304C75.2421 316.733 77.1518 320.904 80.3326 323.996C83.4104 327.094 87.0898 329.529 91.1441 331.151C95.1603 332.86 99.3537 334.118 103.647 334.902C112.272 336.433 121.107 336.344 129.7 334.641C133.983 333.797 138.147 332.431 142.098 330.575Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.6435 347.299L78.2783 372.308V369.148L38.3792 345.747L35.6435 347.299Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.2783 364.977L38.3791 341.559V345.747L78.2783 369.148V364.977Z"
      fill="#896D02"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.6428 347.3V339.953L38.3805 341.558L78.2788 364.978V372.307L35.6428 347.3ZM32.905 335.712V348.347L81.0166 376.565V363.931L32.905 335.712Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81.0159 363.93V376.565L93.6934 369.358V356.723L81.0159 363.93Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M93.6934 356.723C90.1186 356.112 86.9274 355.467 83.527 354.612C80.2848 353.81 77.0888 352.831 73.9535 351.68C70.9836 350.598 68.0885 349.322 65.2868 347.858C62.6893 346.504 60.2102 344.934 57.8757 343.164C55.737 341.547 53.7522 339.736 51.9467 337.754C50.299 335.935 48.833 333.96 47.5698 331.856C46.9246 330.791 46.3316 329.657 45.8085 328.557L45.6341 328.47L32.9043 335.712L81.016 363.93L93.6934 356.723Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.6433 339.954V347.299L38.3789 345.747V341.559L35.6433 339.954Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g clip-path="url(#clip0_1849_1566)">
      <path
        d="M69.8279 243.361C69.7639 267.065 86.3351 295.922 106.836 307.838C117.577 314.065 127.251 314.43 134.063 309.985L145.232 303.458C151.916 299.572 156.016 291.306 156.08 279.518C156.123 255.814 139.551 226.957 119.029 214.998C108.715 209.029 99.3617 208.471 92.5922 212.422L81.1673 219.121C81.0178 219.206 80.8683 219.292 80.7402 219.357C74.0349 223.243 69.8493 231.552 69.8279 243.361Z"
        fill="#DEDFFB"
      />
      <path
        d="M80.6754 219.404C87.4099 215.459 96.7692 216.019 107.083 221.99C127.605 233.906 144.176 262.776 144.123 286.502C144.096 298.258 139.96 306.549 133.279 310.441L145.226 303.458C151.908 299.566 156.017 291.302 156.07 279.519C156.123 255.82 139.552 226.95 119.03 215.007C108.717 209.036 99.3573 208.476 92.5961 212.421L80.6754 219.404Z"
        fill="#E2E2E2"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-miterlimit="10"
      />
      <path
        d="M107.084 221.992C86.5836 210.092 69.889 219.658 69.8222 243.361C69.7554 267.065 86.3416 295.93 106.842 307.83C127.375 319.75 144.053 310.194 144.12 286.49C144.187 262.787 127.617 233.912 107.084 221.992Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-miterlimit="10"
      />
      <path
        d="M76.3657 241.05L78.3094 242.556L87.7598 249.862L89.6979 251.362L97.3416 257.277L99.2854 258.783L102.098 260.956L107.221 264.916L106.014 255.673L105.974 255.363L105.654 252.932L104.402 243.349L104.088 240.918L102.544 229.082L102.224 226.645C89.1033 221.414 78.6468 227.266 76.3657 241.05Z"
        fill="#CCC39A"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-miterlimit="10"
      />
      <path
        d="M107.07 229.02C89.9384 219.075 75.9563 227.091 75.9006 246.883C75.8447 266.68 89.736 290.871 106.868 300.816C124 310.761 137.985 302.752 138.041 282.955C138.097 263.162 124.202 238.965 107.07 229.02ZM108.015 242.966L108.046 231.888C123.314 241.34 135.595 262.729 136.056 280.666L126.466 275.1C126.003 263.277 117.997 249.335 108.015 242.966ZM107.857 299.089L107.888 288.015C117.873 293.233 125.931 288.615 126.46 277.369L136.049 282.936C135.489 300.28 123.127 307.364 107.857 299.089ZM106.081 230.747L106.05 241.826C96.0652 236.604 88.0102 241.223 87.4814 252.469L77.8923 246.903C78.4519 229.558 90.8106 222.473 106.081 230.747ZM105.892 297.949C90.6245 288.496 78.346 267.109 77.8859 249.173L87.475 254.739C87.9387 266.561 95.9414 280.502 105.923 286.874L105.892 297.949ZM107.894 285.741L107.92 276.748C112.541 278.899 116.234 276.779 116.711 271.71L124.492 276.227C123.97 286.226 116.795 290.337 107.894 285.741ZM107.983 254.229L108.008 245.24C116.906 250.975 124.034 263.39 124.498 273.957L116.718 269.44C116.366 266.441 115.41 263.546 113.909 260.93C112.407 258.315 110.391 256.035 107.983 254.229ZM106.044 244.1L106.018 253.089C101.401 250.943 97.7075 253.063 97.2302 258.128L89.4495 253.611C89.9714 243.616 97.1431 239.503 106.044 244.1ZM105.955 275.607L105.93 284.596C97.0322 278.861 89.907 266.449 89.443 255.881L97.2238 260.398C97.5745 263.397 98.5297 266.292 100.031 268.907C101.532 271.522 103.548 273.802 105.955 275.607ZM107.926 274.458L107.948 266.623L114.729 270.56C114.273 274.386 111.459 275.994 107.926 274.458ZM107.955 264.354L107.977 256.523C111.685 259.395 114.115 263.625 114.736 268.29L107.955 264.354ZM105.983 265.483L105.961 273.318C102.254 270.444 99.826 266.214 99.2055 261.548L105.983 265.483ZM106.012 255.382L105.99 263.213L99.2119 259.278C99.6687 255.457 102.479 253.847 106.012 255.382Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linejoin="round"
      />
      <path
        d="M118.085 250.161C116.261 249.102 114.775 249.953 114.769 252.063C114.829 253.213 115.159 254.332 115.732 255.33C116.305 256.327 117.104 257.174 118.064 257.801C119.891 258.862 121.376 258.012 121.382 255.902C121.322 254.751 120.992 253.632 120.419 252.634C119.846 251.636 119.046 250.789 118.085 250.161Z"
        fill="#D1514E"
        stroke="#3D464D"
        stroke-width="0.1"
        stroke-miterlimit="10"
      />
      <path
        d="M91.1918 263.612C89.3672 262.553 87.8813 263.404 87.8754 265.514C87.9357 266.664 88.2657 267.783 88.8383 268.781C89.411 269.778 90.21 270.625 91.1703 271.252C92.9979 272.313 94.4823 271.463 94.4883 269.353C94.4281 268.203 94.0981 267.083 93.5252 266.085C92.9522 265.087 92.1527 264.24 91.1918 263.612Z"
        fill="#D1514E"
        stroke="#3D464D"
        stroke-width="0.1"
        stroke-miterlimit="10"
      />
      <path
        d="M132.759 293.403C130.935 292.344 129.449 293.195 129.443 295.305C129.503 296.455 129.833 297.574 130.406 298.571C130.978 299.569 131.777 300.416 132.738 301.043C134.565 302.104 136.05 301.254 136.056 299.144C135.995 297.993 135.665 296.874 135.093 295.876C134.52 294.878 133.72 294.031 132.759 293.403Z"
        fill="#D1514E"
        stroke="#3D464D"
        stroke-width="0.1"
        stroke-miterlimit="10"
      />
    </g>
    <mask
      id="mask0_1849_1566"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="50"
      y="48"
      width="110"
      height="168"
    >
      <path
        d="M158.886 152.837L50.9893 215.133L50.6955 111.052L158.592 48.7637L158.886 152.837Z"
        fill="white"
        stroke="white"
        stroke-width="0.25"
      />
    </mask>
    <g class="wifi-animation wifi-2" mask="url(#mask0_1849_1566)">
      <path
        d="M104.644 80.3717L104.499 80.4552C83.0943 93.6526 64.9452 111.513 51.4062 132.704L60.0268 139.996C71.4403 122.223 86.7043 107.244 104.689 96.1675L104.786 96.1118C111.248 91.73 118.57 88.7752 126.264 87.4443C133.957 86.1134 141.846 86.4369 149.405 88.3932L158.005 71.1592C142.916 65.9364 123.986 69.2046 104.644 80.3717Z"
        fill="#00C1BC"
      />
      <path
        d="M104.72 107.639L104.623 107.695C89.2122 117.212 76.1382 130.073 66.3691 145.325L74.9897 152.617C82.6011 140.78 92.7771 130.807 104.765 123.435L104.861 123.38C109.161 120.459 114.033 118.489 119.154 117.6C124.275 116.712 129.527 116.924 134.559 118.225L143.158 100.991C136.645 99.3221 129.852 99.0517 123.227 100.198C116.602 101.343 110.294 103.88 104.72 107.639Z"
        fill="#00C1BC"
      />
      <path
        d="M104.806 134.965L104.71 135.021C95.2972 140.811 87.3106 148.648 81.3428 157.949L89.9631 165.241C93.7714 159.364 98.8406 154.408 104.803 150.734L104.899 150.678C107.049 149.228 109.48 148.246 112.033 147.795C114.587 147.344 117.207 147.434 119.724 148.059L128.323 130.825C124.333 129.823 120.175 129.674 116.123 130.387C112.071 131.101 108.215 132.66 104.806 134.965Z"
        fill="#00C1BC"
      />
      <path
        d="M104.883 163.122C102.305 164.798 100.161 167.06 98.6234 169.723C97.086 172.386 96.1996 175.374 96.0361 178.444C96.052 184.042 100.02 186.359 104.94 183.518C107.505 181.84 109.639 179.58 111.168 176.923C112.696 174.266 113.577 171.285 113.738 168.224C113.722 162.626 109.754 160.309 104.883 163.122Z"
        fill="#00C1BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1849_1566">
        <rect
          width="86.4615"
          height="103.273"
          fill="white"
          transform="matrix(-1 0 0 1 156.177 209.794)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
