<template>
  <div class="mobile-content">
    <template v-if="false">
      <h1>6G Foundation</h1>
      <section class="section carousel-wrap">
        <carousel :items-to-show="1.157">
          <slide v-for="slide in 4" :key="slide">
            <div class="slide-content">
              <img src="@/assets/images/field.svg" width="48" alt="" />
              <span>Navigation {{ slide }}</span>
            </div>
          </slide>

          <template #addons>
            <pagination />
          </template>
        </carousel>
      </section>
    </template>
    <h1 class="sticky-title">Fields of application</h1>
    <section class="section">
      <div class="home-fields-wrap">
        <router-link :to="{ name: 'driven' }" class="field-wrap">
          <p class="title">Application 6G Driven</p>

          <platform-el1 />
          <span class="details-link"> Details → </span>
        </router-link>
        <router-link :to="{ name: 'worlds' }" class="field-wrap">
          <p class="title">Connected Worlds</p>
          <platform-el2 />
          <span class="details-link"> Details → </span>
        </router-link>
        <router-link :to="{ name: 'smart' }" class="field-wrap">
          <p class="title">Smart Agriculture</p>
          <platform-el3 />
          <span class="details-link"> Details → </span>
        </router-link>
        <router-link :to="{ name: 'logic' }" class="field-wrap">
          <p class="title">6G Future Factory</p>
          <platform-el4 />
          <span class="details-link"> Details → </span>
        </router-link>
      </div>
    </section>
  </div>
  <section class="desktop-content home-section">
    <platform-element />
    <div class="home-footer-img-wrap">
      <a href="https://www.bmbf.de/bmbf/de/home/home_node.html" target="_blank">
        <img class="home-footer-img" src="@/assets/images/footer.svg" alt="" />
      </a>
    </div>
  </section>
</template>

<script lang="ts" setup>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import PlatformElement from "@/components/PlatformElement.vue";
import { computed } from "vue";
import { Z_INDEX } from "@/constants";
import PlatformEl1 from "@/components/PlatformElement/PlatformEl1.vue";
import PlatformEl2 from "@/components/PlatformElement/PlatformEl2.vue";
import PlatformEl3 from "@/components/PlatformElement/PlatformEl3.vue";
import PlatformEl4 from "@/components/PlatformElement/PlatformEl4.vue";

const zIndex = computed(() => Z_INDEX);
</script>

<style>
.home-fields-wrap {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;

  .field-wrap {
    display: grid;
    gap: 24px;
    justify-items: center;
    padding: 32px 0 40px;
    overflow: hidden;
    text-decoration: none;

    &:not(:last-child) {
      border-bottom: 1px #c9dbe4 solid;
    }

    .title {
      color: #005478;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }
    svg.panel-el {
      width: 100%;
      height: 220px;

      .platform-text {
        display: none;
      }
      .section-title-outline {
        display: none;
      }
      .section-title {
        outline: none;
      }

      &.panel-el1 {
        transform: scale(1.18) translateX(20px);
      }
      &.panel-el2 {
        transform: scale(1.08) translateX(0px);
      }
      &.panel-el3 {
        transform: scale(1.06) translateX(-5px);
      }
      &.panel-el4 {
        transform: scale(1.02) translateX(-18px);
      }
    }

    .details-link {
      color: #4399be;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.carousel-wrap {
  .slide-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: white;
    width: 100%;
    margin-right: 16px;
    color: #005478;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    padding: 16px;
    height: 90px;
    border-radius: 8px;

    img {
      margin-right: 16px;
    }
  }
  .carousel__pagination {
    margin-top: 16px;
    .carousel__pagination-button {
      padding: 5px;
      &:after {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background: #c9dbe4;
      }
    }

    .carousel__pagination-button--active:after {
      background: #fcfcfc;
    }
  }
}

.sticky-title {
  top: 111px;
  position: sticky;
  z-index: v-bind(zIndex[ "title"]);
  background: #005478;
}

.home-section {
  position: relative;
  height: 100%;
  width: 2160px;
  max-width: 100%;
  .home-footer-img-wrap {
    position: fixed;
    left: 50%;
    bottom: 0;
    width: 100%;
    max-width: 1920px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: 0 50px 50px 50px;
    transform: translateX(-50%);
    pointer-events: none;

    a {
      display: flex;
      cursor: pointer;
      pointer-events: auto;
      .home-footer-img {
        height: 110px;
      }

      @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
        justify-content: center;
        .home-footer-img {
          height: 142px;
        }
      }
    }
  }
}
</style>
