<template>
  <div class="panels-wrap">
    <div
      class="panels"
      @keydown.enter="onClick"
      @keydown.space="onClick"
      @click="onClick"
    >
      <svg
        class="platform-floor non-tv-content"
        width="1287"
        height="770"
        viewBox="0 0 1287 770"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_2618_7360)">
          <path
            d="M557.5 20L1246.5 413.5L729.395 709.504L40 315.722L557.5 20Z"
            fill="#FFFFFF"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2618_7360"
            x="0"
            y="0"
            width="1286.5"
            height="769.504"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="20" />
            <feGaussianBlur stdDeviation="20" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2618_7360"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2618_7360"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <platform-el1 v-model="section1Active" />
      <platform-el2 v-model="section2Active" />
      <platform-el3 v-model="section3Active" />
      <platform-el4 v-model="section4Active" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { initialSelectPlatformData, SelectPlatformData } from "@/types";
import PlatformEl1 from "@/components/PlatformElement/PlatformEl1.vue";
import PlatformEl2 from "@/components/PlatformElement/PlatformEl2.vue";
import PlatformEl3 from "@/components/PlatformElement/PlatformEl3.vue";
import PlatformEl4 from "@/components/PlatformElement/PlatformEl4.vue";
const router = useRouter();

const selectedSection = ref<number | boolean>(false);

const isTvVersion = computed(() => {
  return (
    window.innerWidth >= 1836 &&
    window.innerHeight >= 3264 &&
    window.innerHeight > window.innerWidth
  );
});

const section1Active = ref<SelectPlatformData>({
  ...initialSelectPlatformData,
});

const section2Active = ref<SelectPlatformData>({
  ...initialSelectPlatformData,
});

const section3Active = ref<SelectPlatformData>({
  ...initialSelectPlatformData,
});

const section4Active = ref<SelectPlatformData>({
  ...initialSelectPlatformData,
});

const onClick = (e: MouseEvent | KeyboardEvent) => {
  const target = e.target as Element;

  if (target.closest(".section-title") || target.closest(".platform-section")) {
    switch (true) {
      case section1Active.value.selected: {
        if (isTvVersion.value && selectedSection.value !== 1)
          selectedSection.value = 1;
        else router.push({ name: "driven" });
        break;
      }
      case section2Active.value.selected: {
        if (isTvVersion.value && selectedSection.value !== 2)
          selectedSection.value = 2;
        else router.push({ name: "worlds" });
        break;
      }
      case section3Active.value.selected: {
        if (isTvVersion.value && selectedSection.value !== 3)
          selectedSection.value = 3;
        else router.push({ name: "smart" });
        break;
      }
      case section4Active.value.selected: {
        if (isTvVersion.value && selectedSection.value !== 4)
          selectedSection.value = 4;
        else router.push({ name: "logic" });
        break;
      }
      default:
        break;
    }
  }
};

watch(
  () =>
    section1Active.value.selected ||
    section2Active.value.selected ||
    section3Active.value.selected ||
    section4Active.value.selected,
  (value) => {
    if (!value) {
      selectedSection.value = false;
    }
  }
);
</script>

<style>
.panels-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .panels {
    position: relative;
    height: 410px;
    width: 786px;

    .platform-floor {
      position: absolute;
      top: -156px;
      left: -260px;
      pointer-events: none;
      transform: scale(0.45);
    }

    .panel-el {
      pointer-events: none;
      position: absolute;
      overflow: visible;
      transform: scale(0.17);
    }
    .panel-el > * {
      pointer-events: auto;
    }

    .panel-el1 {
      top: -344px;
      left: -370px;
    }
    .panel-el2 {
      top: -266px;
      left: -164px;
    }
    .panel-el3 {
      top: -290px;
      left: -374px;
    }
    .panel-el4 {
      top: -168px;
      left: -332px;
    }

    @media (min-width: 1024px) {
      width: 1024px;
      height: 558px;
      .panel-el {
        transform: scale(0.225);
      }

      .panel-el1 {
        top: -318px;
        left: -240px;
      }
      .panel-el2 {
        top: -216px;
        left: 2px;
      }
      .panel-el3 {
        top: -240px;
        left: -286px;
      }
      .panel-el4 {
        top: -92px;
        left: -208px;
      }
      .platform-floor {
        top: -106px;
        left: -144px;
        transform: scale(0.6);
      }
    }
    @media (min-width: 1280px) {
      width: 1280px;
      height: 768px;
      .panel-el {
        transform: scale(0.29);
      }
      .panel-el1 {
        top: -226px;
        left: -78px;
      }
      .panel-el2 {
        top: -102px;
        left: 196px;
      }
      .panel-el3 {
        top: -120px;
        left: -186px;
      }
      .panel-el4 {
        top: 56px;
        left: -70px;
      }
      .platform-floor {
        top: 8px;
        left: -16px;
        transform: scale(0.78);
      }
    }

    @media (min-width: 1920px) {
      width: 1920px;
      height: 868px;
      .panel-el {
        transform: scale(0.35);
      }
      .panel-el1 {
        top: -190px;
        left: 250px;
      }
      .panel-el2 {
        top: -40px;
        left: 562px;
      }
      .panel-el3 {
        top: -58px;
        left: 98px;
      }
      .panel-el4 {
        top: 146px;
        left: 256px;
      }
      .platform-floor {
        top: 70px;
        left: 302px;
        transform: scale(0.94);
      }
    }

    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      width: 1836px;
      height: 2500px;
      .panel-el {
        transform: none;
      }

      .panel-el1 {
        top: 100px;
        left: -136px;
      }
      .panel-el2 {
        top: 552px;
        left: 608px;
      }
      .panel-el3 {
        top: 1009px;
        left: -150px;
      }
      .panel-el4 {
        top: 1541px;
        left: 483px;
      }
    }

    .section-title {
      cursor: pointer;
      outline: none;
    }
    .section-title-outline {
      transition: all 0.5s;
      opacity: 0;
    }

    .section-floor {
      transition: all 0.5s;
      fill: #dbf3ff;
    }
    .section-outline {
      transition: all 0.5s;
      opacity: 0;
    }

    .section-title-outline-focused {
      opacity: 1;
    }

    .platform-section {
      transition: all 0.5s;
      cursor: pointer;

      .platform-section-shadow {
        transition: all 0.5s;
        opacity: 0;
      }
    }
    .platform-section-active {
      transform: translateY(-15px);

      .platform-section-shadow {
        opacity: 1;
      }

      .section-floor {
        fill: #c2e7fe;
      }
      .section-outline {
        opacity: 1;
      }
    }
  }
}
</style>
