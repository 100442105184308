<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Smart Hospital</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        The Smart Hospital embodies the integration of cutting-edge technologies
        to revolutionize healthcare delivery and patient monitoring.
      </p>
      <p class="info-modal-text">
        The development aims to capture and optimize internal clinical
        processes. In addition to optimizing established processes,
        orchestration and data aggregation should enable new services in
        diagnostics and treatment.
      </p>
      <p class="info-modal-text">
        Central to this setup is a high-precision tracking system with up to 0.1
        mm accuracy at 300 Hz, essential for various medical procedures.
      </p>
      <p class="info-modal-text">
        Body-Area-Network, Brain-Computer-Interface and collaborative Robotics
        are detailed in the other sections in more detail. Their general
        integration within the Smart Hospital demonstrates the comprehensive
        approach to modern healthcare.
      </p>
      <p class="info-modal-text">
        Key components include a Brain-Computer Interface (BCI) using a
        32-channel EEG and a 16/16 fNIRS system for advanced neuro-monitoring
        and neurorehabilitation. Additionally, a dedicated URLLC sensor network
        connects to the edge/cloud, enabling seamless body area network (BAN)
        operations and continuous health monitoring through IoT solutions.
      </p>
      <p class="info-modal-text">
        Collaborative robotic systems further enhance surgical precision and
        assistance with low-latency capabilities, JCAS testing, IoT integration,
        live-force feedback control, and a 1kHz Franka control interface.
      </p>
    </div>
    <div class="info-modal-card-wrap">
      <img
        class="info-modal-card-img"
        src="@/assets/images/groups/connectedWorlds/group1/01.png"
        alt=""
      />
      <div class="info-modal-card-content">
        <p class="info-modal-card-title">
          Tracking reference system for JCAS applications
        </p>
        <p class="info-modal-card-text">up to 0.1 mm accuracy at 300 Hz</p>
      </div>
    </div>
    <div class="info-modal-card-wrap">
      <div class="info-modal-card-content">
        <p class="info-modal-card-title">BCI applications</p>
        <p class="info-modal-card-text">32-channel EEG + 16/16 fNIRS system</p>
      </div>
      <img
        class="info-modal-card-img"
        src="@/assets/images/groups/connectedWorlds/group1/02.png"
        alt=""
      />
    </div>
    <div class="info-modal-card-wrap">
      <img
        class="info-modal-card-img"
        src="@/assets/images/groups/connectedWorlds/group1/03.png"
        alt=""
      />
      <div class="info-modal-card-content">
        <p class="info-modal-card-title">
          BAN sensors, WBAN network, IoT applications
        </p>
        <p class="info-modal-card-text">
          Dedicated URLLC sensor network, connection to Edge/ Cloud
        </p>
      </div>
    </div>
    <div class="info-modal-card-wrap">
      <div class="info-modal-card-content">
        <p class="info-modal-card-title">Collaborative robot systems</p>
        <p class="info-modal-card-text">
          Latency and JCAS tests, connection to IoT systems, live force feedback
          control, 1 kHz Franka Control interface
        </p>
      </div>
      <img
        class="info-modal-card-img"
        src="@/assets/images/groups/connectedWorlds/group1/04.png"
        alt=""
      />
    </div>
    <div>
      <p class="info-modal-text">
        Partners from TU Darmstadt around Robin Klose investigate decentralized
        URLLC in the context of lab-telemanipulators. The communication between
        two or more network nodes of the LivingLab is carried out by a
        communication mechanism for decentralized URLLC (dURLLC). Each data
        packet that is sent by one of the nodes is retransmitted as a concurrent
        cooperative transmission (CCT) by a group of relays. In doing so, the
        group of relays does not only extend the communication range of the
        wireless link, but it also enhances the reliability of the communication
        link, as the tightly scheduled retransmissions generate additional
        reception opportunities of each message at the receiver. CCT-based
        communications additionally allows using multiple relays in each group
        of relays, which allows for a redundant execution of each
        retransmission, which in turn improves the robustness of the
        communication system. A prototype for CCT-based communication from TUDa
        is employed, which is based on the WARP v3 SDR and that features
        functions for synchronization in time and frequency.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/connectedWorlds/group1/05.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        Wireless Open Access Research Platform for decentralized URLLC
      </p>
    </div>

    <div>
      <p class="info-modal-text info-modal-text-small info-modal-text-bolt">
        Publications:
      </p>
      <p class="info-modal-text info-modal-text-small">
        Robin Klose, "Decentralized Ultra-Reliable Low-Latency Communications
        through Concurrent Cooperative Transmission". Dissertation, Technische
        Universität Darmstadt, Juli 2023. Online: http://tubiblio.ulb.tu-
        darmstadt.de/138849
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
