<template>
  <header>
    <div class="header-content">
      <div
        @click="navIsOpened = false"
        class="nav-wrap"
        :class="{ opened: navIsOpened }"
      >
        <nav @click.stop="onNavClick">
          <div class="actions">
            <router-link :to="{ name: 'home' }" class="desktop-logo-wrap">
              <img class="desktop-logo" src="@/assets/images/logo.svg" alt="" />
            </router-link>
            <button class="close-button" @click="navIsOpened = false">
              <svg
                width="24"
                height="23"
                viewBox="0 0 24 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.47225 22.9718L0.527832 21.0273L10.0556 11.4996L0.527832 1.97176L2.47225 0.0273438L12 9.55514L21.5278 0.0273438L23.4722 1.97176L13.9445 11.4996L23.4722 21.0273L21.5278 22.9718L12 13.444L2.47225 22.9718Z"
                  fill="#5A5F62"
                />
              </svg>
            </button>
          </div>
          <div class="links">
            <template v-for="(item, i) in items">
              <a
                :key="`link-${i}`"
                v-if="typeof item?.to === 'string'"
                :href="item.to"
                target="_blank"
              >
                {{ item.name }}
              </a>
              <router-link
                v-else
                :key="i"
                :to="item?.to || { name: 'worlds' }"
                :tabindex="navIsOpened ? undefined : -1"
              >
                {{ item.name }}
              </router-link>
            </template>
          </div>
          <a
            href="https://www.bmbf.de/bmbf/de/home/home_node.html"
            target="_blank"
            class="footer-link"
          >
            <img class="footer-img" src="@/assets/images/footer.svg" alt="" />
          </a>
        </nav>
      </div>
      <router-link @click="onNavClick" class="logo" :to="{ name: 'home' }">
        <img class="logo-img" src="@/assets/images/logo.svg" alt=""
      /></router-link>
      <button @click="navIsOpened = true" class="menu">
        <svg
          width="30"
          height="21"
          viewBox="0 0 30 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 20.5V17.7223H30V20.5H0ZM0 11.8889V9.11112H30V11.8889H0ZM0 3.27775V0.5H30V3.27775H0Z"
            :fill="props.buttonColor"
          />
        </svg>
      </button>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { Z_INDEX } from "@/constants";

// eslint-disable-next-line no-undef
const props = defineProps<{
  buttonColor: string;
  tabletColor: string;
  color: string;
}>();

const navIsOpened = ref(false);

watch(navIsOpened, (value) => {
  document.body.style.overflowY = value ? "hidden" : "auto";
});

const zIndex = computed(() => Z_INDEX);

const onNavClick = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (target.closest("a")) {
    navIsOpened.value = false;
  }
};

const items = [
  { name: "Open6GHub", to: "https://www.open6ghub.de/" },
  // { name: "6G Applications" },
  { name: "Imprint", to: { name: "imprint" } },
  { name: "Data & Privacy", to: { name: "privacy" } },
];
</script>

<style scoped>
header {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: v-bind(zIndex[ "header"]);
  background: v-bind(props[ "color"]);
  pointer-events: none;

  @media (min-width: 786px) {
    background: v-bind(props[ "tabletColor"]);
  }
  @media (min-width: 1280px) {
    background: transparent;
  }
  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    background: v-bind(props[ "color"]);
  }

  .header-content {
    position: relative;
    width: 100%;
    max-width: 1920px;
    box-sizing: border-box;
    display: flex;
    padding: 16px;
    justify-content: flex-end;
    align-items: center;
    height: 112px;

    pointer-events: auto;

    & > * {
      pointer-events: auto;
    }

    @media (min-width: 786px) {
      pointer-events: none;
    }

    @media (min-width: 1280px) {
      padding: 50px;
      height: 156.5px;
    }

    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      align-items: flex-start;
      height: 350px;
      width: 100%;
      max-width: 100%;
    }

    .menu {
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 16px;

      @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
        padding: 30px 20px;
        svg {
          width: 64px;
          height: 44px;
        }
      }
    }

    .nav-wrap {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      pointer-events: none;
      background: rgba(0, 0, 0, 0);
      transition: background-color 0.15s;
      z-index: v-bind(zIndex[ "nav"]);
      nav {
        display: flex;
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100%;
        left: 100%;
        top: 0;
        background: #dbf3ff;
        transition: left 0.15s;
        padding: 16px 16px 70px 16px;
        box-sizing: border-box;

        @media (min-width: 786px) {
          max-width: 430px;
          padding-bottom: 16px;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;

          .desktop-logo-wrap {
            display: flex;
            .desktop-logo {
              visibility: hidden;
              height: 80px;
              @media (min-width: 786px) {
                visibility: visible;
              }
            }
          }
          .close-button {
            cursor: pointer;
            padding: 16px;
            border: none;
            background: transparent;
            width: 56px;
            height: 56px;
          }
        }

        .links {
          margin-top: 24px;
          flex: 1;
          a {
            display: flex;
            text-decoration: none;
            color: #005478;
            font-size: 24px;
            font-weight: 400;
            padding: 16px 0;
          }
        }

        .footer-link {
          width: min-content;
          display: flex;
          cursor: pointer;
          .footer-img {
            width: 122px;
          }
        }
      }
      &.opened {
        pointer-events: auto;
        background: rgba(0, 0, 0, 0.34);
        nav {
          left: 0;
          @media (min-width: 786px) {
            left: calc(100% - 430px);
          }
        }
      }
    }

    .logo {
      display: flex;
      position: absolute;
      z-index: v-bind(zIndex[ "logo"]);
      left: 16px;
      top: 16px;

      @media (min-width: 1280px) {
        left: 50px;
        top: 50px;
        z-index: auto;
      }

      .logo-img {
        height: 80px;
      }

      @media (min-width: 1280px) {
        .logo-img {
          height: 105px;
        }
      }

      @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
        left: 50%;
        transform: translateX(-50%);
        top: 50px;
        .logo-img {
          height: 198px;
        }
      }
    }
  }
}
</style>
