<template>
  <svg
    width="494"
    height="728"
    viewBox="0 0 494 728"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M202.57 452.149C207.713 455.122 208.772 453.284 213.916 456.257C219.059 459.23 216.34 463.944 221.483 466.917C226.626 469.89 227.62 468.166 232.764 471.14C237.908 474.113 237.977 473.994 243.121 476.968C248.262 479.94 248.542 479.455 253.683 482.426C258.826 485.399 259.003 485.093 264.146 488.066C269.289 491.039 268.327 492.706 273.471 495.679C278.614 498.652 280.016 496.22 285.16 499.193C290.304 502.167 288.689 504.967 293.834 507.941C298.98 510.916 299.899 509.322 305.045 512.297C310.19 515.271 310.921 514.003 316.066 516.977C321.214 519.953 319.835 522.345 324.983 525.321C330.132 528.297 330.235 528.117 335.383 531.093C338.3 532.895 341.597 533.99 345.011 534.289C348.222 534.289 351.519 531.345 353.968 529.929C358.867 527.098 358.886 522.171 353.988 519.339C348.845 516.366 347.818 518.148 342.675 515.175C337.532 512.202 338.139 511.149 332.996 508.176C327.852 505.203 329.741 501.929 324.597 498.955C319.453 495.982 317.943 498.601 312.798 495.627C307.657 492.656 307.312 493.255 302.17 490.283C297.028 487.31 296.338 488.506 291.195 485.533C286.052 482.559 288.604 478.134 283.461 475.161C278.318 472.188 275.978 476.246 270.834 473.273C265.69 470.299 267.227 467.632 262.083 464.658C256.937 461.684 254.755 465.467 249.609 462.492C244.464 459.519 244.782 458.966 239.638 455.993C234.489 453.017 234.039 453.798 228.89 450.822C223.742 447.846 225.584 444.652 220.436 441.676C217.987 440.26 214.469 442.933 211.258 442.933C208.048 442.933 204.146 439.594 201.697 441.01C196.798 443.841 197.671 449.317 202.57 452.149Z"
      fill="#333333"
    />
    <path
      d="M190.947 460.498C197.026 464.011 198.085 462.174 204.164 465.687C210.243 469.202 207.525 473.916 213.604 477.43C219.683 480.943 220.676 479.22 226.755 482.734C232.832 486.247 232.901 486.128 238.979 489.641C245.056 493.154 245.336 492.669 251.413 496.182C257.491 499.695 257.668 499.389 263.746 502.902C269.825 506.416 268.863 508.084 274.942 511.598C281.024 515.113 282.426 512.681 288.507 516.196C294.587 519.711 292.972 522.51 299.052 526.025C305.135 529.541 306.054 527.948 312.138 531.464C318.221 534.981 318.953 533.713 325.036 537.23C329.935 540.061 338.221 542.453 343.12 539.622C344.236 538.932 345.156 537.966 345.79 536.817C346.425 535.668 346.753 534.374 346.742 533.061C346.732 531.748 346.383 530.46 345.731 529.321C345.078 528.182 344.143 527.23 343.016 526.559C336.938 523.045 337.372 522.292 331.293 518.778C325.215 515.265 324.109 517.18 318.029 513.666C311.951 510.153 311.971 510.119 305.892 506.605C299.815 503.092 298.787 504.874 292.71 501.361C286.633 497.847 287.24 496.794 281.162 493.281C275.085 489.768 276.972 486.494 270.895 482.981C264.816 479.467 263.305 482.086 257.226 478.572C251.145 475.056 250.799 475.656 244.718 472.141C238.638 468.626 237.949 469.822 231.869 466.308C225.786 462.791 228.339 458.365 222.255 454.848C216.171 451.331 213.831 455.39 207.747 451.873C202.849 449.042 194.885 446.375 189.986 449.206C185.087 452.038 186.048 457.666 190.947 460.498Z"
      fill="#1A1A1A"
    />
    <path
      d="M169.793 465.209C175.872 468.723 184.083 467.936 190.161 471.449C196.241 474.964 192.163 482.035 198.243 485.549C204.321 489.063 205.812 486.478 211.89 489.991C217.968 493.505 218.071 493.326 224.148 496.839C230.225 500.352 230.645 499.625 236.723 503.138C242.8 506.651 243.065 506.191 249.143 509.704C255.223 513.218 253.78 515.72 259.859 519.234C265.94 522.749 268.044 519.101 274.126 522.616C280.205 526.131 277.783 530.33 283.863 533.844C289.946 537.361 291.325 534.971 297.408 538.487C303.492 542.004 304.589 540.102 310.672 543.619C315.571 546.451 324.029 550.038 328.928 547.207C330.057 546.364 330.971 545.266 331.596 544.002C332.221 542.738 332.539 541.344 332.524 539.934C332.509 538.524 332.161 537.137 331.509 535.887C330.857 534.637 329.92 533.558 328.773 532.74C322.694 529.226 323.345 528.097 317.267 524.583C311.187 521.069 309.53 523.943 303.45 520.429C297.372 516.916 297.401 516.865 291.322 513.352C285.245 509.838 283.704 512.51 277.627 508.997C271.55 505.484 272.46 503.904 266.383 500.392C260.305 496.878 263.137 491.967 257.059 488.454C250.98 484.94 248.715 488.868 242.635 485.354C236.554 481.839 236.036 482.738 229.954 479.223C223.875 475.708 222.84 477.502 216.761 473.988C210.677 470.471 214.506 463.832 208.423 460.315C202.339 456.798 198.829 462.885 192.745 459.369C187.847 456.537 177.022 451.569 172.123 454.4C167.224 457.231 164.894 462.378 169.793 465.209Z"
      fill="#333333"
    />
    <path
      d="M162.859 476.79C168.937 480.303 171.845 476.481 177.923 479.995C184.003 483.509 181.284 488.223 187.364 491.738C193.442 495.251 194.436 493.528 200.514 497.041C206.592 500.554 206.66 500.435 212.738 503.948C218.815 507.462 219.095 506.977 225.172 510.49C231.25 514.003 231.427 513.696 237.505 517.209C243.584 520.724 242.622 522.391 248.702 525.906C254.783 529.421 256.185 526.989 262.267 530.504C268.346 534.018 266.732 536.818 272.811 540.332C278.895 543.849 279.814 542.255 285.897 545.772C291.981 549.289 292.712 548.021 298.796 551.537C303.694 554.369 311.981 556.761 316.879 553.929C317.995 553.24 318.915 552.274 319.549 551.124C320.184 549.975 320.512 548.682 320.501 547.369C320.491 546.056 320.143 544.767 319.49 543.628C318.838 542.489 317.903 541.538 316.776 540.866C310.697 537.352 311.131 536.6 305.053 533.086C298.973 529.572 297.869 531.488 291.789 527.974C285.711 524.461 285.73 524.427 279.652 520.913C273.574 517.4 272.547 519.181 266.469 515.668C260.391 512.155 260.999 511.102 254.921 507.589C248.844 504.076 250.732 500.801 244.654 497.288C238.575 493.774 237.065 496.393 230.986 492.879C224.904 489.364 224.559 489.963 218.477 486.448C212.398 482.934 211.708 484.13 205.629 480.616C199.545 477.099 202.098 472.673 196.014 469.156C189.931 465.639 187.59 469.698 181.506 466.181C176.608 463.349 168.644 460.682 163.745 463.514C158.846 466.346 157.96 473.958 162.859 476.79Z"
      fill="#1A1A1A"
    />
    <path
      d="M153.065 482.207C158.208 485.18 159.268 483.342 164.41 486.315C169.553 489.288 166.835 494.002 171.978 496.975C177.122 499.948 178.115 498.225 183.259 501.198C188.403 504.172 188.472 504.053 193.616 507.027C198.757 509.998 199.037 509.513 204.177 512.485C209.32 515.458 209.497 515.151 214.64 518.124C219.784 521.097 218.822 522.765 223.965 525.738C229.108 528.711 230.511 526.279 235.654 529.252C240.799 532.226 239.184 535.025 244.329 537.999C249.475 540.974 250.394 539.381 255.54 542.355C260.685 545.329 261.416 544.062 266.561 547.036C271.709 550.011 270.33 552.403 275.478 555.379C280.626 558.355 280.73 558.175 285.878 561.151C288.794 562.954 292.092 564.049 295.506 564.347C298.716 564.347 302.014 561.403 304.463 559.987C309.362 557.156 309.381 552.229 304.483 549.398C299.339 546.425 298.312 548.206 293.17 545.233C288.027 542.26 288.634 541.207 283.491 538.234C278.348 535.261 280.235 531.987 275.092 529.014C269.947 526.04 268.437 528.659 263.293 525.685C258.152 522.714 257.806 523.313 252.666 520.342C247.522 517.369 246.832 518.564 241.689 515.591C236.546 512.618 239.099 508.192 233.956 505.219C228.813 502.246 226.472 506.304 221.329 503.331C216.184 500.357 217.722 497.69 212.578 494.717C207.431 491.742 205.25 495.526 200.103 492.551C194.959 489.577 195.277 489.025 190.132 486.051C184.984 483.075 184.533 483.856 179.385 480.88C174.237 477.904 176.079 474.71 170.931 471.734C168.481 470.318 164.963 472.991 161.753 472.991C158.543 472.991 154.64 469.652 152.191 471.067C147.292 473.899 148.166 479.376 153.065 482.207Z"
      fill="#333333"
    />
    <path
      d="M136.51 489.962C142.588 493.476 143.648 491.638 149.726 495.151C155.805 498.666 153.087 503.38 159.167 506.894C165.245 510.407 166.239 508.684 172.317 512.198C178.395 515.711 178.463 515.592 184.541 519.105C190.619 522.618 190.898 522.133 196.976 525.646C203.054 529.159 203.23 528.853 209.308 532.366C215.387 535.88 214.426 537.548 220.505 541.062C226.586 544.577 227.989 542.145 234.07 545.66C240.149 549.175 238.535 551.974 244.615 555.488C250.698 559.005 251.617 557.412 257.7 560.928C263.784 564.445 264.515 563.177 270.599 566.694C275.497 569.525 283.784 571.917 288.683 569.086C289.799 568.396 290.718 567.43 291.353 566.281C291.987 565.132 292.315 563.838 292.304 562.525C292.294 561.212 291.946 559.924 291.293 558.785C290.641 557.646 289.706 556.695 288.579 556.023C282.501 552.509 282.934 551.756 276.856 548.243C270.777 544.728 269.671 546.645 263.592 543.131C257.514 539.617 257.533 539.583 251.455 536.07C245.377 532.556 244.35 534.338 238.273 530.825C232.195 527.312 232.802 526.258 226.724 522.745C220.647 519.232 222.535 515.958 216.458 512.445C210.378 508.93 208.868 511.55 202.789 508.035C196.707 504.52 196.362 505.12 190.28 501.604C184.201 498.09 183.511 499.286 177.432 495.772C171.348 492.255 173.901 487.829 167.817 484.312C161.734 480.795 159.393 484.853 153.309 481.337C148.411 478.506 140.447 475.839 135.549 478.67C130.65 481.502 131.611 487.131 136.51 489.962Z"
      fill="#1A1A1A"
    />
    <path
      d="M338.457 526.104C343.6 529.077 344.66 527.239 349.803 530.212C354.945 533.185 352.227 537.899 357.37 540.872C362.513 543.845 363.508 542.122 368.651 545.095C373.795 548.069 373.864 547.95 379.008 550.924C384.149 553.895 384.429 553.41 389.57 556.382C394.713 559.355 394.89 559.048 400.033 562.021C405.176 564.994 404.214 566.662 409.358 569.635C414.501 572.608 415.903 570.176 421.046 573.149C426.191 576.123 424.576 578.922 429.721 581.896C434.867 584.871 435.786 583.278 440.933 586.252C446.077 589.227 446.808 587.959 451.953 590.933C457.101 593.908 455.722 596.3 460.87 599.276C466.019 602.252 466.122 602.072 471.271 605.048C474.187 606.851 477.484 607.946 480.899 608.244C484.109 608.244 487.406 605.3 489.855 603.884C494.754 601.053 494.774 596.126 489.875 593.295C484.732 590.322 483.705 592.103 478.562 589.13C473.419 586.157 474.026 585.104 468.883 582.132C463.74 579.158 465.628 575.884 460.484 572.911C455.34 569.937 453.83 572.556 448.685 569.582C443.544 566.611 443.199 567.21 438.058 564.239C432.915 561.266 432.225 562.462 427.082 559.489C421.939 556.516 424.491 552.089 419.348 549.116C414.205 546.143 411.865 550.201 406.722 547.228C401.577 544.254 403.115 541.588 397.97 538.614C392.824 535.639 390.642 539.423 385.495 536.448C380.351 533.474 380.669 532.922 375.524 529.948C370.376 526.972 369.926 527.754 364.777 524.778C359.629 521.802 361.471 518.607 356.323 515.631C353.874 514.215 350.356 516.888 347.146 516.888C343.935 516.888 340.033 513.549 337.584 514.965C332.685 517.796 333.558 523.272 338.457 526.104Z"
      fill="#333333"
    />
    <path
      d="M326.834 534.453C332.912 537.967 333.972 536.129 340.05 539.643C346.13 543.157 343.411 547.871 349.491 551.385C355.569 554.899 356.563 553.176 362.641 556.689C368.719 560.202 368.787 560.083 374.865 563.596C380.942 567.109 381.222 566.624 387.3 570.137C393.377 573.651 393.554 573.344 399.632 576.857C405.711 580.371 404.749 582.039 410.829 585.553C416.91 589.068 418.313 586.636 424.394 590.151C430.473 593.666 428.859 596.466 434.938 599.98C441.022 603.496 441.941 601.903 448.024 605.42C454.108 608.936 454.839 607.669 460.923 611.185C465.821 614.017 474.108 616.409 479.006 613.577C480.122 612.887 481.042 611.921 481.677 610.772C482.311 609.623 482.639 608.329 482.629 607.016C482.618 605.703 482.27 604.415 481.617 603.276C480.965 602.137 480.03 601.186 478.903 600.514C472.824 597 473.259 596.247 467.18 592.734C461.1 589.22 459.996 591.136 453.916 587.622C447.838 584.108 447.857 584.075 441.779 580.561C435.701 577.048 434.674 578.829 428.596 575.316C422.519 571.803 423.126 570.75 417.049 567.237C410.971 563.723 412.859 560.449 406.781 556.936C400.702 553.422 399.192 556.041 393.112 552.527C387.031 549.012 386.686 549.611 380.604 546.096C374.525 542.582 373.835 543.778 367.755 540.264C361.672 536.747 364.225 532.321 358.141 528.804C352.057 525.287 349.717 529.345 343.634 525.829C338.735 522.997 330.771 520.33 325.872 523.162C320.973 525.994 321.935 531.622 326.834 534.453Z"
      fill="#1A1A1A"
    />
    <path
      d="M305.679 539.165C311.758 542.679 319.969 541.892 326.048 545.405C332.127 548.92 328.049 555.991 334.129 559.505C340.207 563.018 341.698 560.433 347.776 563.947C353.854 567.46 353.957 567.282 360.034 570.795C366.112 574.308 366.531 573.58 372.608 577.094C378.686 580.607 378.952 580.147 385.03 583.66C391.109 587.174 389.666 589.676 395.745 593.189C401.827 596.705 403.93 593.057 410.012 596.572C416.091 600.086 413.67 604.286 419.749 607.8C425.833 611.317 427.211 608.927 433.294 612.443C439.378 615.959 440.475 614.059 446.558 617.575C451.457 620.407 459.915 623.994 464.814 621.163C465.943 620.32 466.858 619.221 467.483 617.957C468.107 616.693 468.425 615.3 468.41 613.889C468.395 612.479 468.047 611.093 467.395 609.842C466.743 608.592 465.806 607.514 464.659 606.695C458.58 603.182 459.231 602.053 453.153 598.539C447.073 595.025 445.416 597.899 439.337 594.385C433.258 590.872 433.287 590.821 427.209 587.307C421.131 583.794 419.591 586.466 413.513 582.953C407.436 579.44 408.346 577.86 402.269 574.347C396.191 570.835 399.023 565.922 392.946 562.409C386.866 558.895 384.601 562.824 378.522 559.309C372.44 555.794 371.922 556.693 365.84 553.178C359.761 549.664 358.727 551.458 352.647 547.944C346.564 544.427 350.392 537.787 344.309 534.27C338.225 530.754 334.715 536.841 328.631 533.324C323.733 530.493 312.908 525.524 308.009 528.356C303.11 531.187 300.781 536.333 305.679 539.165Z"
      fill="#333333"
    />
    <path
      d="M298.745 550.745C304.824 554.258 307.731 550.437 313.81 553.951C319.889 557.465 317.171 562.179 323.25 565.693C329.329 569.206 330.322 567.483 336.401 570.997C342.478 574.509 342.547 574.391 348.624 577.904C354.702 581.417 354.982 580.932 361.059 584.445C367.137 587.959 367.314 587.652 373.391 591.165C379.47 594.679 378.509 596.347 384.588 599.861C390.67 603.376 392.072 600.944 398.154 604.459C404.233 607.973 402.618 610.773 408.698 614.288C414.781 617.804 415.7 616.211 421.784 619.727C427.867 623.243 428.599 621.976 434.682 625.493C439.581 628.324 447.867 630.716 452.766 627.885C453.882 627.195 454.802 626.229 455.436 625.08C456.071 623.93 456.398 622.637 456.388 621.324C456.378 620.011 456.029 618.723 455.377 617.584C454.724 616.445 453.789 615.493 452.662 614.821C446.584 611.308 447.018 610.555 440.939 607.041C434.86 603.528 433.755 605.444 427.676 601.93C421.597 598.416 421.617 598.382 415.538 594.869C409.461 591.356 408.433 593.137 402.356 589.623C396.278 586.11 396.886 585.058 390.808 581.545C384.73 578.031 386.618 574.757 380.541 571.244C374.461 567.73 372.951 570.349 366.872 566.835C360.791 563.32 360.445 563.919 354.364 560.404C348.284 556.889 347.594 558.085 341.515 554.571C335.431 551.055 337.984 546.628 331.9 543.112C325.816 539.595 323.477 543.653 317.393 540.136C312.494 537.305 304.531 534.638 299.632 537.47C294.733 540.302 293.846 547.913 298.745 550.745Z"
      fill="#1A1A1A"
    />
    <path
      d="M288.951 556.163C294.094 559.135 295.153 557.298 300.296 560.271C305.439 563.244 302.721 567.957 307.864 570.93C313.007 573.903 314.001 572.18 319.145 575.154C324.289 578.127 324.358 578.008 329.502 580.982C334.643 583.954 334.923 583.469 340.064 586.44C345.207 589.413 345.384 589.106 350.527 592.08C355.67 595.053 354.708 596.72 359.851 599.693C364.994 602.666 366.397 600.234 371.54 603.207C376.685 606.181 375.07 608.981 380.215 611.955C385.361 614.929 386.28 613.336 391.426 616.311C396.571 619.285 397.302 618.017 402.447 620.991C407.595 623.967 406.216 626.359 411.364 629.335C416.512 632.31 416.616 632.131 421.764 635.107C424.681 636.909 427.978 638.004 431.392 638.303C434.602 638.303 437.9 635.359 440.349 633.943C445.248 631.111 445.267 626.185 440.369 623.353C435.226 620.38 434.199 622.162 429.056 619.189C423.912 616.216 424.52 615.163 419.377 612.19C414.234 609.217 416.121 605.942 410.978 602.969C405.834 599.995 404.323 602.614 399.179 599.641C394.038 596.669 393.693 597.269 388.552 594.297C383.408 591.324 382.719 592.52 377.576 589.547C372.432 586.574 374.985 582.147 369.842 579.174C364.699 576.201 362.358 580.259 357.215 577.286C352.071 574.313 353.608 571.646 348.464 568.672C343.317 565.697 341.136 569.481 335.989 566.506C330.844 563.532 331.163 562.98 326.018 560.006C320.87 557.03 320.419 557.812 315.271 554.836C310.124 551.859 311.965 548.665 306.817 545.689C304.368 544.274 300.849 546.946 297.639 546.946C294.429 546.946 290.527 543.607 288.077 545.023C283.179 547.855 284.052 553.331 288.951 556.163Z"
      fill="#333333"
    />
    <path
      d="M272.396 563.917C278.475 567.431 279.535 565.593 285.613 569.107C291.692 572.621 288.974 577.335 295.053 580.849C301.132 584.362 302.125 582.639 308.204 586.153C314.282 589.666 314.35 589.547 320.427 593.06C326.505 596.573 326.785 596.088 332.862 599.601C338.94 603.114 339.117 602.808 345.194 606.321C351.274 609.835 350.312 611.503 356.391 615.017C362.473 618.532 363.875 616.1 369.957 619.615C376.036 623.13 374.421 625.93 380.501 629.444C386.585 632.96 387.503 631.367 393.587 634.884C399.671 638.4 400.402 637.132 406.485 640.649C411.384 643.481 419.67 645.873 424.569 643.041C425.685 642.351 426.605 641.385 427.239 640.236C427.874 639.087 428.201 637.793 428.191 636.48C428.181 635.167 427.832 633.879 427.18 632.74C426.527 631.601 425.592 630.65 424.465 629.978C418.387 626.464 418.821 625.711 412.743 622.198C406.663 618.683 405.558 620.6 399.479 617.086C393.4 613.572 393.42 613.538 387.341 610.025C381.264 606.512 380.237 608.293 374.159 604.78C368.082 601.267 368.689 600.214 362.611 596.701C356.534 593.187 358.422 589.913 352.344 586.4C346.265 582.886 344.755 585.505 338.675 581.991C332.594 578.476 332.248 579.075 326.167 575.56C320.087 572.045 319.397 573.241 313.318 569.727C307.235 566.211 309.787 561.784 303.704 558.268C297.62 554.751 295.28 558.809 289.196 555.293C284.298 552.461 276.334 549.794 271.435 552.626C266.537 555.458 267.497 561.086 272.396 563.917Z"
      fill="#1A1A1A"
    />
    <path
      d="M447.203 565.162C441.907 569.621 438.971 570.977 435.688 569.073C435.032 568.821 434.422 568.459 433.885 568.005C430.544 571.423 427.426 575.053 424.55 578.872L423.043 577.737C425.968 573.854 429.139 570.162 432.537 566.686C432.059 566.15 431.682 565.532 431.423 564.863C429.6 561.753 431.141 558.488 437.203 553.368C444.783 547.109 456.61 543.1 471.457 541.525C464.259 550.267 456.126 558.193 447.203 565.162Z"
      fill="#6C9D00"
    />
    <path
      d="M403.966 539.402C411.47 543.084 413.631 546.597 412.325 550.017C412.185 550.716 411.919 551.384 411.538 551.986C415.473 554.874 419.042 558.232 422.164 561.987L420.711 563.189C417.687 559.554 414.231 556.302 410.42 553.506C409.961 554.046 409.419 554.508 408.813 554.875C405.97 557.273 403.321 556.347 397.538 553.477C393.897 551.768 390.102 549.473 385.349 547.074C379.738 544.198 373.746 542.138 367.553 540.957C373.195 537.054 379.937 535.059 386.792 535.264C392.745 535.404 398.601 536.814 403.966 539.402Z"
      fill="#6C9D00"
    />
    <path
      d="M444.224 533.383C438.844 537.833 435.951 539.303 432.599 537.523C431.937 537.288 431.318 536.945 430.767 536.507C427.539 540.017 424.63 543.807 422.075 547.835L420.481 546.827C423.096 542.704 426.074 538.823 429.378 535.231C428.884 534.71 428.489 534.103 428.213 533.44C426.322 530.358 427.897 526.84 434.379 521.46C442.483 514.936 455.371 511.06 470.842 511.472C465.505 516.356 460.645 520.4 456.157 523.97C451.728 527.464 447.655 530.677 444.224 533.383Z"
      fill="#6C9D00"
    />
    <path
      d="M409.253 501.73C417.053 507.405 419.056 511.584 417.354 514.918C417.112 515.583 416.751 516.198 416.288 516.733C419.72 520.173 422.858 523.896 425.669 527.862L424.125 528.945C421.377 525.069 418.31 521.431 414.955 518.068C414.417 518.537 413.806 518.915 413.147 519.186C409.863 520.984 406.93 519.186 400.483 514.474C396.436 511.59 392.241 508.094 387.039 504.307C381.003 499.826 374.446 496.094 367.512 493.193C375.102 491.011 383.14 490.931 390.771 492.961C397.411 494.74 403.673 497.711 409.253 501.73Z"
      fill="#6C9D00"
    />
    <path
      d="M445.862 493.506C442.67 497.234 440.499 498.335 437.082 496.846C436.392 496.712 435.73 496.464 435.12 496.114C432.465 500.069 430.205 504.277 428.376 508.676L426.632 507.958C428.511 503.441 430.83 499.121 433.556 495.06C432.99 494.619 432.509 494.077 432.137 493.462C429.647 490.918 430.166 488.087 434.111 483.457C439.049 477.777 448.221 473.903 459.538 472.433C456.156 480.156 451.537 487.274 445.862 493.506Z"
      fill="#6C9D00"
    />
    <path
      d="M413.992 459.681C418.199 464.492 418.733 467.632 416.205 470.332C415.849 470.945 415.383 471.488 414.832 471.933C417.61 475.927 420.015 480.168 422.018 484.602L420.298 485.374C418.343 481.047 415.996 476.909 413.285 473.011C412.674 473.377 412.008 473.64 411.312 473.79C408.016 475.248 405.98 474.025 402.565 470.1C398.232 465.329 395.111 457.604 387.407 448.423C392.86 447.813 398.373 448.818 403.26 451.316C407.334 453.398 410.976 456.237 413.992 459.681Z"
      fill="#6C9D00"
    />
    <path
      d="M411.66 596.933L418.596 595.476L416.74 547.985L416.707 547.145L416.88 546.616L426.941 516.018L418.632 480.592L418.586 480.389L418.589 480.269L419.803 443.664L421.007 480.063L432.278 515.426L432.477 516.047L432.272 516.88L424.569 547.958L429.681 594.65L435.932 597.926L429.368 598.546L427.884 604.249L423.796 600.032L417.48 602.263L418.1 599.288L411.66 596.933Z"
      fill="#784326"
    />
    <path
      d="M225.626 439.655C219.723 442.811 216.999 443.639 213.98 441.293C213.364 440.946 212.806 440.503 212.328 439.982C208.555 442.804 205.429 446.401 203.158 450.532L201.466 449.699C203.859 445.315 207.164 441.497 211.158 438.502C210.754 437.913 210.465 437.253 210.306 436.557C208.908 433.272 211.073 429.911 218.162 426.102C223.062 423.623 228.391 422.107 233.86 421.636C240.581 420.929 247.366 421.116 254.038 422.192C249.083 425.952 243.914 429.419 238.557 432.577C233.745 435.397 229.282 437.687 225.626 439.655Z"
      fill="#6C9D00"
    />
    <path
      d="M180.255 419.222C188.104 421.996 190.585 425.32 189.627 428.825C189.558 429.535 189.36 430.227 189.04 430.865C193.252 433.349 197.109 436.39 200.507 439.908L199.147 441.215C195.867 437.821 192.144 434.886 188.079 432.489C187.677 433.071 187.185 433.585 186.62 434.011C184.036 436.707 181.39 436.068 175.481 433.935C171.771 432.693 167.84 430.9 162.931 429.161C157.105 427.059 150.984 425.886 144.794 425.685C149.685 420.94 156.026 417.976 162.8 417.269C168.691 416.603 174.656 417.271 180.255 419.222Z"
      fill="#6C9D00"
    />
    <path
      d="M213.366 403.049C210.745 407.054 208.853 408.486 205.227 407.697C204.525 407.69 203.829 407.572 203.164 407.347C201.318 411.711 200.173 416.341 199.772 421.063L197.893 420.917C198.31 415.993 199.502 411.165 201.427 406.614C200.785 406.29 200.21 405.847 199.733 405.309C199.045 404.889 198.486 404.286 198.121 403.566C197.755 402.847 197.596 402.04 197.662 401.235C197.988 398.78 198.962 396.456 200.483 394.502C205.021 387.698 214.957 382.428 226.841 381.857C223.853 386.887 221.491 390.88 219.27 394.297C217.07 397.704 215.05 400.574 213.366 403.049Z"
      fill="#6C9D00"
    />
    <path
      d="M214.24 372.619C209.267 374.708 206.739 375.29 203.908 372.774C203.308 372.406 202.768 371.946 202.309 371.412C198.501 374.224 195.19 377.656 192.515 381.564L190.957 380.501C193.751 376.418 197.21 372.833 201.188 369.894C200.802 369.29 200.535 368.617 200.402 367.912C200.05 367.122 199.93 366.248 200.057 365.393C200.184 364.537 200.552 363.736 201.119 363.083C203.057 360.925 205.512 359.298 208.253 358.353C217.221 354.597 230.377 356.311 241.254 364.464C234.968 365.972 229.925 367.406 225.502 368.756C221.099 370.099 217.352 371.356 214.24 372.619Z"
      fill="#6C9D00"
    />
    <path
      d="M178.005 365.211C180.788 367.273 182.953 370.061 184.264 373.27C184.645 374.163 184.761 375.148 184.598 376.105C184.434 377.063 183.998 377.953 183.341 378.668C182.953 379.265 182.453 379.78 181.869 380.185C184.404 384.425 186.032 389.146 186.648 394.049L184.776 394.273C184.191 389.632 182.65 385.164 180.25 381.151C179.616 381.47 178.938 381.692 178.238 381.809C174.685 382.968 172.806 381.143 168.396 377.336C165.233 374.549 161.81 372.072 158.174 369.939C152.72 366.867 146.526 365.361 140.272 365.588C142.802 363.003 145.862 360.998 149.241 359.714C152.621 358.429 156.238 357.895 159.844 358.148C166.495 358.506 172.857 360.981 178.005 365.211Z"
      fill="#6C9D00"
    />
    <path
      d="M215.905 347.457C212.68 350.192 210.596 350.756 207.488 348.721C206.824 348.481 206.202 348.136 205.646 347.699C202.455 351.221 199.705 355.118 197.454 359.306L195.79 358.42C198.114 354.093 200.955 350.067 204.252 346.429C203.758 345.913 203.365 345.308 203.093 344.647C200.95 341.821 201.698 339.255 205.885 335.674C211.114 331.314 220.138 329.469 230.39 330.557C228.151 334.291 225.571 337.809 222.685 341.068C220.585 343.361 218.319 345.497 215.905 347.457Z"
      fill="#6C9D00"
    />
    <path
      d="M192.022 345.445C195.825 351.043 195.862 354.44 192.949 356.698C192.499 357.247 191.951 357.707 191.333 358.054C193.426 362.471 194.946 367.137 195.856 371.94L194.003 372.283C193.122 367.641 191.652 363.13 189.628 358.86C188.966 359.119 188.266 359.27 187.556 359.308C184.05 360.202 182.306 358.675 179.434 354.423C177.465 351.306 175.664 348.086 174.038 344.777C171.668 340.19 168.755 335.905 165.363 332.015C171.023 331.381 176.733 332.642 181.601 335.601C185.74 338.095 189.294 341.453 192.022 345.445Z"
      fill="#6C9D00"
    />
    <path
      d="M181.18 403.833L180.414 402.605L180.882 401.433L196.401 362.647L193.297 328.07L198.838 362.677L198.877 362.923L198.775 363.241L186.343 401.868L206.434 429.585L207.202 430.644L207.073 432.218L203.47 476.273L210.304 476.926L206.093 479.571L209.313 483.539L201.223 481.885L197.26 486.514L196.104 481.885L189.748 483.539L191.812 480.114L186.198 479.654C186.198 479.654 190.191 476.89 192.631 475.42L199.138 432.606L181.18 403.833Z"
      fill="#784326"
    />
    <path
      d="M215.113 370.685C215.029 370.183 215.001 369.672 215.031 369.164C215.025 368.077 215.017 366.339 215.005 363.734H214.927V361.847H215.005C215.017 359.242 215.025 357.584 215.03 356.576C214.997 356.12 215.025 355.661 215.111 355.212C215.159 355.406 215.181 355.605 215.177 355.804L215.249 357.421C215.293 358.789 215.354 360.684 215.422 362.791C215.366 366.211 215.31 368.185 215.257 369.303C215.204 370.421 215.155 370.685 215.113 370.685Z"
      fill="#6C9D00"
    />
    <path
      d="M364.944 528.001C360.893 530.205 358.589 530.398 355.951 527.761C355.354 527.386 354.822 526.918 354.374 526.373C350.495 529.131 346.908 532.279 343.67 535.769L342.282 534.49C345.611 530.903 349.298 527.666 353.286 524.831C352.913 524.22 352.657 523.543 352.532 522.837C351.083 519.601 352.458 517.202 357.526 514.428C363.828 511.057 373.351 510.854 383.887 513.562C378.493 519.478 372.075 524.369 364.944 528.001Z"
      fill="#6C9D00"
    />
    <path
      d="M319.956 504.197C327.344 505.715 329.781 508.666 329.029 512.201C329.035 512.913 328.904 513.62 328.644 514.283C333.087 516.393 337.111 519.291 340.524 522.835L339.162 524.14C335.908 520.761 332.071 517.999 327.835 515.987C327.487 516.605 327.045 517.166 326.527 517.649C324.348 520.621 322.111 520.446 317.254 519.435C314.231 518.87 310.956 517.806 306.699 517.003C301.519 515.953 296.184 515.929 290.995 516.933C293.729 511.491 298.48 507.335 304.233 505.354C309.278 503.576 314.705 503.177 319.956 504.197Z"
      fill="#6C9D00"
    />
    <path
      d="M334.074 479.29C338.321 485.619 338.47 489.383 335.678 491.756C335.236 492.305 334.696 492.768 334.085 493.12C336.218 497.495 337.935 502.061 339.216 506.757L337.396 507.253C336.147 502.67 334.471 498.215 332.39 493.947C331.727 494.214 331.025 494.373 330.311 494.417C326.719 495.253 324.829 493.29 321.386 488.121C317.03 481.798 313.86 472.576 305.027 461.68C311.392 461.958 317.545 464.054 322.76 467.719C327.224 470.83 331.063 474.756 334.074 479.29Z"
      fill="#6C9D00"
    />
    <path
      d="M375.3 479.033C370.647 481.145 368.124 481.24 365.658 478.461C365.091 478.044 364.597 477.536 364.195 476.959C360.101 479.396 356.243 482.209 352.669 485.361L351.418 483.949C355.081 480.718 359.035 477.835 363.231 475.336C362.908 474.696 362.704 474.001 362.631 473.287C361.475 469.894 363.221 467.54 368.905 464.947C375.972 461.81 386.14 462.085 397.405 465.4C390.808 471.093 383.346 475.695 375.3 479.033Z"
      fill="#6C9D00"
    />
    <path
      d="M369.362 501.871C364.248 502.157 361.848 501.362 360.498 497.906C360.113 497.317 359.827 496.668 359.651 495.986C354.971 496.84 350.393 498.182 345.991 499.99L345.269 498.247C349.794 496.387 354.501 495.007 359.313 494.129C359.232 493.416 359.284 492.694 359.465 491.999C359.576 488.407 362.112 486.791 368.513 486.421C376.433 486.052 386.08 490.184 395.201 497.86C390.405 499.28 385.507 500.328 380.55 500.996C376.306 501.551 372.552 501.732 369.362 501.871Z"
      fill="#6C9D00"
    />
    <path
      d="M337.13 448.831C342.797 452.026 344.316 454.909 342.75 458.255C342.602 458.95 342.327 459.611 341.939 460.205C345.824 463.153 349.36 466.537 352.476 470.289L351.021 471.49C347.998 467.85 344.568 464.568 340.8 461.709C340.332 462.244 339.781 462.701 339.167 463.061C336.49 465.473 334.235 464.972 329.839 462.475C324.321 459.542 318.889 453.415 308.144 448.139C312.916 445.338 318.454 444.128 323.958 444.686C328.587 445.146 333.071 446.557 337.13 448.831Z"
      fill="#6C9D00"
    />
    <path
      d="M362.496 441.401C360.414 445.568 358.715 447.138 355.006 446.678C354.308 446.742 353.604 446.694 352.921 446.535C351.52 451.068 350.774 455.779 350.707 460.524L348.822 460.511C348.891 455.581 349.665 450.687 351.121 445.977C350.449 445.717 349.833 445.333 349.303 444.846C346.209 443.126 345.879 440.118 348.685 434.459C352.215 427.527 360.889 421.447 372.055 418.942C370.217 424.328 368.62 428.56 367.009 432.183C365.413 435.793 363.836 438.818 362.496 441.401Z"
      fill="#6C9D00"
    />
    <path
      d="M332.182 509.435L332.998 508.175L351.672 479.245L344.25 444.662L344.211 444.479V444.385L344.602 407.293L346.628 444.149L357.09 479.102L357.384 480.075L356.797 481.136L340.534 510.634L353.567 554.745L358.875 554.33L356.317 557.306L363.501 560.092H354.337L354.585 563.668L350.455 561.437L343.522 565.654L344.018 562.016L338.156 560.941L342.889 557.103L332.571 511.16L332.182 509.435Z"
      fill="#784326"
    />
    <path
      d="M288.083 474.806C283.425 479.229 280.927 480.841 277.397 479.349C276.716 479.184 276.067 478.908 275.475 478.534C272.645 482.356 270.283 486.504 268.439 490.89L266.7 490.163C268.601 485.638 271.038 481.357 273.958 477.413C273.409 476.947 272.951 476.382 272.607 475.749C270.417 472.956 271.502 469.278 277.431 463.59C284.828 456.697 297.56 452.421 312.321 453.593C307.427 457.863 302.86 461.849 298.618 465.551C294.595 469.003 291.016 472.083 288.083 474.806Z"
      fill="#6C9D00"
    />
    <path
      d="M252.315 453.591C257.876 458.162 258.963 461.588 256.899 464.616C256.628 465.269 256.238 465.866 255.749 466.377C259.027 469.982 261.91 473.929 264.349 478.149L262.716 479.091C260.344 474.987 257.541 471.15 254.353 467.645C253.792 468.087 253.164 468.435 252.493 468.678C249.324 470.53 247.106 469.332 242.703 465.705C237.192 461.314 232.097 453.805 221.16 446.447C226.917 444.62 233.093 444.591 238.867 446.363C243.785 447.853 248.357 450.31 252.315 453.591Z"
      fill="#6C9D00"
    />
    <path
      d="M289.363 442.314C285.441 444.151 283.254 444.215 280.72 441.483C280.136 441.084 279.618 440.595 279.185 440.034C275.239 442.669 271.66 445.817 268.541 449.395L267.112 448.164C270.344 444.454 274.054 441.191 278.146 438.46C277.794 437.839 277.562 437.157 277.465 436.45C276.11 433.179 277.562 430.782 282.797 428.31C289.287 425.331 298.975 425.955 308.743 430.239C305.304 433.07 301.668 435.653 297.862 437.968C295.116 439.581 292.278 441.032 289.363 442.314Z"
      fill="#6C9D00"
    />
    <path
      d="M261.248 412.76C266.424 417.573 267.181 421.06 264.812 423.861C264.484 424.489 264.04 425.049 263.504 425.513C266.447 429.426 268.815 433.741 270.534 438.326L268.766 438.981C267.11 434.566 264.829 430.412 261.995 426.644C261.397 427.03 260.741 427.32 260.053 427.502C256.77 429.08 254.781 427.874 250.97 424.315C248.569 422.125 246.296 419.317 243.295 416.096C239.753 412.179 235.673 408.786 231.177 406.018C236.634 403.804 242.688 403.554 248.307 405.31C253.127 406.785 257.55 409.332 261.248 412.76Z"
      fill="#6C9D00"
    />
    <path
      d="M292.218 407.667C288.928 410.258 286.885 410.824 283.756 408.802C283.091 408.559 282.468 408.214 281.909 407.779C278.751 411.309 276.169 415.314 274.255 419.647L272.524 418.9C274.519 414.377 277.214 410.197 280.509 406.515C280.013 406.002 279.62 405.399 279.352 404.737C277.231 401.912 278.056 399.168 282.657 395.514C288.366 391.079 298.243 389.596 308.525 392.338C304.904 396.331 302.028 399.349 299.361 401.779C296.717 404.204 294.302 406.081 292.218 407.667Z"
      fill="#6C9D00"
    />
    <path
      d="M262.984 377.699C267.887 381.498 268.751 384.572 266.644 387.518C266.38 388.181 265.995 388.789 265.508 389.309C268.835 392.904 271.623 396.964 273.784 401.361L272.087 402.182C270.007 397.954 267.325 394.051 264.126 390.594C263.576 391.039 262.958 391.393 262.297 391.644C259.244 393.646 257.322 392.892 253.762 390.118C251.254 388.014 248.897 385.736 246.708 383.301C243.495 379.899 239.859 376.924 235.889 374.45C240.523 371.938 245.897 371.151 251.056 372.227C255.395 373.121 259.474 374.993 262.984 377.699Z"
      fill="#6C9D00"
    />
    <path
      d="M261.52 510.758L261.592 510.69L259.807 465.034L259.771 464.194L259.948 463.665L270.126 432.711L271.558 395.463L262.871 360.713L273.943 395.002L273.979 395.12L273.989 395.335L275.444 433.188L275.454 433.465L275.339 433.929L267.637 465.008L272.452 508.988L277.62 509.657L273.656 512.525L277.62 517.826L268.785 515.511L264.368 521.298L263.627 515.099L256.568 515.471L261.52 510.758Z"
      fill="#784326"
    />
    <path
      d="M70.2508 535.395C75.3936 538.368 76.453 536.53 81.5961 539.503C86.7392 542.475 84.0204 547.19 89.1635 550.163C94.3067 553.136 95.3009 551.412 100.444 554.385C105.589 557.359 105.658 557.241 110.801 560.214C115.943 563.186 116.222 562.701 121.363 565.673C126.507 568.646 126.683 568.339 131.826 571.312C136.969 574.285 136.008 575.952 141.151 578.925C146.294 581.898 147.697 579.466 152.84 582.439C157.984 585.413 156.37 588.213 161.514 591.187C166.661 594.162 167.58 592.568 172.726 595.543C177.871 598.517 178.602 597.249 183.746 600.223C188.894 603.199 187.515 605.591 192.664 608.566C197.812 611.542 197.915 611.363 203.064 614.338C205.98 616.142 209.277 617.236 212.692 617.535C215.902 617.535 219.199 614.591 221.649 613.175C226.547 610.344 226.567 605.417 221.668 602.585C216.525 599.612 215.498 601.393 210.355 598.421C205.213 595.448 205.82 594.395 200.677 591.422C195.533 588.449 197.422 585.174 192.278 582.201C187.134 579.227 185.623 581.847 180.479 578.873C175.338 575.901 174.992 576.5 169.851 573.529C164.708 570.556 164.019 571.752 158.875 568.779C153.732 565.806 156.285 561.38 151.142 558.406C145.998 555.433 143.658 559.491 138.515 556.519C133.371 553.545 134.908 550.878 129.764 547.904C124.617 544.929 122.435 548.713 117.289 545.738C112.144 542.765 112.462 542.212 107.318 539.238C102.17 536.263 101.719 537.044 96.571 534.068C91.4227 531.092 93.265 527.898 88.1166 524.921C85.6672 523.506 82.1493 526.179 78.9389 526.179C75.7286 526.179 71.8263 522.84 69.3772 524.255C64.4784 527.087 65.352 532.563 70.2508 535.395Z"
      fill="#333333"
    />
    <path
      d="M58.6274 543.744C64.7061 547.258 65.7654 545.42 71.8442 548.934C77.9237 552.448 75.2049 557.162 81.2843 560.676C87.363 564.19 88.3565 562.467 94.4351 565.98C100.513 569.493 100.581 569.374 106.658 572.888C112.736 576.401 113.016 575.916 119.094 579.429C125.171 582.942 125.348 582.635 131.426 586.149C137.505 589.662 136.544 591.33 142.623 594.844C148.704 598.36 150.106 595.928 156.187 599.443C162.267 602.957 160.653 605.757 166.732 609.271C172.816 612.788 173.735 611.194 179.818 614.711C185.902 618.227 186.633 616.96 192.717 620.476C197.615 623.308 205.901 625.7 210.8 622.868C211.916 622.178 212.836 621.212 213.47 620.063C214.105 618.914 214.433 617.62 214.422 616.307C214.412 614.994 214.064 613.706 213.411 612.567C212.758 611.428 211.823 610.477 210.697 609.805C204.618 606.291 205.052 605.539 198.973 602.025C192.894 598.511 191.789 600.427 185.71 596.913C179.631 593.4 179.651 593.366 173.573 589.852C167.495 586.339 166.468 588.12 160.39 584.607C154.313 581.094 154.92 580.041 148.842 576.528C142.765 573.015 144.653 569.741 138.575 566.228C132.496 562.713 130.986 565.333 124.906 561.818C118.825 558.303 118.479 558.902 112.397 555.387C106.318 551.873 105.629 553.069 99.5492 549.555C93.4653 546.038 96.0184 541.611 89.9345 538.095C83.8506 534.579 81.5109 538.636 75.4274 535.119C70.5285 532.288 62.565 529.622 57.6661 532.453C52.7672 535.285 53.729 540.913 58.6274 543.744Z"
      fill="#1A1A1A"
    />
    <path
      d="M37.4731 548.456C43.5518 551.969 51.7632 551.183 57.8414 554.696C63.9209 558.21 59.8433 565.281 65.9228 568.795C72.001 572.309 73.4918 569.724 79.5701 573.238C85.6475 576.751 85.7504 576.573 91.8279 580.086C97.9053 583.599 98.3249 582.871 104.403 586.384C110.481 589.898 110.745 589.438 116.823 592.951C122.903 596.465 121.46 598.966 127.54 602.48C133.621 605.996 135.724 602.348 141.806 605.863C147.885 609.377 145.463 613.577 151.543 617.091C157.626 620.608 159.005 618.218 165.089 621.734C171.173 625.251 172.269 623.349 178.352 626.866C183.251 629.697 191.709 633.285 196.608 630.453C197.737 629.61 198.652 628.512 199.276 627.248C199.901 625.984 200.219 624.59 200.204 623.18C200.189 621.77 199.841 620.383 199.189 619.133C198.537 617.883 197.6 616.805 196.453 615.986C190.374 612.473 191.025 611.344 184.947 607.83C178.867 604.316 177.21 607.19 171.131 603.676C165.052 600.162 165.081 600.112 159.003 596.598C152.925 593.085 151.385 595.757 145.307 592.244C139.23 588.731 140.14 587.151 134.063 583.638C127.985 580.125 130.817 575.213 124.74 571.7C118.66 568.186 116.395 572.115 110.316 568.601C104.234 565.086 103.716 565.984 97.6346 562.469C91.5551 558.955 90.5204 560.749 84.4408 557.235C78.3574 553.718 82.1865 547.078 76.1027 543.562C70.0189 540.045 66.509 546.132 60.4251 542.616C55.5267 539.784 44.7018 534.815 39.8029 537.647C34.904 540.479 32.5747 545.624 37.4731 548.456Z"
      fill="#333333"
    />
    <path
      d="M30.5388 560.037C36.6175 563.55 39.525 559.729 45.6032 563.242C51.6826 566.757 48.9643 571.471 55.0438 574.985C61.122 578.498 62.1159 576.775 68.1941 580.289C74.2716 583.802 74.3404 583.683 80.4178 587.196C86.4953 590.709 86.7752 590.224 92.8526 593.737C98.93 597.25 99.1075 596.944 105.185 600.457C111.264 603.971 110.302 605.639 116.382 609.153C122.463 612.668 123.866 610.236 129.947 613.751C136.027 617.266 134.412 620.065 140.491 623.579C146.575 627.096 147.494 625.503 153.577 629.019C159.661 632.536 160.392 631.268 166.476 634.785C171.374 637.616 179.661 640.008 184.559 637.177C185.675 636.487 186.595 635.521 187.23 634.372C187.864 633.223 188.192 631.929 188.182 630.616C188.171 629.303 187.823 628.015 187.17 626.876C186.518 625.737 185.583 624.785 184.456 624.113C178.377 620.6 178.812 619.847 172.733 616.334C166.654 612.819 165.549 614.736 159.469 611.222C153.391 607.708 153.41 607.674 147.332 604.161C141.254 600.648 140.227 602.429 134.149 598.916C128.072 595.403 128.679 594.35 122.602 590.837C116.524 587.323 118.412 584.049 112.334 580.536C106.255 577.022 104.745 579.641 98.6656 576.127C92.5842 572.612 92.2387 573.211 86.1571 569.696C80.0776 566.181 79.388 567.377 73.3089 563.863C67.225 560.347 69.7777 555.92 63.6938 552.403C57.6099 548.887 55.2702 552.945 49.1867 549.428C44.2878 546.597 36.3242 543.93 31.4253 546.762C26.5264 549.593 25.6404 557.205 30.5388 560.037Z"
      fill="#1A1A1A"
    />
    <path
      d="M20.745 565.453C25.8882 568.426 26.9479 566.589 32.0907 569.561C37.2334 572.534 34.5154 577.248 39.6582 580.221C44.8017 583.194 45.7955 581.471 50.9391 584.444C56.0833 587.418 56.1517 587.299 61.2966 590.273C66.4372 593.244 66.7167 592.759 71.8577 595.731C77.0008 598.704 77.1778 598.397 82.3209 601.37C87.464 604.343 86.5026 606.011 91.6457 608.984C96.7888 611.956 98.1913 609.525 103.334 612.498C108.479 615.472 106.864 618.272 112.009 621.245C117.155 624.22 118.074 622.627 123.221 625.602C128.365 628.575 129.096 627.307 134.241 630.281C139.39 633.258 138.01 635.649 143.159 638.625C148.307 641.602 148.411 641.421 153.558 644.397C156.475 646.2 159.772 647.295 163.187 647.594C166.397 647.594 169.694 644.649 172.143 643.234C177.042 640.402 177.061 635.476 172.163 632.644C167.02 629.671 165.993 631.452 160.85 628.479C155.707 625.507 156.314 624.453 151.171 621.481C146.028 618.508 147.916 615.233 142.773 612.26C137.628 609.286 136.118 611.905 130.973 608.931C125.832 605.96 125.487 606.559 120.346 603.588C115.203 600.615 114.513 601.811 109.37 598.838C104.227 595.865 106.779 591.438 101.636 588.465C96.4931 585.492 94.153 589.55 89.0095 586.578C83.8648 583.603 85.403 580.937 80.2583 577.963C75.1119 574.988 72.9302 578.772 67.7834 575.797C62.6391 572.824 62.9575 572.271 57.8122 569.297C52.6644 566.321 52.2136 567.103 47.0656 564.127C41.9176 561.151 43.7596 557.956 38.6112 554.981C36.1617 553.565 32.6435 556.238 29.4335 556.238C26.223 556.238 22.3208 552.899 19.8718 554.314C14.9729 557.145 15.8465 562.622 20.745 565.453Z"
      fill="#333333"
    />
    <path
      d="M4.19011 573.208C10.2688 576.722 11.3284 574.884 17.407 578.398C23.486 581.912 20.7677 586.626 26.8472 590.14C32.9258 593.654 33.9192 591.931 39.998 595.444C46.0753 598.957 46.1439 598.838 52.2217 602.352C58.2995 605.865 58.5785 605.38 64.6563 608.893C70.7342 612.406 70.9108 612.099 76.9886 615.613C83.0677 619.126 82.1062 620.794 88.1853 624.308C94.2668 627.823 95.6691 625.392 101.75 628.907C107.83 632.421 106.216 635.221 112.295 638.735C118.379 642.252 119.297 640.658 125.381 644.175C131.465 647.692 132.196 646.424 138.279 649.94C143.178 652.772 151.464 655.164 156.363 652.332C157.479 651.642 158.399 650.676 159.033 649.527C159.668 648.378 159.995 647.084 159.985 645.771C159.975 644.458 159.626 643.17 158.974 642.031C158.321 640.892 157.386 639.941 156.26 639.269C150.181 635.756 150.615 635.003 144.537 631.489C138.457 627.975 137.352 629.891 131.273 626.377C125.194 622.863 125.214 622.83 119.135 619.316C113.057 615.803 112.031 617.584 105.953 614.071C99.8757 610.559 100.483 609.505 94.4049 605.992C88.327 602.479 90.2156 599.204 84.1382 595.692C78.0587 592.177 76.5485 594.796 70.4689 591.282C64.3874 587.767 64.0421 588.367 57.9608 584.851C51.8814 581.337 51.1917 582.533 45.1122 579.018C39.0287 575.502 41.581 571.075 35.4975 567.559C29.414 564.043 27.0739 568.1 20.9898 564.584C16.0914 561.752 8.12743 559.085 3.22887 561.917C-1.66969 564.749 -0.708649 570.377 4.19011 573.208Z"
      fill="#1A1A1A"
    />
    <path
      d="M206.137 609.351C211.28 612.323 212.34 610.486 217.482 613.459C222.625 616.432 219.907 621.146 225.05 624.119C230.194 627.092 231.188 625.368 236.331 628.342C241.476 631.315 241.544 631.197 246.688 634.17C251.829 637.142 252.109 636.657 257.25 639.629C262.393 642.602 262.57 642.295 267.713 645.268C272.856 648.241 271.894 649.908 277.038 652.881C282.181 655.854 283.583 653.422 288.727 656.395C293.871 659.369 292.256 662.169 297.401 665.143C302.547 668.118 303.466 666.524 308.613 669.499C313.757 672.473 314.488 671.205 319.633 674.179C324.781 677.155 323.402 679.547 328.551 682.523C333.698 685.499 333.802 685.319 338.951 688.295C341.867 690.098 345.164 691.192 348.579 691.491C351.789 691.491 355.086 688.547 357.535 687.131C362.434 684.3 362.454 679.373 357.555 676.541C352.412 673.568 351.385 675.35 346.242 672.377C341.099 669.404 341.706 668.351 336.563 665.378C331.42 662.405 333.308 659.131 328.164 656.157C323.02 653.183 321.51 655.803 316.365 652.829C311.224 649.857 310.879 650.457 305.738 647.485C300.595 644.512 299.905 645.708 294.762 642.735C289.619 639.762 292.171 635.336 287.028 632.363C281.885 629.39 279.545 633.448 274.402 630.475C269.257 627.501 270.795 624.834 265.65 621.86C260.504 618.886 258.322 622.669 253.175 619.695C248.031 616.721 248.349 616.169 243.204 613.195C238.056 610.219 237.606 611 232.457 608.024C227.309 605.048 229.151 601.854 224.003 598.878C221.554 597.462 218.036 600.135 214.825 600.135C211.615 600.135 207.713 596.796 205.263 598.212C200.365 601.043 201.238 606.519 206.137 609.351Z"
      fill="#333333"
    />
    <path
      d="M194.514 617.7C200.593 621.214 201.652 619.376 207.731 622.89C213.81 626.404 211.092 631.118 217.171 634.632C223.249 638.146 224.243 636.422 230.322 639.936C236.399 643.449 236.468 643.33 242.545 646.843C248.623 650.356 248.903 649.871 254.98 653.385C261.058 656.898 261.235 656.591 267.312 660.104C273.392 663.618 272.43 665.286 278.509 668.8C284.59 672.315 285.993 669.883 292.074 673.398C298.154 676.913 296.539 679.713 302.619 683.227C308.702 686.743 309.621 685.15 315.704 688.667C321.788 692.183 322.52 690.915 328.603 694.432C333.501 697.264 341.788 699.656 346.687 696.824C347.803 696.134 348.722 695.168 349.357 694.019C349.991 692.87 350.319 691.576 350.309 690.263C350.299 688.95 349.95 687.662 349.298 686.523C348.645 685.384 347.71 684.433 346.583 683.761C340.505 680.247 340.939 679.495 334.86 675.981C328.781 672.467 327.676 674.383 321.596 670.869C315.518 667.356 315.537 667.321 309.459 663.808C303.382 660.295 302.354 662.076 296.277 658.563C290.199 655.05 290.806 653.997 284.729 650.484C278.651 646.971 280.539 643.696 274.462 640.183C268.383 636.669 266.872 639.288 260.793 635.774C254.711 632.259 254.366 632.858 248.284 629.343C242.205 625.828 241.515 627.024 235.436 623.51C229.352 619.994 231.905 615.567 225.821 612.051C219.737 608.534 217.397 612.592 211.314 609.075C206.415 606.244 198.452 603.577 193.553 606.409C188.654 609.24 189.615 614.868 194.514 617.7Z"
      fill="#1A1A1A"
    />
    <path
      d="M173.36 622.412C179.438 625.925 187.65 625.138 193.728 628.652C199.808 632.166 195.73 639.237 201.809 642.751C207.888 646.265 209.378 643.68 215.457 647.193C221.534 650.706 221.637 650.528 227.715 654.041C233.792 657.554 234.212 656.827 240.289 660.34C246.367 663.853 246.632 663.393 252.71 666.906C258.789 670.42 257.347 672.922 263.426 676.436C269.508 679.951 271.611 676.303 277.693 679.819C283.772 683.333 281.35 687.532 287.429 691.046C293.513 694.563 294.891 692.173 300.975 695.69C307.059 699.207 308.155 697.305 314.239 700.822C319.138 703.653 327.596 707.241 332.495 704.409C333.624 703.566 334.538 702.468 335.163 701.204C335.788 699.94 336.106 698.546 336.091 697.136C336.076 695.726 335.728 694.339 335.076 693.089C334.424 691.839 333.486 690.76 332.339 689.942C326.261 686.428 326.912 685.299 320.833 681.785C314.754 678.271 313.097 681.146 307.017 677.631C300.939 674.118 300.968 674.068 294.89 670.554C288.812 667.041 287.271 669.712 281.194 666.199C275.116 662.686 276.027 661.107 269.949 657.594C263.872 654.081 266.704 649.169 260.626 645.656C254.547 642.142 252.281 646.07 246.202 642.556C240.121 639.041 239.603 639.94 233.521 636.425C227.442 632.91 226.407 634.704 220.328 631.19C214.244 627.674 218.073 621.034 211.99 617.517C205.906 614 202.396 620.088 196.312 616.571C191.413 613.74 180.588 608.77 175.69 611.602C170.791 614.434 168.461 619.58 173.36 622.412Z"
      fill="#333333"
    />
    <path
      d="M166.426 633.991C172.504 637.505 175.411 633.683 181.49 637.197C187.569 640.711 184.851 645.425 190.93 648.939C197.008 652.453 198.002 650.729 204.081 654.243C210.159 657.756 210.227 657.637 216.304 661.15C222.382 664.664 222.662 664.179 228.739 667.692C234.817 671.205 234.994 670.898 241.072 674.411C247.151 677.926 246.189 679.593 252.268 683.107C258.35 686.623 259.752 684.19 265.834 687.706C271.913 691.22 270.299 694.02 276.378 697.534C282.462 701.05 283.38 699.457 289.464 702.974C295.548 706.49 296.279 705.222 302.362 708.739C307.261 711.571 315.547 713.963 320.446 711.131C321.562 710.441 322.482 709.475 323.116 708.326C323.751 707.177 324.079 705.883 324.068 704.57C324.058 703.257 323.709 701.969 323.057 700.83C322.404 699.691 321.469 698.74 320.343 698.068C314.264 694.554 314.698 693.802 308.62 690.288C302.54 686.774 301.435 688.69 295.356 685.176C289.277 681.662 289.297 681.629 283.219 678.115C277.141 674.602 276.114 676.383 270.036 672.87C263.959 669.357 264.566 668.304 258.488 664.791C252.411 661.277 254.299 658.004 248.221 654.49C242.142 650.976 240.631 653.595 234.552 650.081C228.471 646.566 228.125 647.165 222.043 643.65C215.964 640.135 215.275 641.331 209.195 637.817C203.111 634.301 205.664 629.874 199.58 626.358C193.496 622.841 191.157 626.899 185.073 623.383C180.174 620.551 172.211 617.884 167.312 620.716C162.414 623.548 161.527 631.16 166.426 633.991Z"
      fill="#1A1A1A"
    />
    <path
      d="M156.632 639.409C161.775 642.382 162.834 640.544 167.977 643.517C173.12 646.489 170.402 651.204 175.545 654.176C180.688 657.15 181.682 655.427 186.826 658.4C191.97 661.373 192.038 661.255 197.183 664.228C202.324 667.2 202.603 666.715 207.744 669.687C212.887 672.66 213.064 672.353 218.208 675.326C223.351 678.299 222.389 679.967 227.532 682.939C232.675 685.912 234.078 683.48 239.221 686.453C244.366 689.427 242.751 692.227 247.895 695.2C253.042 698.175 253.961 696.582 259.107 699.557C264.252 702.531 264.983 701.263 270.127 704.237C275.276 707.213 273.896 709.605 279.044 712.581C284.193 715.556 284.297 715.377 289.445 718.353C292.361 720.155 295.658 721.25 299.073 721.549C302.283 721.549 305.58 718.605 308.029 717.189C312.928 714.358 312.947 709.431 308.049 706.599C302.906 703.626 301.879 705.407 296.736 702.435C291.593 699.462 292.201 698.409 287.057 695.436C281.914 692.463 283.802 689.188 278.659 686.215C273.514 683.242 272.004 685.861 266.859 682.887C261.719 679.915 261.373 680.515 256.232 677.543C251.089 674.57 250.399 675.766 245.256 672.793C240.113 669.82 242.666 665.393 237.522 662.421C232.379 659.448 230.039 663.506 224.896 660.533C219.751 657.559 221.289 654.892 216.144 651.918C210.998 648.943 208.816 652.727 203.67 649.752C198.525 646.779 198.843 646.226 193.699 643.253C188.551 640.276 188.1 641.058 182.952 638.082C177.803 635.106 179.646 631.911 174.497 628.936C172.048 627.52 168.53 630.193 165.32 630.193C162.11 630.193 158.207 626.854 155.758 628.269C150.86 631.101 151.733 636.577 156.632 639.409Z"
      fill="#333333"
    />
    <path
      d="M140.076 647.164C146.155 650.678 147.215 648.84 153.293 652.354C159.373 655.868 156.654 660.582 162.734 664.096C168.812 667.609 169.806 665.886 175.884 669.4C181.962 672.913 182.03 672.794 188.108 676.307C194.185 679.82 194.465 679.335 200.543 682.848C206.62 686.362 206.797 686.055 212.875 689.568C218.954 693.082 217.992 694.75 224.071 698.264C230.153 701.779 231.555 699.347 237.637 702.862C243.716 706.377 242.102 709.176 248.181 712.69C254.265 716.207 255.184 714.614 261.267 718.13C267.351 721.647 268.082 720.379 274.166 723.896C279.064 726.727 287.351 729.119 292.249 726.288C293.365 725.598 294.285 724.632 294.92 723.483C295.554 722.333 295.882 721.04 295.871 719.727C295.861 718.414 295.513 717.126 294.86 715.987C294.207 714.848 293.272 713.896 292.146 713.224C286.067 709.711 286.501 708.958 280.423 705.445C274.343 701.931 273.239 703.847 267.159 700.333C261.08 696.819 261.1 696.785 255.022 693.272C248.944 689.759 247.917 691.54 241.839 688.027C235.761 684.514 236.369 683.461 230.291 679.948C224.214 676.435 226.102 673.16 220.024 669.647C213.945 666.133 212.435 668.752 206.356 665.238C200.274 661.723 199.929 662.322 193.847 658.807C187.768 655.292 187.078 656.488 180.998 652.974C174.915 649.457 177.468 645.031 171.384 641.514C165.301 637.998 162.96 642.056 156.877 638.539C151.978 635.707 144.014 633.041 139.116 635.872C134.217 638.704 135.178 644.332 140.076 647.164Z"
      fill="#1A1A1A"
    />
    <path
      d="M227.713 608.067C222.417 612.526 219.48 613.882 216.198 611.979C215.541 611.726 214.932 611.365 214.395 610.91C211.054 614.328 207.936 617.958 205.06 621.777L203.553 620.643C206.478 616.759 209.649 613.067 213.046 609.591C212.569 609.055 212.192 608.438 211.933 607.768C210.11 604.658 211.651 601.393 217.712 596.273C225.293 590.014 237.119 586.006 251.967 584.431C244.769 593.173 236.636 601.099 227.713 608.067Z"
      fill="#6C9D00"
    />
    <path
      d="M184.476 582.306C191.98 585.989 194.141 589.502 192.834 592.922C192.695 593.621 192.428 594.289 192.048 594.891C195.983 597.779 199.552 601.137 202.674 604.892L201.221 606.094C198.197 602.459 194.741 599.207 190.93 596.41C190.471 596.951 189.928 597.413 189.322 597.78C186.479 600.178 183.83 599.252 178.048 596.382C174.407 594.673 170.611 592.378 165.859 589.979C160.248 587.102 154.255 585.042 148.063 583.862C153.705 579.958 160.446 577.964 167.301 578.169C173.255 578.309 179.111 579.719 184.476 582.306Z"
      fill="#6C9D00"
    />
    <path
      d="M224.734 576.288C219.353 580.737 216.46 582.207 213.108 580.428C212.446 580.192 211.827 579.849 211.277 579.412C208.049 582.921 205.14 586.712 202.584 590.739L200.99 589.731C203.606 585.609 206.584 581.728 209.888 578.135C209.394 577.614 208.999 577.007 208.722 576.344C206.831 573.262 208.406 569.744 214.888 564.364C222.992 557.84 235.88 553.964 251.352 554.377C246.014 559.26 241.155 563.305 236.667 566.874C232.237 570.369 228.164 573.582 224.734 576.288Z"
      fill="#6C9D00"
    />
    <path
      d="M189.763 544.635C197.563 550.31 199.566 554.488 197.864 557.823C197.622 558.488 197.261 559.103 196.797 559.638C200.23 563.078 203.368 566.801 206.179 570.767L204.635 571.85C201.887 567.974 198.82 564.336 195.465 560.973C194.927 561.442 194.316 561.82 193.657 562.091C190.373 563.888 187.44 562.091 180.993 557.379C176.946 554.495 172.751 550.999 167.549 547.213C161.513 542.731 154.956 538.999 148.022 536.098C155.612 533.916 163.65 533.836 171.281 535.866C177.921 537.645 184.183 540.616 189.763 544.635Z"
      fill="#6C9D00"
    />
    <path
      d="M226.372 536.41C223.18 540.139 221.009 541.24 217.592 539.751C216.902 539.617 216.239 539.369 215.63 539.019C212.975 542.974 210.715 547.182 208.885 551.581L207.142 550.863C209.02 546.346 211.34 542.026 214.066 537.965C213.5 537.524 213.019 536.982 212.648 536.367C210.158 533.823 210.676 530.992 214.621 526.362C219.559 520.682 228.731 516.808 240.048 515.337C236.666 523.061 232.047 530.179 226.372 536.41Z"
      fill="#6C9D00"
    />
    <path
      d="M194.502 502.585C198.709 507.397 199.243 510.536 196.716 513.237C196.36 513.85 195.894 514.393 195.342 514.838C198.12 518.832 200.526 523.072 202.528 527.507L200.808 528.279C198.853 523.952 196.506 519.813 193.795 515.916C193.184 516.281 192.518 516.544 191.822 516.695C188.526 518.153 186.491 516.929 183.076 513.005C178.743 508.234 175.622 500.509 167.918 491.328C173.371 490.717 178.883 491.723 183.77 494.221C187.845 496.303 191.487 499.141 194.502 502.585Z"
      fill="#6C9D00"
    />
    <path
      d="M192.172 639.837L199.104 638.381L197.248 590.889L197.215 590.05L197.392 589.517L207.453 558.922L199.144 523.497L199.095 523.294L199.098 523.173L200.311 486.568L201.519 522.968L212.79 558.331L212.986 558.952L212.78 559.781L205.078 590.863L210.193 637.554L216.441 640.83L209.88 641.448L208.392 647.151L204.308 642.937L197.992 645.168L198.609 642.192L192.172 639.837Z"
      fill="#784326"
    />
    <path
      d="M311.361 637.812C305.459 640.968 302.735 641.796 299.716 639.449C299.1 639.102 298.542 638.66 298.064 638.138C294.291 640.961 291.164 644.558 288.894 648.689L287.202 647.856C289.595 643.472 292.9 639.653 296.894 636.659C296.489 636.07 296.201 635.41 296.042 634.713C294.644 631.429 296.809 628.068 303.898 624.258C308.798 621.78 314.127 620.264 319.596 619.793C326.317 619.086 333.102 619.273 339.774 620.349C334.819 624.109 329.65 627.576 324.293 630.734C319.481 633.554 315.017 635.844 311.361 637.812Z"
      fill="#6C9D00"
    />
    <path
      d="M265.991 617.379C273.84 620.152 276.32 623.477 275.362 626.981C275.294 627.692 275.095 628.384 274.776 629.022C278.987 631.505 282.844 634.547 286.243 638.064L284.883 639.372C281.603 635.978 277.88 633.042 273.815 630.645C273.413 631.228 272.92 631.742 272.355 632.168C269.771 634.864 267.125 634.224 261.217 632.091C257.506 630.85 253.576 629.058 248.666 627.317C242.84 625.216 236.72 624.043 230.53 623.842C235.421 619.097 241.762 616.133 248.536 615.426C254.427 614.76 260.392 615.428 265.991 617.379Z"
      fill="#6C9D00"
    />
    <path
      d="M299.101 601.207C296.48 605.211 294.589 606.643 290.963 605.854C290.261 605.848 289.564 605.729 288.899 605.504C287.054 609.869 285.909 614.498 285.508 619.22L283.628 619.075C284.045 614.15 285.238 609.323 287.162 604.771C286.521 604.447 285.946 604.004 285.469 603.466C284.78 603.046 284.222 602.443 283.856 601.724C283.49 601.004 283.331 600.198 283.397 599.393C283.724 596.938 284.698 594.613 286.219 592.659C290.757 585.856 300.692 580.586 312.577 580.015C309.589 585.044 307.227 589.038 305.005 592.455C302.806 595.861 300.786 598.731 299.101 601.207Z"
      fill="#6C9D00"
    />
    <path
      d="M299.976 570.776C295.003 572.865 292.475 573.447 289.644 570.931C289.043 570.563 288.504 570.103 288.045 569.568C284.236 572.381 280.926 575.813 278.251 579.721L276.693 578.658C279.487 574.575 282.945 570.989 286.924 568.051C286.537 567.447 286.271 566.774 286.138 566.069C285.786 565.279 285.666 564.405 285.793 563.55C285.92 562.694 286.288 561.893 286.854 561.24C288.793 559.082 291.248 557.455 293.989 556.51C302.957 552.754 316.113 554.468 326.989 562.621C320.703 564.129 315.661 565.563 311.238 566.913C306.835 568.256 303.088 569.513 299.976 570.776Z"
      fill="#6C9D00"
    />
    <path
      d="M263.741 563.369C266.524 565.431 268.689 568.219 269.999 571.428C270.381 572.321 270.497 573.305 270.333 574.263C270.169 575.22 269.733 576.11 269.076 576.825C268.689 577.422 268.189 577.937 267.605 578.343C270.14 582.582 271.767 587.303 272.383 592.206L270.512 592.431C269.927 587.79 268.385 583.321 265.985 579.308C265.352 579.628 264.674 579.849 263.974 579.966C260.42 581.125 258.542 579.3 254.131 575.493C250.968 572.706 247.546 570.229 243.91 568.096C238.456 565.024 232.261 563.519 226.008 563.745C228.537 561.16 231.598 559.156 234.977 557.871C238.356 556.587 241.974 556.053 245.58 556.306C252.23 556.664 258.593 559.138 263.741 563.369Z"
      fill="#6C9D00"
    />
    <path
      d="M301.641 545.614C298.416 548.349 296.332 548.913 293.224 546.878C292.56 546.638 291.938 546.293 291.382 545.856C288.191 549.377 285.44 553.275 283.19 557.462L281.525 556.577C283.849 552.25 286.691 548.224 289.988 544.586C289.494 544.069 289.101 543.465 288.829 542.803C286.686 539.977 287.434 537.412 291.621 533.831C296.849 529.47 305.874 527.626 316.125 528.713C313.886 532.447 311.307 535.966 308.421 539.225C306.321 541.518 304.055 543.654 301.641 545.614Z"
      fill="#6C9D00"
    />
    <path
      d="M277.757 543.602C281.561 549.2 281.597 552.597 278.685 554.855C278.235 555.404 277.687 555.864 277.069 556.211C279.162 560.628 280.682 565.294 281.592 570.097L279.738 570.44C278.858 565.797 277.388 561.286 275.364 557.017C274.702 557.275 274.002 557.427 273.292 557.465C269.786 558.359 268.041 556.831 265.17 552.58C263.201 549.463 261.4 546.243 259.773 542.934C257.403 538.347 254.491 534.062 251.099 530.171C256.758 529.538 262.468 530.799 267.336 533.758C271.475 536.252 275.03 539.609 277.757 543.602Z"
      fill="#6C9D00"
    />
    <path
      d="M266.915 601.99L266.149 600.762L266.618 599.591L282.137 560.805L279.033 526.228L284.574 560.835L284.613 561.081L284.511 561.398L272.079 600.027L292.17 627.742L292.937 628.802L292.809 630.376L289.205 674.431L296.039 675.084L291.829 677.729L295.049 681.696L286.958 680.043L282.995 684.672L281.84 680.043L275.483 681.696L277.547 678.272L271.934 677.811C271.934 677.811 275.927 675.048 278.366 673.578L284.873 630.764L266.915 601.99Z"
      fill="#784326"
    />
    <path
      d="M300.849 568.843C300.765 568.34 300.737 567.83 300.766 567.321C300.761 566.234 300.753 564.497 300.74 561.892H300.662V560.005H300.74C300.753 557.4 300.761 555.741 300.765 554.734C300.733 554.278 300.76 553.819 300.847 553.37C300.894 553.564 300.916 553.762 300.912 553.962C300.93 554.343 300.954 554.895 300.985 555.579C301.029 556.947 301.089 558.841 301.157 560.949C301.102 564.369 301.046 566.342 300.993 567.461C300.94 568.58 300.89 568.843 300.849 568.843Z"
      fill="#6C9D00"
    />
    <path
      d="M93.4481 536.493C89.3973 538.697 87.093 538.89 84.4542 536.253C83.8577 535.878 83.3254 535.41 82.8776 534.865C78.9985 537.623 75.4118 540.771 72.1738 544.261L70.7863 542.983C74.1148 539.395 77.802 536.158 81.7902 533.324C81.4167 532.712 81.1607 532.035 81.0353 531.329C79.587 528.094 80.962 525.694 86.0301 522.921C92.332 519.549 101.855 519.346 112.391 522.054C106.997 527.97 100.579 532.862 93.4481 536.493Z"
      fill="#6C9D00"
    />
    <path
      d="M48.4589 512.69C55.8474 514.207 58.2835 517.159 57.5322 520.694C57.5379 521.406 57.4073 522.113 57.1474 522.776C61.5898 524.886 65.6145 527.783 69.0273 531.328L67.6654 532.633C64.411 529.254 60.5735 526.492 56.3378 524.48C55.9894 525.098 55.5481 525.659 55.0293 526.142C52.8512 529.114 50.6136 528.939 45.7572 527.927C42.7338 527.363 39.459 526.299 35.2013 525.496C30.0217 524.446 24.6868 524.422 19.4981 525.426C22.2316 519.984 26.9827 515.828 32.736 513.846C37.7811 512.069 43.2084 511.669 48.4589 512.69Z"
      fill="#6C9D00"
    />
    <path
      d="M62.5775 487.784C66.8243 494.113 66.9733 497.876 64.1817 500.25C63.7391 500.799 63.199 501.261 62.5885 501.614C64.7209 505.989 66.4384 510.554 67.7185 515.251L65.8997 515.746C64.6506 511.164 62.9746 506.709 60.8939 502.44C60.2303 502.708 59.5279 502.867 58.8138 502.911C55.2226 503.746 53.333 501.783 49.8892 496.614C45.533 490.291 42.3629 481.07 33.5304 470.173C39.8957 470.452 46.0486 472.547 51.2633 476.212C55.7273 479.323 59.5657 483.249 62.5775 487.784Z"
      fill="#6C9D00"
    />
    <path
      d="M103.804 487.525C99.1506 489.638 96.6274 489.733 94.1614 486.954C93.5947 486.537 93.1004 486.029 92.6981 485.451C88.6045 487.889 84.7459 490.702 81.1722 493.854L79.9211 492.442C83.5839 489.211 87.5387 486.327 91.7346 483.829C91.4107 483.188 91.2071 482.494 91.1339 481.78C89.9781 478.387 91.7248 476.032 97.4082 473.44C104.475 470.302 114.643 470.577 125.909 473.893C119.312 479.585 111.85 484.187 103.804 487.525Z"
      fill="#6C9D00"
    />
    <path
      d="M97.865 510.364C92.7518 510.649 90.3517 509.855 89.0015 506.399C88.6168 505.809 88.3307 505.16 88.1546 504.478C83.4742 505.332 78.8959 506.674 74.4947 508.483L73.7718 506.739C78.2967 504.88 83.0038 503.499 87.816 502.622C87.7356 501.908 87.7874 501.186 87.9687 500.492C88.0793 496.899 90.6154 495.283 97.0168 494.914C104.937 494.545 114.584 498.677 123.705 506.353C118.908 507.773 114.01 508.821 109.053 509.488C104.809 510.043 101.055 510.225 97.865 510.364Z"
      fill="#6C9D00"
    />
    <path
      d="M65.6338 457.324C71.3005 460.519 72.8197 463.402 71.2528 466.748C71.1051 467.443 70.8304 468.104 70.4424 468.698C74.3275 471.646 77.8628 475.03 80.9794 478.783L79.5246 479.983C76.5009 476.343 73.0714 473.061 69.3028 470.202C68.8347 470.737 68.2836 471.194 67.6706 471.554C64.9931 473.967 62.7386 473.465 58.3426 470.968C52.8241 468.035 47.3922 461.908 36.6475 456.632C41.4194 453.831 46.9576 452.622 52.4612 453.179C57.0899 453.639 61.5742 455.05 65.6338 457.324Z"
      fill="#6C9D00"
    />
    <path
      d="M90.9995 449.894C88.9169 454.061 87.2184 455.63 83.5098 455.17C82.8118 455.234 82.108 455.186 81.4252 455.028C80.0238 459.561 79.2781 464.272 79.2106 469.016L77.3258 469.003C77.3944 464.073 78.1685 459.179 79.6245 454.469C78.953 454.21 78.3361 453.826 77.8061 453.338C74.7123 451.618 74.383 448.61 77.1888 442.952C80.7184 436.019 89.3923 429.94 100.559 427.435C98.7203 432.82 97.1238 437.052 95.5119 440.676C93.9168 444.285 92.3398 447.31 90.9995 449.894Z"
      fill="#6C9D00"
    />
    <path
      d="M60.6857 517.932L61.5013 516.668L80.175 487.739L72.7532 453.155L72.714 452.976V452.881L73.1055 415.786L75.1314 452.646L85.5971 487.595L85.8874 488.568L85.3002 489.633L69.0373 519.127L82.0705 563.238L87.3816 562.823L84.8206 565.799L92.0043 568.585H82.8404L83.0883 572.165L78.9582 569.931L72.0256 574.147L72.5215 570.509L66.659 569.434L71.3927 565.596L61.0739 519.653L60.6857 517.932Z"
      fill="#784326"
    />
    <path
      d="M152.614 557.53C147.957 561.952 145.459 563.565 141.929 562.073C141.248 561.908 140.599 561.632 140.007 561.258C137.177 565.08 134.815 569.228 132.971 573.613L131.231 572.886C133.133 568.361 135.57 564.081 138.49 560.137C137.941 559.67 137.483 559.106 137.138 558.472C134.948 555.68 136.034 552.001 141.963 546.314C149.36 539.421 162.091 535.145 176.852 536.317C171.959 540.587 167.392 544.573 163.15 548.275C159.127 551.727 155.548 554.807 152.614 557.53Z"
      fill="#6C9D00"
    />
    <path
      d="M116.847 536.315C122.408 540.886 123.495 544.313 121.431 547.341C121.16 547.994 120.77 548.59 120.281 549.101C123.559 552.707 126.442 556.653 128.881 560.874L127.247 561.815C124.876 557.712 122.072 553.875 118.885 550.369C118.324 550.811 117.696 551.16 117.025 551.402C113.855 553.254 111.638 552.057 107.234 548.43C101.724 544.039 96.6291 536.529 85.6921 529.171C91.449 527.345 97.6249 527.316 103.399 529.088C108.317 530.578 112.889 533.035 116.847 536.315Z"
      fill="#6C9D00"
    />
    <path
      d="M153.895 525.038C149.973 526.875 147.786 526.939 145.253 524.207C144.668 523.808 144.15 523.319 143.718 522.758C139.771 525.393 136.192 528.541 133.073 532.119L131.643 530.889C134.876 527.178 138.586 523.915 142.678 521.184C142.326 520.563 142.094 519.881 141.997 519.174C140.642 515.903 142.095 513.506 147.329 511.034C153.819 508.055 163.507 508.679 173.275 512.963C169.836 515.794 166.2 518.377 162.394 520.692C159.648 522.305 156.81 523.756 153.895 525.038Z"
      fill="#6C9D00"
    />
    <path
      d="M125.78 495.485C130.956 500.298 131.714 503.785 129.344 506.586C129.016 507.214 128.572 507.775 128.036 508.238C130.979 512.151 133.347 516.466 135.067 521.052L133.299 521.706C131.642 517.291 129.361 513.137 126.527 509.369C125.929 509.756 125.274 510.045 124.585 510.227C121.302 511.805 119.314 510.599 115.502 507.04C113.101 504.851 110.828 502.042 107.827 498.821C104.285 494.904 100.205 491.511 95.7094 488.743C101.166 486.529 107.22 486.279 112.84 488.035C117.659 489.51 122.083 492.057 125.78 495.485Z"
      fill="#6C9D00"
    />
    <path
      d="M156.75 490.39C153.46 492.982 151.417 493.548 148.288 491.526C147.623 491.282 147 490.937 146.441 490.503C143.283 494.032 140.7 498.037 138.787 502.371L137.056 501.624C139.051 497.101 141.746 492.921 145.041 489.238C144.545 488.726 144.152 488.122 143.885 487.46C141.763 484.636 142.588 481.891 147.189 478.238C152.898 473.803 162.776 472.319 173.058 475.061C169.436 479.055 166.56 482.072 163.893 484.502C161.249 486.928 158.834 488.805 156.75 490.39Z"
      fill="#6C9D00"
    />
    <path
      d="M127.515 460.422C132.419 464.221 133.282 467.296 131.175 470.242C130.911 470.905 130.526 471.512 130.039 472.034C133.366 475.628 136.154 479.688 138.316 484.085L136.618 484.906C134.538 480.678 131.857 476.775 128.658 473.318C128.108 473.762 127.49 474.117 126.829 474.368C123.776 476.37 121.854 475.616 118.293 472.842C115.786 470.738 113.429 468.46 111.24 466.025C108.027 462.623 104.39 459.648 100.42 457.174C105.055 454.662 110.428 453.875 115.588 454.951C119.927 455.845 124.005 457.716 127.515 460.422Z"
      fill="#6C9D00"
    />
    <path
      d="M126.054 593.483L126.122 593.414L124.338 547.758L124.305 546.919L124.478 546.39L134.66 515.436L136.089 478.187L127.401 443.438L138.474 477.723L138.513 477.844L138.519 478.06L139.974 515.912L139.984 516.187L139.87 516.651L132.167 547.732L136.986 591.712L142.15 592.382L138.19 595.25L142.15 600.551L133.319 598.235L128.902 604.023L128.158 597.824L121.101 598.196L126.054 593.483Z"
      fill="#784326"
    />
    <g class="drone-animation">
      <path
        style="mix-blend-mode: multiply"
        d="M257.14 147.478L438.051 520.683L57.8358 511.139L257.14 147.478Z"
        :fill="`url(#${prefix}paint0_linear_1598_11113)`"
      />
      <path
        d="M243.372 66.3368H246.367C246.947 64.9278 248.014 63.7748 249.373 63.0889C251.782 61.8656 254.445 61.228 257.146 61.228C259.847 61.228 262.51 61.8656 264.919 63.0889C266.278 63.7751 267.344 64.928 267.924 66.3368H270.92L266.761 70.6653C266.213 71.2165 265.594 71.6908 264.919 72.0753C262.51 73.2988 259.847 73.9365 257.146 73.9365C254.445 73.9365 251.781 73.2988 249.373 72.0753C248.699 71.6936 248.083 71.219 247.542 70.6653L243.372 66.3368Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M245.713 50.5107C245.812 50.4503 245.908 50.3891 246.01 50.3302C249.461 48.5775 253.276 47.6641 257.145 47.6641C261.015 47.6641 264.83 48.5775 268.28 50.3302C268.382 50.389 268.478 50.4503 268.577 50.5107H272.893V61.9202C272.765 63.3067 272.27 64.6339 271.459 65.7652C270.648 66.8966 269.551 67.7912 268.28 68.3569C264.83 70.1096 261.015 71.0231 257.145 71.0231C253.276 71.0231 249.461 70.1096 246.01 68.3569C244.739 67.7912 243.642 66.8967 242.831 65.7653C242.021 64.634 241.526 63.3067 241.398 61.9202V50.5107H245.713Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.145 59.6135C248.448 59.6135 241.398 55.538 241.398 50.5106C241.398 45.4832 248.448 41.4077 257.145 41.4077C265.842 41.4077 272.893 45.4832 272.893 50.5106C272.893 55.538 265.842 59.6135 257.145 59.6135Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M248.456 45.5962C248.531 45.5505 248.603 45.5042 248.681 45.4592C251.304 44.1269 254.204 43.4326 257.146 43.4326C260.087 43.4326 262.987 44.1269 265.61 45.4592C265.688 45.5042 265.761 45.5505 265.836 45.5962H269.116V50.138C269.019 51.1919 268.643 52.2008 268.026 53.0608C267.41 53.9207 266.576 54.6008 265.61 55.0309C262.987 56.3632 260.087 57.0575 257.146 57.0575C254.204 57.0575 251.304 56.3632 248.681 55.0309C247.715 54.6008 246.881 53.9207 246.265 53.0608C245.649 52.2008 245.273 51.1919 245.175 50.138V45.5962H248.456Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.146 52.515C250.534 52.515 245.175 49.4171 245.175 45.5956C245.175 41.7742 250.534 38.6763 257.146 38.6763C263.757 38.6763 269.116 41.7742 269.116 45.5956C269.116 49.4171 263.757 52.515 257.146 52.515Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M253.632 38.0051C254.7 37.4445 255.887 37.147 257.093 37.1374C258.3 37.1279 259.491 37.4064 260.568 37.95C261.04 37.9941 261.513 38.0125 261.986 38.0051V45.1858C261.947 45.6119 261.795 46.0199 261.546 46.3676C261.296 46.7154 260.959 46.9903 260.568 47.1641C259.508 47.7029 258.335 47.9837 257.146 47.9837C255.956 47.9837 254.783 47.7029 253.723 47.1641C253.332 46.9903 252.995 46.7153 252.746 46.3676C252.497 46.0198 252.345 45.6119 252.305 45.1858V38.0051H253.632Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M253.723 36.0271C253.309 36.1671 252.95 36.4333 252.696 36.7882C252.442 37.1431 252.305 37.5689 252.305 38.0057C252.305 38.4424 252.442 38.8682 252.696 39.2231C252.95 39.578 253.309 39.8442 253.723 39.9842C254.783 40.523 255.956 40.8039 257.146 40.8039C258.335 40.8039 259.508 40.523 260.568 39.9842C260.982 39.8443 261.341 39.5782 261.595 39.2233C261.85 38.8684 261.986 38.4426 261.986 38.0058C261.987 37.569 261.85 37.1432 261.595 36.7882C261.341 36.4333 260.982 36.1671 260.569 36.0271C259.508 35.4883 258.335 35.2075 257.146 35.2075C255.956 35.2075 254.784 35.4883 253.723 36.0271Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M252.545 31.8162C253.808 30.6787 255.447 30.0493 257.145 30.0493C258.844 30.0493 260.482 30.6787 261.745 31.8162C262.341 32.3558 262.818 33.0137 263.146 33.748C263.474 34.4824 263.646 35.277 263.65 36.0814V39.8435C263.597 40.4162 263.393 40.9644 263.058 41.4317C262.723 41.8991 262.27 42.2686 261.745 42.5023C260.32 43.2264 258.744 43.6037 257.145 43.6037C255.547 43.6037 253.971 43.2264 252.545 42.5023C252.02 42.2686 251.567 41.8991 251.232 41.4318C250.897 40.9645 250.693 40.4162 250.64 39.8435V36.0815C250.644 35.2771 250.816 34.4824 251.144 33.748C251.472 33.0137 251.949 32.3558 252.545 31.8162Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.146 61.9207C258.54 61.7715 259.95 61.9437 261.268 62.424C262.586 62.9044 263.777 63.6802 264.749 64.6922C264.749 64.6922 266.861 80.0657 268.06 89.2601C268.608 93.1709 268.926 97.1104 269.012 101.058C269.012 101.058 268.206 106.941 257.146 106.595C246.085 106.941 245.279 101.058 245.279 101.058C245.365 97.1104 245.683 93.1709 246.231 89.2601C247.43 80.0656 249.541 64.6922 249.541 64.6922C250.502 63.6638 251.691 62.8768 253.012 62.3952C254.333 61.9136 255.75 61.751 257.146 61.9207Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M269.012 101.058C268.926 97.1103 268.608 93.1708 268.06 89.26C266.861 80.0655 264.749 64.6925 264.749 64.6925L264.308 63.2664L262.771 63.1509C265.487 76.4568 264.937 91.3389 265.199 105.282C266.126 104.935 266.956 104.37 267.619 103.635C268.283 102.9 268.761 102.017 269.012 101.058Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M245.279 101.058C245.366 97.1104 245.683 93.1709 246.231 89.2601C247.43 80.0656 249.541 64.6926 249.541 64.6926L250.079 63.1509H251.474C248.758 76.4568 249.354 91.3389 249.093 105.282C248.166 104.935 247.335 104.371 246.672 103.636C246.008 102.901 245.53 102.017 245.279 101.058Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M223.982 124.21C224.268 124.035 224.547 123.857 224.843 123.686C242.683 113.374 271.608 113.374 289.448 123.686C289.744 123.857 290.022 124.035 290.309 124.21H302.828V142.358C302.828 149.116 298.367 155.874 289.448 161.03C271.608 171.343 242.683 171.343 224.843 161.03C215.923 155.874 211.463 149.116 211.463 142.358V124.21H223.982Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.145 150.616C231.916 150.616 211.463 138.793 211.463 124.209C211.463 109.626 231.916 97.8032 257.145 97.8032C282.375 97.8032 302.828 109.626 302.828 124.209C302.828 138.793 282.375 150.616 257.145 150.616Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.146 138.466C236.765 138.466 220.243 128.915 220.243 117.135C220.243 105.354 236.765 95.8037 257.146 95.8037C277.526 95.8037 294.048 105.354 294.048 117.135C294.048 128.915 277.526 138.466 257.146 138.466Z"
        fill="#8491A1"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M231.362 113.645L233.398 105.984H237.57C237.931 105.747 238.303 105.514 238.694 105.288C244.412 102.383 250.734 100.87 257.145 100.87C263.557 100.87 269.878 102.383 275.596 105.288C275.988 105.514 276.359 105.747 276.721 105.984H280.893L282.934 113.645C284.151 118.233 281.71 123.084 275.596 126.618C269.878 129.522 263.557 131.036 257.145 131.036C250.734 131.036 244.412 129.522 238.694 126.618C232.581 123.084 230.14 118.233 231.362 113.645Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.145 121.998C243.874 121.998 233.116 115.779 233.116 108.108C233.116 100.437 243.874 94.2188 257.145 94.2188C270.416 94.2188 281.174 100.437 281.174 108.108C281.174 115.779 270.416 121.998 257.145 121.998Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M301.713 128.261L301.926 119.264C301.926 119.264 334.296 121.599 340.905 123.13C350.253 124.348 359.664 125.017 369.09 125.133C369.265 126.813 369.477 128.224 369.708 129.387C369.798 129.859 369.89 130.284 369.983 130.682L301.636 131.356L301.713 128.261Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M301.636 131.356L369.983 130.683C370.182 132.199 370.746 133.645 371.625 134.897L371.062 140.009L346.278 141.255C332.074 143.912 301.271 146.515 301.271 146.515L301.547 134.897L301.636 131.356Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M371.625 131.036L301.547 134.897L301.713 128.262L369.707 129.387C370.564 133.881 371.625 131.036 371.625 131.036Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M398.611 140.008H395.615C395.036 138.6 393.969 137.447 392.61 136.761C390.201 135.537 387.538 134.899 384.837 134.899C382.136 134.899 379.473 135.537 377.064 136.761C375.705 137.446 374.638 138.599 374.059 140.008H371.063L375.222 144.337C375.769 144.888 376.389 145.362 377.064 145.747C379.473 146.97 382.136 147.608 384.837 147.608C387.538 147.608 390.201 146.97 392.61 145.747C393.284 145.365 393.9 144.891 394.441 144.337L398.611 140.008Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M396.269 124.182C396.17 124.121 396.074 124.06 395.972 124.001C392.521 122.248 388.706 121.335 384.837 121.335C380.967 121.335 377.152 122.248 373.702 124.001C373.599 124.06 373.503 124.121 373.405 124.182H369.089V135.591C369.218 136.978 369.712 138.305 370.523 139.436C371.334 140.568 372.431 141.462 373.702 142.028C377.152 143.781 380.967 144.694 384.837 144.694C388.706 144.694 392.521 143.781 395.972 142.028C397.243 141.462 398.34 140.568 399.151 139.436C399.961 138.305 400.456 136.978 400.584 135.591V124.182H396.269Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M395.972 117.745C402.122 121.3 402.122 127.063 395.972 130.618C392.521 132.371 388.706 133.284 384.837 133.284C380.967 133.284 377.152 132.371 373.701 130.618C367.552 127.063 367.552 121.3 373.701 117.745C377.152 115.993 380.967 115.079 384.837 115.079C388.706 115.079 392.521 115.993 395.972 117.745Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M393.527 119.268C393.452 119.222 393.379 119.175 393.302 119.13C390.679 117.798 387.779 117.104 384.837 117.104C381.896 117.104 378.996 117.798 376.373 119.13C376.295 119.175 376.222 119.222 376.147 119.268H372.867V123.809C372.964 124.863 373.34 125.872 373.956 126.732C374.573 127.592 375.407 128.272 376.373 128.702C378.996 130.035 381.896 130.729 384.837 130.729C387.779 130.729 390.679 130.035 393.302 128.702C394.268 128.272 395.102 127.592 395.718 126.732C396.334 125.872 396.71 124.863 396.808 123.809V119.268H393.527Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M393.302 114.375C397.976 117.077 397.976 121.458 393.302 124.161C390.679 125.493 387.779 126.187 384.837 126.187C381.896 126.187 378.996 125.493 376.373 124.161C371.698 121.458 371.698 117.077 376.373 114.375C378.996 113.042 381.896 112.348 384.837 112.348C387.779 112.348 390.679 113.042 393.302 114.375Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M388.351 111.677C387.283 111.116 386.096 110.818 384.89 110.809C383.683 110.799 382.492 111.078 381.414 111.621C380.943 111.666 380.47 111.684 379.996 111.677V118.857C380.036 119.283 380.188 119.691 380.437 120.039C380.686 120.387 381.024 120.662 381.414 120.836C382.475 121.375 383.648 121.655 384.837 121.655C386.027 121.655 387.199 121.375 388.26 120.836C388.651 120.662 388.988 120.387 389.237 120.039C389.486 119.691 389.638 119.283 389.678 118.857V111.677H388.351Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M388.26 109.698C388.673 109.838 389.032 110.104 389.287 110.459C389.541 110.814 389.678 111.24 389.678 111.677C389.678 112.114 389.541 112.54 389.287 112.895C389.032 113.25 388.673 113.516 388.26 113.656C387.199 114.194 386.026 114.475 384.837 114.475C383.648 114.475 382.475 114.194 381.414 113.656C381.001 113.516 380.642 113.25 380.387 112.895C380.133 112.54 379.996 112.114 379.996 111.677C379.996 111.24 380.133 110.814 380.387 110.459C380.642 110.104 381.001 109.838 381.414 109.698C382.475 109.16 383.648 108.879 384.837 108.879C386.026 108.879 387.199 109.16 388.26 109.698Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M389.437 105.487C388.174 104.35 386.536 103.721 384.837 103.721C383.138 103.721 381.5 104.35 380.237 105.487C379.641 106.027 379.164 106.685 378.836 107.419C378.508 108.154 378.336 108.948 378.331 109.753V113.515C378.384 114.088 378.589 114.636 378.924 115.103C379.259 115.571 379.712 115.94 380.237 116.174C381.662 116.898 383.238 117.275 384.837 117.275C386.435 117.275 388.011 116.898 389.437 116.174C389.962 115.94 390.415 115.571 390.75 115.103C391.085 114.636 391.289 114.088 391.342 113.515V109.753C391.338 108.948 391.166 108.154 390.838 107.419C390.51 106.685 390.033 106.027 389.437 105.487Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.583 129.387C144.814 128.224 145.026 126.813 145.201 125.133C154.627 125.017 164.039 124.348 173.387 123.13C179.995 121.598 212.366 119.264 212.366 119.264L212.577 128.261L212.66 131.549L144.374 130.393C144.444 130.077 144.515 129.747 144.583 129.387Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M212.659 131.55L212.743 134.897L213.02 146.515C213.02 146.515 182.216 143.912 168.013 141.255L143.228 140.009L142.666 134.897C143.595 133.559 144.182 132.012 144.374 130.394L212.659 131.55Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M142.666 131.036L212.743 134.897L212.577 128.262L144.583 129.387C143.726 133.881 142.666 131.036 142.666 131.036Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M115.679 140.008H118.675C119.255 138.6 120.321 137.447 121.68 136.761C124.089 135.537 126.752 134.899 129.453 134.899C132.155 134.899 134.818 135.537 137.227 136.761C138.586 137.447 139.652 138.6 140.232 140.008H143.228L139.069 144.337C138.521 144.888 137.901 145.362 137.227 145.747C134.818 146.97 132.155 147.608 129.453 147.608C126.752 147.608 124.089 146.97 121.68 145.747C121.007 145.365 120.391 144.891 119.85 144.337L115.679 140.008Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M118.021 124.182C118.12 124.121 118.216 124.06 118.318 124.001C121.769 122.248 125.584 121.335 129.453 121.335C133.323 121.335 137.138 122.248 140.588 124.001C140.691 124.06 140.787 124.121 140.885 124.182H145.201V135.591C145.073 136.978 144.578 138.305 143.767 139.436C142.957 140.568 141.859 141.462 140.589 142.028C137.138 143.781 133.323 144.694 129.453 144.694C125.584 144.694 121.769 143.781 118.318 142.028C117.048 141.462 115.95 140.568 115.14 139.436C114.329 138.305 113.834 136.978 113.706 135.591V124.182H118.021Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M118.318 117.745C112.168 121.3 112.168 127.063 118.318 130.618C121.769 132.371 125.584 133.284 129.454 133.284C133.323 133.284 137.138 132.371 140.589 130.618C146.739 127.063 146.739 121.3 140.589 117.745C137.138 115.993 133.323 115.079 129.454 115.079C125.584 115.079 121.769 115.993 118.318 117.745Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M120.764 119.268C120.839 119.222 120.912 119.175 120.989 119.13C123.613 117.798 126.513 117.104 129.454 117.104C132.395 117.104 135.295 117.798 137.918 119.13C137.996 119.175 138.069 119.222 138.144 119.268H141.425V123.809C141.327 124.863 140.951 125.872 140.335 126.732C139.718 127.592 138.884 128.272 137.918 128.702C135.295 130.035 132.395 130.729 129.454 130.729C126.513 130.729 123.613 130.035 120.989 128.702C120.024 128.272 119.19 127.592 118.573 126.732C117.957 125.872 117.581 124.863 117.483 123.809V119.268H120.764Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M120.989 114.375C116.314 117.077 116.314 121.458 120.989 124.161C123.612 125.493 126.512 126.187 129.454 126.187C132.395 126.187 135.295 125.493 137.918 124.161C142.593 121.458 142.593 117.077 137.918 114.375C135.295 113.042 132.395 112.348 129.454 112.348C126.512 112.348 123.612 113.042 120.989 114.375Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.939 111.677C127.008 111.116 128.195 110.818 129.401 110.809C130.608 110.799 131.799 111.078 132.877 111.621C133.348 111.666 133.821 111.684 134.294 111.677V118.857C134.255 119.283 134.103 119.691 133.854 120.039C133.605 120.387 133.267 120.662 132.877 120.836C131.816 121.375 130.643 121.655 129.454 121.655C128.264 121.655 127.092 121.375 126.031 120.836C125.64 120.662 125.303 120.387 125.054 120.039C124.805 119.691 124.652 119.283 124.613 118.857V111.677H125.939Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.031 109.698C125.617 109.838 125.258 110.104 125.004 110.459C124.75 110.814 124.613 111.24 124.613 111.677C124.613 112.114 124.75 112.54 125.004 112.895C125.258 113.25 125.617 113.516 126.031 113.656C127.092 114.194 128.264 114.475 129.454 114.475C130.643 114.475 131.816 114.194 132.877 113.656C133.29 113.516 133.649 113.249 133.903 112.895C134.157 112.54 134.294 112.114 134.294 111.677C134.294 111.24 134.157 110.814 133.903 110.459C133.649 110.104 133.29 109.838 132.877 109.698C131.816 109.16 130.643 108.879 129.454 108.879C128.264 108.879 127.092 109.16 126.031 109.698Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M124.854 105.487C126.117 104.35 127.755 103.721 129.454 103.721C131.152 103.721 132.791 104.35 134.054 105.487C134.65 106.027 135.127 106.685 135.455 107.419C135.783 108.153 135.955 108.948 135.959 109.753V113.515C135.906 114.088 135.702 114.636 135.367 115.103C135.032 115.571 134.579 115.94 134.054 116.174C132.628 116.898 131.052 117.275 129.454 117.275C127.855 117.275 126.279 116.898 124.854 116.174C124.329 115.94 123.876 115.571 123.541 115.103C123.206 114.636 123.002 114.088 122.949 113.515V109.753C122.953 108.948 123.125 108.153 123.453 107.419C123.781 106.685 124.258 106.027 124.854 105.487Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.146 192.493L264.749 192.412C264.749 192.412 266.861 177.039 268.06 167.845C268.608 163.934 268.926 159.994 269.012 156.046C269.012 156.046 268.206 150.163 257.146 150.509C246.085 150.163 245.279 156.046 245.279 156.046C245.365 159.994 245.683 163.934 246.231 167.845C247.43 177.039 249.541 192.412 249.541 192.412L257.146 192.493Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M269.012 156.047C268.926 159.995 268.608 163.934 268.06 167.845C266.861 177.04 264.749 192.413 264.749 192.413L261.746 191.483C264.14 178.401 265.296 165.122 265.199 151.823C266.126 152.17 266.956 152.735 267.619 153.47C268.283 154.205 268.761 155.088 269.012 156.047Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M245.279 156.047C245.365 159.995 245.683 163.934 246.231 167.845C247.43 177.04 249.541 192.413 249.541 192.413L252.546 191.483C250.152 178.401 248.995 165.122 249.093 151.823C248.166 152.17 247.335 152.734 246.672 153.47C246.008 154.205 245.53 155.088 245.279 156.047Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M243.372 215.318H246.367C246.947 213.909 248.014 212.756 249.373 212.07C251.782 210.847 254.445 210.209 257.146 210.209C259.847 210.209 262.51 210.847 264.919 212.07C266.278 212.756 267.345 213.909 267.924 215.318H270.92L266.761 219.647C266.213 220.198 265.594 220.672 264.919 221.057C262.51 222.28 259.847 222.918 257.146 222.918C254.445 222.918 251.781 222.28 249.373 221.057C248.699 220.675 248.083 220.2 247.542 219.647L243.372 215.318Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M245.713 199.491C245.812 199.431 245.908 199.369 246.01 199.311C249.461 197.558 253.276 196.645 257.145 196.645C261.015 196.645 264.83 197.558 268.28 199.311C268.382 199.369 268.478 199.431 268.577 199.491H272.893V210.901C272.765 212.287 272.27 213.614 271.459 214.746C270.648 215.877 269.551 216.772 268.28 217.337C264.83 219.09 261.015 220.003 257.145 220.003C253.276 220.003 249.461 219.09 246.01 217.337C244.739 216.772 243.642 215.877 242.831 214.746C242.021 213.614 241.526 212.287 241.398 210.901V199.491H245.713Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.145 208.594C248.448 208.594 241.398 204.519 241.398 199.491C241.398 194.464 248.448 190.389 257.145 190.389C265.842 190.389 272.893 194.464 272.893 199.491C272.893 204.519 265.842 208.594 257.145 208.594Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M248.456 194.578C248.531 194.532 248.603 194.486 248.681 194.441C251.304 193.108 254.204 192.414 257.146 192.414C260.087 192.414 262.987 193.108 265.61 194.441C265.688 194.486 265.761 194.532 265.836 194.578H269.116V199.12C269.019 200.173 268.643 201.182 268.026 202.042C267.41 202.902 266.576 203.582 265.61 204.012C262.987 205.345 260.087 206.039 257.146 206.039C254.204 206.039 251.304 205.345 248.681 204.012C247.715 203.582 246.881 202.902 246.265 202.042C245.649 201.182 245.273 200.173 245.175 199.12V194.578H248.456Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M248.681 189.684C244.006 192.386 244.006 196.767 248.681 199.47C251.304 200.802 254.204 201.496 257.146 201.496C260.087 201.496 262.987 200.802 265.61 199.47C270.285 196.767 270.285 192.386 265.61 189.684C262.987 188.352 260.087 187.657 257.146 187.657C254.204 187.657 251.304 188.352 248.681 189.684Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M253.632 186.986C254.7 186.425 255.887 186.128 257.093 186.118C258.3 186.108 259.491 186.387 260.568 186.93C261.04 186.975 261.513 186.993 261.986 186.986V194.166C261.947 194.592 261.795 195 261.546 195.348C261.296 195.696 260.959 195.971 260.568 196.145C259.508 196.683 258.335 196.964 257.146 196.964C255.956 196.964 254.783 196.683 253.723 196.145C253.332 195.971 252.995 195.696 252.746 195.348C252.497 195 252.345 194.592 252.305 194.166V186.986H253.632Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M253.723 185.008C253.309 185.148 252.95 185.414 252.696 185.769C252.442 186.124 252.305 186.549 252.305 186.986C252.305 187.423 252.442 187.849 252.696 188.204C252.95 188.559 253.309 188.825 253.723 188.965C254.783 189.503 255.956 189.784 257.146 189.784C258.335 189.784 259.508 189.503 260.568 188.965C260.982 188.825 261.341 188.559 261.595 188.204C261.85 187.849 261.986 187.423 261.986 186.986C261.987 186.55 261.85 186.124 261.595 185.769C261.341 185.414 260.982 185.148 260.569 185.008C259.508 184.469 258.335 184.188 257.146 184.188C255.956 184.188 254.784 184.469 253.723 185.008Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M252.545 180.797C253.808 179.66 255.447 179.03 257.145 179.03C258.844 179.03 260.482 179.66 261.745 180.797C262.341 181.337 262.818 181.995 263.146 182.729C263.474 183.463 263.646 184.258 263.65 185.062V188.824C263.597 189.397 263.393 189.945 263.058 190.413C262.723 190.88 262.27 191.25 261.745 191.483C260.32 192.207 258.744 192.585 257.145 192.585C255.547 192.585 253.971 192.207 252.545 191.483C252.02 191.25 251.567 190.88 251.232 190.413C250.897 189.945 250.693 189.397 250.64 188.824V185.062C250.644 184.258 250.816 183.463 251.144 182.729C251.472 181.995 251.949 181.337 252.545 180.797Z"
        fill="#EFEFEF"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M129.227 155.201C90.2778 155.201 58.7036 136.95 58.7036 114.436C58.7036 91.9221 90.2778 73.6709 129.227 73.6709C168.175 73.6709 199.75 91.9221 199.75 114.436C199.75 136.95 168.175 155.201 129.227 155.201Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M129.226 114.436L90.7484 80.2739C83.2474 82.9559 76.2902 86.9686 70.2101 92.1198L129.226 114.436Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M129.226 114.437L188.328 136.679C185.494 139.164 182.419 141.359 179.148 143.23C175.528 145.309 171.744 147.088 167.834 148.551L129.226 114.437Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M129.226 114.375L188.328 92.1335C182.268 86.9705 175.325 82.9482 167.834 80.2612L129.226 114.375Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M129.226 114.374L90.7484 148.537C86.8327 147.079 83.0421 145.305 79.414 143.231C76.1358 141.364 73.0525 139.173 70.2101 136.691L129.226 114.374Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M95.6408 144.194C113.238 153.014 144.079 153.631 162.877 144.17C140.894 150.369 117.628 150.378 95.6408 144.194Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M76.7171 95.9639C62.2079 106.192 62.2172 123.59 77.7388 133.844C67.6774 121.406 66.9027 109.123 76.7171 95.9639Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M163.041 84.7653C143.914 75.129 112.601 75.8763 94.957 84.9405C117.19 78.5165 140.776 78.4558 163.041 84.7653Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M180.252 134.247C195.861 124.088 197.093 105.945 180.689 95.0063C191.842 108.576 191.071 121.534 180.252 134.247Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M101.359 139.129C116.827 146.476 141.616 146.517 157.154 139.108C138.949 144.522 119.567 144.529 101.359 139.129Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M85.6565 99.106C73.7964 107.947 74.0386 121.835 86.5047 130.54C77.4767 120.134 77.194 109.656 85.6565 99.106Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M157.29 89.8125C141.498 82.2664 116.327 82.3893 100.792 89.9581C119.198 84.3302 138.856 84.2796 157.29 89.8125Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M171.572 130.874C184.627 121.916 184.874 107.413 171.934 98.3115C181.221 108.88 181.47 119.976 171.572 130.874Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M107.263 133.899C114.213 136.877 121.695 138.412 129.256 138.409C136.816 138.406 144.297 136.867 151.245 133.883C136.865 137.941 121.646 137.947 107.263 133.899Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M94.8845 102.349C85.1239 109.192 85.4289 120.476 95.5527 127.128C94.0218 125.284 92.7455 123.242 91.7582 121.056C88.9715 114.462 90.3286 108.502 94.8845 102.349Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M151.353 95.0231C144.317 91.9909 136.733 90.4365 129.073 90.4562C121.412 90.476 113.837 92.0694 106.816 95.1379C121.355 90.9032 136.793 90.8634 151.353 95.0231Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M162.612 127.392C173.273 120.489 173.451 108.724 162.898 101.723C169.814 110.194 170.099 118.538 162.612 127.392Z"
          fill="white"
        />
      </g>
      <path
        d="M124.854 105.487C126.117 104.35 127.755 103.721 129.454 103.721C131.152 103.721 132.791 104.35 134.054 105.487C134.65 106.027 135.127 106.685 135.455 107.419C135.783 108.153 135.955 108.948 135.959 109.753V113.515C135.906 114.088 135.702 114.636 135.367 115.103C135.032 115.571 134.579 115.94 134.054 116.174C132.628 116.898 131.052 117.275 129.454 117.275C127.855 117.275 126.279 116.898 124.854 116.174C124.329 115.94 123.876 115.571 123.541 115.103C123.206 114.636 123.002 114.088 122.949 113.515V109.753C122.953 108.948 123.125 108.153 123.453 107.419C123.781 106.685 124.258 106.027 124.854 105.487Z"
        fill="white"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M384.61 155.201C345.661 155.201 314.087 136.95 314.087 114.436C314.087 91.922 345.661 73.6709 384.61 73.6709C423.559 73.6709 455.133 91.922 455.133 114.436C455.133 136.95 423.559 155.201 384.61 155.201Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M384.61 114.436L346.132 80.2739C338.631 82.9558 331.674 86.9685 325.593 92.1198L384.61 114.436Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M384.61 114.437L443.711 136.679C440.878 139.164 437.803 141.359 434.532 143.23C430.911 145.309 427.127 147.088 423.218 148.551L384.61 114.437Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M384.61 114.375L443.711 92.1335C437.651 86.9704 430.708 82.9482 423.218 80.2612L384.61 114.375Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M384.61 114.374L346.132 148.537C342.216 147.079 338.426 145.305 334.797 143.231C331.519 141.364 328.436 139.173 325.593 136.691L384.61 114.374Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M351.024 144.194C368.621 153.014 399.462 153.631 418.261 144.17C396.277 150.369 373.012 150.378 351.024 144.194Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M332.101 95.9639C317.592 106.192 317.601 123.59 333.122 133.844C323.061 121.406 322.286 109.123 332.101 95.9639Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M418.425 84.7653C399.298 75.129 367.984 75.8763 350.341 84.9405C372.573 78.5165 396.159 78.4558 418.425 84.7653Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M435.635 134.247C451.245 124.088 452.477 105.945 436.073 95.0063C447.226 108.576 446.455 121.534 435.635 134.247Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M356.743 139.129C372.21 146.476 397 146.517 412.537 139.108C394.333 144.522 374.951 144.529 356.743 139.129Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M341.04 99.106C329.18 107.947 329.422 121.835 341.888 130.54C332.86 120.134 332.578 109.656 341.04 99.106Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M412.674 89.8125C396.882 82.2664 371.711 82.3893 356.176 89.9581C374.582 84.3302 394.239 84.2796 412.674 89.8125Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M426.956 130.874C440.011 121.916 440.258 107.413 427.318 98.3115C436.604 108.88 436.854 119.976 426.956 130.874Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M362.646 133.899C369.597 136.877 377.079 138.412 384.639 138.409C392.2 138.406 399.681 136.867 406.629 133.883C392.249 137.941 377.029 137.947 362.646 133.899Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M350.267 102.349C340.507 109.192 340.812 120.476 350.936 127.128C349.405 125.284 348.129 123.241 347.141 121.056C344.354 114.462 345.712 108.502 350.267 102.349Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M406.737 95.0231C399.701 91.9909 392.117 90.4365 384.457 90.4562C376.796 90.476 369.221 92.0694 362.2 95.1379C376.739 90.9032 392.177 90.8634 406.737 95.0231Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M417.995 127.392C428.657 120.489 428.834 108.724 418.282 101.723C425.197 110.194 425.483 118.538 417.995 127.392Z"
          fill="white"
        />
      </g>
      <path
        d="M380.237 105.487C381.5 104.35 383.138 103.721 384.837 103.721C386.536 103.721 388.174 104.35 389.437 105.487C390.033 106.027 390.51 106.685 390.838 107.419C391.166 108.154 391.338 108.948 391.342 109.753V113.515C391.289 114.088 391.085 114.636 390.75 115.103C390.415 115.571 389.962 115.94 389.437 116.174C388.011 116.898 386.435 117.275 384.837 117.275C383.238 117.275 381.662 116.898 380.237 116.174C379.712 115.94 379.259 115.571 378.924 115.103C378.589 114.636 378.384 114.088 378.331 113.515V109.753C378.336 108.948 378.508 108.153 378.836 107.419C379.164 106.685 379.641 106.027 380.237 105.487Z"
        fill="white"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 230.511C217.969 230.511 186.395 212.26 186.395 189.746C186.395 167.232 217.969 148.98 256.918 148.98C295.867 148.98 327.441 167.232 327.441 189.746C327.441 212.26 295.867 230.511 256.918 230.511Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 189.746L218.44 155.583C210.939 158.265 203.982 162.278 197.901 167.429L256.918 189.746Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 189.746L316.019 211.987C313.186 214.473 310.111 216.668 306.84 218.539C303.22 220.617 299.436 222.397 295.526 223.859L256.918 189.746Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 189.684L316.019 167.443C309.96 162.279 303.017 158.257 295.526 155.57L256.918 189.684Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 189.685L218.44 223.847C214.524 222.389 210.734 220.615 207.105 218.541C203.827 216.674 200.744 214.483 197.901 212.001L256.918 189.685Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M223.332 219.504C240.929 228.324 271.771 228.941 290.569 219.48C268.585 225.68 245.32 225.688 223.332 219.504Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M204.409 171.273C189.9 181.502 189.909 198.9 205.431 209.154C195.369 196.716 194.594 184.433 204.409 171.273Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M290.733 160.075C271.606 150.438 240.292 151.186 222.648 160.25C244.881 153.826 268.467 153.765 290.733 160.075Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M307.944 209.556C323.553 199.398 324.785 181.255 308.381 170.316C319.534 183.885 318.763 196.844 307.944 209.556Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M229.051 214.438C244.518 221.785 269.308 221.826 284.846 214.417C266.641 219.831 247.259 219.838 229.051 214.438Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M213.348 174.415C201.488 183.256 201.73 197.144 214.196 205.849C205.168 195.443 204.886 184.965 213.348 174.415Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M284.982 165.122C269.19 157.576 244.019 157.699 228.484 165.267C246.89 159.64 266.547 159.589 284.982 165.122Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M299.264 206.183C312.319 197.225 312.566 182.723 299.626 173.621C308.913 184.189 309.162 195.285 299.264 206.183Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M234.955 209.208C241.905 212.187 249.387 213.721 256.948 213.718C264.508 213.715 271.989 212.176 278.937 209.192C264.557 213.251 249.338 213.256 234.955 209.208Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M222.576 177.659C212.816 184.503 213.12 195.786 223.245 202.438C221.713 200.594 220.437 198.552 219.45 196.366C216.663 189.772 218.02 183.812 222.576 177.659Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M279.045 170.332C272.008 167.3 264.425 165.746 256.764 165.765C249.104 165.785 241.528 167.378 234.507 170.447C249.047 166.213 264.484 166.173 279.045 170.332Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M290.304 202.701C300.965 195.798 301.143 184.033 290.59 177.032C297.506 185.503 297.791 193.847 290.304 202.701Z"
          fill="white"
        />
      </g>
      <path
        d="M252.545 180.797C253.808 179.66 255.447 179.03 257.145 179.03C258.844 179.03 260.482 179.66 261.745 180.797C262.341 181.337 262.818 181.995 263.146 182.729C263.474 183.463 263.646 184.258 263.65 185.062V188.824C263.597 189.397 263.393 189.945 263.058 190.413C262.723 190.88 262.27 191.25 261.745 191.483C260.32 192.207 258.744 192.585 257.145 192.585C255.547 192.585 253.971 192.207 252.545 191.483C252.02 191.25 251.567 190.88 251.232 190.413C250.897 189.945 250.693 189.397 250.64 188.824V185.062C250.644 184.258 250.816 183.463 251.144 182.729C251.472 181.995 251.949 181.337 252.545 180.797Z"
        fill="white"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 81.5302C217.969 81.5302 186.395 63.279 186.395 40.7651C186.395 18.2512 217.969 0 256.918 0C295.867 0 327.441 18.2512 327.441 40.7651C327.441 63.279 295.867 81.5302 256.918 81.5302Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 40.7655L218.44 6.60254C210.939 9.28489 203.982 13.2975 197.901 18.4484L256.918 40.7655Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 40.7651L316.019 63.0067C313.186 65.4925 310.111 67.6874 306.84 69.5585C303.22 71.637 299.436 73.4163 295.526 74.8789L256.918 40.7651Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 40.7037L316.019 18.462C309.96 13.2989 303.017 9.2767 295.526 6.58984L256.918 40.7037Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M256.918 40.7031L218.44 74.8659C214.524 73.408 210.734 71.6334 207.105 69.5598C203.827 67.6926 200.744 65.5017 197.901 63.0197L256.918 40.7031Z"
          fill="#DFE9F2"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M223.332 70.5229C240.929 79.3425 271.771 79.9593 290.569 70.4985C268.585 76.6982 245.32 76.7066 223.332 70.5229Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M204.409 22.293C189.9 32.5217 189.909 49.9195 205.431 60.1737C195.369 47.7354 194.594 35.4526 204.409 22.293Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M290.733 11.0943C271.606 1.45749 240.292 2.20477 222.648 11.2694C244.881 4.84499 268.467 4.78431 290.733 11.0943Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M307.944 60.5749C323.553 50.4168 324.785 32.2733 308.381 21.335C319.534 34.9042 318.763 47.8626 307.944 60.5749Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M229.051 65.4569C244.518 72.8039 269.308 72.8456 284.845 65.437C266.641 70.8501 247.259 70.857 229.051 65.4569Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M213.348 25.4346C201.488 34.2753 201.73 48.1636 214.196 56.869C205.168 46.4627 204.886 35.9845 213.348 25.4346Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M284.982 16.1418C269.19 8.59574 244.019 8.71822 228.484 16.2871C246.89 10.6593 266.547 10.6087 284.982 16.1418Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M299.264 57.2025C312.319 48.2446 312.566 33.7424 299.626 24.6401C308.913 35.2086 309.162 46.3048 299.264 57.2025Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M234.955 60.2263C241.905 63.2046 249.387 64.7388 256.948 64.7361C264.508 64.7334 271.989 63.1937 278.937 60.2104C264.557 64.2688 249.338 64.2743 234.955 60.2263Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M222.576 28.6772C212.816 35.5215 213.12 46.8045 223.245 53.4569C221.713 51.6126 220.437 49.5703 219.45 47.3851C216.663 40.7904 218.02 34.8305 222.576 28.6772Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M279.045 21.3518C272.008 18.3195 264.425 16.7651 256.764 16.7849C249.104 16.8046 241.528 18.398 234.507 21.4665C249.047 17.2323 264.484 17.1925 279.045 21.3518Z"
          fill="white"
        />
      </g>
      <g style="mix-blend-mode: screen" opacity="0.3">
        <path
          d="M290.304 53.7201C300.965 46.8171 301.143 35.0515 290.59 28.0508C297.506 36.5214 297.791 44.8658 290.304 53.7201Z"
          fill="white"
        />
      </g>
      <path
        d="M252.545 31.8162C253.808 30.6787 255.447 30.0493 257.145 30.0493C258.844 30.0493 260.482 30.6787 261.745 31.8162C262.341 32.3558 262.818 33.0137 263.146 33.748C263.474 34.4824 263.646 35.277 263.65 36.0814V39.8435C263.597 40.4162 263.393 40.9644 263.058 41.4317C262.723 41.8991 262.27 42.2686 261.745 42.5023C260.32 43.2264 258.744 43.6037 257.145 43.6037C255.547 43.6037 253.971 43.2264 252.545 42.5023C252.02 42.2686 251.567 41.8991 251.232 41.4318C250.897 40.9645 250.693 40.4162 250.64 39.8435V36.0815C250.644 35.2771 250.816 34.4824 251.144 33.748C251.472 33.0137 251.949 32.3558 252.545 31.8162Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        :id="`${prefix}paint0_linear_1598_11113`"
        x1="247.944"
        y1="470.34"
        x2="247.944"
        y2="172.303"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18074" stop-color="white" />
        <stop offset="0.29338" stop-color="#F4F9FC" />
        <stop offset="0.48899" stop-color="#D8E7F4" />
        <stop offset="0.7423" stop-color="#AACBE8" />
        <stop offset="0.96734" stop-color="#7CAFDB" />
        <stop offset="1" stop-color="#E0F1FF" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { v4 as uuidv4 } from "uuid";

const prefix = computed(() => uuidv4());
</script>
