<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Adaptive RAN</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        A vision for mobile communications beyond 5G extends beyond mere
        performance enhancement and the introduction of novel features.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group7/01.png"
        alt=""
        width="100%"
      />
    </div>
    <div>
      <p class="info-modal-text">
        It evolves the ability to tailor these advancements precisely to the
        demands of specific usage scenarios, encompassing both feature sets and
        performance characteristics. Such a vision necessitates new tools within
        the infrastructure and architectural modifications.
      </p>
      <p class="info-modal-text">
        One innovative technique, Joint Communications and Sensing (JCaS),
        empowers wireless devices with environmental sensing capabilities,
        facilitating tasks like localization. Context-aware operation further
        refines this capability, automatically optimizing system behavior to
        suit individual circumstances. While Artificial Intelligence (AI) holds
        promise for inference and efficiency optimization, it is data-intensive
        and requires careful orchestration.
      </p>
      <p class="info-modal-text">
        The proposed modifications to current 5G Radio Access Network (RAN)
        architecture aim to facilitate adaptation to diverse use cases and
        integrate tools like JCaS, AI, and context management. Specifically, a
        modular architecture for the RAN is suggested. This modular design
        leverages the aforementioned tools to tailor the radio interface to the
        specific circumstances of application environments.
      </p>
      <p class="info-modal-text info-modal-text-bolt">
        Enhanced Beam Alignment
      </p>
      <p class="info-modal-text">
        The continuing demand for data traffic and the quest for ultra-reliable,
        low-latency communication are propelling the evolution of 6G cellular
        networks. Among the key technologies underpinning this evolution,
        beamforming stands out for its role in enhancing signal transmission
        efficiency, particularly in the Millimeter Wave spectrum. However,
        traditional beamforming approaches encounter challenges related to
        limited channel knowledge and complex environmental conditions. This
        paper explores the integration of position data into beamforming
        techniques for adaptive 6G cellular networks. By leveraging user
        location information, the network can precisely direct signals towards
        users, promising significant advancements in data rates, network
        capacity, and user experience. We present a system model that
        incorporates user location information into the beamforming algorithm
        and evaluate its potential compared to the traditional technique in 5G.
      </p>
      <p class="info-modal-text">
        Additionally, we discuss the challenges and opportunities associated
        with this approach, offering insights into its potential impact on
        future cellular networks.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
