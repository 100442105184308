<template>
  <section class="data-sectoin">
    <p class="title">Impressum</p>
    <div>
      <p class="subtitle">Anschrift</p>
      <div class="list">
        <p class="text bolt">Konsortium Open6GHub</p>
        <p class="text">
          c/o Deutsches Forschungszentrum für Künstliche Intelligenz GmbH
          Trippstadter Straße 122 · 67663 Kaiserslautern
        </p>
      </div>
    </div>
    <div>
      <p class="subtitle">E-Mail</p>
      <a href="mailto:info@dfki.de" class="email subtitle">info@dfki.de</a>
    </div>
    <div>
      <p class="subtitle">Vertretungsberechtigte Geschäftsführer</p>
      <p class="text">Prof. Dr. Antonio Krüger <br />Helmut Ditzer</p>
    </div>
    <div>
      <p class="subtitle">Vorsitzender des Aufsichtsrates</p>
      <p class="text">Dr. Ferri Abolhassanr</p>
    </div>
    <div>
      <p class="subtitle">Registergericht: Amtsgericht Kaiserslautern</p>
      <p class="text">Registernummer HRB 2313 · ID-Nummer: DE 148 646 973</p>
    </div>
    <div>
      <p class="subtitle">Redaktionsverantwortliche</p>
      <p class="text">
        Redaktionsverantwortlicher für die Internetpräsenz der Deutsches
        Forschungszentrum für Künstliche Intelligenz GmbH im Sinne von § 18 Abs.
        2 MStV ist:
      </p>
      <br />
      <p class="text">
        Marc Ruffing · Trippstadter Str. 122 · 67663 Kaiserslautern,
        <br />Deutschland Telefon: +49 631 20575 1826 · E-Mail:
        <a class="email" href="mailto:marc.ruffing@dfki.de"
          >marc.ruffing@dfki.de</a
        >
      </p>
    </div>
    <div>
      <p class="subtitle">Rechtliche Hinweise zur Haftung für eigene Inhalte</p>
      <p class="text">
        Die Deutsches Forschungszentrum für Künstliche Intelligenz GmbH (DFKI)
        ist als Inhaltsanbieterin gemäß § 7 Abs. 1 Telemediengesetz für die
        eigenen Inhalte, die zur Nutzung bereitgehalten werden, nach den
        allgemeinen Gesetzen verantwortlich.
      </p>
      <br />
      <p class="text">
        Das DFKI ist um Korrektheit und Aktualität der auf dieser
        Internetpräsenz bereitgestellten Informationen bemüht. Dennoch ist ein
        vollständiger Ausschluss von Fehlern und Unklarheiten nicht möglich. Das
        DFKI übernimmt daher keine Gewähr für die Aktualität, Korrektheit,
        Vollständigkeit oder Qualität der bereitgestellten Informationen. Für
        Schäden materieller oder ideeller Art, die durch die Nutzung oder
        Nichtnutzung der dargebotenen Informationen resp. durch die Nutzung
        fehlerhafter und unvollständiger Informationen unmittelbar oder
        mittelbar verursacht werden, haftet das DFKI nicht, sofern nicht
        nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
        Dies bezieht sich auch auf für zum Download bereitgestellte Software
        oder Daten.
      </p>
      <br />
      <p class="text">
        Dem DFKI ist es vorbehalten, Teile des Internetangebots oder das gesamte
        Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu
        löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
      </p>
    </div>
    <div>
      <p class="subtitle">
        Rechtliche Hinweise für Verweise auf externe Webseiten
      </p>
      <p class="text">
        Von vorstehenden eigenen Inhalten sind Querverweise („Links“) auf die
        von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Für
        diese Inhalte der verlinkten externen Seiten ist stets der jeweilige
        Anbieter verantwortlich. Diese fremden Inhalte wurden vonseiten des DFKI
        bei der erstmaligen Link-Setzung daraufhin überprüft, ob etwaige
        Rechtsverstöße bestehen. Zum Zeitpunkt der Überprüfung waren keine
        Rechtsverstöße ersichtlich. Es ist jedoch nicht auszuschließen, dass die
        Inhalte im Nachhinein von den jeweiligen Anbietern verändert werden. Das
        DFKI überprüft die Inhalte, auf die es in ihrem Angebot verweist, nicht
        ständig auf Veränderungen, die eine Verantwortlichkeit neu begründen
        könnten. Sollten Sie der Ansicht sein, dass die verlinkten externen
        Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte
        haben, so teilen Sie uns dies bitte direkt an: info@dfki.de mit.
      </p>
      <br />
      <p class="text">
        Sollte das DFKI feststellen oder einen Hinweis darauf erhalten, dass ein
        externes Angebot, auf das es verlinkt hat, eine zivil- oder
        strafrechtliche Verantwortlichkeit auslöst, wird das DFKI den Link auf
        dieses Angebot unverzüglich aufheben.
      </p>
    </div>
    <div>
      <p class="subtitle">Rechtliche Hinweise zum Urheberrecht</p>
      <p class="text">
        Das Layout der Homepage, die verwendeten Grafiken sowie die sonstigen
        Inhalte der Internetpräsenz des DFKI sind urheberrechtlich geschützt.
        Die Vervielfältigung, Bearbeitung, Verbreitung und jedwede Art der
        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
        Zustimmung des DFKI (in Schriftform). Soweit die Inhalte auf dieser
        Seite nicht von DFKI erstellt wurden, werden die Urheberrechte Dritter
        beachtet. Sollten Sie dennoch auf eine Urheberrechtsverletzung
        aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
        Bekanntwerden von Rechtsverletzungen, wird DFKI derartige Inhalte
        umgehend entfernen.
      </p>
    </div>
    <div>
      <p class="subtitle">Bildnachweise</p>
      <p class="text">
        © Yurchanka Siarhei, cono0430, Surasak_Photo, Gorodenkoff, Macrovector,
        Golden Sikorka, HappyPictures, elenabsl, Muhammad Abubakar Muneer,
        smashingstocks, klyaksun, Olga W Boeva, rassco, Edge Creative, Jiw
        Ingka, Fajar Ardianto AS @shutterstock.com
      </p>
    </div>
  </section>
</template>
<script setup lang="ts"></script>
