import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WorldsView from "@/views/WorldsView.vue";
import DrivenView from "@/views/DrivenView.vue";
import SmartView from "@/views/SmartView.vue";
import LogicView from "@/views/LogicView.vue";
import ImprintView from "@/views/ImprintView.vue";
import PrivacyView from "@/views/PrivacyView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/worlds",
    name: "worlds",
    component: WorldsView,
  },
  {
    path: "/driven",
    name: "driven",
    component: DrivenView,
  },
  {
    path: "/smart",
    name: "smart",
    component: SmartView,
  },
  {
    path: "/logic",
    name: "logic",
    component: LogicView,
  },
  {
    path: "/imprint",
    name: "imprint",
    component: ImprintView,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
