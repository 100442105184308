<template>
  <div class="logic-tv-item logic-group-2">
    <logic2-border class="logic2-border" />
    <logic2-el1 @click="smallModalOpened = true" class="logic2-el1" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content>
      <div class="small-modal-content">
        <logic2-el1 class="small-modal-image" />
        <p class="small-modal-title">Reconfigurable Intelligent Surface</p>
        <p class="small-modal-text">
          A reconfigurable intelligent surface, or short RIS, is a device that
          interacts with electromagnetic radiation.
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <logic2-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logic2Border from "@/components/Logic/Logic2/components/Logic2Border.vue";
import Logic2El1 from "@/components/Logic/Logic2/components/Logic2El1.vue";
import AppModal from "@/components/AppModal.vue";
import Logic2ModalContent from "@/components/Logic/Logic2/components/Logic2ModalContent.vue";

const smallModalOpened = ref(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.logic-group-2 {
  position: relative;
  display: flex;
  width: 1385px;
  height: 874px;
  pointer-events: none;

  svg {
    pointer-events: auto;
  }
  .logic2-border {
    pointer-events: none;

    position: absolute;
    top: 83px;
    left: 0;
  }
  .logic2-el1 {
    position: absolute;
    top: 0;
    left: 304px;
  }
}
</style>
