<template>
  <h1 class="smart-title">Smart Agriculture</h1>
  <div class="smart-sections-wrap mobile-content">
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Smart Agriculture"
        description="The OpenLab Smart Agriculture focuses on the broad field of connected
          and intelligent agriculture."
      >
        <smart-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Smart Agriculture"
        description="The OpenLab Smart Agriculture focuses on the broad field of connected
          and intelligent agriculture."
      >
        <smart-el2 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Smart Agriculture"
        description="The OpenLab Smart Agriculture focuses on the broad field of connected
          and intelligent agriculture."
      >
        <smart-el3 />
      </field-element>
    </section>
  </div>
  <div class="smart-tv-groups-wrap">
    <div class="smart-tv-groups desktop-content">
      <smart-wrap />
    </div>
  </div>
  <app-modal v-model="modal1Opened">
    <template #content>
      <smart-modal-content />
    </template>
  </app-modal>
</template>

<style>
.smart-sections-wrap {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  box-sizing: border-box;

  .section {
    padding: 0 24px;
  }
}
.smart-title {
  text-align: center;

  @media (min-width: 786px) {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #4399be;
  }

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    font-size: 85px;
  }
}

.smart-tv-groups-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    display: block;
  }

  .smart-tv-groups {
    position: relative;
  }
}
</style>
<script setup lang="ts">
import SmartWrap from "@/components/Smart/SmartWrap.vue";
import FieldElement from "@/components/FieldElement.vue";
import { ref } from "vue";
import SmartEl1 from "@/components/Smart/SmartEl1.vue";
import AppModal from "@/components/AppModal.vue";
import SmartModalContent from "@/components/Smart/SmartModalContent.vue";
import SmartEl2 from "@/components/Smart/SmartEl2.vue";
import SmartEl3 from "@/components/Smart/SmartEl3.vue";

const modal1Opened = ref(false);
</script>
