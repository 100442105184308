<template>
  <h1 class="worlds-title">Connected Worlds</h1>
  <div class="worlds-sections-wrap mobile-content">
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Smart Hospital"
        description="Intelligent logistic, asset tracking, and patient care"
      >
        <worlds1-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="Smart Hospital"
        description="Intelligent logistic, asset tracking, and patient care"
        @click="modal1Opened = true"
      >
        <worlds1-el2 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="Smart Hospital"
        description="Intelligent logistic, asset tracking, and patient care"
        @click="modal1Opened = true"
      >
        <worlds1-el3 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="E-Health Applications"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
        @click="modal2Opened = true"
      >
        <worlds2-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="E-Health Applications"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
        @click="modal2Opened = true"
      >
        <worlds2-el2 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="E-Health Applications"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
        @click="modal2Opened = true"
      >
        <worlds2-el3 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="E-Health Applications"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
        @click="modal2Opened = true"
      >
        <worlds2-el4 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="E-Health Applications"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
        @click="modal2Opened = true"
      >
        <worlds2-el5 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="Body Area Networks"
        description="Health-monitoring and bioauthentication"
        @click="modal3Opened = true"
      >
        <worlds3-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="Body Area Networks"
        description="Health-monitoring and bioauthentication"
        @click="modal3Opened = true"
      >
        <worlds3-el2 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="Teleoperation"
        description="Testbed for wireless robotic manipulation"
        @click="modal4Opened = true"
      >
        <worlds4-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="Teleoperation"
        description="Testbed for wireless robotic manipulation"
        @click="modal4Opened = true"
      >
        <worlds4-el2 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        title="Teleoperation"
        description="Testbed for wireless robotic manipulation"
        @click="modal4Opened = true"
      >
        <worlds4-el3 />
      </field-element>
    </section>
  </div>
  <div class="worlds-tv-groups-wrap">
    <div class="desktop-content worlds-tv-groups">
      <worlds1-wrap />
      <worlds2-wrap />
      <antenna-wrap class="worlds-tv-groups-item" />
      <worlds3-wrap />
      <worlds4-wrap />
    </div>
  </div>
  <app-modal v-model="modal1Opened">
    <template #content>
      <worlds1-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal2Opened">
    <template #content>
      <worlds2-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal3Opened">
    <template #content>
      <worlds3-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal4Opened">
    <template #content>
      <worlds4-modal-content />
    </template>
  </app-modal>
</template>

<style>
.worlds-title {
  text-align: center;

  @media (min-width: 786px) {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #4399be;
  }

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    font-size: 85px;
  }
}

.worlds-sections-wrap {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  box-sizing: border-box;

  .section {
    padding: 0 24px;
  }
}

.worlds-tv-groups-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    display: block;
  }
  .worlds-tv-groups {
    position: relative;
    width: 800px;
    height: 500px;

    .worlds-tv-groups-item {
      position: absolute;
      transform: scale(0.28);

      svg {
        overflow: visible;
        cursor: pointer;
      }
    }

    .worlds-group-1 {
      top: -408px;
      left: -648px;
    }
    .worlds-group-2 {
      top: -295px;
      left: -77px;
    }
    .worlds-group-3 {
      top: -33px;
      left: -514px;
    }
    .worlds-group-4 {
      top: -152px;
      left: -211px;
    }
    .antenna {
      top: -50px;
      left: 200px;
    }

    @media (min-width: 1531px) {
      width: 1200px;
      height: 800px;

      .worlds-tv-groups-item {
        transform: scale(0.4);
      }
      .worlds-group-1 {
        top: -335px;
        left: -575px;
      }
      .worlds-group-2 {
        top: -212px;
        left: 184px;
      }
      .worlds-group-3 {
        top: 163px;
        left: -442px;
      }
      .worlds-group-4 {
        top: 34px;
        left: 49px;
      }
      .antenna {
        top: 50px;
        left: 370px;
      }
    }

    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      width: 1836px;
      height: 3100px;

      .worlds-tv-groups-item {
        transform: none;
      }

      .worlds-group-1 {
        top: 0;
        left: 0;
      }
      .worlds-group-2 {
        top: 847px;
        left: 635px;
      }
      .worlds-group-3 {
        top: 1569px;
        left: 30px;
      }
      .worlds-group-4 {
        top: 2148px;
        left: 365px;
      }

      .antenna {
        position: absolute;
        top: 963px;
        left: 157px;
      }
    }
  }
}
</style>
<script setup lang="ts">
import FieldElement from "@/components/FieldElement.vue";
import Worlds1Wrap from "@/components/Worlds/Worlds1/Worlds1Wrap.vue";
import Worlds2Wrap from "@/components/Worlds/Worlds2/Worlds2Wrap.vue";
import Worlds3Wrap from "@/components/Worlds/Worlds3/Worlds3Wrap.vue";
import Worlds4Wrap from "@/components/Worlds/Worlds4/Worlds4Wrap.vue";
import AntennaWrap from "@/components/AntennaWrap.vue";
import Worlds1El1 from "@/components/Worlds/Worlds1/components/Worlds1El1.vue";
import Worlds1ModalContent from "@/components/Worlds/Worlds1/components/Worlds1ModalContent.vue";
import AppModal from "@/components/AppModal.vue";
import { ref } from "vue";
import Worlds1El2 from "@/components/Worlds/Worlds1/components/Worlds1El2.vue";
import Worlds2ModalContent from "@/components/Worlds/Worlds2/components/Worlds2ModalContent.vue";
import Worlds3ModalContent from "@/components/Worlds/Worlds3/components/Worlds3ModalContent.vue";
import Worlds4ModalContent from "@/components/Worlds/Worlds4/components/Worlds4ModalContent.vue";
import Worlds1El3 from "@/components/Worlds/Worlds1/components/Worlds1El3.vue";
import Worlds2El1 from "@/components/Worlds/Worlds2/components/Worlds2El1.vue";
import Worlds2El2 from "@/components/Worlds/Worlds2/components/Worlds2El2.vue";
import Worlds2El3 from "@/components/Worlds/Worlds2/components/Worlds2El3.vue";
import Worlds2El4 from "@/components/Worlds/Worlds2/components/Worlds2El4.vue";
import Worlds2El5 from "@/components/Worlds/Worlds2/components/Worlds2El5.vue";
import Worlds3El1 from "@/components/Worlds/Worlds3/components/Worlds3El1.vue";
import Worlds3El2 from "@/components/Worlds/Worlds3/components/Worlds3El2.vue";
import Worlds4El1 from "@/components/Worlds/Worlds4/components/Worlds4El1.vue";
import Worlds4El2 from "@/components/Worlds/Worlds4/components/Worlds4El2.vue";
import Worlds4El3 from "@/components/Worlds/Worlds4/components/Worlds4El3.vue";

const modal1Opened = ref(false);
const modal2Opened = ref(false);
const modal3Opened = ref(false);
const modal4Opened = ref(false);
</script>
