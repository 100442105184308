<template>
  <div
    class="worlds-tv-groups-item worlds-group-1 heart-small-animation-wrap drone-animation-wrap"
  >
    <worlds1-border class="worlds1-border" />
    <worlds1-el1 @click="smallModalOpened = 1" class="worlds1-el1" />
    <worlds1-el2 @click="smallModalOpened = 2" class="worlds1-el2" />
    <worlds1-el3 @click="smallModalOpened = 3" class="worlds1-el3" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content="{ value }">
      <div class="small-modal-content">
        <worlds1-el1 v-if="value === 1" class="small-modal-image" />
        <worlds1-el2 v-else-if="value === 2" class="small-modal-image" />
        <worlds1-el3 v-else-if="value === 3" class="small-modal-image" />
        <p class="small-modal-title">Smart Hospital</p>
        <p class="small-modal-text">
          Intelligent logistic, asset tracking, and patient care
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <worlds1-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Worlds1Border from "@/components/Worlds/Worlds1/components/Worlds1Border.vue";
import Worlds1El1 from "@/components/Worlds/Worlds1/components/Worlds1El1.vue";
import Worlds1El2 from "@/components/Worlds/Worlds1/components/Worlds1El2.vue";
import Worlds1El3 from "@/components/Worlds/Worlds1/components/Worlds1El3.vue";
import AppModal from "@/components/AppModal.vue";
import Worlds1ModalContent from "@/components/Worlds/Worlds1/components/Worlds1ModalContent.vue";

const smallModalOpened = ref<number | false>(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.worlds-group-1 {
  position: relative;
  display: flex;
  width: 1700px;
  height: 1087px;

  .worlds1-border {
    pointer-events: none;
    position: absolute;
    top: 144px;
    left: 0;
  }
  .worlds1-el1 {
    position: absolute;
    top: 0;
    left: 259px;
  }
  .worlds1-el2 {
    position: absolute;
    top: 367px;
    left: 1169px;
  }
  .worlds1-el3 {
    position: absolute;
    top: 598px;
    left: 571px;
  }
}
</style>
