<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Positioning</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">Channel Charting</p>
      <p class="info-modal-text">
        Channel Charting is a dimensionality reduction technique that
        reconstructs a map of the radio environment from similarity
        relationships found in channel state information. A neural network takes
        high-dimensional channel state information as input and produces a
        location estimate as output.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group4/01.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        localization method
      </p>
    </div>
    <div>
      <p class="info-modal-text">
        In contrast to classical, model-based localization methods, such as
        triangulation and multilateration, Channel Charting does not rely on the
        assumption of line-of-sight channels, but even learns to exploit
        multipath propagation effects to improve localization performance. The
        training of the neural network is performed in a self-supervised manner,
        that is, the training procedure does not require any reference
        positions.
      </p>
      <p class="info-modal-text">
        Localization is just one potential application of Channel Charting.
        Other potential use cases include channel prediction, handover
        management and pilot assignment.
      </p>
    </div>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        5G TDOA Positioning Testbench
      </p>
      <p class="info-modal-text">
        Our current testbench for TDOA measurements is based on an SDR platform.
        The setup is operated in the frequency range of 5G Campus Networks. With
        100 MHz SRS signals, we exploit the entire 5G bandwidth.
      </p>
      <p class="info-modal-text">
        This setup allows us to evaluate and compare different algorithms on a
        real indoor channel in a reasonable amount of time. Based on current
        results, we expect our 5G positioning solutions to be comparable with
        common indoor systems like ultra-wideband. Especially with carrier phase
        positioning, introduced in 3GPP Rel-18, cm-level accuracy seems
        possible.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group4/02.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        5G TDOA Testbench
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
