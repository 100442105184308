<template>
  <h1 class="driven-title">Application 6G Driven</h1>
  <div class="driven-sections-wrap mobile-content">
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Application-Driven 6G"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
      >
        <driven-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Application-Driven 6G"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
      >
        <driven-el2 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Application-Driven 6G"
        description="Brain-Computer-Interface, emergency detection and teleconsulting"
      >
        <driven-el3 />
      </field-element>
    </section>
  </div>
  <div class="driven-tv-groups-wrap">
    <div class="driven-tv-groups desktop-content">
      <driven-wrap />
    </div>
  </div>
  <app-modal v-model="modal1Opened">
    <template #content>
      <driven-modal-content />
    </template>
  </app-modal>
</template>

<style scoped>
.driven-sections-wrap {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  box-sizing: border-box;

  .section {
    padding: 0 24px;
  }
}
.driven-title {
  text-align: center;

  @media (min-width: 786px) {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #4399be;
  }

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    font-size: 85px;
  }
}

.driven-tv-groups-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    display: block;
  }

  .driven-tv-groups {
    position: relative;
  }
}
</style>
<script setup lang="ts">
import DrivenWrap from "@/components/Driven/DrivenWrap.vue";
import FieldElement from "@/components/FieldElement.vue";
import AppModal from "@/components/AppModal.vue";
import DrivenModalContent from "@/components/Driven/DrivenModalContent.vue";
import { ref } from "vue";
import DrivenEl1 from "@/components/Driven/DrivenEl1.vue";
import DrivenEl2 from "@/components/Driven/DrivenEl2.vue";
import DrivenEl3 from "@/components/Driven/DrivenEl3.vue";

const modal1Opened = ref(false);
</script>
