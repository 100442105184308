<template>
  <a class="download-button" :href="props.href" target="_blank">
    <slot />
    <img src="@/assets/images/download.svg" alt="" />
  </a>
</template>

<script lang="ts" setup>
// eslint-disable-next-line no-undef
const props = defineProps<{
  href: string;
}>();
</script>

<style scoped>
.download-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #005478;
  font-weight: 400;
  height: 32px;
  padding: 6px 10px;
  font-size: 16px;
  background: transparent;
  outline: transparent 1px solid;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.15s;
  text-decoration: none;
  border-radius: 4px;

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    height: 112px;
    padding: 24px 32px;
    font-size: 48px;
  }

  img {
    margin-left: 8px;
    width: 20px;
    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      margin-left: 20px;
      width: 36px;
    }
  }
}

.download-button:focus {
  outline: #6ebade 4px solid;
}
.download-button:hover,
.download-button:focus {
  background: #edf9ff;
}
</style>
