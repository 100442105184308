<template>
  <h1 class="logic-title">6G Future Factory</h1>
  <div class="logic-sections-wrap mobile-content">
    <section class="section">
      <field-element
        @click="modal1Opened = true"
        title="Distributed AI"
        description="Real time computer vision becomes an essential part in automation
          industry..."
      >
        <logic1-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal2Opened = true"
        title="Reconfigurable Intelligent Surface"
        description="A reconfigurable intelligent surface, or short RIS, is a device that
          interacts with electromagnetic radiation."
      >
        <logic2-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal3Opened = true"
        title="AR/VR"
        description="6G technology will significantly enhance these applications by
          offering ultra-low latency, high data rates, ..."
      >
        <logic3-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal4Opened = true"
        title="Positioning"
        description="Channel Charting is a dimensionality reduction technique that
          reconstructs a map of the radio environment..."
      >
        <logic4-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal5Opened = true"
        title="JCAS"
        description="Joint communication and sensing (JCAS) as a 6G candidate technology..."
      >
        <logic5-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal6Opened = true"
        title="Collaborative Robots"
        description="6G technology will significantly enhance these applications by
          offering ultra-low latency, high data rates, ..."
      >
        <logic6-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal7Opened = true"
        title="Adaptive RAN"
        description="A vision for mobile communications beyond 5G extends beyond mere
          performance enhancement and the introduction of novel features..."
      >
        <logic7-el1 />
      </field-element>
    </section>
    <section class="section">
      <field-element
        @click="modal8Opened = true"
        title="Digital Twin"
        description="This technology enables companies to enhance efficiency, optimize
          operations, and..."
      >
        <logic8-el1 />
      </field-element>
    </section>
  </div>
  <div class="logic-tv-groups-wrap">
    <div class="logic-tv-groups desktop-content">
      <logic1-wrap />
      <div class="dots-animation-wrap box-car-animation-wrap">
        <logic2-wrap />
        <dotted-lines class="logic-tv-item" />
      </div>
      <antenna-wrap class="logic-tv-item" />
      <logic3-wrap />
      <logic6-wrap />
      <logic4-wrap />
      <logic5-wrap />
      <logic7-wrap />
      <logic8-wrap />
    </div>
  </div>
  <app-modal v-model="modal1Opened">
    <template #content>
      <logic1-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal2Opened">
    <template #content>
      <logic2-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal3Opened">
    <template #content>
      <logic3-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal4Opened">
    <template #content>
      <logic4-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal5Opened">
    <template #content>
      <logic5-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal6Opened">
    <template #content>
      <logic6-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal7Opened">
    <template #content>
      <logic7-modal-content />
    </template>
  </app-modal>
  <app-modal v-model="modal8Opened">
    <template #content>
      <logic8-modal-content />
    </template>
  </app-modal>
</template>

<style>
.logic-sections-wrap {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  box-sizing: border-box;

  .section {
    padding: 0 24px;
  }
}
.logic-title {
  text-align: center;

  @media (min-width: 786px) {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #4399be;
  }

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    font-size: 85px;
  }
}

.logic-tv-groups-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    display: block;
  }

  .logic-tv-groups {
    position: relative;
    width: 950px;
    height: 600px;

    .logic-tv-item {
      position: absolute;
      transform: scale(0.3);

      svg {
        overflow: visible;
        cursor: pointer;
      }
    }

    .logic-group-1 {
      top: -95px;
      left: 130px;
    }
    .logic-group-2 {
      top: -250px;
      left: 90px;
    }
    .logic-group-3 {
      top: -20px;
      left: 30px;
    }
    .logic-group-4 {
      top: 160px;
      left: 260px;
    }
    .logic-group-5 {
      top: 145px;
      left: 350px;
    }
    .logic-group-6 {
      top: -25px;
      left: -460px;
    }
    .logic-group-7 {
      top: 190px;
      left: -300px;
    }
    .logic-group-8 {
      top: 400px;
      left: -35px;
    }

    .antenna {
      top: -50px;
      left: 340px;
    }
    .dotted-lines {
      top: -20px;
      left: 200px;
    }

    @media (min-width: 1531px) {
      width: 1450px;
      height: 750px;
      .logic-tv-item {
        transform: scale(0.4);
      }

      .logic-group-1 {
        top: -120px;
        left: 400px;
      }
      .logic-group-2 {
        top: -250px;
        left: 500px;
      }
      .logic-group-3 {
        top: -10px;
        left: 240px;
      }
      .logic-group-4 {
        top: 190px;
        left: 490px;
      }
      .logic-group-5 {
        top: 180px;
        left: 590px;
      }
      .logic-group-6 {
        top: 40px;
        left: -280px;
      }
      .logic-group-7 {
        top: 250px;
        left: -210px;
      }
      .logic-group-8 {
        top: 510px;
        left: 130px;
      }

      .antenna {
        top: -50px;
        left: 640px;
      }
      .dotted-lines {
        top: -35px;
        left: 595px;
      }
    }
    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      width: 1836px;
      height: 3000px;
      .logic-tv-item {
        transform: none;
      }

      .logic-group-1 {
        top: 432px;
        left: -88px;
      }
      .logic-group-2 {
        top: 444px;
        left: 330px;
      }
      .logic-group-3 {
        top: 1145px;
        left: 788px;
      }
      .logic-group-4 {
        top: 1504px;
        left: 1208px;
      }
      .logic-group-5 {
        top: 1564px;
        left: 1480px;
      }
      .logic-group-6 {
        top: 1527px;
        left: 115px;
      }
      .logic-group-7 {
        top: 1859px;
        left: -90px;
      }
      .logic-group-8 {
        top: 2295px;
        left: 393px;
      }

      .antenna {
        top: 963px;
        left: 7px;
      }
      .dotted-lines {
        display: none;
      }
    }
  }
}
</style>
<script setup lang="ts">
import Logic1Wrap from "@/components/Logic/Logic1/Logic1Wrap.vue";
import Logic2Wrap from "@/components/Logic/Logic2/Logic2Wrap.vue";
import Logic3Wrap from "@/components/Logic/Logic3/Logic3Wrap.vue";
import Logic4Wrap from "@/components/Logic/Logic4/Logic4Wrap.vue";
import Logic5Wrap from "@/components/Logic/Logic5/Logic5Wrap.vue";
import Logic6Wrap from "@/components/Logic/Logic6/Logic6Wrap.vue";
import Logic7Wrap from "@/components/Logic/Logic7/Logic7Wrap.vue";
import Logic8Wrap from "@/components/Logic/Logic8/Logic8Wrap.vue";
import AntennaWrap from "@/components/AntennaWrap.vue";
import DottedLines from "@/components/Logic/DottedLines.vue";
import SmartEl1 from "@/components/Smart/SmartEl1.vue";
import FieldElement from "@/components/FieldElement.vue";
import { ref } from "vue";
import AppModal from "@/components/AppModal.vue";
import Logic1ModalContent from "@/components/Logic/Logic1/components/Logic1ModalContent.vue";
import Logic2ModalContent from "@/components/Logic/Logic2/components/Logic2ModalContent.vue";
import Logic3ModalContent from "@/components/Logic/Logic3/components/Logic3ModalContent.vue";
import Logic4ModalContent from "@/components/Logic/Logic4/components/Logic4ModalContent.vue";
import Logic5ModalContent from "@/components/Logic/Logic5/components/Logic5ModalContent.vue";
import Logic6ModalContent from "@/components/Logic/Logic6/components/Logic6ModalContent.vue";
import Logic7ModalContent from "@/components/Logic/Logic7/components/Logic7ModalContent.vue";
import Logic8ModalContent from "@/components/Logic/Logic8/components/Logic8ModalContent.vue";
import Logic1El1 from "@/components/Logic/Logic1/components/Logic1El1.vue";
import Logic2El1 from "@/components/Logic/Logic2/components/Logic2El1.vue";
import Logic3El1 from "@/components/Logic/Logic3/components/Logic3El1.vue";
import Logic4El1 from "@/components/Logic/Logic4/components/Logic4El1.vue";
import Logic5El1 from "@/components/Logic/Logic5/components/Logic5El1.vue";
import Logic6El1 from "@/components/Logic/Logic6/components/Logic6El1.vue";
import Logic7El1 from "@/components/Logic/Logic7/components/Logic7El1.vue";
import Logic8El1 from "@/components/Logic/Logic8/components/Logic8El1.vue";

const modal1Opened = ref(false);
const modal2Opened = ref(false);
const modal3Opened = ref(false);
const modal4Opened = ref(false);
const modal5Opened = ref(false);
const modal6Opened = ref(false);
const modal7Opened = ref(false);
const modal8Opened = ref(false);
</script>
