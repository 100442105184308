<template>
  <svg
    width="423"
    height="571"
    viewBox="0 0 423 571"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="antenna"
  >
    <g class="wifi-animation" clip-path="url(#clip0_1609_14280)">
      <path
        d="M24.9086 99.9492H3.49391C-0.275909 74.4429 17.6831 47.586 52.2921 27.742C53.3393 27.1705 54.3865 26.5472 55.486 25.9757C101.509 0.936958 154.758 -0.673431 179 0.15773V12.3135C159.313 11.4823 111.614 12.2096 70.0941 34.8068C37.527 52.5729 20.8769 76.9883 24.9086 99.9492Z"
        fill="#00C1BC"
      />
      <path
        d="M79.728 43.586C80.5134 43.1185 81.3512 42.6509 82.1366 42.2354C118.578 22.4433 162.455 23.1706 179 24.3134V36.5731C169.471 35.6899 129.678 33.1965 96.7446 51.0665C72.9738 63.8975 61.769 82.5987 67.319 99.9492H45.852C40.7209 79.8455 53.2869 58.7028 79.728 43.586Z"
        fill="#00C1BC"
      />
      <path
        d="M109.049 58.3911C108.473 58.7028 107.897 59.0145 107.321 59.3781C88.7337 69.9754 80.4087 85.0922 84.545 99.9492H106.169C101.614 88.0013 108.054 75.6377 123.605 67.2222C144.705 55.8456 171.67 57.9755 179 58.8067V46.4951C165.073 45.4042 134.024 44.8847 109.049 58.3911Z"
        fill="#00C1BC"
      />
    </g>
    <path
      d="M253.21 529.103V530.084C253.21 530.461 253.133 530.838 253.133 531.216C252.944 532.499 252.636 533.762 252.212 534.99C252.136 535.216 252.059 535.367 251.982 535.594C251.875 536.036 251.721 536.466 251.522 536.877C251.214 537.556 250.907 538.236 250.524 538.915C247.965 543.004 244.261 546.28 239.855 548.349C238.398 549.179 236.862 549.859 235.403 550.538C227.916 553.639 219.951 555.476 211.843 555.972C203.709 556.442 195.55 555.652 187.665 553.633C181.357 552.154 175.409 549.461 170.166 545.708C166.099 543.011 163.03 539.092 161.418 534.537V534.386C161.045 533.059 160.864 531.687 160.88 530.31V530.16C160.878 528.501 161.111 526.849 161.571 525.253C162.043 523.621 162.713 522.049 163.567 520.574C167.224 515.205 172.558 511.152 178.763 509.026C186.208 505.867 194.155 504.002 202.249 503.516C210.382 503.047 218.54 503.837 226.424 505.856C232.733 507.334 238.68 510.028 243.923 513.781C247.469 516.18 250.304 519.462 252.136 523.291C252.36 523.778 252.54 524.283 252.672 524.801C252.844 525.497 252.972 526.203 253.056 526.914V527.065C253.183 527.737 253.235 528.42 253.21 529.103Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M253.173 543.593V544.349C253.004 547.867 251.775 551.256 249.642 554.085C246.952 557.605 243.523 560.513 239.588 562.614C228.024 568.391 215.065 570.924 202.134 569.935C191.906 570.051 181.852 567.331 173.123 562.086C166.522 557.859 160.382 551.292 160.382 543.971C160.382 539.367 160.612 534.838 160.612 530.309C160.597 531.686 160.778 533.058 161.15 534.386V534.536C162.763 539.092 165.832 543.011 169.899 545.707C175.142 549.46 181.09 552.153 187.398 553.632C195.283 555.651 203.441 556.441 211.575 555.972C219.684 555.475 227.649 553.638 235.136 550.537C236.595 549.858 238.13 549.179 239.588 548.348C243.994 546.279 247.698 543.003 250.257 538.914C250.64 538.235 250.947 537.555 251.254 536.876C251.454 536.466 251.608 536.035 251.715 535.593C251.792 535.366 251.869 535.216 251.945 534.989C252.396 533.769 252.705 532.503 252.866 531.215C252.866 530.838 252.942 530.46 252.942 530.083L253.173 543.593Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M224.494 417.073C224.093 417.073 223.703 416.943 223.386 416.704C223.068 416.464 222.839 416.128 222.735 415.748C222.631 415.367 222.658 414.964 222.811 414.6C222.964 414.236 223.235 413.931 223.582 413.734L229.371 410.446C234.19 407.7 238.192 403.757 240.976 399.014C243.761 394.27 245.231 388.891 245.24 383.414V207.724C245.237 207.487 245.282 207.252 245.372 207.033C245.462 206.813 245.596 206.614 245.765 206.445C245.934 206.277 246.136 206.143 246.358 206.052C246.58 205.961 246.818 205.914 247.059 205.914C247.3 205.914 247.538 205.961 247.76 206.052C247.982 206.143 248.183 206.277 248.353 206.445C248.522 206.614 248.655 206.813 248.745 207.033C248.836 207.252 248.881 207.487 248.878 207.724V383.414C248.868 389.519 247.229 395.514 244.125 400.802C241.022 406.089 236.562 410.483 231.191 413.545L225.401 416.833C225.126 416.99 224.812 417.073 224.494 417.073Z"
      fill="#5B5F6D"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M156.107 185.331V221.836C156.117 228.495 157.906 235.035 161.295 240.8C164.683 246.566 169.553 251.355 175.415 254.687L197.583 267.091C198.419 267.565 199.412 267.693 200.344 267.446C201.276 267.2 202.071 266.6 202.553 265.778C203.035 264.955 203.165 263.979 202.914 263.062C202.664 262.145 202.054 261.364 201.218 260.89L179.046 248.487C174.291 245.782 170.341 241.897 167.593 237.22C164.844 232.543 163.393 227.238 163.384 221.835V185.331C163.381 184.384 162.996 183.477 162.314 182.808C161.632 182.14 160.709 181.765 159.746 181.765C158.783 181.765 157.859 182.14 157.178 182.808C156.496 183.477 156.111 184.384 156.107 185.331Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g style="mix-blend-mode: multiply" opacity="0.5">
      <path
        d="M156.107 204.286V214.633C156.788 215.677 157.86 216.413 159.091 216.681C160.321 216.949 161.61 216.727 162.674 216.064C163.069 215.656 163.318 215.132 163.384 214.572V204.286H156.107Z"
        fill="#B493BC"
      />
      <path
        d="M156.107 204.286V214.633C156.788 215.677 157.86 216.413 159.091 216.681C160.321 216.949 161.61 216.727 162.674 216.064C163.069 215.656 163.318 215.132 163.384 214.572V204.286H156.107Z"
        stroke="black"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      d="M188.889 45.2549L188.885 190.208C188.873 190.98 188.623 191.731 188.168 192.362L149.611 214.626V69.7239L188.889 45.2549Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M149.611 67.6162V214.63C146.072 215.683 142.286 215.627 138.78 214.469V67.6162H149.611Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M138.78 67.6162V214.469C138.121 214.239 137.484 213.951 136.877 213.61L125.944 207.403C125.09 207.029 124.352 206.438 123.807 205.691C123.261 204.944 122.928 204.068 122.841 203.152V58.1348L138.78 67.6162Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M159.691 35.3438C162.064 34.1991 164.67 33.5979 167.313 33.5852C169.956 33.5724 172.567 34.1485 174.952 35.2702L185.705 41.0854C189.913 43.3611 189.953 47.155 185.794 49.5163L152.002 68.7027C149.657 69.8672 147.067 70.4739 144.44 70.4739C141.813 70.474 139.223 69.8674 136.878 68.7031L125.945 62.4962C125.037 62.1967 124.247 61.6228 123.69 60.8563C123.132 60.0898 122.835 59.1697 122.84 58.2272C122.845 57.2848 123.153 56.368 123.719 55.6075C124.285 54.8471 125.081 54.2817 125.993 53.9921L159.691 35.3438Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M257.521 185.331V221.836C257.511 228.495 255.722 235.035 252.333 240.8C248.945 246.566 244.075 251.355 238.213 254.687L216.802 266.753C216.252 267.068 215.627 267.233 214.991 267.233C214.189 267.234 213.409 266.974 212.773 266.495C212.136 266.015 211.679 265.343 211.471 264.581C211.263 263.82 211.317 263.012 211.624 262.284C211.931 261.555 212.473 260.947 213.168 260.553L234.582 248.487C239.337 245.783 243.287 241.897 246.036 237.22C248.784 232.543 250.236 227.238 250.244 221.836V185.331C250.247 184.384 250.632 183.477 251.314 182.808C251.996 182.14 252.92 181.765 253.882 181.765C254.845 181.765 255.769 182.14 256.451 182.808C257.133 183.477 257.518 184.384 257.521 185.331Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g style="mix-blend-mode: multiply" opacity="0.5">
      <path
        d="M257.522 204.286V214.633C256.841 215.677 255.769 216.413 254.538 216.681C253.308 216.949 252.02 216.727 250.955 216.064C250.56 215.656 250.311 215.132 250.245 214.572V204.286H257.522Z"
        fill="#B493BC"
      />
      <path
        d="M257.522 204.286V214.633C256.841 215.677 255.769 216.413 254.538 216.681C253.308 216.949 252.02 216.727 250.955 216.064C250.56 215.656 250.311 215.132 250.245 214.572V204.286H257.522Z"
        stroke="black"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      d="M224.74 45.2549L224.744 190.208C224.756 190.98 225.006 191.731 225.461 192.362L264.018 214.626V69.7239L224.74 45.2549Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M264.018 67.6162V214.63C267.557 215.683 271.343 215.627 274.849 214.469V67.6162H264.018Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M274.849 67.6162V214.469C275.507 214.238 276.144 213.951 276.751 213.61L287.684 207.402C288.538 207.029 289.276 206.438 289.822 205.691C290.367 204.944 290.7 204.067 290.787 203.152V58.1348L274.849 67.6162Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M253.938 35.3438C251.565 34.1991 248.96 33.5979 246.317 33.5852C243.674 33.5724 241.062 34.1485 238.678 35.2702L227.925 41.0854C223.717 43.3611 223.676 47.155 227.835 49.5163L261.628 68.7027C263.973 69.8672 266.563 70.4739 269.19 70.474C271.816 70.474 274.407 69.8674 276.751 68.7031L287.684 62.4963C288.593 62.1968 289.382 61.6229 289.94 60.8564C290.497 60.0898 290.795 59.1698 290.789 58.2273C290.784 57.2848 290.477 56.3681 289.91 55.6076C289.344 54.8471 288.549 54.2818 287.637 53.9921L253.938 35.3438Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M229.502 372.177C229.1 372.178 228.71 372.048 228.392 371.807C228.074 371.567 227.845 371.23 227.742 370.849C227.639 370.468 227.666 370.063 227.821 369.699C227.975 369.335 228.248 369.032 228.596 368.836C228.945 368.639 229.743 368.239 230.95 367.634C236.724 364.908 242.298 361.79 247.63 358.302C270.54 340.715 271.912 309.709 271.786 300.625V252.319C271.784 252.083 271.829 251.848 271.919 251.628C272.009 251.409 272.142 251.209 272.312 251.041C272.481 250.873 272.682 250.739 272.904 250.648C273.126 250.557 273.365 250.51 273.605 250.51C273.846 250.51 274.084 250.557 274.306 250.648C274.528 250.739 274.73 250.873 274.899 251.041C275.068 251.209 275.202 251.409 275.292 251.628C275.382 251.848 275.427 252.083 275.424 252.319V300.601C275.533 309.166 274.466 317.706 272.253 325.991C268.961 339.819 261.087 352.177 249.869 361.122C244.356 364.756 238.585 367.998 232.602 370.823C231.526 371.362 230.676 371.788 230.403 371.941C230.129 372.096 229.818 372.177 229.502 372.177Z"
      fill="#5B5F6D"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M268.446 241.98V278.485C268.436 285.144 266.647 291.684 263.259 297.45C259.87 303.215 255.001 308.004 249.138 311.337L214.888 330.739C214.052 331.211 213.06 331.338 212.129 331.091C211.198 330.844 210.405 330.245 209.924 329.423C209.442 328.602 209.312 327.626 209.561 326.711C209.811 325.795 210.419 325.014 211.254 324.539L245.507 305.136C250.263 302.432 254.212 298.547 256.961 293.87C259.71 289.192 261.161 283.887 261.17 278.485V241.98C261.17 241.031 261.553 240.121 262.235 239.45C262.917 238.779 263.843 238.402 264.808 238.402C265.773 238.402 266.698 238.779 267.38 239.45C268.062 240.121 268.446 241.031 268.446 241.98Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M293.251 99.9199L293.248 244.873C293.235 245.646 292.985 246.396 292.531 247.027L253.974 269.292V124.389L293.251 99.9199Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M253.973 122.28V269.294C250.434 270.348 246.648 270.291 243.143 269.133V122.28H253.973Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M243.143 122.281V269.134C242.484 268.903 241.847 268.616 241.24 268.275L230.307 262.067C229.453 261.694 228.715 261.103 228.17 260.356C227.624 259.609 227.291 258.733 227.204 257.817V112.8L243.143 122.281Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M264.053 90.0088C266.426 88.8642 269.032 88.2629 271.675 88.2502C274.318 88.2375 276.93 88.8136 279.314 89.9353L290.067 95.7505C294.275 98.0261 294.315 101.82 290.156 104.182L256.364 123.368C254.019 124.532 251.429 125.139 248.802 125.139C246.175 125.139 243.585 124.532 241.24 123.368L230.308 117.161C229.399 116.862 228.609 116.288 228.052 115.521C227.494 114.755 227.197 113.835 227.202 112.892C227.207 111.95 227.515 111.033 228.081 110.273C228.647 109.512 229.443 108.947 230.355 108.657L264.053 90.0088Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M271.013 228.428L276.212 225.476V216.531L271.013 219.483V228.428ZM271.013 132.814V215.011L276.212 212.059V129.862L271.013 132.814ZM271.013 245.147L276.212 242.196V229.948L271.013 232.9V245.147Z"
      fill="#00C1BC"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M274.871 241.126L271.006 243.32V245.151L276.205 242.199V229.952L274.871 230.709V241.126ZM274.871 130.624V210.989L271.006 213.184V215.015L276.205 212.063V129.866L274.871 130.624ZM274.871 224.406L271.006 226.601V228.431L276.205 225.48V216.535L274.871 217.292V224.406Z"
      fill="#00C1BC"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M216.639 18.7793V534.097H216.631C216.522 534.921 216.201 535.704 215.698 536.373C215.195 537.041 214.527 537.571 213.757 537.914C210.976 539.33 207.816 539.856 204.716 539.42C203.005 539.24 201.346 538.728 199.837 537.914C199.066 537.572 198.397 537.042 197.894 536.373C197.39 535.705 197.068 534.922 196.96 534.097H196.952V18.7793H216.639Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M204.716 18.7793V539.42C203.005 539.24 201.346 538.728 199.837 537.914C199.066 537.572 198.397 537.042 197.894 536.373C197.39 535.705 197.068 534.922 196.96 534.097H196.952V18.7793H204.716Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M213.755 14.8262C211.598 13.7502 209.214 13.1895 206.795 13.1895C204.377 13.1895 201.993 13.7502 199.836 14.8262C198.996 15.1056 198.265 15.6372 197.748 16.346C197.231 17.0548 196.953 17.9052 196.953 18.7776C196.953 19.65 197.231 20.5004 197.748 21.2092C198.265 21.918 198.996 22.4495 199.836 22.729C201.993 23.805 204.377 24.3658 206.795 24.3658C209.214 24.3658 211.598 23.805 213.755 22.729C214.595 22.4495 215.325 21.918 215.843 21.2092C216.36 20.5004 216.638 19.65 216.638 18.7776C216.638 17.9052 216.36 17.0548 215.843 16.346C215.325 15.6372 214.595 15.1057 213.755 14.8262Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M182.752 404.843V409.421V442.453V447.035L201.139 457.478V452.896V419.864V415.282L182.752 404.843Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M201.139 415.282V457.478L243.251 433.555V391.358L201.139 415.282Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M224.865 381.416L243.252 391.359L201.139 415.282L182.752 404.843L224.865 381.416Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M202.23 455.618V415.903L242.16 393.22V432.934L202.23 455.618ZM202.958 416.316V454.378L241.432 432.521V394.46L202.958 416.316Z"
      fill="#D6D7D8"
    />
    <path
      d="M236.028 426.733L239.396 432.126L232.665 435.945L236.028 426.733Z"
      fill="#FFFF00"
    />
    <path
      d="M236.821 431.168C236.675 431.115 236.53 431.067 236.385 431.016L236.234 430.962L236.284 430.854C236.348 430.697 236.414 430.54 236.48 430.385C236.547 430.226 236.615 430.069 236.685 429.913L236.756 429.756C236.768 429.732 236.825 429.653 236.775 429.702C236.857 429.621 236.703 429.508 236.618 429.464C236.495 429.4 236.278 429.325 236.198 429.405C236.141 429.478 236.098 429.559 236.07 429.646C235.999 429.803 235.931 429.962 235.863 430.121C235.796 430.278 235.73 430.436 235.665 430.595C235.622 430.674 235.59 430.757 235.569 430.843C235.566 430.867 235.57 430.891 235.58 430.914C235.59 430.936 235.606 430.955 235.626 430.969C235.795 431.095 235.989 431.185 236.194 431.236L236.426 431.317C236.391 431.454 236.339 431.581 236.302 431.71C236.246 431.908 236.18 432.101 236.136 432.31C236.098 432.488 236.724 432.73 236.762 432.552C236.806 432.343 236.872 432.15 236.928 431.952C236.993 431.777 237.044 431.596 237.081 431.413C237.097 431.285 236.889 431.192 236.821 431.168Z"
      fill="#381616"
    />
    <path
      d="M182.752 355.944V360.527V393.559V398.137L201.139 408.58V404.002V370.965V366.387L182.752 355.944Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M224.865 332.517L243.252 342.463L201.139 366.387L182.752 355.944L224.865 332.517Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M201.139 366.386V408.579L243.251 384.656V342.463L201.139 366.386Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M202.23 406.72V367.006L242.16 344.322V384.036L202.23 406.72ZM202.958 367.419V405.48L241.432 383.623V345.562L202.958 367.419Z"
      fill="#D6D7D8"
    />
    <path
      d="M235.568 377.926L238.931 383.318L232.2 387.138L235.568 377.926Z"
      fill="#FFFF00"
    />
    <path
      d="M236.357 382.362C236.211 382.31 236.066 382.262 235.921 382.21L235.771 382.156L235.82 382.048C235.884 381.891 235.95 381.734 236.016 381.579C236.084 381.421 236.151 381.263 236.221 381.107L236.293 380.95C236.304 380.926 236.361 380.848 236.311 380.897C236.393 380.816 236.239 380.703 236.155 380.658C236.031 380.594 235.814 380.519 235.734 380.599C235.678 380.672 235.634 380.753 235.606 380.84C235.536 380.997 235.467 381.156 235.399 381.315C235.332 381.472 235.266 381.63 235.201 381.79C235.158 381.868 235.126 381.951 235.105 382.037C235.103 382.061 235.106 382.086 235.116 382.108C235.126 382.13 235.142 382.149 235.162 382.163C235.331 382.289 235.525 382.38 235.731 382.431L235.962 382.511C235.928 382.648 235.875 382.775 235.838 382.905C235.782 383.103 235.716 383.296 235.672 383.505C235.634 383.682 236.26 383.925 236.298 383.746C236.342 383.537 236.408 383.344 236.465 383.146C236.53 382.971 236.581 382.791 236.617 382.608C236.633 382.479 236.425 382.386 236.357 382.362Z"
      fill="#381616"
    />
    <path
      d="M190.192 431.159C185.183 431.171 180.204 430.393 175.445 428.853C163.834 424.619 154.041 416.614 147.685 406.163C138.474 392.58 133.961 376.44 134.814 360.136V252.32C134.813 252.084 134.859 251.851 134.95 251.633C135.041 251.415 135.175 251.216 135.344 251.049C135.513 250.882 135.714 250.75 135.935 250.659C136.156 250.569 136.393 250.522 136.633 250.522C136.873 250.522 137.11 250.569 137.331 250.659C137.552 250.75 137.753 250.882 137.922 251.049C138.091 251.216 138.225 251.415 138.316 251.633C138.407 251.851 138.453 252.084 138.452 252.32V360.171L138.451 360.24C137.623 375.786 141.913 391.18 150.685 404.139C156.614 413.925 165.747 421.441 176.588 425.457C180.978 426.876 185.571 427.593 190.192 427.581C190.227 427.581 190.258 427.582 190.296 427.581C190.777 427.583 191.238 427.772 191.578 428.107C191.918 428.442 192.109 428.895 192.109 429.368C192.11 429.842 191.919 430.295 191.58 430.631C191.241 430.967 190.781 431.157 190.3 431.159L190.192 431.159Z"
      fill="#5B5F6D"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M181.342 383.591C176.111 383.572 171.1 381.517 167.403 377.876C163.707 374.235 161.626 369.304 161.614 364.159V200.856C161.612 200.619 161.657 200.385 161.748 200.166C161.838 199.947 161.972 199.748 162.141 199.58C162.31 199.412 162.511 199.279 162.733 199.188C162.955 199.097 163.193 199.05 163.433 199.05C163.674 199.05 163.912 199.097 164.133 199.188C164.355 199.279 164.556 199.412 164.726 199.58C164.895 199.748 165.028 199.947 165.119 200.166C165.209 200.385 165.255 200.619 165.252 200.856V364.159C165.252 366.937 165.996 369.665 167.408 372.071C168.82 374.476 170.851 376.474 173.297 377.862C175.743 379.251 178.518 379.982 181.342 379.982C184.166 379.982 186.941 379.251 189.387 377.862C189.805 377.627 190.3 377.563 190.765 377.687C191.23 377.81 191.627 378.109 191.868 378.519C192.108 378.93 192.174 379.417 192.05 379.875C191.926 380.332 191.622 380.723 191.206 380.961C188.211 382.676 184.808 383.584 181.342 383.591Z"
      fill="#5B5F6D"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M143.051 241.98V278.485C143.061 285.144 144.85 291.684 148.238 297.45C151.627 303.215 156.496 308.004 162.359 311.337L196.608 330.74C197.444 331.21 198.436 331.336 199.366 331.089C200.296 330.842 201.088 330.242 201.569 329.421C202.05 328.601 202.181 327.626 201.932 326.711C201.684 325.795 201.076 325.015 200.243 324.539L165.99 305.136C161.234 302.432 157.284 298.547 154.536 293.87C151.787 289.192 150.336 283.887 150.327 278.485V241.98C150.327 241.031 149.944 240.121 149.262 239.45C148.579 238.779 147.654 238.402 146.689 238.402C145.724 238.402 144.799 238.779 144.116 239.45C143.434 240.121 143.051 241.031 143.051 241.98Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M118.246 99.9199L118.25 244.873C118.262 245.646 118.512 246.396 118.966 247.027L157.524 269.292V124.389L118.246 99.9199Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M157.523 122.28V269.294C161.063 270.348 164.849 270.291 168.354 269.133V122.28H157.523Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M168.354 122.281V269.134C169.013 268.903 169.65 268.616 170.257 268.275L181.19 262.067C182.044 261.694 182.782 261.103 183.328 260.356C183.873 259.609 184.206 258.733 184.293 257.817V112.8L168.354 122.281Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M147.444 90.0088C145.071 88.8642 142.466 88.2629 139.823 88.2502C137.18 88.2375 134.568 88.8136 132.184 89.9353L121.43 95.7504C117.222 98.0261 117.182 101.82 121.341 104.182L155.134 123.368C157.478 124.532 160.069 125.139 162.695 125.139C165.322 125.139 167.912 124.532 170.257 123.368L181.19 117.161C182.099 116.862 182.888 116.288 183.446 115.521C184.003 114.755 184.301 113.835 184.295 112.892C184.29 111.95 183.982 111.033 183.416 110.273C182.85 109.512 182.054 108.947 181.142 108.657L147.444 90.0088Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M140.484 219.483L135.285 216.531V225.476L140.484 228.428V219.483ZM135.285 129.862V212.059L140.484 215.011V132.814L135.285 129.862ZM140.484 232.9L135.285 229.948V242.196L140.484 245.147V232.9Z"
      fill="#00C1BC"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M136.627 230.709L135.293 229.952V242.199L140.492 245.151V243.32L136.627 241.126L136.627 230.709ZM135.293 129.866V212.063L140.492 215.015V213.184L136.627 210.989V130.624L135.293 129.866ZM136.627 217.292L135.293 216.535V225.48L140.492 228.431V226.601L136.627 224.406L136.627 217.292Z"
      fill="#00C1BC"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g class="wifi-animation" clip-path="url(#clip1_1609_14280)">
      <!--      <path-->
      <!--        d="M340.067 216.148C340.855 215.732 341.695 215.263 342.483 214.794C369 199.633 381.603 178.429 376.457 158.268H354.928C360.494 175.668 349.257 194.423 325.417 207.292C292.388 225.213 252.481 222.713 242.924 221.827V234.122C259.517 235.268 303.52 235.998 340.067 216.148ZM418.937 158.268H397.461C401.504 181.295 384.806 205.781 352.145 223.598C310.504 246.261 262.668 246.99 242.924 246.157V258.348C267.236 259.181 320.639 257.566 366.795 232.455C367.898 231.882 368.948 231.257 369.998 230.684C404.707 210.782 422.718 183.848 418.937 158.268ZM313.077 199.946C313.655 199.633 314.232 199.321 314.81 198.956C333.451 188.328 341.8 173.168 337.652 158.268H315.965C320.534 170.25 314.075 182.649 298.479 191.089C277.318 202.499 250.275 200.363 242.924 199.529V211.876C256.891 212.97 288.03 213.491 313.077 199.946Z"-->
      <!--        fill="#00C1BC"-->
      <!--      />-->
      <path
        d="M397.091 158H418.506C422.276 183.506 404.317 210.363 369.708 230.207C368.661 230.779 367.614 231.402 366.514 231.973C320.491 257.012 267.242 258.623 243 257.791V245.636C262.687 246.467 310.386 245.74 351.906 223.142C384.473 205.376 401.123 180.961 397.091 158Z"
        fill="#00C1BC"
      />
      <path
        d="M342.272 214.363C341.487 214.831 340.649 215.298 339.863 215.714C303.422 235.506 259.545 234.779 243 233.636V221.376C252.529 222.259 292.322 224.753 325.255 206.883C349.026 194.052 360.231 175.351 354.681 158H376.148C381.279 178.104 368.713 199.246 342.272 214.363Z"
        fill="#00C1BC"
      />
      <path
        d="M312.951 199.558C313.527 199.246 314.103 198.935 314.679 198.571C333.266 187.974 341.591 172.857 337.455 158H315.831C320.386 169.948 313.946 182.312 298.395 190.727C277.295 202.104 250.33 199.974 243 199.143V211.454C256.927 212.545 287.976 213.065 312.951 199.558Z"
        fill="#00C1BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1609_14280">
        <rect
          width="422.048"
          height="100.238"
          fill="white"
          transform="translate(422.048 100.238) rotate(-180)"
        />
      </clipPath>
      <clipPath id="clip1_1609_14280">
        <rect
          width="422.048"
          height="100.238"
          fill="white"
          transform="translate(0 158.268)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup lang="ts"></script>
