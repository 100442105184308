<template>
  <svg
    width="195"
    height="241"
    viewBox="0 0 195 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M96.9847 166.408C96.8515 168.962 96.1217 171.449 94.8548 173.665C93.5879 175.881 91.8197 177.763 89.6943 179.16L16.344 221.859C13.5356 223.49 11.1365 223.021 9.89052 221.052C10.2023 221.59 10.6356 222.047 11.1552 222.384L3.30678 217.771C2.56945 217.238 1.9838 216.519 1.60845 215.687C1.2331 214.854 1.08122 213.937 1.16799 213.026L0.740234 56.7067C0.877867 54.1582 1.6077 51.678 2.87084 49.4663C4.13399 47.2545 5.89507 45.3731 8.012 43.9739L81.3811 1.29352C82.1222 0.743197 82.9958 0.402607 83.9114 0.307002C84.8269 0.211396 85.7512 0.364251 86.5885 0.749744L94.4367 5.34408C94.3438 5.30653 94.2322 5.28779 94.1392 5.25025C94.9663 5.73583 95.6353 6.45364 96.0646 7.31609C96.4939 8.17854 96.665 9.14834 96.5569 10.1072L96.9847 166.408Z"
      fill="#1D2943"
    />
    <path
      d="M94.4324 5.35239C93.5966 4.9681 92.6753 4.8122 91.7609 4.90033C90.8465 4.98847 89.9711 5.31754 89.2227 5.85453L15.866 48.5731C13.7445 49.9711 11.9799 51.8539 10.7153 54.0685C9.45075 56.2831 8.72191 58.7672 8.58797 61.3191L9.00929 217.628C8.92256 218.539 9.07508 219.458 9.45157 220.292C9.82806 221.126 10.4153 221.845 11.1544 222.378L3.30472 217.775C2.56564 217.242 1.97843 216.523 1.60194 215.689C1.22545 214.855 1.07289 213.936 1.15955 213.024L0.738281 56.7163C0.876885 54.1655 1.60769 51.6832 2.87182 49.4692C4.13596 47.2553 5.89809 45.3717 8.0163 43.9701L81.373 1.29007C82.1147 0.73997 82.9888 0.399778 83.9048 0.304719C84.8207 0.209659 85.7452 0.363188 86.5826 0.749408L94.4324 5.35239Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M89.2182 5.87246C93.2536 3.52327 96.5415 5.41254 96.5549 10.1164L96.9822 166.407C96.8483 168.962 96.1185 171.449 94.852 173.666C93.5855 175.882 91.8181 177.767 89.6934 179.165L16.3458 221.864C12.3104 224.213 9.02239 222.295 9.00924 217.62L8.58203 61.3293C8.71247 58.7737 9.44087 56.2855 10.7077 54.0681C11.9745 51.8507 13.7438 49.9671 15.8708 48.5712L89.2182 5.87246Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M93.322 161.121C93.2544 162.407 92.8859 163.658 92.2468 164.772C91.6076 165.887 90.7158 166.833 89.6445 167.533L16.3261 210.212C14.296 211.41 12.6488 210.444 12.6488 208.088L12.2656 60.3161C12.3189 59.0414 12.6734 57.7979 13.2998 56.6894C13.9261 55.5808 14.8061 54.6392 15.8664 53.9429L89.2231 11.2244C91.2533 10.0657 92.9006 10.9927 92.9006 13.3487L93.322 161.121Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M18.8758 55.1991C20.759 54.1029 22.2804 54.9769 22.2864 57.1305C22.2264 58.3208 21.8882 59.4799 21.2993 60.5132C20.7104 61.5466 19.8876 62.425 18.898 63.0767C17.0392 64.1587 15.4934 63.2706 15.4873 61.0886C15.5549 59.9079 15.8968 58.7597 16.4854 57.7367C17.074 56.7136 17.8928 55.8444 18.8758 55.1991ZM18.893 61.2915C19.439 60.9387 19.894 60.4601 20.2204 59.895C20.5468 59.3299 20.7352 58.6946 20.7701 58.0417C20.7667 56.8232 19.9324 56.3438 18.8807 56.956C18.3337 57.3146 17.8786 57.7985 17.5524 58.3682C17.2262 58.9379 17.0382 59.5773 17.0037 60.2342C17.0071 61.4244 17.8657 61.8897 18.893 61.2915Z"
      fill="#79838C"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M18.879 56.9563C19.9307 56.3441 20.7649 56.8235 20.7683 58.0419C20.7334 58.6949 20.545 59.3302 20.2187 59.8952C19.8923 60.4603 19.4373 60.939 18.8912 61.2918C17.864 61.8898 17.0053 61.4246 17.002 60.2345C17.0364 59.5776 17.2245 58.9382 17.5507 58.3685C17.8769 57.7988 18.332 57.3149 18.879 56.9563Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M6.89516 89.713V95.5262C6.91543 95.7508 6.87616 95.9768 6.78138 96.1811C6.68661 96.3853 6.53971 96.5606 6.35573 96.6888L5.14695 97.3827C4.94278 97.5163 4.70245 97.5827 4.45921 97.5726C4.21597 97.5626 3.98185 97.4765 3.78924 97.3264C3.26953 96.986 2.83692 96.5266 2.52667 95.9857C2.21642 95.4447 2.0373 94.8375 2.00391 94.2136L2.02239 88.4004C1.99214 88.1634 2.03259 87.9226 2.13859 87.7089C2.24459 87.4951 2.41132 87.318 2.61754 87.2002C2.58041 87.2002 2.56182 87.2002 2.5432 87.219L3.84509 86.4689C4.04777 86.3726 4.27265 86.334 4.4955 86.3571C4.71835 86.3802 4.93071 86.4643 5.1097 86.6001C5.63255 86.9371 6.06758 87.3957 6.37826 87.9374C6.68894 88.4791 6.86621 89.088 6.89516 89.713Z"
      fill="#111D33"
    />
    <path
      d="M5.64998 70.7345L5.61285 80.4106C5.61285 81.5545 4.81305 82.0233 3.82738 81.442C3.30666 81.0982 2.87364 80.6355 2.56344 80.0914C2.25324 79.5473 2.07459 78.9371 2.04199 78.3104L2.06058 68.6342C2.06058 67.509 2.87897 67.0402 3.86469 67.6215C4.38482 67.9615 4.81775 68.4208 5.12804 68.9619C5.43834 69.5029 5.61719 70.1103 5.64998 70.7345Z"
      fill="#111D33"
    />
    <path
      d="M6.95184 69.9656L6.93325 79.6419C6.95539 79.8667 6.91697 80.0934 6.82205 80.298C6.72714 80.5026 6.57923 80.6776 6.39392 80.8046L5.09206 81.5733C5.11065 81.5546 5.14791 81.536 5.1664 81.4984C4.96557 81.6319 4.72819 81.6983 4.48787 81.6882C4.24755 81.678 4.01648 81.5919 3.82738 81.4421C3.30666 81.0983 2.87364 80.6355 2.56344 80.0915C2.25324 79.5474 2.07459 78.9372 2.04199 78.3105L2.06058 68.6343C2.03237 68.4027 2.07407 68.1678 2.18023 67.9604C2.2864 67.753 2.45211 67.5827 2.65573 67.4717C2.62954 67.4689 2.60322 67.4755 2.58137 67.4903L3.90177 66.7215C4.10556 66.6301 4.32955 66.5941 4.55144 66.6171C4.77333 66.6401 4.98536 66.7214 5.1664 66.8528C5.68495 67.1944 6.11668 67.6541 6.4268 68.1948C6.73692 68.7355 6.91676 69.3421 6.95184 69.9656Z"
      fill="#79838C"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M3.86936 67.6166C4.38752 67.9576 4.81876 68.4168 5.12817 68.9571C5.43757 69.4974 5.61645 70.1035 5.65031 70.7265L5.62311 80.4046C5.61977 81.556 4.81276 82.0199 3.83048 81.4482C3.30793 81.1059 2.87312 80.6439 2.5616 80.0997C2.25007 79.5556 2.07068 78.9449 2.03809 78.3175L2.0653 68.6396C2.06844 67.5017 2.87559 67.038 3.86936 67.6166Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M6.89891 89.7118V95.5228C6.91911 95.7466 6.87999 95.9718 6.78556 96.1754C6.69113 96.3789 6.54478 96.5535 6.36147 96.6814L5.0646 97.4474C5.24348 97.3164 5.3854 97.1407 5.47637 96.9375C5.56734 96.7344 5.60422 96.5108 5.58337 96.2889L5.60202 90.4778C5.56729 89.8538 5.38831 89.2467 5.07938 88.7049C4.77045 88.1631 4.34013 87.7016 3.8228 87.3574C3.64079 87.2221 3.42608 87.1386 3.20112 87.1156C2.97615 87.0926 2.74918 87.131 2.54395 87.2267L3.84121 86.4604C4.04645 86.3646 4.27344 86.3263 4.49842 86.3493C4.72341 86.3723 4.93814 86.4558 5.12014 86.5912C5.64012 86.9326 6.07245 87.3934 6.38164 87.9358C6.69084 88.4783 6.86811 89.0869 6.89891 89.7118Z"
      fill="#79838C"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M3.81834 87.3554C4.33808 87.698 4.77027 88.1595 5.07975 88.7023C5.38922 89.2451 5.56724 89.8539 5.59931 90.4792L5.58279 96.2915C5.57965 97.4292 4.77251 97.8932 3.79039 97.3214C3.26946 96.9808 2.83563 96.521 2.52418 95.9794C2.21273 95.4378 2.03247 94.8298 1.99805 94.2047L2.01428 88.3921C2.01747 87.2407 2.82451 86.777 3.81834 87.3554Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M41.9556 97.2716C43.4365 96.414 45.0932 95.9115 46.7975 95.8027C48.5019 95.6939 50.2082 95.9818 51.7845 96.6441C52.6102 96.9505 53.5579 96.0741 54.0404 95.3309C56.4905 91.1075 59.8252 87.4741 63.8117 84.6842C72.7915 79.5247 80.12 83.6961 80.0908 94.202C79.8839 99.0006 78.8289 103.723 76.9755 108.148C70.7746 124.083 57.3198 141.469 53.7181 145.94C53.5208 146.235 53.2595 146.48 52.9541 146.658C52.6487 146.836 52.3072 146.941 51.9554 146.966C48.3988 146.629 35.0317 144.76 28.8496 136.028C27.6906 134.457 26.8406 132.676 26.3453 130.782C25.8623 129.088 25.6235 127.332 25.6362 125.568C25.9588 119.89 27.6046 114.369 30.4401 109.452C33.2756 104.536 37.2208 100.363 41.9556 97.2716Z"
      fill="#D1514E"
    />
    <path
      d="M50.8214 104.868L55.4822 116.984L59.6166 108.871C60.0663 107.986 60.7964 107.823 61.0574 108.157L65.2592 113.554L69.477 105.528C69.5743 105.309 69.722 105.117 69.9079 104.967C70.0938 104.818 70.3125 104.715 70.5457 104.668L75.0648 104.644L81.7543 100.75C82.2523 100.46 82.6492 100.693 82.6508 101.27C82.6351 101.582 82.5462 101.886 82.3913 102.156C82.2365 102.427 82.0202 102.656 81.7601 102.826C74.6659 106.916 74.8263 106.961 74.46 106.962L70.3714 106.985L65.808 115.654C65.3506 116.521 64.6466 116.684 64.372 116.348L60.1898 110.974L55.856 119.481C55.4654 120.25 54.5876 120.732 54.3317 120.051L50.1488 109.175L45.9951 131.296C45.7739 132.426 44.615 133.113 44.2962 132.438L39.7792 122.782L36.0192 131.862C35.8209 132.337 35.2633 133.002 34.7652 132.898L30.5079 132.067L24.0136 135.847C23.5204 136.135 23.1188 135.904 23.1172 135.332C23.1336 135.021 23.2228 134.717 23.3776 134.447C23.5323 134.177 23.7483 133.947 24.0078 133.777L30.7755 129.837C30.9231 129.741 31.0996 129.7 31.274 129.723L34.998 130.445L39.1806 120.348C39.6116 119.323 40.5172 119.051 40.7583 119.583L44.8865 128.417L49.1127 105.965C49.3216 104.821 50.5292 104.114 50.8214 104.868Z"
      fill="white"
    />
    <path
      d="M89.1866 40.3857L48.8242 63.881V66.897L89.1866 43.3991V40.3857Z"
      fill="white"
    />
    <path
      d="M89.1866 47.6406L48.8242 71.1385V74.1519L89.1866 50.654V47.6406Z"
      fill="white"
    />
    <path
      d="M48.8242 81.3976L78.5241 64.1057L78.5162 61.0977L48.8242 78.3816V81.3976Z"
      fill="white"
    />
    <path
      d="M89.1866 33.1406L48.8242 56.6385V59.6545L89.1866 36.1566V33.1406Z"
      fill="white"
    />
    <path
      d="M30.2834 62.4139C37.8876 57.9872 44.065 61.5368 44.0898 70.3472C43.8375 75.145 42.4632 79.8146 40.0793 83.9747C37.6954 88.1348 34.3694 91.6673 30.373 94.2837C22.7852 98.701 16.6077 95.1514 16.583 86.3601C16.832 81.5621 18.203 76.8915 20.5841 72.7298C22.9651 68.568 26.2887 65.0332 30.2834 62.4139Z"
      fill="#F7EFDC"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M31.615 68.4235L31.6307 73.9335L34.9827 68.0781L36.8286 69.1432L33.4662 75.0117L38.2431 72.233L38.251 75.2464L33.4766 78.0277L36.8574 79.9707L35.0271 83.1766L31.6516 81.2362L31.6673 86.7488L29.0526 88.27L29.0369 82.76L25.6823 88.6154L23.839 87.5503L27.1988 81.6817L22.4297 84.4578L22.4219 81.4418L27.191 78.6657L23.8076 76.7227L25.6405 73.5169L29.016 75.4573L29.0003 69.9447L31.615 68.4235Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M71.7285 147.221C76.5137 144.435 80.3973 146.667 80.4129 152.211C80.2546 155.229 79.3903 158.166 77.8907 160.782C76.3912 163.399 74.2989 165.62 71.7848 167.266L37.4804 187.236C32.7069 190.015 28.8115 187.79 28.7959 182.259C28.9541 179.24 29.8182 176.301 31.3177 173.682C32.8172 171.063 34.9095 168.839 37.424 167.191L71.7285 147.221Z"
      fill="#FFD06C"
    />
    <path
      d="M40.0978 170.268C38.7516 171.102 37.6362 172.265 36.8551 173.65C36.074 175.035 35.6525 176.596 35.6299 178.189C35.7102 181.498 37.6061 182.457 40.2212 180.938C41.5631 180.099 42.6752 178.935 43.4557 177.551C44.2362 176.168 44.6603 174.609 44.6891 173.017C44.6125 169.934 42.8508 168.668 40.0978 170.268ZM43.3488 173.692C43.3513 174.863 43.0562 176.015 42.4915 177.038C41.9268 178.061 41.1114 178.921 40.1232 179.536C38.1341 180.701 36.9757 179.736 36.9703 177.513C36.9703 176.343 37.2666 175.192 37.831 174.169C38.3954 173.147 39.2094 172.286 40.1957 171.669C42.0793 170.572 43.3431 171.349 43.3488 173.692Z"
      fill="#4C5C75"
    />
    <path
      d="M49.615 167.483C48.6707 168.079 47.9068 168.924 47.4065 169.928L47.4047 169.685C47.421 169.596 47.4096 169.503 47.372 169.42C47.3345 169.337 47.2726 169.268 47.1947 169.222C47.1168 169.175 47.0267 169.154 46.9366 169.161C46.8465 169.169 46.7607 169.203 46.6909 169.261C46.5077 169.378 46.3558 169.539 46.2485 169.729C46.1411 169.919 46.0816 170.133 46.0752 170.352L46.0933 176.63C46.0933 177.046 46.389 177.269 46.8071 177.029C46.9877 176.915 47.138 176.758 47.2453 176.573C47.3525 176.387 47.4134 176.178 47.4228 175.964L47.4101 171.596C47.8374 170.571 48.5376 169.685 49.4333 169.036C50.3366 168.503 50.6868 168.877 50.6901 169.932L50.701 173.948C50.7046 174.371 51.0074 174.583 51.413 174.346C51.5945 174.233 51.7459 174.077 51.8539 173.891C51.9618 173.705 52.0231 173.496 52.0323 173.281L52.0196 169.116C52.0143 167.483 51.1362 166.604 49.615 167.483Z"
      fill="#4C5C75"
    />
    <path
      d="M54.3686 161.56C54.1891 161.681 54.04 161.843 53.9331 162.032C53.8262 162.221 53.7645 162.432 53.7529 162.65L53.7784 172.156C53.7665 172.244 53.7812 172.333 53.8207 172.413C53.8601 172.492 53.9223 172.558 53.9993 172.601C54.0762 172.644 54.1643 172.662 54.2519 172.654C54.3395 172.646 54.4226 172.611 54.4903 172.554C54.6737 172.441 54.8268 172.284 54.9364 172.097C55.0459 171.911 55.1085 171.7 55.1187 171.483L55.0933 161.977C55.0905 161.55 54.7986 161.315 54.3686 161.56Z"
      fill="#4C5C75"
    />
    <path
      d="M57.5632 159.926L57.4887 159.969C57.2863 160.101 57.1179 160.279 56.9975 160.489C56.8772 160.699 56.8083 160.936 56.7968 161.178V161.229C56.7831 161.327 56.7989 161.426 56.8419 161.515C56.8849 161.603 56.9533 161.677 57.0382 161.726C57.1231 161.775 57.2205 161.797 57.318 161.789C57.4155 161.782 57.5085 161.745 57.585 161.684L57.6631 161.64C57.8646 161.507 58.0322 161.329 58.1522 161.119C58.2721 160.909 58.341 160.673 58.3532 160.431V160.381C58.3696 160.282 58.3555 160.181 58.313 160.09C58.2704 160 58.2014 159.925 58.1153 159.876C58.0292 159.826 57.9301 159.804 57.8313 159.814C57.7326 159.823 57.639 159.862 57.5632 159.926Z"
      fill="#4C5C75"
    />
    <path
      d="M57.5179 162.959C57.338 163.077 57.189 163.237 57.0837 163.425C56.9784 163.614 56.9199 163.825 56.9131 164.042L56.9313 170.32C56.916 170.408 56.9281 170.499 56.9657 170.579C57.0033 170.66 57.0647 170.727 57.1416 170.772C57.2184 170.816 57.3069 170.835 57.395 170.827C57.4831 170.819 57.5666 170.784 57.6341 170.726C57.8167 170.612 57.9692 170.455 58.0783 170.268C58.1875 170.082 58.2501 169.871 58.2607 169.655L58.2426 163.376C58.2426 162.909 57.9356 162.716 57.5179 162.959Z"
      fill="#4C5C75"
    />
    <path
      d="M63.5954 159.346C62.6566 159.939 61.8966 160.78 61.3978 161.778L61.396 161.542C61.4093 161.452 61.3954 161.36 61.3562 161.278C61.317 161.197 61.2542 161.129 61.1761 161.084C61.098 161.039 61.0082 161.018 60.9185 161.026C60.8287 161.033 60.7432 161.067 60.6732 161.124C60.4895 161.241 60.3371 161.401 60.2294 161.591C60.1217 161.781 60.0621 161.995 60.0557 162.214L60.0738 168.492C60.0738 168.903 60.3632 169.132 60.7876 168.891C60.968 168.775 61.1187 168.617 61.2276 168.431C61.3364 168.245 61.4004 168.036 61.4142 167.82L61.4015 163.451C61.8214 162.426 62.5188 161.542 63.4139 160.898C64.3257 160.362 64.6674 160.748 64.6707 161.794L64.6834 165.808C64.6834 166.227 64.9796 166.44 65.3972 166.208C65.5777 166.091 65.7284 165.934 65.837 165.748C65.9456 165.561 66.0089 165.352 66.022 165.136L66.011 160.971C66.0052 159.305 65.1002 158.477 63.5954 159.346Z"
      fill="#4C5C75"
    />
    <path
      d="M73.5788 157.392C73.5732 155.324 72.394 154.229 70.4168 155.374C69.4648 155.984 68.6839 156.83 68.1487 157.831C67.6136 158.832 67.3421 159.955 67.3602 161.092C67.3677 163.684 68.7174 164.347 70.782 163.147C71.6498 162.656 72.4103 161.994 73.0177 161.199C73.3254 160.816 73.5571 160.171 73.1648 159.997C72.8307 159.843 72.6576 160.144 72.4711 160.248C72.001 160.824 71.4319 161.31 70.791 161.683C69.6641 162.34 68.874 162.215 68.7187 160.928L72.9523 158.464C73.1366 158.351 73.2903 158.195 73.3997 158.008C73.509 157.821 73.5706 157.609 73.5788 157.392ZM72.2439 157.581L68.7204 159.633C68.7726 159.032 68.9616 158.451 69.2729 157.936C69.5841 157.421 70.0092 156.985 70.5148 156.663C71.4252 156.128 72.1719 156.422 72.2439 157.581Z"
      fill="#4C5C75"
    />
    <path
      d="M120.486 83.982L119.262 82.6194L120.06 81.8906L116.74 83.0536C116.083 83.2866 115.369 83.2991 114.705 83.0892C112.933 82.5219 109.289 81.5302 108.255 82.5917C106.57 84.3222 110.419 85.4125 110.419 85.4125C110.419 85.4125 100.849 86.2554 100.713 88.851C100.59 91.4468 107.279 89.9365 107.279 89.9365C107.073 90.8296 107.137 91.7645 107.464 92.6203C107.79 93.4762 108.364 94.2137 109.11 94.7377C112.714 97.6964 117.87 93.5555 117.87 93.5555L124.595 90.3639L121.666 82.9047L120.486 83.982Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M124.596 80.707L118.945 82.0796C116.526 82.3422 116.042 86.6201 116.954 89.7722C117.512 91.7234 118.61 93.2245 120.117 93.0744L124.596 92.1281V80.707Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M159.81 57.2642L146.004 87.3112C145.696 87.7907 145.285 88.1935 144.801 88.4893C144.316 88.7851 143.772 88.9661 143.208 89.0187L121.831 93.3842C120.236 93.543 119.075 91.9546 118.484 89.8897C117.519 86.5542 118.031 82.0273 120.591 81.7494L137.895 76.3642C138.391 76.1348 138.789 75.7332 139.017 75.2325C144.274 61.1953 145.85 51.1093 155.754 50.0172H155.912L156.069 49.9974L156.503 49.9775L157.487 52.1615L159.81 57.2642Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M137.587 221.561C137.628 223.182 138.453 223.617 139.921 223.976C141.325 224.305 142.785 224.309 144.191 223.99C146.179 223.508 148.067 222.679 149.77 221.538C153.573 219.158 156.888 216.036 160.813 213.919C162.294 213.333 163.585 212.344 164.541 211.061C165.102 209.898 165.204 208.564 164.828 207.327C164.527 206.28 164.136 205.262 163.659 204.284C163.583 204.114 163.507 203.944 163.431 203.758C163.043 202.581 162.427 201.494 161.62 200.558C160.854 199.908 159.949 199.445 158.977 199.207C157.483 198.789 155.935 198.607 154.387 198.668C153.77 198.636 153.153 198.731 152.574 198.947C151.994 199.162 151.464 199.494 151.016 199.922C150.125 200.969 150.166 202.512 149.672 203.807C149.181 204.919 148.445 205.903 147.521 206.686C147.244 206.963 146.952 207.224 146.676 207.485C146.323 207.809 145.985 208.131 145.648 208.501H145.632C145.279 208.824 144.941 209.177 144.604 209.531C144.02 210.147 143.437 210.778 142.884 211.408C141.808 212.64 140.764 213.902 139.765 215.195C138.865 216.214 138.184 217.41 137.764 218.707C137.575 219.646 137.515 220.606 137.587 221.561Z"
      fill="#8491A1"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M137.619 221.606C137.665 223.226 138.492 223.658 139.961 224.013C141.366 224.337 142.826 224.337 144.231 224.013C146.217 223.526 148.103 222.69 149.802 221.544C153.597 219.152 156.903 216.019 160.821 213.89C162.301 213.299 163.588 212.305 164.54 211.02C165.098 209.855 165.196 208.52 164.816 207.285C164.571 206.397 164.243 205.534 163.836 204.708C163.668 205.325 163.5 205.927 163.27 206.529C160.979 211.319 157.386 215.354 152.909 218.164C151.371 219.361 149.683 220.347 147.889 221.096C146.073 221.839 144.062 221.942 142.18 221.39C140.772 220.911 139.563 219.954 138.155 219.445C138 219.385 137.842 219.333 137.681 219.291C137.591 220.059 137.571 220.834 137.619 221.606Z"
      fill="#8491A1"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M149.052 189.965L149.916 202.487C149.932 202.729 150.01 202.964 150.142 203.167C150.275 203.37 150.458 203.534 150.673 203.644C152.41 204.584 154.376 205.01 156.343 204.872C158.311 204.734 160.199 204.038 161.791 202.864C161.988 202.725 162.147 202.536 162.25 202.317C162.354 202.097 162.399 201.854 162.381 201.612L161.516 189.09L149.052 189.965Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M143.578 88.4814L143.84 95.4309L147.899 199.902C147.908 200.174 147.983 200.44 148.116 200.676C148.25 200.913 148.438 201.113 148.665 201.26C149.934 202.067 151.319 202.672 152.771 203.053C154.658 203.471 156.612 203.484 158.505 203.089C160.397 202.694 162.186 201.902 163.754 200.763C163.973 200.618 164.155 200.424 164.287 200.197C164.418 199.969 164.495 199.713 164.511 199.45L165.422 185.16C166.171 176.624 165.912 168.028 164.652 159.554L160.204 95.3968L144.584 88.4814H143.578Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M161.357 237.595C161.397 239.216 162.223 239.651 163.691 240.01C165.095 240.339 166.554 240.343 167.961 240.024C169.948 239.543 171.836 238.713 173.539 237.572C177.342 235.193 180.658 232.071 184.582 229.954C186.064 229.367 187.354 228.378 188.31 227.095C188.871 225.932 188.974 224.598 188.598 223.361C188.296 222.315 187.905 221.296 187.429 220.318C187.353 220.148 187.277 219.978 187.201 219.792C186.812 218.615 186.197 217.528 185.389 216.592C184.624 215.942 183.719 215.479 182.746 215.241C181.253 214.823 179.705 214.641 178.156 214.702C177.539 214.67 176.923 214.765 176.343 214.981C175.764 215.197 175.234 215.528 174.785 215.956C173.894 217.003 173.936 218.546 173.442 219.841C172.95 220.953 172.214 221.937 171.29 222.72C171.014 222.997 170.722 223.258 170.446 223.52C170.093 223.843 169.755 224.166 169.417 224.535H169.402C169.049 224.858 168.711 225.212 168.373 225.565C167.79 226.181 167.206 226.812 166.653 227.443C165.578 228.674 164.533 229.936 163.534 231.229C162.634 232.248 161.953 233.444 161.534 234.741C161.344 235.68 161.285 236.64 161.357 237.595Z"
      fill="#8491A1"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M161.389 237.64C161.435 239.26 162.261 239.692 163.731 240.047C165.136 240.371 166.595 240.371 168.001 240.047C169.987 239.56 171.872 238.724 173.572 237.578C177.367 235.186 180.673 232.054 184.591 229.924C186.07 229.333 187.358 228.339 188.31 227.054C188.867 225.889 188.966 224.554 188.585 223.319C188.341 222.431 188.013 221.568 187.606 220.742C187.438 221.359 187.269 221.961 187.04 222.563C184.749 227.353 181.155 231.388 176.678 234.199C175.141 235.395 173.453 236.381 171.658 237.13C169.842 237.873 167.831 237.976 165.95 237.424C164.542 236.945 163.333 235.988 161.925 235.479C161.77 235.419 161.611 235.367 161.45 235.325C161.361 236.093 161.34 236.868 161.389 237.64Z"
      fill="#8491A1"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M172.819 206L173.684 218.522C173.699 218.764 173.777 218.999 173.91 219.202C174.042 219.405 174.225 219.569 174.44 219.679C176.178 220.619 178.144 221.045 180.111 220.907C182.078 220.769 183.967 220.073 185.558 218.899C185.756 218.76 185.915 218.571 186.018 218.352C186.121 218.132 186.166 217.889 186.148 217.647L185.284 205.125L172.819 206Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M163.172 127.237L169.743 179.601L172.128 216.756C174.015 218.461 176.417 219.477 178.946 219.639C181.476 219.802 183.986 219.101 186.073 217.651C186.423 217.374 186.757 217.075 187.071 216.756C188.449 195.2 189.227 178.714 185.523 166.367C184.958 164.504 184.667 162.568 184.661 160.62V120.091L176.88 122.674L163.172 127.237Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M189.483 141.1C186.327 122.781 189.533 94.2107 188.224 63.6288C177.017 51.1812 164.823 48.9525 158.231 49.8486L158.193 49.857C150.155 52.8967 145.836 59.2029 144.38 74.1772C144.259 75.4248 144.292 76.6828 144.166 77.9298L140.164 138.523C139.574 150.001 188.893 152.579 189.483 141.1Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M152.234 70.1438L152.41 74.3708C152.417 74.5478 152.475 74.7187 152.578 74.8625C152.68 75.0063 152.822 75.1166 152.987 75.1796C153.151 75.2426 153.33 75.2556 153.501 75.217C153.672 75.1784 153.829 75.0899 153.951 74.9624C161.471 67.2449 170.704 59.2711 178.55 55.3093C172.513 51.0274 165.139 49.1015 157.799 49.8895L157.517 50.1246L152.863 65.2685C152.378 66.8454 152.166 68.4944 152.234 70.1438Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M155.315 69.0566C161.807 63.9024 168.039 58.4235 173.987 52.6405L171.644 39.9434L168.989 42.1519L162.169 45.6572C159.385 53.2993 157.095 61.1155 155.315 69.0566Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M152.837 36.3618C152.967 37.0806 153.085 37.801 153.145 38.5286C153.279 40.1435 153.124 41.7687 153.177 43.3883C153.257 45.8184 153.882 48.1975 154.136 50.6155C154.179 51.6919 154.372 52.757 154.708 53.7797C156.184 57.4487 161.179 55.8065 163.869 54.7482C166.676 53.6431 169.387 52.3014 171.971 50.7372C173.38 49.9824 174.622 48.9461 175.62 47.6915C176.611 46.0924 177.208 44.2782 177.364 42.3996C177.85 39.2569 177.961 36.0668 177.695 32.8975C177.626 31.0914 177.096 29.3338 176.156 27.7947C174.401 25.7284 171.944 24.3968 169.267 24.0603C165.095 22.9288 160.669 23.2291 156.685 24.9141C155.404 25.498 154.299 26.4134 153.483 27.5672C152.266 29.8675 151.952 32.5453 152.604 35.0683C152.679 35.4998 152.76 35.9305 152.837 36.3618Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M180.076 37.5465C179.315 41.7942 177.386 45.7407 174.507 48.9356L173.54 46.0356C173.477 45.8791 173.449 45.7106 173.458 45.542C173.467 45.3733 173.513 45.2088 173.592 45.0601L176.102 40.051C176.244 39.7645 176.327 39.4522 176.347 39.1326C176.367 38.813 176.324 38.4926 176.219 38.1903C176.114 37.888 175.95 37.6099 175.737 37.3726C175.524 37.1352 175.266 36.9433 174.978 36.8083C174.672 36.6757 174.343 36.6041 174.01 36.5974C173.504 36.5932 173.011 36.7558 172.605 37.0603C172.199 37.3649 171.903 37.7948 171.762 38.2847L171.056 40.631C170.996 40.8214 170.871 40.9842 170.704 41.0905C170.536 41.1967 170.336 41.2394 170.14 41.211C169.933 41.1891 169.741 41.0909 169.601 40.9353C169.462 40.7796 169.383 40.5775 169.382 40.3674V40.2356C169.702 38.6395 169.621 36.9879 169.146 35.4315C168.671 33.8751 167.818 32.4632 166.663 31.3247C164.594 31.4099 162.522 31.3305 160.466 31.0874C158.572 30.9109 156.708 30.4855 154.923 29.822C154.38 29.6118 153.848 29.3742 153.328 29.1101C152.359 28.6003 151.462 27.9619 150.661 27.212C150.553 27.1099 150.469 26.985 150.415 26.8461C150.361 26.7073 150.338 26.5581 150.348 26.4093C150.358 26.2604 150.401 26.1157 150.473 25.9855C150.545 25.8553 150.645 25.7429 150.766 25.6565C150.859 25.5983 150.955 25.5455 151.053 25.4983C153.881 24.3031 156.488 22.6365 158.767 20.5683C159.215 20.1961 159.687 19.8527 160.179 19.5402C160.595 19.2756 161.032 19.0463 161.486 18.8547C161.559 18.8009 161.638 18.7566 161.721 18.7229C168.781 15.7965 176.886 17.7738 178.246 23.3102C179.478 25.4396 180.275 27.7964 180.59 30.2416C180.904 32.6867 180.73 35.1705 180.076 37.5465Z"
      fill="#B07443"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M156.455 104.209C156.582 104.216 153.468 102.212 154.867 101.398C156.266 100.584 159.893 104.094 159.893 104.094C160.708 104.817 161.232 105.814 161.367 106.899C161.503 107.984 161.24 109.082 160.629 109.985C159.116 108.152 157.722 106.223 156.455 104.209Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M154.638 92.7117L134.749 84.916L134.048 85.4855L138.132 112.748L158.024 120.546L158.725 119.977L154.638 92.7117Z"
      fill="#AA6256"
    />
    <path
      d="M158.025 120.547L158.725 119.977L154.639 92.7148L153.94 93.2817L158.025 120.547Z"
      fill="#A19E8D"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M153.94 93.2812L154.638 92.7144L134.749 84.916L134.048 85.4855L153.94 93.2812Z"
      fill="#A19E8D"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M134.048 85.4854L138.132 112.748L158.024 120.546L153.94 93.2811L134.048 85.4854Z"
      fill="#A19E8D"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M152.367 103.692C153.908 103.684 155.446 103.858 156.948 104.209C157.075 104.216 160.386 104.095 160.386 104.095C161.2 104.817 161.724 105.815 161.859 106.9C161.995 107.985 161.732 109.082 161.121 109.986C159.799 110.907 158.307 111.551 156.734 111.88C155.16 112.208 153.537 112.214 151.961 111.897C148.755 111.067 149.796 103.563 152.367 103.692Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M158.699 102.831C160.309 102.495 161.922 102.19 163.538 101.903L162.519 110.42L160.264 110.596C158.539 110.949 156.974 103.185 158.699 102.831Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M159.453 107.204C159.917 109.047 160.708 110.646 161.552 110.483L187.626 108.453C192.337 107.771 193.919 105.098 194.161 100.583C194.696 89.2641 193.496 77.9292 190.602 66.9792C190.146 65.5805 189.302 64.3429 188.17 63.4126C187.038 62.4824 185.665 61.8983 184.215 61.7296C184.025 61.7064 183.834 61.6833 183.642 61.6774C182.273 61.6172 180.912 61.9214 179.696 62.5594C178.214 63.3363 177.022 64.579 176.303 66.0995C175.583 67.62 175.374 69.3355 175.708 70.9863C177.562 80.2652 179.824 92.4402 182.529 99.0108C174.917 99.5728 167.35 100.638 159.876 102.199C158.869 102.412 158.914 104.964 159.453 107.204Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
