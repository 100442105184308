<template>
  <div class="logic-tv-item logic-group-1">
    <logic1-border class="logic1-border" />
    <logic1-el1 @click="smallModalOpened = true" class="logic1-el1" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content>
      <div class="small-modal-content">
        <logic1-el1 class="small-modal-image" />
        <p class="small-modal-title">Distributed AI</p>
        <p class="small-modal-text">
          Real time computer vision becomes an essential part in automation
          industry...
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <logic1-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logic1Border from "@/components/Logic/Logic1/components/Logic1Border.vue";
import Logic1El1 from "@/components/Logic/Logic1/components/Logic1El1.vue";
import AppModal from "@/components/AppModal.vue";
import Logic1ModalContent from "@/components/Logic/Logic1/components/Logic1ModalContent.vue";

const smallModalOpened = ref(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.logic-group-1 {
  position: relative;
  display: flex;
  width: 750px;
  height: 446px;
  pointer-events: none;

  svg {
    pointer-events: auto;
  }

  .logic1-border {
    pointer-events: none;
    position: absolute;
    top: 40px;
    left: 0;
  }
  .logic1-el1 {
    position: absolute;
    top: 0;
    left: 180px;
  }
}
</style>
