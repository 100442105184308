<template>
  <div class="logic-tv-item logic-group-4">
    <logic4-border class="logic4-border" />
    <logic4-el1 @click="smallModalOpened = true" class="logic4-el1" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content>
      <div class="small-modal-content">
        <logic4-el1 class="small-modal-image" />
        <p class="small-modal-title">Positioning</p>
        <p class="small-modal-text">
          Channel Charting is a dimensionality reduction technique that
          reconstructs a map of the radio environment...
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <logic4-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logic4Border from "@/components/Logic/Logic4/components/Logic4Border.vue";
import Logic4El1 from "@/components/Logic/Logic4/components/Logic4El1.vue";
import AppModal from "@/components/AppModal.vue";
import Logic4ModalContent from "@/components/Logic/Logic4/components/Logic4ModalContent.vue";

const smallModalOpened = ref(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.logic-group-4 {
  position: relative;
  display: flex;
  width: 434px;
  height: 346px;
  pointer-events: none;

  svg {
    pointer-events: auto;
  }

  .logic4-border {
    pointer-events: none;

    position: absolute;
    top: 100px;
    left: 0;
  }
  .logic4-el1 {
    position: absolute;
    top: 0;
    left: 80px;
  }
}
</style>
