<template>
  <svg
    width="764"
    height="697"
    viewBox="0 0 764 697"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M392.044 346.794L384.395 351.213V581.027L392.044 576.609V346.794Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M384.395 351.213L376.745 346.794V576.609L384.395 581.027V351.213Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M384.395 342.372L376.745 346.794L384.395 351.212L392.045 346.794L384.395 342.372Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M476.186 298.178L468.536 302.597V532.414L476.186 527.993V298.178Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M468.535 302.597L460.888 298.178V527.993L468.535 532.414V302.597Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M468.535 293.759L460.888 298.178L468.535 302.597L476.185 298.178L468.535 293.759Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M476.185 466.121L384.395 519.155L575.626 629.643L667.416 576.608L476.185 466.121Z"
      fill="#EAEAEA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M575.626 629.643L384.395 519.155V527.993L575.626 638.483V629.643Z"
      fill="#B3B3B3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M667.416 576.608L575.626 629.643V638.483L667.416 585.448V576.608Z"
      fill="#CECECE"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M476.185 326.648L384.395 379.682L575.626 490.17L667.416 437.135L476.185 326.648Z"
      fill="#EAEAEA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M575.626 490.17L384.395 379.682V388.522L575.626 499.01V490.17Z"
      fill="#B3B3B3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M667.416 437.135L575.626 490.169V499.009L667.416 445.975V437.135Z"
      fill="#CECECE"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M517.332 321.951L456.139 357.308V410.342L517.332 374.985V321.951Z"
      fill="#D1A561"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M517.332 321.951L456.139 357.308V410.342L517.332 374.985V321.951Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M456.139 357.308L410.242 330.791V383.825L456.139 410.343V357.308Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M471.436 295.434L410.242 330.791L456.139 357.308L517.332 321.951L471.436 295.434Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M490.924 306.694L429.73 342.048L436.595 346.015L497.788 310.658L490.924 306.694Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M436.595 346.015L429.73 342.048V359.728L436.595 363.692V346.015Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M509.682 361.727L486.735 374.985V383.825L509.682 370.567V361.727Z"
      fill="#E0E0E0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M492.812 372.721L487.664 375.695V382.041L492.812 379.067V372.721Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M498.208 369.605L493.352 372.41V373.671L498.208 370.863V369.605Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M498.208 369.605L493.352 372.41V373.671L498.208 370.863V369.605Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M509.042 363.343L499.834 368.665V369.295L509.042 363.973V363.343Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M509.042 363.343L499.834 368.665V369.295L509.042 363.973V363.343Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M509.042 364.624L499.834 369.947V370.577L509.042 365.255V364.624Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M509.042 365.908L499.834 371.228V371.858L509.042 366.539V365.908Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M509.042 367.468L507.953 368.096V369.05L509.042 368.422V367.468Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M507.58 368.313L506.491 368.941V369.896L507.58 369.268V368.313Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M498.208 371.516L493.352 374.322V375.583L498.208 372.775V371.516Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M498.208 373.428L493.352 376.234V377.495L498.208 374.689V373.428Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M569.188 396.106L546.238 409.365V418.205L569.188 404.946V396.106Z"
      fill="#E0E0E0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M552.316 407.101L547.166 410.077V416.42L552.316 413.446V407.101Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M557.715 403.984L552.855 406.789V408.05L557.715 405.245V403.984Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M557.715 403.984L552.855 406.789V408.05L557.715 405.245V403.984Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M568.548 397.722L559.337 403.044V403.675L568.548 398.353V397.722Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M568.548 397.722L559.337 403.044V403.675L568.548 398.353V397.722Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M568.548 399.006L559.337 404.326V404.956L568.548 399.636V399.006Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M568.548 400.288L559.337 405.61V406.238L568.548 400.918V400.288Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M568.547 401.847L567.458 402.475V403.432L568.547 402.802V401.847Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M567.085 402.692L565.996 403.32V404.277L567.085 403.647V402.692Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M557.715 405.896L552.855 408.701V409.962L557.715 407.157V405.896Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M557.715 407.808L552.855 410.613V411.874L557.715 409.069V407.808Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M636.82 390.988L575.626 426.345V479.38L636.82 444.022V390.988Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M575.626 426.345L529.729 399.828V452.862L575.626 479.379V426.345Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M590.923 364.471L529.729 399.828L575.626 426.345L636.82 390.988L590.923 364.471Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M610.411 375.731L549.218 411.085L556.082 415.052L617.276 379.695L610.411 375.731Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M556.082 415.052L549.218 411.085V428.765L556.082 432.729V415.052Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M629.17 430.764L606.223 444.023V452.862L629.17 439.604V430.764Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M535.793 484.648L510.224 499.421V534.775L535.793 520.003V484.648Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M507.816 468.482L482.132 483.321L510.109 499.487L535.793 484.649L507.816 468.482Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M510.223 499.421L482.246 483.255V518.612L510.223 534.775V499.421Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M516.409 473.448L490.843 488.22L499.342 493.131L524.909 478.359L516.409 473.448Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M499.342 493.131L490.843 488.22V502.41L499.342 507.322V493.131Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M524.123 480.998L513.958 486.872L520.154 490.451L530.316 484.58L524.123 480.998Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M466.028 486.769L440.459 501.542V536.896L466.028 522.124V486.769Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M438.051 470.604L412.367 485.442L440.344 501.608L466.028 486.77L438.051 470.604Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M440.459 501.542L412.481 485.376V520.733L440.459 536.897V501.542Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M446.645 475.568L421.078 490.341L429.577 495.252L455.144 480.48L446.645 475.568Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M429.577 495.252L421.078 490.341V504.531L429.577 509.442V495.252Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M454.356 483.119L444.193 488.993L450.389 492.572L460.552 486.701L454.356 483.119Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M499.074 505.861L473.507 520.633V555.99L499.074 541.218V505.861Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M471.096 489.697L445.412 504.536L473.389 520.7L499.073 505.861L471.096 489.697Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M473.507 520.633L445.53 504.47V539.827L473.507 555.99V520.633Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M479.691 494.663L454.124 509.435L462.623 514.346L488.193 499.574L479.691 494.663Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M462.623 514.346L454.124 509.435V523.625L462.623 528.536V514.346Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M487.403 502.213L477.238 508.087L483.434 511.666L493.599 505.792L487.403 502.213Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M639.787 545.994L578.593 581.349V616.706L639.787 581.349V545.994Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M593.889 519.477L532.581 554.9L578.475 581.417L639.786 545.994L593.889 519.477Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M578.593 581.348L532.696 554.831V590.188L578.593 616.705V581.348Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M612.53 530.247L551.334 565.601L559.836 570.513L621.029 535.158L612.53 530.247Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M559.836 570.512L551.334 565.601V579.791L559.836 584.703V570.512Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M563.821 542.331L545.088 553.154L554.59 558.643L573.32 547.819L563.821 542.331Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M590.923 461.7L583.275 466.121V695.935L590.923 691.517V461.7Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M583.276 466.121L575.626 461.7V691.517L583.276 695.935V466.121Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M583.276 457.281L575.626 461.7L583.276 466.121L590.923 461.7L583.276 457.281Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M667.417 417.505L659.767 421.924V651.741L667.417 647.32V417.505Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M659.766 421.924L652.119 417.505V647.32L659.766 651.741V421.924Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M659.766 413.087L652.119 417.505L659.766 421.924L667.416 417.505L659.766 413.087Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M502.226 33.5295L503.785 32.6254L503.783 64.3827L502.224 65.2843L502.226 33.5295Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M467.68 13.6634L469.239 12.7618L503.786 32.6253L502.227 33.5295L467.68 13.6634Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M502.226 33.5295L502.223 65.2843L492.115 69.5287L477.727 61.2542L467.676 45.4208L467.678 13.6635L502.226 33.5295Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M472.035 19.3356C471.867 18.9559 471.642 18.6036 471.368 18.2906C471.154 18.0418 470.9 17.8292 470.618 17.6613C470.444 17.5358 470.24 17.4589 470.026 17.4385C469.813 17.4182 469.598 17.4551 469.403 17.5456L468.472 18.0844C468.666 17.9941 468.881 17.9573 469.095 17.9777C469.308 17.9981 469.512 18.075 469.686 18.2004C469.969 18.3684 470.222 18.5809 470.437 18.8297C470.711 19.1428 470.935 19.495 471.104 19.8747C471.291 20.2782 471.392 20.7157 471.402 21.1599C471.424 21.3713 471.389 21.5846 471.301 21.7781C471.213 21.9716 471.075 22.1384 470.901 22.2615L471.832 21.7223C472.006 21.5992 472.144 21.4323 472.232 21.2388C472.32 21.0454 472.355 20.8321 472.333 20.6208C472.324 20.1765 472.222 19.739 472.035 19.3356Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M469.686 18.2004C468.741 17.6569 467.971 18.1038 467.968 19.1855C467.998 19.7772 468.168 20.3534 468.465 20.8669C468.762 21.3805 469.177 21.8167 469.675 22.14C470.629 22.6885 471.399 22.2511 471.402 21.1599C471.37 20.5669 471.198 19.9898 470.9 19.4755C470.602 18.9612 470.186 18.5243 469.686 18.2004Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M472.035 44.4919C471.867 44.1121 471.642 43.7598 471.368 43.4469C471.154 43.1979 470.9 42.9853 470.618 42.8173C470.444 42.6919 470.24 42.615 470.026 42.5947C469.813 42.5743 469.598 42.6112 469.403 42.7016L468.472 43.2407C468.666 43.1503 468.881 43.1134 469.095 43.1337C469.308 43.1541 469.512 43.231 469.686 43.3565C469.969 43.5244 470.222 43.737 470.437 43.9858C470.711 44.2988 470.935 44.6511 471.104 45.0308C471.291 45.4343 471.392 45.8719 471.402 46.3162C471.424 46.5275 471.389 46.7408 471.301 46.9343C471.213 47.1277 471.075 47.2945 470.901 47.4175L471.832 46.8784C472.006 46.7553 472.144 46.5885 472.232 46.395C472.32 46.2016 472.355 45.9884 472.333 45.7771C472.324 45.3328 472.222 44.8952 472.035 44.4919Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M469.686 43.3565C468.741 42.8133 467.971 43.2599 467.968 44.3416C467.998 44.9334 468.168 45.5096 468.465 46.0232C468.762 46.5367 469.177 46.9731 469.675 47.2964C470.629 47.8446 471.399 47.4075 471.402 46.3163C471.37 45.7233 471.198 45.1461 470.9 44.6317C470.602 44.1174 470.186 43.6805 469.686 43.3565Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M410.066 89.0125L394.461 79.981L477.029 32.5013L492.634 41.5328L410.066 89.0125Z"
      fill="#818D99"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M394.357 108.651L378.753 99.6194C377.9 99.0398 377.205 98.2582 376.73 97.3447C376.256 96.4312 376.016 95.4146 376.034 94.3862L391.638 103.418C391.621 104.446 391.86 105.463 392.334 106.376C392.809 107.29 393.504 108.071 394.357 108.651Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M394.775 92.7097L379.171 83.6783C380.713 81.1229 382.845 78.971 385.39 77.4012C386.347 76.708 387.472 76.2821 388.65 76.1677C389.828 76.0533 391.015 76.2545 392.088 76.7504L407.693 85.7819C406.619 85.2859 405.432 85.0847 404.254 85.1992C403.077 85.3136 401.951 85.7394 400.994 86.4326C398.449 88.0026 396.317 90.1545 394.775 92.7097Z"
      fill="#818D99"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M352.78 100.126L337.176 91.0947L379.17 83.6779L394.775 92.7093L352.78 100.126Z"
      fill="#818D99"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M350.057 110.762L334.452 101.73L337.176 91.0946L352.78 100.126L350.057 110.762Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M352.781 100.127L394.776 92.7097C396.318 90.1544 398.45 88.0025 400.995 86.4326C405.286 83.965 408.915 85.175 410.066 89.0116L492.634 41.5337L492.661 51.1203L408.489 99.5204C406.886 103.001 404.304 105.944 401.056 107.995C396.084 110.854 392.013 108.789 391.639 103.418L350.057 110.762L352.781 100.127Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M500.056 35.4775C499.888 35.0977 499.663 34.7455 499.389 34.4325C499.175 34.1837 498.922 33.9711 498.639 33.8032C498.465 33.6777 498.261 33.6008 498.048 33.5804C497.834 33.56 497.619 33.5969 497.425 33.6872L496.493 34.2263C496.688 34.136 496.903 34.0992 497.116 34.1196C497.33 34.14 497.534 34.2169 497.707 34.3423C497.99 34.5103 498.244 34.7228 498.458 34.9716C498.732 35.2847 498.957 35.6369 499.125 36.0166C499.312 36.4201 499.414 36.8576 499.423 37.3018C499.445 37.5132 499.41 37.7265 499.322 37.92C499.234 38.1135 499.096 38.2803 498.922 38.4034L499.854 37.8642C500.027 37.7411 500.165 37.5743 500.253 37.3808C500.341 37.1873 500.376 36.974 500.354 36.7627C500.345 36.3184 500.244 35.8808 500.056 35.4775Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M497.708 34.3423C496.763 33.7987 495.992 34.2456 495.989 35.327C496.019 35.9188 496.19 36.4951 496.487 37.0086C496.783 37.5222 497.198 37.9585 497.697 38.2819C498.65 38.8301 499.42 38.393 499.424 37.3018C499.392 36.7088 499.22 36.1316 498.922 35.6173C498.623 35.103 498.207 34.6662 497.708 34.3423Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M500.056 60.6338C499.888 60.254 499.663 59.9016 499.389 59.5886C499.175 59.3397 498.922 59.1272 498.639 58.9593C498.465 58.8338 498.261 58.7569 498.048 58.7365C497.834 58.7162 497.619 58.7531 497.425 58.8435L496.493 59.3824C496.688 59.2921 496.903 59.2553 497.116 59.2757C497.33 59.296 497.534 59.373 497.707 59.4984C497.99 59.6664 498.244 59.8789 498.458 60.1277C498.732 60.4407 498.957 60.793 499.125 61.1727C499.312 61.5762 499.414 62.0137 499.423 62.4579C499.445 62.6693 499.41 62.8826 499.322 63.0761C499.234 63.2696 499.096 63.4364 498.922 63.5595L499.854 63.0203C500.027 62.8972 500.165 62.7305 500.253 62.537C500.341 62.3435 500.376 62.1303 500.354 61.919C500.345 61.4747 500.244 61.0372 500.056 60.6338Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M497.708 59.4984C496.763 58.9548 495.992 59.4018 495.989 60.4834C496.019 61.0752 496.19 61.6514 496.487 62.1649C496.783 62.6784 497.198 63.1147 497.697 63.438C498.65 63.9865 499.42 63.5491 499.424 62.4579C499.392 61.8649 499.22 61.2877 498.922 60.7735C498.623 60.2592 498.207 59.8223 497.708 59.4984Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      d="M317.509 0.441406L258.553 102.154L343.798 151.493L402.753 49.7811L317.509 0.441406Z"
      fill="#E2E2E2"
      stroke="black"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M402.754 49.7814L343.798 151.494L345.865 152.677L404.829 50.9594L402.754 49.7814Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M316.562 8.77051L264.991 97.7442L339.557 140.902L391.128 51.9308L316.562 8.77051Z"
      fill="#EFEFEF"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M391.128 51.9308L339.557 140.902L341.365 141.935L392.944 52.9615L391.128 51.9308Z"
      fill="#E2E2E2"
      stroke="black"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <g class="box-car-animation">
      <path
        d="M753.258 338.197L755.275 346.808C753.473 352.405 750.244 357.435 745.906 361.401C743.557 363.562 740.979 365.46 738.218 367.06C735.13 368.852 731.893 370.372 728.543 371.603C720.435 374.476 712.016 376.375 703.461 377.262L703.751 367.243C711.906 366.357 719.929 364.519 727.656 361.767C730.833 360.601 733.902 359.157 736.827 357.454C739.457 355.939 741.912 354.138 744.148 352.085C748.322 348.312 751.459 343.53 753.258 338.197Z"
        fill="#456E82"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M753.257 338.197C751.458 343.53 748.322 348.312 744.148 352.085C741.912 354.139 739.456 355.939 736.826 357.454C733.901 359.157 730.833 360.601 727.656 361.767C719.928 364.519 711.905 366.357 703.751 367.243C670.034 356.613 643.724 341.644 624.822 322.335C628.485 317.84 632.702 313.826 637.371 310.389C642.523 306.607 648.049 303.362 653.863 300.707C660.476 297.694 667.32 295.213 674.328 293.289C705.998 305.113 732.307 320.082 753.257 338.197Z"
        fill="#E2E2E2"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M703.751 367.243L703.461 377.262C703.247 377.185 703.048 377.124 702.834 377.063C668.826 366.341 642.267 351.229 623.156 331.727C623.004 331.559 622.835 331.39 622.683 331.238L624.822 322.335C643.729 341.639 670.039 356.608 703.751 367.243Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M703.761 348.927C731.364 345.562 747.866 335.88 753.267 319.88V338.204C747.872 354.204 731.369 363.886 703.761 367.251V348.927Z"
        fill="#FFCE10"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M703.761 348.927V367.251C670.043 356.636 643.734 341.667 624.832 322.343V304.019C643.739 323.337 670.048 338.307 703.761 348.927Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M624.832 304.019C636 291 651.896 281.274 674.338 274.972C706.007 286.796 732.317 301.765 753.267 319.88C747.871 335.88 731.369 345.562 703.761 348.927C670.043 338.296 643.734 323.327 624.832 304.019Z"
        fill="#FFCE10"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M712.989 307.941C713.258 308.582 713.443 309.256 713.539 309.945C713.443 310.815 713.216 311.666 712.866 312.469C711.97 314.326 710.625 315.929 708.953 317.134C706.335 319.079 703.351 320.473 700.18 321.233C697.017 322.068 693.761 322.495 690.49 322.503C687.233 322.538 683.986 322.147 680.83 321.34C678.464 320.74 676.183 319.84 674.044 318.663C672.24 317.689 670.637 316.381 669.321 314.809C668.254 313.562 667.527 312.06 667.212 310.45C667.183 310.293 667.162 310.134 667.15 309.975C667.408 308.375 668.053 306.863 669.03 305.57C670.622 303.539 672.676 301.918 675.022 300.844C677.919 299.434 681.01 298.464 684.192 297.968C688.002 297.359 691.881 297.323 695.702 297.861C699.544 298.32 703.264 299.509 706.661 301.364C707.564 301.869 708.427 302.441 709.244 303.077C710.895 304.344 712.186 306.02 712.989 307.941Z"
        fill="#456E82"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M711.384 303.337C712.438 304.591 713.158 306.09 713.478 307.697C713.606 308.439 713.626 309.196 713.539 309.945C713.444 309.256 713.259 308.582 712.989 307.941C712.187 306.021 710.896 304.344 709.245 303.077C708.428 302.442 707.565 301.869 706.662 301.364C703.265 299.509 699.545 298.321 695.703 297.861C691.882 297.323 688.003 297.359 684.193 297.968C681.011 298.465 677.92 299.434 675.023 300.844C672.677 301.919 670.623 303.539 669.031 305.57C668.054 306.863 667.409 308.375 667.151 309.976C666.998 308.509 667.23 307.027 667.824 305.677C668.725 303.82 670.076 302.216 671.752 301.012C674.366 299.071 677.345 297.676 680.51 296.913C683.673 296.079 686.93 295.652 690.2 295.644C693.457 295.614 696.704 296.005 699.86 296.806C702.235 297.402 704.522 298.307 706.662 299.498C708.461 300.472 710.063 301.774 711.384 303.337Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M713.54 309.945C713.626 309.196 713.606 308.439 713.478 307.696C713.158 306.09 712.438 304.591 711.384 303.337C710.063 301.774 708.461 300.472 706.662 299.498C704.522 298.307 702.235 297.402 699.86 296.806C696.703 296.005 693.457 295.614 690.2 295.643C686.929 295.652 683.673 296.079 680.51 296.913C677.345 297.676 674.366 299.07 671.752 301.012C670.075 302.216 668.725 303.82 667.824 305.677C667.23 307.027 666.998 308.509 667.151 309.975C667.163 310.134 667.184 310.293 667.212 310.45C667.528 312.06 668.255 313.562 669.322 314.809C670.638 316.381 672.241 317.689 674.045 318.663C676.184 319.84 678.465 320.74 680.831 321.34C683.986 322.147 687.234 322.538 690.491 322.503C693.762 322.495 697.018 322.068 700.181 321.233C703.352 320.473 706.336 319.079 708.954 317.134C710.626 315.929 711.971 314.326 712.867 312.469C713.217 311.666 713.444 310.815 713.54 309.945ZM726.103 306.947C726.226 307.649 726.287 308.36 726.287 309.073C726.269 310.879 725.89 312.663 725.171 314.319C723.776 317.191 721.691 319.672 719.103 321.539C715.059 324.537 710.454 326.691 705.561 327.871C700.67 329.149 695.638 329.807 690.582 329.829C685.542 329.882 680.517 329.275 675.634 328.025C671.975 327.103 668.45 325.71 665.149 323.879C662.36 322.383 659.88 320.369 657.843 317.945C656.2 316.017 655.079 313.7 654.588 311.215C654.466 310.533 654.405 309.842 654.404 309.15V309.073C654.426 307.271 654.811 305.492 655.535 303.842C656.925 300.967 659.004 298.481 661.588 296.607C665.641 293.615 670.25 291.462 675.145 290.275C680.036 288.996 685.069 288.339 690.124 288.317C695.159 288.264 700.179 288.871 705.057 290.122C708.722 291.04 712.252 292.434 715.557 294.267H715.573C718.351 295.773 720.824 297.786 722.863 300.202C724.499 302.138 725.614 304.459 726.103 306.947Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M667.472 329.294C664.561 327.956 661.883 326.159 659.54 323.971C657.654 322.235 656.181 320.097 655.23 317.715C654.69 316.298 654.41 314.796 654.404 313.279V309.15C654.405 309.842 654.466 310.533 654.588 311.215C655.079 313.7 656.2 316.017 657.843 317.945C659.88 320.369 662.36 322.383 665.149 323.879C668.45 325.71 671.975 327.103 675.634 328.024C680.517 329.275 685.542 329.882 690.582 329.829C695.638 329.807 700.67 329.149 705.561 327.871C710.454 326.691 715.059 324.537 719.103 321.539C721.691 319.672 723.776 317.191 725.171 314.319C725.89 312.663 726.269 310.879 726.287 309.073H726.302V313.279C726.072 317.162 724.398 320.818 721.61 323.527C718.912 326.243 715.687 328.378 712.134 329.799C708.613 331.297 704.938 332.4 701.175 333.087C693.615 334.429 685.871 334.351 678.34 332.858C674.586 332.118 670.936 330.921 667.472 329.294Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M760.779 343.953L723.41 365.873V363.103L758.382 342.592L760.779 343.953Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M723.41 359.448L758.382 338.922V342.592L723.41 363.103V359.448Z"
        fill="#896D02"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M760.781 343.953V337.514L758.381 338.921L723.41 359.448V365.872L760.781 343.953ZM763.18 333.797V344.871L721.011 369.604V358.53L763.18 333.797Z"
        fill="#FFCE10"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M721.011 358.53V369.604L709.899 363.287V352.213L721.011 358.53Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M709.899 352.213C713.033 351.677 715.83 351.112 718.81 350.362C721.652 349.659 724.453 348.802 727.201 347.792C729.804 346.845 732.342 345.726 734.798 344.443C737.074 343.256 739.247 341.88 741.293 340.328C743.168 338.911 744.908 337.324 746.49 335.587C747.934 333.993 749.219 332.261 750.327 330.417C750.892 329.484 751.412 328.489 751.87 327.526L752.023 327.449L763.181 333.797L721.011 358.53L709.899 352.213Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M760.78 337.515V343.953L758.382 342.592V338.921L760.78 337.515Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M715.626 277.475L690.057 292.247V327.602L715.626 312.83V277.475Z"
        fill="#E1AF7E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M687.649 261.309L661.965 276.148L689.942 292.314L715.626 277.475L687.649 261.309Z"
        fill="#E1AF7E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M690.056 292.248L662.079 276.082V311.439L690.056 327.602V292.248Z"
        fill="#AE7C4B"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M696.242 266.274L670.676 281.047L679.175 285.958L704.742 271.186L696.242 266.274Z"
        fill="#D4BB9D"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M679.175 285.958L670.676 281.047V295.237L679.175 300.148V285.958Z"
        fill="#D4BB9D"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M703.956 273.825L693.791 279.699L699.987 283.278L710.149 277.406L703.956 273.825Z"
        fill="#D4BB9D"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3062 140.138L8.65625 144.556V374.371L16.3062 369.952V140.138Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.65667 144.556L1.00684 140.138V369.952L8.65667 374.371V144.556Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.65667 135.716L1.00684 140.137L8.65667 144.556L16.3066 140.137L8.65667 135.716Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M100.448 91.5216L92.7979 95.9403V325.757L100.448 321.336V91.5216Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M92.7968 95.9403L85.1494 91.5216V321.336L92.7968 325.757V95.9403Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M92.7968 87.103L85.1494 91.5217L92.7968 95.9404L100.447 91.5217L92.7968 87.103Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M100.447 259.465L8.65625 312.499L199.887 422.987L291.678 369.952L100.447 259.465Z"
      fill="#EAEAEA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M199.887 422.987L8.65625 312.499V321.336L199.887 431.826V422.987Z"
      fill="#B3B3B3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M291.678 369.952L199.888 422.986V431.826L291.678 378.792V369.952Z"
      fill="#CECECE"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M100.447 119.992L8.65625 173.026L199.887 283.514L291.678 230.479L100.447 119.992Z"
      fill="#EAEAEA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M199.887 283.513L8.65625 173.026V181.866L199.887 292.353V283.513Z"
      fill="#B3B3B3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M291.678 230.479L199.888 283.513V292.353L291.678 239.319V230.479Z"
      fill="#CECECE"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M141.594 115.295L80.4004 150.652V203.686L141.594 168.329V115.295Z"
      fill="#D1A561"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M141.594 115.295L80.4004 150.652V203.686L141.594 168.329V115.295Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M80.4005 150.652L34.5039 124.135V177.169L80.4005 203.686V150.652Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.6977 88.7776L34.5039 124.135L80.4005 150.652L141.594 115.295L95.6977 88.7776Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M115.186 100.037L53.9922 135.392L60.8564 139.359L122.05 104.002L115.186 100.037Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M60.8564 139.359L53.9922 135.392V153.072L60.8564 157.036V139.359Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M133.944 155.071L110.997 168.329V177.169L133.944 163.911V155.071Z"
      fill="#E0E0E0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M117.073 166.065L111.926 169.039V175.385L117.073 172.411V166.065Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.47 162.948L117.613 165.754V167.015L122.47 164.207V162.948Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.47 162.948L117.613 165.754V167.015L122.47 164.207V162.948Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M133.304 156.686L124.096 162.009V162.639L133.304 157.317V156.686Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M133.304 156.686L124.096 162.009V162.639L133.304 157.317V156.686Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M133.304 157.968L124.096 163.29V163.921L133.304 158.599V157.968Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M133.304 159.252L124.096 164.572V165.202L133.304 159.882V159.252Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M133.304 160.811L132.215 161.439V162.394L133.304 161.766V160.811Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.842 161.657L130.753 162.285V163.239L131.842 162.611V161.657Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.47 164.86L117.613 167.666V168.927L122.47 166.119V164.86Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.47 166.772L117.613 169.577V170.838L122.47 168.033V166.772Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M193.45 189.45L170.5 202.709V211.549L193.45 198.29V189.45Z"
      fill="#E0E0E0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M176.578 200.444L171.428 203.421V209.764L176.578 206.79V200.444Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M181.976 197.327L177.117 200.133V201.394L181.976 198.588V197.327Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M181.976 197.327L177.117 200.133V201.394L181.976 198.588V197.327Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.81 191.066L183.599 196.388V197.019L192.81 191.696V191.066Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.81 191.066L183.599 196.388V197.019L192.81 191.696V191.066Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.81 192.35L183.599 197.669V198.3L192.81 192.98V192.35Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.81 193.631L183.599 198.954V199.582L192.81 194.262V193.631Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.809 195.191L191.72 195.819V196.776L192.809 196.146V195.191Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M191.347 196.036L190.258 196.664V197.621L191.347 196.99V196.036Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M181.976 199.24L177.117 202.045V203.306L181.976 200.501V199.24Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M181.976 201.151L177.117 203.957V205.218L181.976 202.412V201.151Z"
      fill="#969696"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M261.081 184.332L199.888 219.689V272.723L261.081 237.366V184.332Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M199.888 219.689L153.991 193.172V246.206L199.888 272.723V219.689Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M215.185 157.815L153.991 193.172L199.888 219.689L261.081 184.332L215.185 157.815Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M234.673 169.074L173.479 204.429L180.344 208.396L241.537 173.039L234.673 169.074Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M180.344 208.396L173.479 204.429V222.109L180.344 226.073V208.396Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M253.431 224.108L230.484 237.366V246.206L253.431 232.948V224.108Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M127.006 258.898L101.439 273.67V309.025L127.006 294.252V258.898Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M99.0291 242.732L73.3477 257.573L101.325 273.737L127.006 258.898L99.0291 242.732Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M101.439 273.67L73.4619 257.504V292.861L101.439 309.025V273.67Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M107.625 247.699L82.0557 262.472L90.5574 267.383L116.124 252.611L107.625 247.699Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M90.5574 267.383L82.0557 262.472V276.659L90.5574 281.571V267.383Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M115.336 255.248L105.174 261.121L111.367 264.7L121.532 258.829L115.336 255.248Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M160.055 277.992L134.485 292.764V328.119L160.055 313.347V277.992Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M132.078 261.826L106.394 276.664L134.371 292.831L160.055 277.992L132.078 261.826Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M134.485 292.764L106.508 276.598V311.955L134.485 328.119V292.764Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M140.671 266.791L115.104 281.563L123.604 286.475L149.17 271.702L140.671 266.791Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M123.604 286.475L115.104 281.563V295.754L123.604 300.665V286.475Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M148.385 274.341L138.22 280.215L144.416 283.794L154.578 277.923L148.385 274.341Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M123.335 299.204L97.7686 313.977V349.334L123.335 334.562V299.204Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.3578 283.041L69.6738 297.88L97.651 314.043L123.335 299.205L95.3578 283.041Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M97.7691 313.977L69.792 297.813V333.171L97.7691 349.334V313.977Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M103.952 288.006L78.3857 302.779L86.885 307.69L112.454 292.918L103.952 288.006Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M86.885 307.69L78.3857 302.778V316.969L86.885 321.88V307.69Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M111.665 295.557L101.5 301.431L107.696 305.009L117.861 299.136L111.665 295.557Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M210.446 308.366L149.252 343.723V379.08L210.446 343.723V308.366Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M164.549 281.849L103.24 317.273L149.134 343.79L210.445 308.366L164.549 281.849Z"
      fill="#E1AF7E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M149.252 343.723L103.355 317.206V352.563L149.252 379.081V343.723Z"
      fill="#AE7C4B"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M183.189 292.619L121.993 327.976L130.495 332.887L191.689 297.53L183.189 292.619Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130.495 332.887L121.993 327.976V342.166L130.495 347.078V332.887Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M134.479 304.706L115.746 315.527L125.248 321.015L143.978 310.194L134.479 304.706Z"
      fill="#D4BB9D"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M215.184 255.043L207.537 259.465V489.279L215.184 484.861V255.043Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M207.538 259.465L199.888 255.043V484.861L207.538 489.279V259.465Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M207.538 250.625L199.888 255.044L207.538 259.465L215.185 255.044L207.538 250.625Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M291.678 210.849L284.028 215.268V445.085L291.678 440.663V210.849Z"
      fill="#E3E3E3"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M284.028 215.268L276.381 210.849V440.663L284.028 445.085V215.268Z"
      fill="#C4C4C4"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M284.028 206.43L276.381 210.849L284.028 215.268L291.678 210.849L284.028 206.43Z"
      fill="#AAAAAA"
      stroke="#3D464D"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M179.384 660.183V657.136L89.3125 605.163V608.215L179.384 660.183Z"
      fill="#D6D7D8"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M237.969 610.411L217.816 622.038L154.431 585.467L174.584 573.841L237.969 610.411Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M236.601 616.546L232.524 618.897L159.639 576.846L163.712 574.494L236.601 616.546Z"
      fill="#D6D7D8"
      stroke="black"
      stroke-width="0.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M179.384 657.136L264.655 607.934L174.585 555.968L89.3125 605.163L179.384 657.136Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M142.308 584.591L106.558 605.214L178.869 646.937L214.623 626.311L142.308 584.591Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M224.876 451.478L211.041 459.463L167.524 434.354L181.362 426.373L224.876 451.478Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M179.385 660.183L264.656 610.985V607.934L179.385 657.136V660.183Z"
      fill="#D6D7D8"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M198.479 385.707C197.737 385.33 196.916 385.134 196.084 385.134C195.251 385.134 194.431 385.33 193.688 385.707C193.415 385.828 193.179 386.02 193.004 386.263C192.829 386.506 192.722 386.791 192.694 387.089V443.469C192.723 443.767 192.83 444.051 193.005 444.294C193.179 444.537 193.415 444.73 193.688 444.852C194.431 445.228 195.252 445.423 196.084 445.423C196.916 445.423 197.737 445.228 198.479 444.852C198.752 444.73 198.988 444.537 199.162 444.294C199.337 444.051 199.444 443.766 199.472 443.469V387.089C199.444 386.791 199.338 386.506 199.163 386.263C198.989 386.02 198.753 385.828 198.479 385.707Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M198.479 385.707C198.768 385.804 199.02 385.99 199.198 386.238C199.376 386.486 199.472 386.783 199.472 387.089C199.472 387.394 199.376 387.691 199.198 387.939C199.02 388.187 198.768 388.373 198.479 388.47C197.737 388.847 196.916 389.043 196.084 389.043C195.251 389.043 194.431 388.847 193.688 388.47C193.399 388.373 193.147 388.187 192.969 387.939C192.791 387.691 192.695 387.394 192.695 387.089C192.695 386.783 192.791 386.486 192.969 386.238C193.147 385.99 193.399 385.804 193.688 385.707C194.431 385.33 195.251 385.134 196.084 385.134C196.916 385.134 197.737 385.33 198.479 385.707Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M224.876 606.375L211.041 614.358V459.463L224.876 451.478V606.375Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M211.041 459.463V614.358L167.524 589.25V434.354L211.041 459.463Z"
      fill="#D6D7D8"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M236.601 449.128L232.524 451.478V380.403L236.601 378.053V449.128Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M236.601 378.053L232.524 380.403L159.639 338.35L163.712 336L236.601 378.053Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M232.524 451.478V380.403L159.639 338.35V409.426L232.524 451.478Z"
      fill="#F4F4F4"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M161.701 408.237V341.92L230.464 381.593V447.911L161.701 408.237Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M221.906 390.87C221.906 394.914 219.064 396.554 215.561 394.531C213.713 393.328 212.175 391.706 211.071 389.797C209.968 387.889 209.331 385.747 209.211 383.546C209.213 379.5 212.055 377.861 215.561 379.884V378.161C211.231 375.662 207.721 377.688 207.721 382.684C207.868 385.402 208.655 388.048 210.017 390.405C211.379 392.763 213.279 394.766 215.561 396.253C219.888 398.752 223.4 396.727 223.4 391.729L221.906 390.87Z"
      fill="#5EA7FF"
    />
    <path
      opacity="0.3"
      d="M215.562 378.161V379.884C217.409 381.088 218.946 382.71 220.048 384.619C221.151 386.527 221.788 388.669 221.907 390.87L223.4 391.729C223.253 389.011 222.466 386.366 221.104 384.009C219.742 381.651 217.843 379.648 215.562 378.161Z"
      fill="#5EA7FF"
    />
    <path
      d="M209.212 405.702C209.212 401.659 212.055 400.018 215.561 402.042V400.319C212.962 398.82 210.743 398.843 209.216 400.387C206.671 402.963 207.45 409.068 210.952 414.027L211.83 413.139C210.24 410.973 209.329 408.385 209.212 405.702Z"
      fill="#5EA7FF"
    />
    <path
      opacity="0.3"
      d="M215.561 400.319V402.041C217.408 403.246 218.945 404.868 220.048 406.777C221.15 408.686 221.787 410.827 221.906 413.028C221.906 417.073 219.064 418.711 215.561 416.689C214.078 415.785 212.805 414.574 211.829 413.139L210.951 414.027C212.144 415.812 213.719 417.309 215.561 418.413C219.888 420.912 223.399 418.884 223.399 413.89C223.252 411.171 222.465 408.526 221.103 406.168C219.742 403.81 217.843 401.806 215.561 400.319Z"
      fill="#5EA7FF"
    />
    <path
      d="M215.651 423.895L205.334 417.942V416.19L215.651 422.141V423.895Z"
      fill="#5EA7FF"
    />
    <path
      d="M221.818 430.858L205.334 421.349V419.594L221.818 429.107V430.858Z"
      fill="#5EA7FF"
    />
    <path
      d="M225.788 436.556L205.334 424.754V423.003L225.788 434.805V436.556Z"
      fill="#5EA7FF"
    />
    <path
      d="M211.065 431.468L205.334 428.162V426.408L211.065 429.718V431.468Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 355.824L166.05 354.737V352.566L167.931 353.652V355.824Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 375.054L169.268 356.593V354.422L201.265 372.881V375.054Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 360.445L166.05 359.359V357.188L167.931 358.273V360.445Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 379.675L169.268 361.215V359.045L201.265 377.505V379.675Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 365.067L166.05 363.981V361.81L167.931 362.895V365.067Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 384.296L169.268 365.837V363.666L201.265 382.126V384.296Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 369.688L166.05 368.603V366.431L167.931 367.516V369.688Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 388.918L169.268 370.459V368.287L201.265 386.747V388.918Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 374.309L166.05 373.223V371.053L167.931 372.137V374.309Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 393.539L169.268 375.081V372.909L201.265 391.369V393.539Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 378.929L166.05 377.845V375.674L167.931 376.758V378.929Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 398.162L169.268 379.7V377.53L201.265 395.989V398.162Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 383.553L166.05 382.466V380.296L167.931 381.38V383.553Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 402.782L169.268 384.323V382.151L201.265 400.611V402.782Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 388.174L166.05 387.087V384.916L167.931 386.003V388.174Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 407.404L169.268 388.943V386.773L201.265 405.233V407.404Z"
      fill="#5EA7FF"
    />
    <path
      d="M167.931 392.795L166.05 391.709V389.538L167.931 390.624V392.795Z"
      fill="#5EA7FF"
    />
    <path
      d="M201.265 412.026L169.268 393.567V391.395L201.265 409.853V412.026Z"
      fill="#5EA7FF"
    />
    <path
      d="M200.337 424.037L194.212 420.504V413.434L200.337 416.967V424.037Z"
      fill="#5EA7FF"
    />
    <path
      d="M191.39 418.736L185.267 415.204V408.134L191.39 411.667V418.736Z"
      fill="#5EA7FF"
    />
    <path
      d="M182.681 414.027L176.556 410.494V403.424L182.681 406.959V414.027Z"
      fill="#5EA7FF"
    />
    <path
      d="M83.3841 426.143L79.5111 419.975C79.5111 419.975 71.8059 421.998 70.4082 427.753C69.009 433.504 64.7105 444.341 63.8623 449.981C63.0099 455.618 62.0907 473.42 65.9324 475.105C67.5298 475.931 69.3004 476.367 71.0989 476.377C72.8974 476.387 74.6726 475.971 76.2791 475.163C79.6584 473.539 85.7734 467.701 85.7734 467.701L83.3841 426.143Z"
      fill="#6CA4FF"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M122.334 381.529C125.726 383.71 127.914 389.086 128.448 393.268C128.982 397.447 127.987 398.418 127.987 398.418C127.987 398.418 130.624 401.164 130.237 401.943C129.328 402.906 128.286 403.734 127.142 404.402C127.282 405.702 127.248 407.016 127.041 408.307C126.683 409.519 125.461 412.1 125.559 413.487C125.664 414.875 125.179 417.253 123.722 417.822C122.27 418.395 116.204 417.377 115.457 419.166C114.708 420.951 113.862 430.42 113.862 430.42C113.862 430.42 103.982 435.777 96.0602 430.943C88.1396 426.112 92.0385 417.657 92.0385 417.657C92.0385 417.657 94.9637 413.284 94.1387 410.037C93.3164 406.791 88.9251 400.692 90.5711 392.818C92.2199 384.945 96.7844 380.977 104.555 379.053C112.323 377.13 117.274 378.272 122.334 381.529Z"
      fill="#FFD2B3"
      stroke="black"
      stroke-width="0.25"
    />
    <path
      d="M114.907 409.151C114.945 407.013 114.354 405.752 112.285 406.341C110.215 406.928 108.832 410.365 110.41 412.866C111.989 415.364 114.745 417.671 114.907 409.151Z"
      fill="#FCC7A2"
    />
    <path
      d="M115.333 376.444C105.493 375.933 93.3951 378.647 90.3185 389.756C87.2432 400.861 91.8458 404.314 91.8458 404.314C92.7581 406.698 93.7823 408.633 94.1382 410.037C94.3085 410.783 94.3293 411.556 94.1997 412.311C96.1051 413.415 98.2163 414.117 100.403 414.375C104.17 414.496 104.216 415.399 106.548 411.917C108.876 408.438 111.26 404.834 114.121 405.476C116.981 406.116 118.875 407.015 119.38 405.688C119.821 404.755 120.017 403.724 119.951 402.694C119.947 401.277 122.31 399.815 123.149 397.661C123.984 395.503 121.499 393.007 123.734 391.108C124.977 390.266 126.116 389.281 127.127 388.172C127.569 387.894 127.939 387.515 128.208 387.067C128.477 386.619 128.636 386.114 128.674 385.593C128.769 383.845 125.173 376.953 115.333 376.444Z"
      fill="#B07443"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M120.412 434.21C120.412 434.21 124.836 436.834 125.941 436.919C127.047 437.001 128.345 436.834 127.933 435.841C127.517 434.848 123.978 432.081 123.978 432.081C123.978 432.081 125.911 431.75 126.217 431.75C126.52 431.75 130.585 434.488 131.967 434.595C133.353 434.709 135.012 433.546 134.321 432.801C132.379 431.588 130.248 430.709 128.015 430.203C127.128 430.203 121.294 432.746 120.412 434.21Z"
      fill="#FFD2B3"
      stroke="black"
      stroke-width="0.25"
    />
    <path
      d="M120.841 443C120.841 443 131.276 442.566 132.217 442.205C132.488 441.993 132.695 441.71 132.815 441.387C132.935 441.064 132.963 440.715 132.897 440.377C132.83 440.039 132.671 439.727 132.437 439.474C132.204 439.221 131.905 439.037 131.573 438.944C128.251 438.559 124.907 438.39 121.562 438.438C121.273 438.438 119.316 436.191 121.998 434.09C124.684 431.988 125.417 432.193 127.325 432.193C129.883 432.214 132.414 432.711 134.789 433.658C136.588 434.595 138.58 435.121 138.853 434.071C139.128 433.02 134.264 429.899 132.273 428.49C130.518 427.263 128.415 426.633 126.274 426.693C123.838 426.555 119.913 426.774 118.199 427.661C116.482 428.543 112.087 431.391 112.087 431.391L120.841 443Z"
      fill="#FFD2B3"
      stroke="black"
      stroke-width="0.25"
    />
    <path
      d="M144.811 660.525C144.763 659.394 144.381 658.302 143.712 657.387C143.043 656.473 142.118 655.777 141.054 655.387C137.388 654.187 131.23 655.017 126.116 653.724C121.004 652.432 118.757 651.385 118.94 648.215L105.465 649.707C105.383 651.714 104.969 653.693 104.241 655.565C102.77 657.815 101.851 660.381 101.56 663.054C101.269 665.726 101.615 668.43 102.568 670.944L103.212 674.802C104.516 675.461 105.938 675.855 107.396 675.961C110.037 676.216 116.799 674.672 120.147 672.873L119.759 670.944C123.053 670.534 126.371 670.343 129.69 670.373C134.649 670.463 140.129 668.311 142.592 665.97C145.058 663.634 144.932 662.312 144.811 660.525Z"
      fill="#8491A1"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M144.834 660.88C143.947 662.618 142.752 664.181 141.308 665.494C138.871 667.756 132.333 669.011 129.046 669.011C125.761 669.011 121.156 669.011 118.783 669.755C116.413 670.498 110.418 672.599 106.389 672.263C105.031 672.187 103.717 671.755 102.579 671.01L103.212 674.802C104.516 675.461 105.938 675.855 107.396 675.961C110.037 676.216 116.799 674.672 120.147 672.873L119.759 670.944C123.053 670.534 126.371 670.343 129.69 670.373C134.649 670.463 140.129 668.311 142.592 665.97C144.89 663.79 144.939 662.493 144.834 660.88Z"
      fill="#414E52"
    />
    <path
      d="M86.2465 624.341C86.2465 624.341 83.6349 631.304 83.3867 634.533V638.699C83.8862 639.497 84.5489 640.181 85.3312 640.706C86.1135 641.231 86.998 641.585 87.9266 641.745C90.9119 642.118 93.9584 641.994 95.9494 639.382C97.0955 637.91 98.056 636.303 98.8093 634.597V633.122C99.5711 631.985 100.611 631.062 101.83 630.439C103.05 629.816 104.408 629.515 105.776 629.564C110.876 629.439 118.088 621.98 117.406 618.75C116.722 615.515 112.553 613.527 107.08 613.343C103.627 613.283 100.204 614.004 97.0691 615.453L86.2465 624.341Z"
      fill="#8491A1"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M98.8093 633.122C99.5711 631.985 100.611 631.062 101.83 630.439C103.05 629.816 104.408 629.515 105.776 629.564C110.582 629.447 117.262 622.817 117.445 619.35C114.514 624.18 109.632 627.801 105.031 628.568C100.18 629.377 98.7478 631.987 96.9436 634.784C95.1408 637.579 94.2708 639.072 88.1748 638.699C87.2323 638.723 86.2989 638.509 85.4617 638.076C84.6245 637.643 83.9106 637.005 83.3867 636.221V638.699C83.8862 639.497 84.5489 640.181 85.3312 640.706C86.1135 641.231 86.998 641.585 87.9266 641.745C90.9119 642.118 93.9584 641.994 95.9494 639.382C97.0955 637.91 98.056 636.303 98.8093 634.597V633.122Z"
      fill="#414E52"
    />
    <path
      d="M101.873 505.196L99.6756 499.251C99.6756 499.251 89.4858 494.927 85.9591 491.607C85.9591 491.607 83.8453 500.464 83.6435 501.169C83.4403 501.873 80.3187 512.822 80.9242 520.132C81.5284 527.436 84.8354 530.96 84.8354 530.96C84.8354 530.96 85.0918 558.236 84.1849 563.672C83.2767 569.107 81.2733 578.065 80.8466 584.607C80.4211 591.15 85.9797 624.192 84.0035 631.322C84.0035 631.322 84.8654 635.845 91.3487 634.627C97.8319 633.414 103.182 621.679 103.484 619.35C103.789 617.014 101.919 606.938 101.481 599.881C101.217 595.634 100.866 578.87 102.68 574.441C104.491 570.013 110.947 542.12 110.947 542.12L101.873 505.196Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M131.097 501.954C132.3 504.73 133.06 507.678 133.348 510.69C133.551 515.642 134.4 520.546 135.872 525.279C137.313 529.329 138.938 541.579 136.593 560.582C134.252 579.582 130.917 580.571 128.033 596.42C125.149 612.272 123.489 647.625 125.581 653.587C125.581 653.587 125.87 661.816 116.407 663.606C106.946 665.4 100.727 662.102 100.727 662.102C100.727 662.102 101.942 642.039 102.032 634.838C102.122 627.633 100.638 615.961 104.241 604.345C107.848 592.73 108.657 587.596 108.028 579.759C107.396 571.928 105.236 551.754 105.774 543.108C105.774 543.108 89.3739 531.852 97.5727 503.938L99.6756 499.251C99.6756 499.251 112.8 508.53 131.097 501.954Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M90.6592 420.003H84.1364C84.1364 420.003 72.212 419.306 70.1337 427.458C68.058 435.612 69.3754 440.017 71.6037 448.268C73.8349 456.521 80.3071 469.143 80.8212 470.184C81.3381 471.224 89.7946 477.282 89.7946 477.282C89.7946 477.282 111.378 488.57 118.927 488.873C126.475 489.179 130.03 482.913 130.03 477.793C130.03 473.041 129.545 461.317 129.475 457.267C129.47 456.953 129.467 456.685 129.467 456.471C129.467 453.484 129.617 443.306 128.908 442.749C128.2 442.192 122.322 438.445 122.322 438.445L117.807 434.953L90.6592 420.003Z"
      fill="#6CA4FF"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M130.03 477.793C129.936 479.761 129.112 481.624 127.717 483.017C126.323 484.411 124.459 485.235 122.489 485.329C115.234 485.832 99.7431 481.38 95.2796 463.582C95.2796 463.582 105.874 435.121 122.018 438.298L117.807 434.827C117.807 434.827 100.512 431.753 91.7175 461.717L90.8747 461.277L91.1516 461.182C82.3459 440.215 88.615 420.003 88.615 420.003H84.1365C80.0058 438.881 84.0029 453.079 86.3336 459.135C86.0607 460.6 84.3138 469.155 80.8213 470.184C80.8213 470.184 86.073 480.664 86.3594 482.671C86.6485 484.682 81.7309 496.085 84.4775 497.776C87.2241 499.468 96.2737 506.216 108.913 507.796C121.553 509.372 131.727 506.511 132.459 505.788C133.189 505.066 130.03 482.816 130.03 477.793Z"
      fill="#CDCDCD"
      stroke="black"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.4287 416.075C91.4287 416.075 96.1022 418.118 104.225 422.104C108.906 424.308 113.01 427.569 116.215 431.63L117.807 434.827C117.807 434.827 102.579 429.958 95.7203 426.352C88.8635 422.747 88.6152 419.295 88.6152 419.295L91.4287 416.075Z"
      fill="#6CA4FF"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M186.861 412.419C186.222 412.508 185.628 412.798 185.166 413.247C184.703 413.697 184.396 414.281 184.289 414.917C183.694 417.05 181.14 421.727 180.637 423.25C179.88 425.078 178.924 426.816 177.784 428.433C177.426 428.976 177.238 429.612 177.242 430.261C177.246 430.91 177.442 431.544 177.805 432.082C178.408 433.161 178.858 434.318 179.145 435.519C179.097 435.721 174.487 444.921 174.487 444.921L184.756 449.165C184.756 449.165 189.201 438.7 189.551 437.888C189.903 437.073 193.029 434.421 193.985 433.096C194.939 431.773 196.542 428.824 196.285 428.016C196.026 427.208 194.298 426.559 194.298 426.559C194.298 426.559 194.435 424.429 193.569 423.778C192.942 423.323 192.279 422.921 191.586 422.574C191.586 422.574 191.972 419.429 190.549 418.831C189.126 418.23 188.123 420.213 187.465 420.116C186.803 420.019 187.34 416.62 187.687 415.2C188.032 413.779 187.872 412.412 186.861 412.419Z"
      fill="#FFD2B3"
      stroke="black"
      stroke-width="0.25"
    />
    <path
      d="M118.253 438.659C121.211 437.542 125.868 439.361 134.186 444.394C142.506 449.429 163.592 460.082 163.592 460.082L179 434.902C182.673 436.283 186.188 438.051 189.485 440.177L187.493 445.098C187.863 447.099 187.531 449.165 186.555 450.95C184.914 453.995 174.018 476.122 168.982 477.058C163.947 477.994 144.291 472.023 137.305 470.152C130.321 468.279 120.285 465.584 114.935 458.677C109.584 451.77 112.882 440.686 118.253 438.659Z"
      fill="#6CA4FF"
      stroke="#3D464D"
      stroke-width="0.25"
    />
  </svg>
</template>
