<template>
  <div class="smart-group-1">
    <smart-el1 @click="smallModalOpened = 1" class="smart-tv-item smart-el1" />
    <div class="tractor-animation-wrap">
      <smart-el2
        @click="smallModalOpened = 2"
        class="smart-tv-item smart-el2"
      />
    </div>
    <smart-el3
      @click="smallModalOpened = 3"
      class="smart-tv-item smart-el3 drone-animation-wrap"
    />

    <app-modal v-model="modalOpened">
      <template #content>
        <smart-modal-content />
      </template>
    </app-modal>
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content="{ value }">
      <div class="small-modal-content">
        <smart-el1 v-if="value === 1" class="small-modal-image" />
        <smart-el2 v-else-if="value === 2" class="small-modal-image" />
        <smart-el3 v-else-if="value === 3" class="small-modal-image" />
        <p class="small-modal-title">Smart Agriculture</p>
        <p class="small-modal-text">
          The OpenLab Smart Agriculture focuses on the broad field of connected
          and intelligent agriculture.
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import SmartEl1 from "@/components/Smart/SmartEl1.vue";
import SmartEl2 from "@/components/Smart/SmartEl2.vue";
import SmartEl3 from "@/components/Smart/SmartEl3.vue";
import AppModal from "@/components/AppModal.vue";
import SmartModalContent from "@/components/Smart/SmartModalContent.vue";

const smallModalOpened = ref<number | false>(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.smart-group-1 {
  position: relative;
  display: flex;
  pointer-events: none;
  width: 800px;
  height: 500px;

  .smart-tv-item {
    position: absolute;
    transform: scale(0.26);
    cursor: pointer;
  }

  .smart-el1 {
    top: -700px;
    left: -365px;
  }
  .smart-el2 {
    top: 0;
    left: -160px;
  }
  .smart-el3 {
    top: -60px;
    left: 455px;
  }

  @media (min-width: 1531px) {
    width: 1300px;
    height: 850px;
    .smart-tv-item {
      transform: scale(0.4);
    }
    .smart-el1 {
      top: -550px;
      left: -130px;
    }
    .smart-el2 {
      top: 300px;
      left: -150px;
    }
    .smart-el3 {
      top: 70px;
      left: 900px;
    }
  }

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    width: 1836px;
    height: 3000px;
    .smart-tv-item {
      transform: none;
    }
    .smart-el1 {
      top: 135px;
      left: 40px;
    }
    .smart-el2 {
      top: 2045px;
      left: 150px;
    }
    .smart-el3 {
      top: 1750px;
      left: 1305px;
    }
  }

  svg {
    pointer-events: auto;
    overflow: visible;
  }
}
</style>
