<template>
  <app-header
    :color="theme.header"
    :button-color="theme.burgerColor"
    :tablet-color="theme.tabletHeader"
  />
  <main :class="{ home: isDarkPage }">
    <router-view />
  </main>
  <!--  <img class="placeholder-img" src="@/assets/placeholder.png" alt="" />-->
  <!--  <footer>Footer</footer>-->
</template>

<script setup lang="ts">
import AppHeader from "@/components/AppHeader.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const isHomePage = computed(() => ["home"].includes(route.name as string));
const isDarkPage = computed(() =>
  ["home", "imprint", "privacy"].includes(route.name as string)
);
const isContentPage = computed(() =>
  ["imprint", "privacy"].includes(route.name as string)
);

const theme = computed(() => ({
  header: isDarkPage.value ? "#005478" : "#DBF3FF",
  burgerColor: isDarkPage.value ? "#ffffff" : "#5A5F62",
  textColor: isDarkPage.value ? "#ffffff" : "#005478",
  tabletHeader: isContentPage.value
    ? isDarkPage.value
      ? "#005478"
      : "#DBF3FF"
    : "transparent",
  desktopTopOffset: isHomePage.value ? "156.5px" : "70px",
  tvTopOffset: isHomePage.value ? "0" : "350px",
  sectionHeight: !isHomePage.value ? "100%" : "100vh",
}));
</script>

<style scoped>
main {
  padding: calc(112px + 8px) 0 24px;
  color: v-bind(theme[ "textColor"]);
  min-height: 100vh;
  box-sizing: border-box;
  background: linear-gradient(180deg, #dbf3ff 0%, #dbf3ff 344px, #ffffff 100%);

  &.home {
    background: linear-gradient(
      180deg,
      #005478 0%,
      #005478 344px,
      #2882aa 100%
    );
    @media (min-width: 1280px) {
      background: linear-gradient(
        180deg,
        #005478 0%,
        #005478 156.5px,
        #2882aa 100vh
      );
    }

    @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
      background: linear-gradient(
        180deg,
        #005478 0%,
        #005478 350px,
        #2882aa 100vh
      );
    }
  }

  @media (min-width: 786px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: v-bind(theme[ "sectionHeight"]);
    overflow: hidden;
  }

  @media (min-width: 1280px) {
    padding: v-bind(theme[ "desktopTopOffset"]) 0 50px;
    background: linear-gradient(
      180deg,
      #dbf3ff 0%,
      #dbf3ff 156.5px,
      #ffffff 100vh
    );
  }

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    padding: v-bind(theme[ "tvTopOffset"]) 0 80px;
    background: linear-gradient(
      180deg,
      #dbf3ff 0%,
      #dbf3ff 350px,
      #ffffff 100vh
    );
  }
}

.placeholder-img {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  opacity: 0.3;
  pointer-events: none;
  display: none;
}
</style>
