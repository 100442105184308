<template>
  <div class="info-modal-content">
    <p class="info-modal-title">E-Health Applications</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        E-Health applications aim to enhance healthcare through advanced
        technology.
      </p>
      <p class="info-modal-text">
        Thereby different modalities should not just be considered stand-alone,
        but together to gain a holistic insight in a patient's condition. By
        monitoring of vital signs, motion signals and brain activity in
        real-time, machine learning algorithms can be used to detect emergencies
        like strokes, heart attack and tumbling. Additionally, tele-consulting
        facilitates remote consultations and data sharing, making healthcare
        accessible for patients in remote locations or those unable to travel,
        and allowing immediate medical decision-making based on live data.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/connectedWorlds/group2/01.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        Equipment for near-infrared spectroscopy
      </p>
    </div>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        EEG-based Authentication
      </p>
      <p class="info-modal-text">
        Biometric systems to measure inherent human characteristics in
        combination with methods of Artificial Intelligence (AI), can result in
        innovative applications, especially in the fields of remote access
        systems and human-machine interfaces. One of these involves the use of
        Electroencephalography (EEG) equipment to monitor and visualize brain
        activity. Besides traditional EEG applications, such as medical aspects
        and neuroscience purposes, it can be used to distinguish individuals
        based on unique and characteristic signals.
      </p>
      <p class="info-modal-text">
        The ability to recognize a person without their physical attendance is
        the first step for future remote access to Brain-Computer Interface
        (BCI) applications. Therefore, in this work, a novel Deep Neural Network
        (DNN), based on inception modules with a kernel-adapted modification, is
        developed.
      </p>
      <p class="info-modal-text">
        Inception-based DNNs recently gained much interest for Time Series
        Classification (TSC), since they provide comparable performance to very
        deep Convolutional Neural Networks (CNN) with much less computational
        complexity. To the best of knowledge, this is the first inception-based
        DNN developed for authentication purposes using EEG. To validate the
        proposed network's performance it is compared to three other
        inception-based DNNs, namely: InceptionTime, EEG-Inception, and
        EEG-ITNet. Using an anonymized dataset of 22 participants with a length
        of 1 s per epoch, the proposed network achieves an average recall and
        precision of 99.1 % and 99.4 %, respectively, outperforming the other
        DNNs, with EEG-Inception achieving the best results with an average
        recall and precision of 96.8 % and 97.1 %, respectively.
      </p>
      <p class="info-modal-text">
        Current studies focus on the aspect of temporal stability: In general
        EEG-signals vary drasticly even over the course of a day and especially
        within days or weeks. Out investigations tackle whether AI systems can
        cope with this temporal instability and still perform with high
        accuracy.
      </p>
    </div>
    <div>
      <p class="info-modal-text info-modal-text-small info-modal-text-bolt">
        Publications:
      </p>
      <p class="info-modal-text info-modal-text-small">
        J. Herbst et al., "Inception Based Deep Learning: Biometric
        Identification Using Electroencephalography (EEG)," 2023 International
        Symposium on Networks, Computers and Communications (ISNCC), Doha,
        Qatar, 2023, pp. 1-7, doi: 10.1109/ISNCC58260.2023.10323854.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
