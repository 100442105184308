<template>
  <div
    class="logic-tv-item logic-group-6 box-animation-wrap cars-animation-wrap"
  >
    <logic6-border class="logic6-border" />
    <logic6-el1 @click="smallModalOpened = true" class="logic6-el1" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content>
      <div class="small-modal-content">
        <logic6-el1 class="small-modal-image" />
        <p class="small-modal-title">Collaborative Robots</p>
        <p class="small-modal-text">
          6G technology will significantly enhance these applications by
          offering ultra-low latency, high data rates, ...
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <logic6-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logic6Border from "@/components/Logic/Logic6/components/Logic6Border.vue";
import Logic6El1 from "@/components/Logic/Logic6/components/Logic6El1.vue";
import AppModal from "@/components/AppModal.vue";
import Logic6ModalContent from "@/components/Logic/Logic6/components/Logic6ModalContent.vue";

const smallModalOpened = ref(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.logic-group-6 {
  position: relative;
  display: flex;
  width: 1531px;
  height: 882px;
  pointer-events: none;

  svg {
    pointer-events: auto;
  }

  .logic6-border {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
  }
  .logic6-el1 {
    position: absolute;
    top: 0;
    left: 459px;
  }
}
</style>
