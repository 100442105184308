<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Reconfigurable Intelligent Surface</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        A reconfigurable intelligent surface, or short RIS, is a device that
        interacts with electromagnetic radiation of a certain frequency range in
        order to control the angle of deflection or shape of an incoming beam or
        even split up a beam in several parts.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group2/01.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        RIS Concept
      </p>
    </div>
    <div>
      <p class="info-modal-text">
        This can for example be used to redirect signals around obstacles and to
        send information transmitted by a single sender to a multitude of
        receivers. The RIS fabricated in the Open6Ghub project is based on a
        circuit board that is divided into hundreds of unit cells each equipped
        with a varactor diode.
      </p>
      <p class="info-modal-text">
        If a voltage is applied to those components their electromagnetic
        properties, especially their capacitance, change drastically. Depending
        on the applied voltage, the incoming electromagnetic radiation exhibits
        a different phase shift at each unit cell. The sum of all the phase
        shifts then determines the shape of the beam after it is reflected at
        the surface.
      </p>
      <p class="info-modal-text">
        To equip the RIS with an intelligent component a machine learning
        algorithm is developed that calculates the voltage patterns needed to
        send information to the desired targets and to maximize the signal
        quality.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
