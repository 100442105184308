<template>
  <div class="info-modal-content">
    <p class="info-modal-title">JCAS</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        As the early standardization period for the sixth generation (6G) of
        wireless communications systems approaches, the discussion on its
        potential use cases and enabling technologies turns from purely
        visionary to more concrete.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group5/01.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        JCAS demonstrator
      </p>
    </div>
    <div>
      <p class="info-modal-text">
        The apparent demand for applications based on object detection,
        tracking, ranging, and improved positioning can be satisfied by
        integrating the sensing functionality into the system, thus
        strengthening positions of joint communication and sensing (JCAS) as a
        6G candidate technology. Making this reality will require novel
        approaches in many research and engineering fields, including protocol
        design, digital and analog signal processing, and hardware development.
      </p>
      <p class="info-modal-text">
        The Open6GHub covers almost all of these aspects that are necessary to
        achieve potential end-to-end solutions for 6G. In addition to studies on
        alternative waveforms, synchronization concepts for bi- and multistatic
        setups, and machine learning (ML)-based parameter estimation algorithms,
        the development and construction of flexible and powerful testbeds to
        evaluate all of these methods in real-world scenarios is an essential
        part of the Open6GHub. For this purpose, the so-called Broadband Massive
        MIMO Testbed is built with in total 256 fully digital transmit (Tx) and
        receive (Rx) channels and an instantaneous signal bandwidth of up to 2
        GHz. All channels can be operated in a phase-coherent manner via common
        clock, trigger and local oscillator signals, which is necessary for
        beamforming in communication and direction-of-arrival (DoA) estimation
        in radar sensing. The main operating frequency of the testbed is in the
        millimeter wave (mmW) range between 24 GHz and 30 GHz. However, the
        frontends can also be exchanged to cover additional frequency ranges
        that could be interesting for future communication standards.
      </p>
      <p class="info-modal-text">
        The testbed supports a play and record mode for rapid prototyping and
        evaluation of new waveforms and signal processing techniques, with
        ability to generate the Tx baseband signals offline and to captured and
        download the Rx signals. In addition, signal processing steps can be
        implemented directly on the field-programmable gate arrays (FPGAs) in
        the baseband modules to achieve real-time operation of the system.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group5/02.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        60 GHz dual-module beam-steering frontend
      </p>
    </div>
    <div>
      <p class="info-modal-text">
        Another prototype being built in the project is the 60GHz dual module
        beam steering frontend. The prototype has 16x2 patch array antennas and
        can do beam steering between -45° and 45° with a resolution of 1.5°.
        This allows us to test monostatic and bistatic sensing as well as MIMO
        communication with this system
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
