<template>
  <div class="logic-tv-item logic-group-5">
    <logic5-border class="logic5-border" />
    <logic5-el1 @click="smallModalOpened = true" class="logic5-el1" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content>
      <div class="small-modal-content">
        <logic5-el1 class="small-modal-image" />
        <p class="small-modal-title">JCAS</p>
        <p class="small-modal-text">
          Joint communication and sensing (JCAS) as a 6G candidate technology...
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <logic5-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logic5Border from "@/components/Logic/Logic5/components/Logic5Border.vue";
import Logic5El1 from "@/components/Logic/Logic5/components/Logic5El1.vue";
import AppModal from "@/components/AppModal.vue";
import Logic5ModalContent from "@/components/Logic/Logic5/components/Logic5ModalContent.vue";

const smallModalOpened = ref(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.logic-group-5 {
  position: relative;
  display: flex;
  width: 434px;
  height: 449px;
  pointer-events: none;

  svg {
    pointer-events: auto;
  }

  .logic5-border {
    pointer-events: none;

    position: absolute;
    top: 202px;
    left: 0;
  }
  .logic5-el1 {
    position: absolute;
    top: 7px;
    left: 103px;
  }
}
</style>
