<template>
  <div class="worlds-tv-groups-item worlds-group-3 heart-animation-wrap">
    <worlds3-border class="worlds3-border" />
    <worlds3-el1 @click="smallModalOpened = 1" class="worlds3-el1" />
    <worlds3-el2 @click="smallModalOpened = 2" class="worlds3-el2" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content="{ value }">
      <div class="small-modal-content">
        <worlds3-el1 v-if="value === 1" class="small-modal-image" />
        <worlds3-el2 v-else-if="value === 2" class="small-modal-image" />
        <p class="small-modal-title">Body Area Networks</p>
        <p class="small-modal-text">Health-monitoring and bioauthentication</p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <worlds3-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Worlds3Border from "@/components/Worlds/Worlds3/components/Worlds3Border.vue";
import Worlds3El1 from "@/components/Worlds/Worlds3/components/Worlds3El1.vue";
import Worlds3El2 from "@/components/Worlds/Worlds3/components/Worlds3El2.vue";
import AppModal from "@/components/AppModal.vue";
import Worlds3ModalContent from "@/components/Worlds/Worlds3/components/Worlds3ModalContent.vue";

const smallModalOpened = ref<number | false>(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.worlds-group-3 {
  position: relative;
  display: flex;
  width: 1432px;
  height: 910px;

  .worlds3-border {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .worlds3-el1 {
    position: absolute;
    top: 0;
    left: 307px;
  }
  .worlds3-el2 {
    position: absolute;
    top: 133px;
    left: 592px;
  }
}
</style>
