<template>
  <div class="logic-tv-item logic-group-3">
    <logic3-border class="logic3-border" />
    <logic3-el1 @click="smallModalOpened = true" class="logic3-el1" />
  </div>
  <app-modal v-model="smallModalOpened" small>
    <template #content>
      <div class="small-modal-content">
        <logic3-el1 class="small-modal-image" />
        <p class="small-modal-title">AR/VR</p>
        <p class="small-modal-text">
          6G technology will significantly enhance these applications by
          offering ultra-low latency, high data rates, ...
        </p>
        <button @click="clickReadMore" class="small-modal-btn">
          read more
        </button>
      </div>
    </template>
  </app-modal>
  <app-modal v-model="modalOpened">
    <template #content>
      <logic3-modal-content />
    </template>
  </app-modal>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logic3Border from "@/components/Logic/Logic3/components/Logic3Border.vue";
import Logic3El1 from "@/components/Logic/Logic3/components/Logic3El1.vue";
import AppModal from "@/components/AppModal.vue";
import Logic3ModalContent from "@/components/Logic/Logic3/components/Logic3ModalContent.vue";

const smallModalOpened = ref(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.logic-group-3 {
  position: relative;
  display: flex;
  width: 715px;
  height: 547px;
  pointer-events: none;

  svg {
    pointer-events: auto;
  }
  .logic3-border {
    pointer-events: none;

    position: absolute;
    top: 140px;
    left: 0;
  }
  .logic3-el1 {
    position: absolute;
    top: 0;
    left: 172px;
  }
}
</style>
