<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Digital Twin</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        Digital twins play a transformative role in various industries by
        providing a virtual model that mirrors the physical world.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group8/01.png"
        alt=""
        width="100%"
      />
      <p class="info-modal-text info-modal-text-small info-modal-text-right">
        Digital Twin
      </p>
    </div>
    <div>
      <p class="info-modal-text">
        This technology enables companies to enhance efficiency, optimize
        operations, and innovate product development.
      </p>
      <ul class="info-modal-text margin-list">
        <li>
          <span class="info-modal-text-bolt">Process Optimization</span>:
          Digital twins can simulate manufacturing processes to identify
          inefficiencies and optimize workflows, reducing waste and improving
          productivity.
        </li>
        <li>
          <span class="info-modal-text-bolt">Predictive Maintenance</span>: By
          continuously monitoring equipment and machinery through their digital
          counterparts, manufacturers can predict failures before they occur,
          minimizing downtime and maintenance costs.
        </li>
        <li>
          <span class="info-modal-text-bolt">Product Development</span>: Digital
          twins enable virtual prototyping and testing of products, reducing the
          need for physical prototypes and accelerating the development cycle.
        </li>
      </ul>

      <p class="info-modal-text">
        6G, the sixth generation of wireless communication technology, is
        expected to significantly enhance the capabilities and applications of
        digital twins across various industries. 6G will provide the necessary
        technological advancements to enhance the functionality, reliability,
        and scalability of digital twins. By offering ultra-low latency, high
        data rates, massive connectivity, and advanced AI capabilities, 6G will
        empower digital twins to deliver more precise, real-time, and immersive
        experiences across various industries.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
