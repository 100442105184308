<template>
  <svg
    width="167"
    height="311"
    viewBox="0 0 167 311"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M146.888 286.567L72.2319 243.48C63.8107 238.62 56.9839 225.581 56.9839 214.357V15.611C56.9839 9.90274 52.7133 9.65931 55.5565 7.60849C55.6645 7.53053 66.5261 1.45463 66.6497 1.40731C68.2863 0.6569 70.0821 0.319232 71.8797 0.423897C73.6772 0.528562 75.4217 1.07237 76.9601 2.00765L151.616 45.0945C160.037 49.9547 166.864 62.9937 166.864 74.2179V272.963C167.266 286.244 157.49 286.579 148.854 291.143C146.133 292.05 150.569 288.692 146.888 286.567Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M141.554 289.465L66.8984 246.378C58.4773 241.518 51.6504 228.479 51.6504 217.255V18.5093C51.6504 7.28514 58.4772 2.12623 66.8984 6.98628L141.554 50.0731C149.975 54.9334 156.802 67.9723 156.802 79.1966V277.942C156.802 289.166 149.975 294.325 141.554 289.465Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M147.608 289.786C145.73 289.717 143.903 289.156 142.31 288.158L67.6532 245.072C59.6623 240.46 53.1611 227.983 53.1611 217.257V18.511C53.1611 11.3175 56.1773 6.66895 60.8452 6.66895C62.7231 6.73769 64.5501 7.29852 66.143 8.29525L140.8 51.3829C148.791 55.9946 155.292 68.472 155.292 79.1979V277.944C155.292 285.137 152.276 289.786 147.608 289.786Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M68.7314 15.5414C62.9076 12.1803 58.1865 14.871 58.1865 21.5513V221.76C58.3907 225.406 59.452 228.952 61.2841 232.111C63.1163 235.27 65.6677 237.953 68.7314 239.941L139.724 280.914C145.547 284.275 150.268 281.584 150.268 274.904V74.6955C150.064 71.0497 149.003 67.5033 147.171 64.3443C145.339 61.1852 142.787 58.5025 139.724 56.5139L68.7314 15.5414Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M162.071 142.537C160.286 143.567 158.839 142.473 158.839 140.095V126.619C158.857 125.409 159.159 124.22 159.72 123.148C160.282 122.076 161.087 121.151 162.071 120.446C163.855 119.416 165.302 120.51 165.302 122.889V136.364C165.284 137.574 164.982 138.763 164.421 139.835C163.86 140.907 163.055 141.832 162.071 142.537Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M114.289 90.1433C114.154 89.642 114.092 89.1144 113.953 88.5935C113.155 85.652 111.531 83.0004 109.274 80.9517C107.017 78.9029 104.22 77.5428 101.215 77.0317C91.1148 75.418 85.7175 81.0216 85.9788 90.8727C86.1336 96.711 89.3986 101.058 92.8471 105.773C96.8041 110.66 99.6362 116.359 101.142 122.464C102.676 129.269 107.992 132.49 114.803 134.006C128.345 137.018 139.688 125.151 139.161 109.973C128.616 110.289 117.123 100.697 114.289 90.1433Z"
      fill="#B07443"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M111.604 264.066C115.44 264.833 122.429 258.866 122.746 256.442C122.758 252.648 122.535 248.858 122.078 245.093L113.853 246.006C113.853 246.006 115.962 253.631 113.326 257.496C111.914 259.566 108.44 263.433 111.604 264.066Z"
      fill="#FFD4C0"
    />
    <path
      d="M90.4291 253.356C93.5996 254.281 99.6899 249.463 102.282 248.406C104.875 247.348 105.306 246.716 104.945 244.854C104.523 242.675 103.926 235.438 103.926 235.438L95.7366 236.14C95.7366 236.14 96.5018 245.135 94.9985 247.629C93.4951 250.124 87.8985 252.619 90.4291 253.356Z"
      fill="#FFD4C0"
    />
    <path
      d="M87.4537 161.05C86.8239 161.249 86.2477 161.588 85.7689 162.043C85.2901 162.498 84.9212 163.056 84.6903 163.675C84.271 164.481 83.8098 165.265 83.3086 166.024C83.3086 166.024 84.4139 165.886 85.6575 165.609C87.4009 165.02 89.108 164.328 90.7697 163.537L87.4537 161.05Z"
      fill="#FFD4C0"
    />
    <path
      d="M104.858 100.098C108.781 100.935 113.585 100.321 110.009 110.538C107.148 118.714 109.938 151.876 107.719 167.22C107.165 171.051 98.3238 172.1 98.3238 172.1C98.3238 172.1 90.6262 134.997 90.7266 124.238C90.8619 117.236 91.6263 110.261 93.0106 103.397L104.858 100.098Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.2941 148.684C91.2941 148.684 90.4316 201.866 90.4316 206.01C90.4316 208.634 94.2312 238.61 94.5766 241.718C94.9221 244.825 105.63 244.342 105.561 241.234C105.509 238.887 104.594 209.761 104.594 209.761L110.667 166.668L110.269 150.157L91.2941 148.684Z"
      fill="url(#paint0_linear_1609_12743)"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M103.206 165.193L97.3408 207.804L101.705 243.65C103.824 243.32 105.589 242.499 105.56 241.233C105.509 238.885 104.593 209.76 104.593 209.76L110.668 166.666L103.206 165.193Z"
        fill="#CED3F4"
      />
    </g>
    <path
      d="M103.288 161.528C103.288 161.528 112.455 248.679 113.103 251.109C113.656 253.181 123.189 253.181 123.327 249.59C123.398 247.749 122.606 230.844 123.204 214.103C123.862 195.716 125.921 177.355 126.149 175.326C126.8 170.261 126.873 165.138 126.367 160.057L103.288 161.528Z"
      fill="url(#paint1_linear_1609_12743)"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M115.728 162.404L120.418 252.123C122.025 251.687 123.278 250.87 123.328 249.589C123.397 247.749 122.604 230.843 123.204 214.102C123.861 195.715 125.921 177.355 126.148 175.325C126.778 170.331 126.86 165.283 126.392 160.271L115.728 162.404Z"
        fill="#CED3F4"
      />
    </g>
    <path
      d="M98.0471 99.7891C94.9759 100.007 92.0795 101.299 89.8653 103.437C87.3889 105.961 87.0967 113.368 87.5184 118.006C87.7913 121.006 89.71 136.218 90.1634 143.691C90.5223 149.608 86.3515 187.506 86.5467 190.725C86.7809 194.585 93.8925 196.264 94.6329 194.284C96.2915 179.443 97.1929 164.527 97.3335 149.594C97.0428 144.265 94.0342 121.383 94.6357 107.955C94.9121 101.785 98.0471 99.7891 98.0471 99.7891Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M106.25 100.036C106.25 100.036 112.075 101.302 110.691 105.687C109.308 110.072 105.454 113.291 102.653 123.659C99.4299 135.588 98.1608 195.368 100.147 201.477C101.493 205.615 130.825 205.665 130.825 197.378C130.825 177.126 126.379 152.967 125.875 139.265C125.288 123.277 126.859 117.212 125.454 113.417C123.365 107.779 117.504 105.304 111.787 102.33C110.048 101.329 108.187 100.559 106.25 100.036Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M96.5517 101.854C96.647 101.799 96.747 101.752 96.8505 101.715C96.7415 101.415 96.6072 101.124 96.4491 100.846L96.4467 100.842C96.317 100.892 96.1913 100.952 96.0703 101.021C95.4958 101.413 95.0373 101.952 94.7424 102.583C94.4475 103.213 94.3272 103.91 94.3939 104.602V117.596H95.3567V104.602C95.2961 104.08 95.3747 103.55 95.5846 103.067C95.7944 102.585 96.128 102.166 96.5517 101.854Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M125.177 154.301C125.177 154.301 122.063 155.206 118.835 155.812C115.607 156.418 111.731 157.029 111.731 157.029C111.731 157.029 111.631 160.831 113.475 161.091C115.32 161.351 125.045 159.826 125.731 158.745C126.417 157.664 125.177 154.301 125.177 154.301Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M95.3654 92.5685C95.1403 90.8409 97.3493 89.1043 100.317 88.6828C103.284 88.2612 105.89 89.3141 106.155 91.0359C106.155 91.0359 108.957 103.537 108.959 103.554C109.255 105.636 106.596 107.736 103.02 108.244C99.4441 108.752 96.305 107.476 96.0091 105.394C96.0067 105.377 95.3654 92.5685 95.3654 92.5685Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M105.435 97.0279C106.149 94.4524 106.372 91.7654 106.091 89.1075C105.316 88.5399 104.43 88.1411 103.491 87.9371C102.551 87.733 101.58 87.7281 100.638 87.9227C97.4799 88.3714 95.1287 90.2197 95.3684 92.0584C95.3684 92.0584 96.0509 105.691 96.0535 105.709C96.1369 106.2 96.3592 106.657 96.6941 107.026C100.816 104.98 103.959 101.385 105.435 97.0279Z"
        fill="#F1D5BE"
      />
    </g>
    <path
      d="M90.0153 83.8016C87.3528 88.7284 91.7675 103.34 96.8542 103.386C101.941 103.431 105.735 98.1117 107.022 93.9909C108.308 89.8701 108.351 81.2017 103.908 79.096C101.414 78.0778 98.6355 77.9932 96.0835 78.8575C93.5315 79.7219 91.377 81.4773 90.0153 83.8016Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M87.7808 84.8451C86.8031 88.4277 87.574 85.93 93.1552 85.6199C95.1609 85.5086 96.1977 90.5805 97.5193 90.3088C98.5405 90.0994 99.6031 90.2987 100.479 90.8638C101.355 91.4289 101.974 92.3148 102.204 93.3313C102.333 93.8723 102.492 94.2512 102.685 94.2834C103.418 94.4055 103.907 90.4971 105.496 91.1077C107.084 91.7184 107.448 100.001 108.792 100.978C110.137 101.955 109.039 95.9932 109.039 92.0848C109.039 88.1763 109.961 78.6883 101.286 78.0776C92.611 77.467 88.5878 81.8869 87.7808 84.8451Z"
      fill="#B07443"
    />
    <path
      d="M102.685 94.2847C102.685 94.2847 97.0829 93.7834 94.7058 90.2706C93.6735 88.933 92.8059 87.4761 92.1218 85.9313C92.4482 88.3463 91.8177 90.7933 90.3647 92.7502C87.9876 95.4365 87.264 93.6801 87.264 93.6801C87.264 93.6801 84.8869 83.0383 91.8117 82.4184C98.7365 81.7984 102.767 88.5141 102.767 88.5141L102.685 94.2847Z"
      fill="#B07443"
    />
    <path
      d="M98.853 131.29H97.7545V123.155C97.6864 122.103 97.3756 121.081 96.8464 120.169C96.3172 119.257 95.5839 118.479 94.7041 117.898C94.449 117.705 94.1479 117.582 93.8307 117.54C93.5134 117.499 93.1908 117.541 92.8947 117.662C92.6452 117.854 92.4501 118.107 92.3287 118.398C92.2074 118.688 92.1641 119.005 92.203 119.317V127.452H91.1045V119.317C91.0583 118.811 91.1492 118.303 91.3675 117.844C91.5859 117.386 91.9237 116.995 92.3455 116.712C92.8066 116.487 93.3198 116.39 93.8312 116.431C94.3427 116.473 94.8336 116.651 95.2526 116.947C96.3046 117.62 97.1803 118.534 97.8067 119.614C98.433 120.694 98.7918 121.908 98.853 123.155L98.853 131.29Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M91.6533 128.629C91.3412 128.629 91.042 128.505 90.8213 128.284C90.6007 128.064 90.4767 127.764 90.4766 127.452V126.898C90.4759 126.743 90.5058 126.589 90.5646 126.446C90.6234 126.303 90.71 126.172 90.8193 126.062C90.9286 125.953 91.0586 125.865 91.2017 125.806C91.3448 125.746 91.4982 125.716 91.6532 125.716C91.8082 125.716 91.9616 125.746 92.1047 125.806C92.2478 125.865 92.3778 125.953 92.4871 126.062C92.5964 126.172 92.683 126.303 92.7418 126.446C92.8006 126.589 92.8305 126.743 92.8298 126.898V127.452C92.8298 127.764 92.7058 128.064 92.4851 128.284C92.2645 128.505 91.9653 128.629 91.6533 128.629Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M98.3046 132.467C97.9925 132.467 97.6933 132.343 97.4727 132.123C97.252 131.902 97.128 131.603 97.1279 131.291V130.736C97.1279 130.424 97.2519 130.125 97.4726 129.904C97.6932 129.684 97.9925 129.56 98.3046 129.56C98.6166 129.56 98.9159 129.684 99.1366 129.904C99.3572 130.125 99.4812 130.424 99.4812 130.736V131.291C99.4811 131.603 99.3571 131.902 99.1365 132.123C98.9159 132.343 98.6166 132.467 98.3046 132.467Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M107.877 99.2373C108.453 100.5 108.788 101.86 108.863 103.246V115.565L109.826 116.553V103.246C109.72 100.975 108.919 98.792 107.533 96.9902C107.495 97.7547 107.612 98.5191 107.877 99.2373Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M107.323 116.356C107.323 115.154 108.173 114.669 109.221 115.274C109.772 115.632 110.231 116.115 110.561 116.684C110.891 117.253 111.082 117.891 111.118 118.547C111.118 119.75 110.269 120.234 109.221 119.629C108.669 119.271 108.21 118.788 107.881 118.219C107.551 117.651 107.36 117.012 107.323 116.356Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M58.1865 134.282V221.759C58.3907 225.405 59.452 228.951 61.2842 232.11C63.1165 235.269 65.668 237.952 68.7317 239.94L139.724 280.913C145.547 284.274 150.268 281.583 150.268 274.903V184.253L58.1865 134.282Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M107.097 180.391C110.403 178.46 110.369 172.253 107.022 166.529C103.674 160.805 98.2807 157.731 94.9749 159.663C91.6691 161.595 91.7029 167.801 95.0503 173.525C98.3977 179.249 103.791 182.323 107.097 180.391Z"
      fill="#D1514E"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M94.2217 166.656L97.6778 168.651C98.2851 167.111 100.146 167.697 101.036 168.211C102.639 169.198 103.832 170.731 104.395 172.528L107.851 174.522C107.656 171.638 104.684 168.586 101.036 166.481C97.3889 164.375 94.4172 163.998 94.2217 166.656ZM104.379 173.548C104.396 173.85 104.484 174.144 104.636 174.406C104.788 174.667 104.999 174.89 105.253 175.054L106.978 176.05C107.46 176.328 107.851 176.105 107.851 175.552V174.788L104.379 172.784L104.379 173.548ZM97.6935 168.926L94.2217 166.922V167.686C94.2386 167.988 94.3265 168.282 94.4783 168.543C94.63 168.805 94.8414 169.027 95.0951 169.192L96.82 170.188C97.3024 170.466 97.6935 170.243 97.6935 169.69L97.6935 168.926Z"
      fill="white"
    />
    <path
      d="M82.6734 161.858C84.0771 162.668 85.2257 162.013 85.2257 160.403V155.899C85.1746 155.017 84.9171 154.159 84.4738 153.395C84.0305 152.63 83.4138 151.981 82.6734 151.498C81.2697 150.688 80.1211 151.343 80.1211 152.953V157.457C80.1722 158.339 80.4297 159.197 80.873 159.961C81.3164 160.725 81.933 161.375 82.6734 161.858Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M86.4053 159.469C86.1703 159.333 85.9799 159.441 85.9799 159.711V160.837C85.9799 162.928 84.4973 163.772 82.6751 162.721C81.7155 162.097 80.9163 161.256 80.3422 160.266C79.7681 159.276 79.4351 158.165 79.3703 157.022V155.896C79.3621 155.749 79.3193 155.606 79.2454 155.479C79.1714 155.351 79.0685 155.243 78.9449 155.163C78.71 155.027 78.5195 155.136 78.5195 155.405V156.531C78.5973 157.839 78.9509 159.114 79.5571 160.275C80.1634 161.436 81.0086 162.455 82.0371 163.266V165.426C81.923 165.406 81.8058 165.414 81.6951 165.448C81.5844 165.482 81.4834 165.542 81.4004 165.622C81.3173 165.703 81.2546 165.802 81.2174 165.912C81.1802 166.021 81.1695 166.138 81.1863 166.253L84.164 167.971C84.107 167.286 83.805 166.644 83.3133 166.163V164.003C85.302 164.796 86.8307 163.707 86.8307 161.328V160.202C86.8225 160.055 86.7797 159.912 86.7058 159.784C86.6318 159.657 86.5289 159.549 86.4053 159.469Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M129.526 184.745L126.297 185.491V184.266C126.265 183.692 126.098 183.135 125.81 182.638C125.522 182.141 125.12 181.719 124.638 181.406L118.226 177.705C117.31 177.177 116.567 177.6 116.567 178.65V183.187C116.6 183.76 116.766 184.318 117.055 184.815C117.343 185.311 117.744 185.733 118.226 186.046L124.639 189.747C125.554 190.276 126.297 189.853 126.297 188.802V187.576L129.526 192.049C129.878 192.536 130.363 192.528 130.363 192.035V185.726C130.362 185.233 129.878 184.664 129.526 184.745Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M123.819 180.398C125.23 181.213 126.379 180.559 126.379 178.939C126.329 178.054 126.071 177.194 125.626 176.427C125.181 175.66 124.562 175.009 123.819 174.525C122.407 173.711 121.259 174.365 121.259 175.984C121.309 176.869 121.567 177.73 122.012 178.497C122.456 179.264 123.075 179.915 123.819 180.398Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M118.677 177.433C119.814 178.09 120.739 177.562 120.739 176.257C120.699 175.544 120.491 174.85 120.133 174.232C119.774 173.614 119.276 173.089 118.677 172.699C117.54 172.043 116.614 172.57 116.614 173.875C116.655 174.589 116.862 175.282 117.221 175.901C117.579 176.519 118.078 177.044 118.677 177.433Z"
      fill="#A5B6C6"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-miterlimit="10"
    />
    <path
      d="M58.1865 221.759C58.3907 225.405 59.452 228.951 61.2842 232.11C63.1165 235.269 65.668 237.952 68.7317 239.941L139.724 280.913C145.547 284.274 150.268 281.583 150.268 274.903V210.038L58.1865 156.894V221.759Z"
      fill="#343B43"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linejoin="round"
    />
    <path
      style="mix-blend-mode: color-dodge"
      d="M58.1865 134.282V135.078L150.268 185.205V184.254L58.1865 134.282Z"
      fill="url(#paint2_radial_1609_12743)"
    />
    <path
      d="M56.2079 155.105C56.2079 155.105 63.5397 153.662 66.6202 154.826C69.7007 155.989 74.9087 160.161 75.0543 161.647C75.1838 162.97 71.3371 165.075 68.8788 164.619C66.4206 164.164 66.1142 163.827 64.134 163.603C61.596 163.264 59.0974 162.677 56.6742 161.85C55.1895 161.227 56.2079 155.105 56.2079 155.105Z"
      fill="#FFD2B3"
    />
    <path
      d="M78.7144 150.483C77.8186 151.197 76.7745 151.701 75.6586 151.959C73.2455 151.925 70.8334 152.078 68.4442 152.418C67.0633 152.898 62.7398 154.194 62.6215 155.357C62.5032 156.52 64.0874 155.75 65.3527 155.574C66.6179 155.398 68.6945 155.242 68.6945 155.242C67.442 156.562 66.0557 157.75 64.558 158.784C62.9792 159.669 60.4377 160.119 60.0169 161.007C59.596 161.895 60.1413 162.746 61.4261 163.636C62.9958 164.548 64.8319 164.891 66.6249 164.607C69.3091 164.677 71.9501 163.921 74.1895 162.44C75.5262 161.335 79.3099 158.348 80.1543 157.845C80.9987 157.342 84.0803 156.188 84.0803 156.188L78.9399 150.356L78.7144 150.483Z"
      fill="#FFD2B3"
    />
    <g style="mix-blend-mode: multiply" opacity="0.5">
      <path
        d="M61.4261 163.637C60.1412 162.746 59.5961 161.895 60.0168 161.007C60.1088 160.839 60.2405 160.696 60.4004 160.591C61.3161 161.324 62.3829 161.846 63.5241 162.118C64.6653 162.39 65.8527 162.406 67.0009 162.165C70.5675 160.702 73.87 158.665 76.777 156.133C78.7123 154.91 80.8227 153.988 83.0356 153.399L84.0804 156.188C84.0804 156.188 80.9985 157.342 80.1544 157.845C79.3099 158.348 75.5261 161.335 74.1895 162.44C71.9501 163.921 69.3092 164.678 66.6251 164.607C64.832 164.891 62.9959 164.548 61.4261 163.637Z"
        fill="#F1D5BE"
      />
    </g>
    <path
      d="M56.4953 154.033C57.1048 154.591 57.581 155.279 57.8887 156.045C58.1965 156.812 58.3279 157.638 58.2732 158.462C58.2947 160.03 57.8093 161.562 56.8894 162.832L26.9555 165.22C26.9555 165.22 4.60671 168.131 4.76056 158.928C4.9072 150.149 10.8954 150.004 24.1335 152.177C34.862 153.574 45.6774 154.194 56.4953 154.033Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M72.646 156.562C73.0762 157.062 72.8258 158.19 71.3851 158.315C69.9444 158.44 66.8126 158.096 65.6225 158.096C63.241 157.521 60.9064 156.768 58.6387 155.841L72.646 156.562Z"
        fill="#F1D5BE"
      />
    </g>
    <path
      d="M65.9381 154.334C67.3984 155.002 69.5806 155.24 70.7625 155.711C71.9443 156.181 72.9141 155.77 73.2434 156.265C73.5727 156.759 73.5096 158.177 70.6329 157.813C68.5955 157.417 66.5164 157.28 64.4447 157.404C62.7702 157.63 59.5095 157.882 58.8118 157.022C58.1141 156.163 59.1689 154.82 59.1689 154.82L65.9381 154.334Z"
      fill="#FFD2B3"
    />
    <path
      d="M75.0649 162.024C75.3494 161.488 72.5192 161.593 71.6708 161.92C70.1321 162.495 68.6254 163.152 67.1571 163.888C66.6653 164.206 65.9926 164.721 67.8148 164.827C71.3067 165.031 74.0293 163.977 75.0649 162.024Z"
      fill="#FFD2B3"
    />
    <path
      d="M2.55076 296.943C2.20934 298.122 2.15679 299.365 2.39755 300.568C2.95465 301.253 3.72904 301.728 4.59269 301.914C5.45633 302.1 6.3575 301.985 7.14719 301.589C9.03692 300.67 9.85407 298.73 10.5691 298.22C13.3392 297.498 15.9649 296.306 18.3321 294.697C21.3453 292.144 20.9367 289.336 17.9235 289.285C14.3031 289.39 10.7486 290.278 7.50472 291.889C5.67175 293.386 4.0103 295.081 2.55076 296.943Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.98102 299.701C6.28891 299.707 8.50731 298.809 10.1608 297.2C11.4375 296.128 13.6847 296.689 16.5959 294.392C19.507 292.094 20.5284 290.512 19.2006 289.746C15.8169 288.98 12.2687 289.513 9.26031 291.241C5.48095 293.283 2.95948 294.749 2.95948 294.749C2.95948 294.749 2.04016 297.557 2.49978 298.17C2.9594 298.782 2.75528 299.446 3.98102 299.701Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.14677 196.461C6.14677 196.461 2.64224 198.533 3.20884 206.745C3.36666 209.032 2.22949 297.195 2.22949 297.195C3.72565 297.791 5.34502 298.009 6.94554 297.833C8.81789 296.947 10.5785 295.842 12.1905 294.542L25.2224 203.069L6.14677 196.461Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.5012 305.285C28.5012 305.285 27.2877 308.555 28.777 309.259C30.5528 310.111 32.5911 310.238 34.4586 309.611C35.8659 308.757 37.4518 308.241 39.0921 308.102C43.1518 307.194 46.9699 305.428 50.2898 302.922C51.7791 301.211 50.2898 299.25 46.594 299.501C42.8981 299.753 37.6027 300.155 36.665 300.457C35.7273 300.759 28.5012 305.285 28.5012 305.285Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.1555 307.7C32.795 307.831 35.4352 307.455 37.9332 306.593C41.1326 305.989 50.2304 302.626 50.0648 300.765C49.8993 298.904 47.807 299.3 46.0419 299.451C44.2769 299.602 38.6504 299.954 38.209 299.954C37.7677 299.954 28.611 303.726 28.611 303.726C28.611 303.726 27.9491 306.442 28.5559 306.845C29.1626 307.247 29.2179 307.599 30.1555 307.7Z"
      fill="#8491A1"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5342 190.717C13.7079 196.33 13.101 202.801 14.8344 208.842C17.6038 219.569 29.2161 260.701 29.2161 260.701C29.2161 260.701 28.5425 299.673 28.5425 304.818C28.5425 306.376 31.8536 307.101 35.3417 305.951C39.7092 304.512 39.6384 303.308 39.8744 301.278C40.0813 299.5 44.9391 265.657 44.5142 255.745C43.955 242.698 41.4926 203.118 41.4926 203.118L16.5342 190.717Z"
      fill="#CDCDCD"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.8251 135.235C8.44152 136.042 4.03539 137.303 3.22998 147.811C2.56851 156.44 0.678711 213.812 0.678711 213.812C0.678711 213.812 8.45639 220.877 28.8843 220.181C42.8163 219.706 43.0848 216.203 43.0848 216.203C43.0848 216.203 43.8088 180.7 44.2213 161.733C44.4139 152.874 43.2327 150.583 40.322 148.181C36.0417 144.833 31.5534 141.76 26.884 138.98C23.0971 136.795 16.5965 134.541 12.8251 135.235Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.7416 143.297C31.5977 145.507 27.9267 147.067 23.5418 146.782C19.157 146.497 15.719 144.474 15.8627 142.265C16.0065 140.055 19.6776 138.495 24.0624 138.78C28.4472 139.065 31.8852 141.087 31.7416 143.297Z"
      fill="white"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M17.033 141.342C16.9526 141.59 16.9349 141.854 16.9815 142.11C17.0281 142.366 17.1375 142.607 17.3 142.81C18.1366 143.681 19.1394 144.376 20.2492 144.852C21.3589 145.329 22.553 145.579 23.7609 145.586C25.8751 145.788 28.002 145.364 29.8774 144.368C30.1032 144.232 30.2971 144.048 30.4459 143.831C30.5947 143.613 30.6949 143.366 30.7397 143.106L31.5895 138.469C31.5895 138.469 35.2084 138.804 36.3329 137.472C37.4573 136.14 41.66 118.99 36.6432 114.514C31.6264 110.037 20.3097 110.884 18.2577 118.677C16.2057 126.469 19.6081 130.82 19.8556 132.65C19.3432 135.669 18.3923 138.598 17.033 141.342Z"
      fill="#FFD2B3"
      stroke="#3D464D"
      stroke-width="0.25"
    />
    <path
      d="M19.7712 133.996C21.6269 134.728 23.6088 135.086 25.6034 135.051C28.7424 134.266 28.8482 127.07 30.9808 125.493C32.2512 124.554 33.2795 125.72 33.1236 127.004C32.9957 128.058 33.2175 128.789 33.6082 128.923C34.6669 126.998 35.5424 124.979 36.2233 122.89C36.2233 122.89 44.806 118.937 42.5032 111.755C39.7398 113.505 22.5076 104.57 17.2594 117.028C13.1196 126.856 17.8863 133.381 19.7712 133.996Z"
      fill="#B07443"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g style="mix-blend-mode: multiply">
      <path
        d="M18.0064 221.637C18.9767 224.959 20.0296 228.522 21.0882 232.089L12.1903 294.543C12.1903 294.543 10.9103 295.561 9.44824 296.5C11.0504 283.795 17.1482 229.394 18.0064 221.637Z"
        fill="#CED3F4"
      />
    </g>
    <path
      d="M41.2149 220.681C41.117 222.646 41.3686 224.613 41.9578 226.491C42.416 228.002 42.3916 229.654 43.183 229.506C43.9745 229.358 44.0283 222.672 44.0283 222.672L41.2149 220.681Z"
      fill="#F2BBA2"
    />
    <path
      d="M46.9277 215.014C48.296 217.345 49.4979 219.769 50.5242 222.27C51.0774 223.809 51.0065 224.066 50.2041 226.942C49.8301 228.282 49.4077 229.234 48.6578 229.211C48.3144 229.201 48.6546 228.426 48.6546 228.426C48.6546 228.426 48.32 229.755 47.3998 229.831C46.4797 229.907 47.1112 228.903 47.1112 228.903C47.1112 228.903 46.6402 230.304 45.7224 230.323C44.8046 230.342 45.4768 229.281 45.4768 229.281C45.4768 229.281 44.9398 230.75 44.1887 230.89C43.4377 231.03 43.3946 230.557 43.5569 229.515C43.6309 227.959 43.2991 226.409 42.5939 225.02C41.7517 222.769 40.9152 221.694 41.3403 219.763C41.5166 218.419 41.1857 217.057 40.4121 215.943L46.9277 215.014Z"
      fill="#FFD4C0"
    />
    <path
      d="M40.3948 148.803C35.7218 146.31 31.876 150.352 29.8694 155.326C27.8628 160.3 27.3571 180.28 28.4891 186.033C29.6211 191.785 40.7866 218.903 40.7866 218.903C42.3091 219.024 43.8411 218.892 45.3205 218.513C47.6204 217.886 47.9653 216.806 47.9653 216.806C47.9653 216.806 43.814 192.221 42.9474 187.089C42.0808 181.958 44.2991 168.341 44.6425 162.752C45.0596 155.962 44.9109 151.212 40.3948 148.803Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M122.5 108.779C130.725 114.682 122.599 131.364 121.167 135.085C119.736 138.806 116.444 147.317 112.435 149.464C108.427 151.61 81.0987 158.565 81.0987 158.565L77.6445 150.554L105.348 138.351C105.348 138.351 109.089 126.427 110.128 122.271C111.956 114.963 114.247 102.856 122.5 108.779Z"
      fill="#EFEFEF"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <mask
      id="mask0_1609_12743"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="81"
      y="189"
      width="40"
      height="64"
    >
      <path
        d="M81.373 229.856L81.4882 189.174L120.191 211.511L120.076 252.191L81.373 229.856Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1609_12743)">
      <path
        d="M97.328 212.03L94.6924 218.68L94.599 218.482L93.0252 215.36L91.6117 216.56L82.9453 211.559C84.702 216.72 87.0822 221.647 90.033 226.232C92.8205 230.719 95.9018 235.017 99.2569 239.097L100.725 240.808L102.214 240.803C105.406 240.641 108.541 239.894 111.464 238.601C113.146 237.933 114.679 236.938 115.973 235.674C117.268 234.41 118.299 232.902 119.007 231.237L106.711 224.14L106.183 222.288L105.161 223.732L101.985 221.899L101.955 232.662L97.328 212.03Z"
        fill="#4ED15B"
      />
      <path
        d="M94.6933 218.68L97.329 212.03L101.956 232.662L101.986 221.899L105.161 223.732L106.184 222.288L106.712 224.14L119.007 231.237C118.3 232.902 117.269 234.41 115.974 235.674C114.68 236.938 113.147 237.933 111.465 238.601C108.542 239.894 105.407 240.641 102.214 240.803L100.726 240.808L99.2578 239.097C95.9027 235.017 92.8214 230.719 90.0339 226.232C87.0831 221.647 84.7029 216.72 82.9462 211.559L91.6126 216.56L93.0261 215.36L94.5999 218.482L94.6933 218.68ZM97.3464 211.41L94.6319 218.249L93.027 215.037L91.5194 216.308L82.6807 211.207L82.7272 211.36C84.4877 216.627 86.9057 221.651 89.924 226.313C92.7145 230.822 95.8065 235.138 99.179 239.231L100.678 240.978L100.725 241.024L102.292 241.028C105.498 240.874 108.646 240.121 111.574 238.808C113.29 238.135 114.852 237.121 116.165 235.828C117.477 234.534 118.513 232.988 119.211 231.282L119.258 231.184L106.837 224.015L106.217 221.857L105.053 223.471L101.815 221.602L101.787 231.198L97.4083 211.733L97.3464 211.41Z"
        fill="#3D464D"
      />
      <path
        d="M108.817 205.145C107.048 203.915 104.973 203.197 102.822 203.07C102.159 203.057 101.497 203.149 100.863 203.343L100.816 203.352L100.753 203.28C100.188 202.446 99.5725 201.646 98.911 200.885C97.2152 198.896 95.1769 197.225 92.8925 195.954C86.4786 192.252 81.6431 195.599 81.6201 203.703C81.6392 205.69 81.902 207.667 82.4025 209.59L90.9125 214.501L93.0327 212.755L94.217 215.13L97.4838 206.919L100.547 220.62L100.551 219.434L104.321 221.61L106.413 218.66L107.686 223.048L119.481 229.855C119.811 228.542 119.978 227.194 119.978 225.84C119.931 221.746 118.891 217.723 116.947 214.119C115.003 210.514 112.214 207.434 108.817 205.145Z"
        fill="#4ED15B"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1609_12743"
        x1="101.058"
        y1="153.711"
        x2="99.3134"
        y2="243.214"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01515" stop-color="#836DF3" />
        <stop offset="0.19157" stop-color="#926DF3" />
        <stop offset="0.53208" stop-color="#BB6DF3" />
        <stop offset="0.53535" stop-color="#BB6DF3" />
        <stop offset="0.69293" stop-color="#CA6DEA" />
        <stop offset="0.99707" stop-color="#F36DD4" />
        <stop offset="1" stop-color="#F36DD4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1609_12743"
        x1="114.986"
        y1="162.939"
        x2="114.737"
        y2="249.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01515" stop-color="#836DF3" />
        <stop offset="0.19157" stop-color="#926DF3" />
        <stop offset="0.53208" stop-color="#BB6DF3" />
        <stop offset="0.53535" stop-color="#BB6DF3" />
        <stop offset="0.69293" stop-color="#CA6DEA" />
        <stop offset="0.99707" stop-color="#F36DD4" />
        <stop offset="1" stop-color="#F36DD4" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_1609_12743"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(104.227 159.744) scale(37.2057 37.1922)"
      >
        <stop stop-color="white" />
        <stop offset="1" />
      </radialGradient>
    </defs>
  </svg>
</template>
