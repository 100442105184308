<template>
  <svg
    width="267"
    height="186"
    viewBox="0 0 267 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M117.101 141.214L114.799 151.038C116.855 157.424 120.54 163.162 125.489 167.687C128.168 170.153 131.11 172.318 134.26 174.143C137.783 176.188 141.476 177.922 145.299 179.326C154.548 182.604 164.154 184.772 173.915 185.783L173.583 174.353C164.28 173.342 155.127 171.245 146.31 168.105C142.685 166.774 139.184 165.127 135.847 163.184C132.847 161.456 130.045 159.401 127.494 157.059C122.732 152.754 119.153 147.299 117.101 141.214Z"
      fill="#456E82"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M117.102 141.214C119.154 147.299 122.732 152.755 127.495 157.059C130.046 159.402 132.847 161.456 135.848 163.184C139.185 165.128 142.686 166.774 146.311 168.105C155.127 171.245 164.28 173.342 173.584 174.353C212.052 162.225 242.069 145.146 263.634 123.117C259.455 117.989 254.644 113.409 249.317 109.488C243.439 105.172 237.134 101.471 230.501 98.4416C222.956 95.0031 215.148 92.1729 207.152 89.978C171.02 103.467 141.004 120.546 117.102 141.214Z"
      fill="#E2E2E2"
      stroke="#3D464D"
      stroke-width="0.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M173.584 174.353L173.915 185.783C174.159 185.696 174.386 185.626 174.63 185.556C213.43 173.323 243.731 156.082 265.535 133.832C265.709 133.64 265.901 133.448 266.075 133.274L263.634 123.117C242.063 145.14 212.046 162.219 173.584 174.353Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M173.573 153.455C142.08 149.616 123.252 138.57 117.091 120.316V141.222C123.247 159.476 142.074 170.522 173.573 174.361V153.455Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M173.573 153.455V174.362C212.042 162.251 242.058 145.172 263.624 123.126V102.22C242.053 124.26 212.036 141.339 173.573 153.455Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M263.624 102.22C251.574 87.3166 232.5 76.5 207.142 69.0804C171.01 82.57 140.993 99.6486 117.092 120.316C123.247 138.57 142.074 149.616 173.573 153.455C212.042 141.327 242.058 124.248 263.624 102.22Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M163.044 106.694C162.736 107.426 162.525 108.195 162.417 108.981C162.526 109.974 162.785 110.944 163.184 111.86C164.206 113.979 165.74 115.808 167.648 117.183C170.635 119.401 174.04 120.992 177.658 121.859C181.266 122.812 184.981 123.299 188.713 123.308C192.429 123.348 196.134 122.902 199.734 121.982C202.434 121.296 205.036 120.27 207.477 118.928C209.535 117.816 211.363 116.324 212.865 114.53C214.082 113.107 214.911 111.394 215.271 109.557C215.304 109.378 215.328 109.197 215.341 109.016C215.048 107.19 214.311 105.464 213.196 103.99C211.38 101.672 209.037 99.8233 206.361 98.5974C203.056 96.9884 199.529 95.8826 195.898 95.3166C191.551 94.6215 187.126 94.5804 182.767 95.1945C178.383 95.7184 174.14 97.0747 170.264 99.1907C169.234 99.7666 168.248 100.42 167.317 101.145C165.433 102.59 163.96 104.503 163.044 106.694Z"
      fill="#456E82"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M164.875 101.442C163.673 102.872 162.851 104.583 162.486 106.416C162.341 107.263 162.317 108.127 162.416 108.981C162.525 108.195 162.736 107.426 163.044 106.695C163.96 104.503 165.433 102.59 167.316 101.145C168.248 100.42 169.233 99.7667 170.263 99.1907C174.139 97.0747 178.383 95.7184 182.766 95.1945C187.125 94.5804 191.551 94.6216 195.897 95.3167C199.529 95.8826 203.055 96.9884 206.36 98.5974C209.036 99.8234 211.38 101.672 213.196 103.99C214.311 105.464 215.047 107.19 215.341 109.016C215.516 107.342 215.251 105.652 214.573 104.112C213.545 101.992 212.004 100.163 210.092 98.7894C207.109 96.574 203.711 94.9834 200.1 94.1126C196.491 93.1606 192.776 92.6739 189.044 92.6641C185.329 92.6306 181.624 93.0764 178.023 93.9904C175.313 94.6708 172.705 95.7033 170.263 97.0618C168.21 98.1727 166.382 99.6584 164.875 101.442Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M162.416 108.981C162.317 108.127 162.341 107.263 162.486 106.415C162.851 104.583 163.673 102.872 164.875 101.442C166.383 99.6583 168.21 98.1727 170.264 97.0618C172.705 95.7034 175.314 94.6708 178.024 93.9905C181.625 93.0764 185.329 92.6307 189.044 92.6642C192.776 92.6739 196.492 93.1607 200.1 94.1126C203.711 94.9834 207.11 96.5741 210.092 98.7895C212.005 100.163 213.545 101.993 214.574 104.112C215.252 105.652 215.516 107.342 215.341 109.016C215.328 109.197 215.304 109.378 215.271 109.557C214.911 111.394 214.082 113.108 212.865 114.53C211.363 116.324 209.534 117.816 207.477 118.928C205.036 120.27 202.434 121.296 199.734 121.982C196.134 122.902 192.429 123.348 188.713 123.308C184.981 123.299 181.266 122.813 177.657 121.86C174.04 120.992 170.635 119.402 167.648 117.183C165.74 115.808 164.206 113.979 163.184 111.86C162.785 110.945 162.526 109.974 162.416 108.981ZM148.082 105.56C147.943 106.361 147.873 107.173 147.873 107.986C147.893 110.046 148.326 112.082 149.146 113.972C150.737 117.248 153.116 120.078 156.069 122.209C160.683 125.629 165.937 128.086 171.519 129.433C177.1 130.891 182.841 131.641 188.608 131.667C194.359 131.727 200.092 131.035 205.663 129.608C209.838 128.557 213.859 126.967 217.625 124.879C220.808 123.171 223.637 120.873 225.961 118.108C227.835 115.909 229.114 113.265 229.675 110.429C229.814 109.652 229.884 108.863 229.884 108.074V107.986C229.86 105.93 229.421 103.9 228.594 102.018C227.009 98.738 224.636 95.9021 221.688 93.7637C217.064 90.3497 211.806 87.8935 206.221 86.5391C200.64 85.0805 194.899 84.33 189.131 84.3053C183.387 84.2452 177.66 84.9375 172.095 86.3643C167.913 87.4125 163.885 89.0024 160.114 91.0936H160.097C156.927 92.8118 154.105 95.1082 151.779 97.8646C149.913 100.073 148.64 102.722 148.082 105.56Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M214.975 131.056C218.297 129.529 221.352 127.479 224.025 124.983C226.177 123.002 227.858 120.563 228.943 117.846C229.558 116.229 229.877 114.515 229.884 112.785V108.073C229.884 108.863 229.814 109.652 229.675 110.429C229.114 113.265 227.835 115.908 225.961 118.108C223.637 120.873 220.808 123.171 217.625 124.879C213.859 126.967 209.838 128.556 205.663 129.608C200.092 131.035 194.359 131.727 188.608 131.667C182.841 131.641 177.1 130.89 171.519 129.433C165.937 128.086 160.683 125.629 156.069 122.208C153.116 120.078 150.737 117.248 149.146 113.971C148.326 112.081 147.893 110.046 147.873 107.986H147.856V112.785C148.119 117.214 150.028 121.385 153.209 124.477C156.287 127.576 159.966 130.011 164.021 131.632C168.037 133.341 172.23 134.6 176.524 135.384C185.149 136.914 193.983 136.826 202.576 135.122C206.859 134.278 211.023 132.912 214.975 131.056Z"
      fill="#3C5D6E"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M108.52 147.78L151.154 172.789V169.629L111.255 146.228L108.52 147.78Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M151.154 165.459L111.255 142.04V146.228L151.154 169.629V165.459Z"
      fill="#896D02"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M108.519 147.781V140.434L111.257 142.04L151.155 165.459V172.788L108.519 147.781ZM105.781 136.194V148.828L153.893 177.046V164.412L105.781 136.194Z"
      fill="#FFCE10"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M153.892 164.412V177.046L166.569 169.839V157.204L153.892 164.412Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M166.569 157.204C162.995 156.594 159.803 155.948 156.403 155.093C153.161 154.291 149.965 153.312 146.829 152.161C143.86 151.08 140.964 149.803 138.163 148.339C135.565 146.985 133.086 145.415 130.752 143.645C128.613 142.029 126.628 140.218 124.823 138.235C123.175 136.417 121.709 134.441 120.446 132.337C119.801 131.272 119.208 130.138 118.684 129.039L118.51 128.951L105.78 136.194L153.892 164.412L166.569 157.204Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M108.519 140.435V147.78L111.255 146.228V142.04L108.519 140.435Z"
      fill="#DCAF00"
      stroke="#3D464D"
      stroke-width="0.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g class="car-pulse-animation">
      <path
        d="M11.8609 72.5898L9.55909 82.4147C11.615 88.7999 15.2995 94.5382 20.2487 99.0629C22.928 101.529 25.8693 103.695 29.0201 105.52C32.5426 107.564 36.2359 109.298 40.0584 110.702C49.3081 113.98 58.914 116.148 68.6743 117.159L68.343 105.729C59.0396 104.718 49.8864 102.621 41.0699 99.4815C37.4452 98.1504 33.9441 96.5037 30.607 94.5603C27.6064 92.8318 24.8052 90.7776 22.2542 88.4351C17.4916 84.1306 13.913 78.6748 11.8609 72.5898Z"
        fill="#456E82"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M11.8615 72.5899C13.9135 78.6748 17.4921 84.1307 22.2546 88.4352C24.8056 90.7778 27.6068 92.8319 30.6075 94.5605C33.9446 96.5039 37.4456 98.1506 41.0703 99.4816C49.8868 102.621 59.04 104.718 68.3434 105.729C106.812 93.6007 136.828 76.5221 158.394 54.4933C154.214 49.3647 149.404 44.7853 144.077 40.8642C138.199 36.5482 131.894 32.8468 125.261 29.8178C117.716 26.3793 109.908 23.5491 101.912 21.3541C65.7801 34.8436 35.7634 51.9222 11.8615 72.5899Z"
        fill="#E2E2E2"
        stroke="#3D464D"
        stroke-width="0.25"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M68.3435 105.729L68.6748 117.159C68.919 117.072 69.1456 117.002 69.3898 116.933C108.189 104.7 138.491 87.4581 160.294 65.2082C160.469 65.0163 160.661 64.8243 160.835 64.6498L158.394 54.4934C136.823 76.5164 106.806 93.595 68.3435 105.729Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M68.3325 84.8315C36.8394 80.9923 18.0121 69.9459 11.8506 51.6923V72.5985C18.0063 90.852 36.8336 101.898 68.3325 105.738V84.8315Z"
        fill="#FFCE10"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M68.3327 84.8317V105.738C106.801 93.627 136.818 76.5485 158.383 54.5022V33.5959C136.812 55.6364 106.795 72.715 68.3327 84.8317Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M158.383 33.5958C146.333 18.6928 127.506 7.64638 101.901 0.456604C65.7693 13.9461 35.7526 31.0247 11.8508 51.6924C18.0064 69.946 36.8337 80.9923 68.3327 84.8316C106.801 72.7032 136.818 55.6247 158.383 33.5958Z"
        fill="#FFCE10"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M57.8041 38.0707C57.4962 38.8021 57.2851 39.5707 57.1763 40.3569C57.2857 41.3499 57.5444 42.3206 57.9436 43.2363C58.9656 45.3548 60.5001 47.1843 62.4079 48.5588C65.3948 50.7776 68.7997 52.3685 72.4173 53.2356C76.0257 54.1887 79.7411 54.6754 83.473 54.684C87.1887 54.7242 90.8937 54.2783 94.4939 53.3578C97.1937 52.6726 99.7954 51.6464 102.236 50.3039C104.294 49.1923 106.123 47.7001 107.625 45.9062C108.842 44.4837 109.671 42.7704 110.031 40.9327C110.064 40.7538 110.087 40.5732 110.101 40.3918C109.808 38.5662 109.071 36.8405 107.956 35.3659C106.14 33.0485 103.796 31.1995 101.12 29.9736C97.8156 28.3646 94.2891 27.2588 90.6577 26.6928C86.3112 25.9978 81.8854 25.9566 77.5267 26.5707C73.143 27.0946 68.8993 28.4509 65.0237 30.5669C63.9933 31.1428 63.0081 31.7962 62.0766 32.5214C60.1929 33.9664 58.72 35.8795 57.8041 38.0707Z"
        fill="#456E82"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M59.6349 32.8184C58.4327 34.2484 57.6109 35.9592 57.2459 37.7919C57.1005 38.6393 57.0769 39.5031 57.176 40.3572C57.2848 39.571 57.4959 38.8024 57.8038 38.071C58.7195 35.8798 60.1924 33.9666 62.0759 32.5216C63.0074 31.7964 63.9926 31.143 65.023 30.5671C68.8987 28.4511 73.1423 27.0947 77.5261 26.5709C81.8847 25.9567 86.3106 25.9979 90.657 26.693C94.2885 27.259 97.815 28.3648 101.12 29.9738C103.796 31.1997 106.139 33.0486 107.956 35.3661C109.07 36.8407 109.807 38.5663 110.1 40.3919C110.275 38.7184 110.011 37.0282 109.333 35.4882C108.305 33.3688 106.764 31.5395 104.851 30.1657C101.869 27.9504 98.4703 26.3597 94.8593 25.4889C91.2508 24.537 87.5355 24.0502 83.8036 24.0405C80.0883 24.0069 76.3842 24.4527 72.7828 25.3667C70.0732 26.0471 67.4644 27.0797 65.0228 28.4381C62.9695 29.549 61.1421 31.0347 59.6349 32.8184Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M57.1762 40.3569C57.0771 39.5028 57.1006 38.639 57.246 37.7916C57.6111 35.9589 58.4329 34.2481 59.635 32.8181C61.1424 31.0344 62.97 29.5488 65.0234 28.4379C67.465 27.0795 70.0738 26.0469 72.7834 25.3666C76.3848 24.4525 80.0889 24.0068 83.8042 24.0403C87.536 24.05 91.2513 24.5368 94.8599 25.4887C98.4709 26.3595 101.869 27.9502 104.852 30.1656C106.765 31.5393 108.305 33.3686 109.334 35.4881C110.011 37.028 110.276 38.7182 110.101 40.3917C110.087 40.5732 110.064 40.7538 110.031 40.9327C109.671 42.7704 108.842 44.4837 107.625 45.9062C106.123 47.7 104.294 49.1923 102.236 50.3039C99.7953 51.6464 97.1936 52.6726 94.4938 53.3578C90.8936 54.2783 87.1886 54.7241 83.4729 54.684C79.741 54.6754 76.0256 54.1887 72.4172 53.2356C68.7996 52.3685 65.3947 50.7776 62.4077 48.5588C60.5 47.1843 58.9655 45.3548 57.9435 43.2363C57.5443 42.3206 57.2856 41.3499 57.1762 40.3569ZM42.8421 36.9365C42.7025 37.7375 42.6324 38.5491 42.6328 39.3622C42.6532 41.4225 43.0861 43.4578 43.9058 45.3478C45.4972 48.6242 47.8758 51.4543 50.8287 53.5846C55.4426 57.0053 60.6968 59.4622 66.2788 60.8093C71.8594 62.2668 77.6007 63.0172 83.3681 63.0431C89.1183 63.1031 94.8516 62.4109 100.423 60.9841C104.598 59.9328 108.619 58.343 112.385 56.2548C115.568 54.5474 118.396 52.2495 120.72 49.4839C122.595 47.2848 123.874 44.641 124.435 41.8055C124.574 41.0279 124.644 40.2396 124.644 39.4497V39.3623C124.619 37.3063 124.181 35.2764 123.354 33.3941C121.768 30.1141 119.396 27.2782 116.448 25.1398C111.824 21.7258 106.565 19.2696 100.981 17.9152C95.4002 16.4566 89.6587 15.7061 83.8912 15.6814C78.1468 15.6213 72.4193 16.3136 66.8543 17.7404C62.6724 18.7886 58.645 20.3785 54.8742 22.4697H54.8567C51.6864 24.1879 48.8652 26.4843 46.5387 29.2407C44.6725 31.4496 43.4003 34.0983 42.8421 36.9365Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M109.734 62.4323C113.056 60.9055 116.112 58.8551 118.785 56.3594C120.937 54.3783 122.617 51.9393 123.702 49.2221C124.318 47.6056 124.637 45.8912 124.644 44.1613V39.4496C124.644 40.2395 124.574 41.0278 124.435 41.8054C123.874 44.6409 122.595 47.2847 120.721 49.4838C118.396 52.2494 115.568 54.5472 112.385 56.2547C108.619 58.3429 104.598 59.9326 100.423 60.9839C94.8517 62.4108 89.1183 63.103 83.3681 63.043C77.6007 63.0171 71.8594 62.2667 66.2788 60.8092C60.6968 59.4621 55.4426 57.0052 50.8287 53.5845C47.8758 51.4542 45.4972 48.6241 43.9058 45.3477C43.0861 43.4577 42.6533 41.4224 42.6328 39.3621H42.6153V44.1611C42.8783 48.5907 44.788 52.7615 47.9689 55.8532C51.0466 58.9519 54.7261 61.3869 58.7803 63.008C62.7966 64.7175 66.9899 65.9758 71.2835 66.7599C79.9087 68.2906 88.7431 68.2018 97.3359 66.4982C101.619 65.6541 105.783 64.2886 109.734 62.4323Z"
        fill="#3C5D6E"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M3.27928 79.1567L45.9141 104.165V101.005L6.0149 77.6044L3.27928 79.1567Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M45.9141 96.835L6.01489 73.4167V77.6046L45.9141 101.005V96.835Z"
        fill="#896D02"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M3.27804 79.1573V71.8105L6.01579 73.416L45.9141 96.8351V104.164L3.27804 79.1573ZM0.540245 67.5699V80.2044L48.6519 108.422V95.788L0.540245 67.5699Z"
        fill="#FFCE10"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M48.6517 95.7879V108.422L61.3291 101.215V88.5807L48.6517 95.7879Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M61.3291 88.5807C57.7543 87.9699 54.5631 87.3242 51.1627 86.4692C47.9205 85.6674 44.7246 84.6887 41.5892 83.5375C38.6194 82.456 35.7242 81.1793 32.9226 79.7157C30.3251 78.3616 27.8459 76.7914 25.5114 75.0214C23.3727 73.4049 21.388 71.5939 19.5825 69.6117C17.9347 67.7931 16.4687 65.8176 15.2055 63.7133C14.5603 62.6488 13.9674 61.5144 13.4442 60.4151L13.2698 60.3277L0.540062 67.5698L48.6517 95.7879L61.3291 88.5807Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M3.27903 71.8115V79.1565L6.01465 77.6042V73.4162L3.27903 71.8115Z"
        fill="#DCAF00"
        stroke="#3D464D"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
    </g>
  </svg>
</template>
