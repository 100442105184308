<template>
  <div class="driven-group-1">
    <driven-el1
      @click="smallModalOpened = 1"
      class="driven-tv-item driven-el1"
    />
    <driven-el2
      @click="smallModalOpened = 2"
      class="driven-tv-item driven-el2"
    />
    <driven-el3
      @click="smallModalOpened = 3"
      class="driven-tv-item driven-el3 railway-animation-wrap robo2-animation-wrap"
    />
    <app-modal v-model="smallModalOpened" small>
      <template #content="{ value }">
        <div class="small-modal-content">
          <driven-el1 v-if="value === 1" class="small-modal-image" />
          <driven-el2 v-else-if="value === 2" class="small-modal-image" />
          <driven-el3 v-else-if="value === 3" class="small-modal-image" />
          <p class="small-modal-title">Application-Driven 6G</p>
          <p class="small-modal-text">
            Brain-Computer-Interface, emergency detection and teleconsulting
          </p>
          <button @click="clickReadMore" class="small-modal-btn">
            read more
          </button>
        </div>
      </template>
    </app-modal>
    <app-modal v-model="modalOpened">
      <template #content>
        <driven-modal-content />
      </template>
    </app-modal>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import DrivenEl1 from "@/components/Driven/DrivenEl1.vue";
import DrivenEl2 from "@/components/Driven/DrivenEl2.vue";
import DrivenEl3 from "@/components/Driven/DrivenEl3.vue";
import AppModal from "@/components/AppModal.vue";
import DrivenModalContent from "@/components/Driven/DrivenModalContent.vue";

const smallModalOpened = ref<number | false>(false);
const modalOpened = ref(false);

const clickReadMore = () => {
  smallModalOpened.value = false;
  modalOpened.value = true;
};
</script>

<style>
.driven-group-1 {
  position: relative;
  display: flex;
  width: 1000px;
  height: 550px;
  pointer-events: none;

  .driven-tv-item {
    position: absolute;
    transform: scale(0.3);
    overflow: visible;
    cursor: pointer;
    pointer-events: auto;
  }

  .driven-el1 {
    top: -500px;
    left: -150px;
  }
  .driven-el2 {
    top: -370px;
    left: -420px;
  }
  .driven-el3 {
    top: -100px;
    left: -380px;
  }
  @media (min-width: 1531px) {
    width: 1250px;
    height: 700px;
    .driven-tv-item {
      transform: scale(0.35);
    }
    .driven-el1 {
      top: -450px;
      left: 50px;
    }
    .driven-el2 {
      top: -280px;
      left: -300px;
    }
    .driven-el3 {
      top: 0;
      left: -350px;
    }
  }
  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    width: 1836px;
    height: 3000px;
    .driven-tv-item {
      transform: none;
    }
    .driven-el1 {
      top: 0;
      left: 109px;
    }
    .driven-el2 {
      top: 1050px;
      left: -38px;
    }
    .driven-el3 {
      top: 1880px;
      left: 160px;
    }
  }
}
</style>
