<template>
  <div class="tooltip" ref="tooltip"><slot name="default" /></div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { createPopper, Instance, VirtualElement } from "@popperjs/core";
import { SelectPlatformData } from "@/types";
import { Z_INDEX } from "@/constants";

const tooltip = ref<HTMLDivElement>();
const mouse = ref({ x: window.screen.width / 2, y: window.screen.height / 2 });

const boundingClientRect = computed(() => {
  const { x, y } = mouse.value;
  return {
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  } as DOMRect;
});

// eslint-disable-next-line no-undef
const props = defineProps<{
  elements: (Element | undefined)[];
}>();
// eslint-disable-next-line no-undef
const emit = defineEmits<{
  (e: "update:modelValue", data: SelectPlatformData): void;
}>();

const popperInstances = ref<Instance[]>([]);
const activeTriggers = ref<Element[]>([]);

const isVisible = ref(false);
const isFocused = ref(false);

const show = (instance: Instance) => {
  const element = (instance.state.elements.reference as VirtualElement)
    .contextElement;
  if (tooltip.value && element) {
    tooltip.value.setAttribute("data-show", "");
    isVisible.value = true;
    void instance.update();
    activeTriggers.value = [...activeTriggers.value, element];
  }
};

const hide = (instance: Instance, e: MouseEvent) => {
  const element = (instance.state.elements.reference as VirtualElement)
    .contextElement;
  if (e.target === element) {
    activeTriggers.value = activeTriggers.value.filter((e) => e !== element);
    if (!activeTriggers.value.length && tooltip.value) {
      isVisible.value = false;
      tooltip.value.removeAttribute("data-show");
    }
  }
};

onMounted(() => {
  document.addEventListener("mousemove", ({ clientX: x, clientY: y }) => {
    mouse.value = { x, y };
  });
});

watch(boundingClientRect, (value) => {
  if (isVisible.value) {
    for (const instance of popperInstances.value) {
      const reference = instance.state.elements.reference as VirtualElement;
      reference.getBoundingClientRect = () => value;
      instance.update();
    }
  }
});

watch(
  () => ({ elements: props.elements, tooltip: tooltip.value }),
  (value) => {
    if (
      value.tooltip &&
      value.elements.every((el) => el) &&
      !popperInstances.value.length
    ) {
      for (const element of value.elements) {
        if (element && value.tooltip) {
          const virtual: VirtualElement = {
            getBoundingClientRect: () => boundingClientRect.value,
            contextElement: element,
          };
          const newInstance = createPopper(virtual, value.tooltip, {
            placement: "left-start",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [20, 15],
                },
              },
            ],
          });

          popperInstances.value = [...popperInstances.value, newInstance];

          if ((element as HTMLElement).tabIndex > -1) {
            element?.addEventListener("focus", () => {
              isFocused.value = true;
            });
            element?.addEventListener("blur", () => {
              isFocused.value = false;
            });
          }

          element?.addEventListener("mouseenter", () => {
            show(newInstance);
          });

          element?.addEventListener("mouseleave", (e) => {
            hide(newInstance, e as MouseEvent);
          });
        }
      }
    }
  },
  { immediate: true }
);

watch(
  () => ({
    selected: isVisible.value || isFocused.value,
    focused: isFocused.value,
  }),
  (value) => {
    emit("update:modelValue", value);
  }
);

const zIndex = computed(() => Z_INDEX);
</script>

<style scoped>
.tooltip {
  background: #322f35;
  color: #f5eff7;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
  border-radius: 4px;
  padding: 4px 8px;
  z-index: v-bind(zIndex[ "tooltip"]);

  @media (orientation: portrait) and (min-width: 1836px) and (min-height: 3264px) {
    display: none;
  }
}

.tooltip[data-show] {
  opacity: 1;
}
</style>
