<template>
  <div class="dotted-lines">
    <div class="line-1">
      <div class="line-wrap line-wrap-left dots-1-animation">
        <svg
          width="567"
          height="332"
          viewBox="0 0 567 332"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_2151_2184"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="567"
            height="332"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M567 6.18919L554.339 13.5108L550.751 7.32161L563.412 0L567 6.18919ZM541.678 20.8324L529.017 28.154L525.429 21.9648L538.09 14.6432L541.678 20.8324ZM516.356 35.4756L503.695 42.7972L500.107 36.608L512.768 29.2864L516.356 35.4756ZM491.034 50.1187L478.373 57.4403L474.785 51.2512L487.446 43.9296L491.034 50.1187ZM465.712 64.7619L453.052 72.0835L449.463 65.8943L462.124 58.5727L465.712 64.7619ZM440.391 79.4051L427.73 86.7267L424.141 80.5375L436.802 73.2159L440.391 79.4051ZM415.069 94.0483L402.408 101.37L398.819 95.1807L411.48 87.8591L415.069 94.0483ZM389.747 108.691L377.086 116.013L373.498 109.824L386.159 102.502L389.747 108.691ZM364.425 123.335L351.764 130.656L348.176 124.467L360.837 117.145L364.425 123.335ZM339.103 137.978L326.442 145.299L322.854 139.11L335.515 131.789L339.103 137.978ZM313.781 152.621L301.12 159.943L297.532 153.753L310.193 146.432L313.781 152.621ZM288.46 167.264L275.799 174.586L272.21 168.397L284.871 161.075L288.46 167.264ZM263.138 181.907L250.477 189.229L246.888 183.04L259.549 175.718L263.138 181.907ZM237.816 196.551L225.155 203.872L221.566 197.683L234.227 190.361L237.816 196.551ZM212.494 211.194L199.833 218.515L196.245 212.326L208.906 205.005L212.494 211.194ZM187.172 225.837L174.511 233.158L170.923 226.969L183.584 219.648L187.172 225.837ZM161.85 240.48L149.189 247.802L145.601 241.612L158.262 234.291L161.85 240.48ZM136.528 255.123L123.867 262.445L120.279 256.256L132.94 248.934L136.528 255.123ZM111.206 269.766L98.5454 277.088L94.957 270.899L107.618 263.577L111.206 269.766ZM85.8845 284.41L73.2236 291.731L69.6352 285.542L82.2961 278.22L85.8845 284.41ZM60.5626 299.053L47.9017 306.374L44.3133 300.185L56.9742 292.864L60.5626 299.053ZM35.2408 313.696L22.5798 321.018L18.9914 314.828L31.6523 307.507L35.2408 313.696ZM9.91888 328.339L3.58841 332L0 325.811L6.33047 322.15L9.91888 328.339Z"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_2151_2184)">
            <path d="M-35 -134H602V396H-35V-134Z" fill="#09807D" />
          </g>
        </svg>
      </div>
    </div>
    <div class="line-2">
      <div class="line-wrap dots-2-animation">
        <svg
          width="371"
          height="218"
          viewBox="0 0 371 218"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_2151_2183"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="0"
            width="365"
            height="218"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M367.356 211.412L354.705 203.996L351.085 210.172L363.736 217.588L367.356 211.412ZM342.054 196.58L329.403 189.164L325.782 195.34L338.434 202.756L342.054 196.58ZM316.752 181.748L304.101 174.332L300.48 180.508L313.131 187.924L316.752 181.748ZM291.45 166.915L278.798 159.499L275.178 165.676L287.829 173.092L291.45 166.915ZM266.147 152.083L253.496 144.667L249.876 150.844L262.527 158.26L266.147 152.083ZM240.845 137.251L228.194 129.835L224.573 136.011L237.225 143.428L240.845 137.251ZM215.543 122.419L202.892 115.003L199.271 121.179L211.922 128.595L215.543 122.419ZM190.24 107.587L177.589 100.171L173.969 106.347L186.62 113.763L190.24 107.587ZM164.938 92.755L152.287 85.3389L148.667 91.5152L161.318 98.9312L164.938 92.755ZM139.636 77.9229L126.985 70.5068L123.364 76.6831L136.015 84.0992L139.636 77.9229ZM114.334 63.0908L101.682 55.6748L98.0619 61.851L110.713 69.2671L114.334 63.0908ZM89.0313 48.2587L76.3802 40.8427L72.7597 47.0189L85.4108 54.435L89.0313 48.2587ZM63.729 33.4266L51.0779 26.0106L47.4574 32.1868L60.1085 39.6029L63.729 33.4266ZM38.4267 18.5945L25.7756 11.1785L22.1551 17.3547L34.8062 24.7708L38.4267 18.5945ZM13.1244 3.76242L6.79885 0.0543997L3.17836 6.23066L9.50393 9.93868L13.1244 3.76242Z"
              fill="#00C1BC"
            />
          </mask>
          <g mask="url(#mask0_2151_2183)">
            <rect
              width="635"
              height="477"
              transform="matrix(1 0 0 -1 -105.891 394.667)"
              fill="#09807D"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<style>
.dotted-lines {
  position: relative;
  width: 1100px;
  height: 332px;
  pointer-events: none;

  .line-wrap {
    width: 65%;
    overflow: hidden;
  }
  .line-wrap-left {
    width: 100%;
  }
  .line-1 {
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0;
  }
  .line-2 {
    display: inline-flex;
    position: absolute;
    top: 12px;
    left: 640px;
  }
}
</style>
<script setup lang="ts"></script>
