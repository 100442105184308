<template>
  <div class="info-modal-content">
    <p class="info-modal-title">Distributed AI</p>
    <div>
      <p class="info-modal-text info-modal-text-bolt">
        With the success of several concepts in deep learning, real time
        computer vision becomes an essential part in automation industry. In
        several iterations a deep neural network family called YOLO presented
        fast models for object detection focusing on the trade-offs between
        computational costs and model accuracy.
      </p>
    </div>
    <div>
      <img
        src="@/assets/images/groups/logic/group1/01.png"
        alt=""
        width="100%"
      />
    </div>
    <div>
      <p class="info-modal-text">
        Consider an use case in which mobile vehicles record image data, either
        a small and less accurate model can be used on an embedded device or the
        data get compressed and sent to a data center for further predictions.
        Another approach is being investigated through our work in which the
        deep neural network is split into two sequential models.
      </p>
      <p class="info-modal-text">
        The first part is used to compress the image data and is integrated on
        an embedded device with less computational power. Meanwhile the second
        part is designed to be placed on data center AI accelerators. The aim is
        to minimize the overall computational demand for the embedded device,
        reduce the load for communication network and maximize model accuracy by
        a larger model on the data center part.
      </p>
      <p class="info-modal-text">
        We will present a novel model architecture specifically designed for
        this distributed approach, which we call Distributed Detector (DiDet).
        Compared to classical JPEG image compression with a quality set to 25%
        DiDet statically compresses an RGB image from size of 640 by 640 pixels
        down to less than 4.2% of the original data size for a single image and
        achieves an accuracy of up to 16.6% for mAP@0.5 and 14.7% for
        mAP@0.5:0.95 higher.
      </p>
      <p class="info-modal-text">
        Our final model comes in two variants, one compressing an image down to
        50 KiB (4.2%) and the second one to 25 KiB (2.1%).
      </p>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
